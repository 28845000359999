import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import formatDatetime from "../../../services/utils/format_datetime";

export default class CustomerInformation extends React.Component {

    static get propTypes() {
        return {
            customer: PropTypes.object.isRequired,
            removeCustomer: PropTypes.func,
            setMarketingPermissionForCustomer: PropTypes.func
        };
    }

    render() {

        const {
            customer_tags,
            customer_name,
            customer_surname,
            customer_gender,
            customer_phone,
            customer_email,
            customer_birthdate,
            customer_favorite_wifi_zone,
            customer_is_parent,

            customer_accepts_kvkk,
            customer_accepts_kvkk_source,
            customer_accepts_kvkk_at,

            customer_allows_call,
            customer_allows_call_source,
            customer_allows_call_at,
            
            customer_allows_sms,
            customer_allows_sms_source,
            customer_allows_sms_at,

            customer_allows_email,
            customer_allows_email_source,
            customer_allows_email_at,

            //customer_form_id,
            //customer_areas_of_interest,

            customer_added_by,
            createdAt,
        } = this.props.customer;

        return (
            <div className="tabContainer">
                <div className="customerTags">
                    {
                        _.map(customer_tags, ({_id, customer_tag}) => (
                            <span className="tag is-rounded" key={_id}>{customer_tag.tag_type_name}</span>
                        ))
                    }
                </div>
                <div className="columns">
                    <div className="column">
                        <ul>

                            <li>
                                <h5>İSİM:</h5>
                                <span>{customer_name}</span>
                            </li>
                            <li>
                                <h5>SOYİSİM:</h5>
                                <span>{customer_surname}</span>
                            </li>
                            <li>
                                <h5>CİNSİYET:</h5>
                                {customer_gender === "male" && (<span>ERKEK</span>)}
                                {customer_gender === "female" && (<span>KADIN</span>)}
                            </li>

                            <li>
                                <h5>DOĞUM TARİHİ:</h5>
                                <span>{customer_birthdate}</span>
                            </li>
                            <li>
                                <h5>FAVORİ BÖLGE:</h5>
                                <span>{customer_favorite_wifi_zone}</span>
                            </li>
                            <li>
                                <h5>EBEVEYN ?:</h5>
                                <span>{customer_is_parent ? "EVET" : "BİLİNMİYOR"}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="column">
                        <ul>
                            <li>
                                <h5>TELEFON:</h5>
                                <span>{customer_phone}</span>
                            </li>
                            <li>
                                <h5>E-POSTA:</h5>
                                <span>{customer_email}</span>
                            </li>
                            {/* <li>
                                <h5>FORM ID:</h5>
                                <span>{customer_form_id}</span>
                            </li> */}
                            {/* <li>
                                <h5>İLGİ ALANLARI:</h5>
                                <span>{customer_areas_of_interest}</span>
                            </li> */}
                            <li>
                                <h5>Oluşturuldu:</h5>
                                <span>{`${customer_added_by} (${formatDatetime(createdAt)})`}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="profileSubSection">
                    <h5>İLETİŞİM İZİNLERİ / KVKK:</h5>
                    <div className="columns">
                        <div className="column">
                            <ul>
                                <li>
                                    <h6>KVKK onaylı mı?:</h6>
                                    <span>{`${customer_accepts_kvkk ? "EVET" : "HAYIR"} (${customer_accepts_kvkk_source})`}</span>
                                </li>
                                {
                                    customer_allows_call_source && (
                                        <li>
                                            <h6>Arama izni?</h6>
                                            <span>{`${customer_allows_call ? "EVET" : "HAYIR"} (${customer_allows_call_source})`}</span>
                                        </li>
                                    )
                                }
                                
                                <li>
                                    <h6>SMS izni?</h6>
                                    <span>{`${customer_allows_sms ? "EVET" : "HAYIR"} (${customer_allows_sms_source})`}</span>
                                </li>
                                <li>
                                    <h6>E-POSTA izni?:</h6>
                                    <span>{`${customer_allows_email ? "EVET" : "HAYIR"} (${customer_allows_email_source})`}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="column">
                            <ul>
                                <li>
                                    <h6>KVKK onay tarihi:</h6>
                                    <span>{formatDatetime(customer_accepts_kvkk_at)}</span>
                                </li>
                                {
                                    customer_allows_call_source && (
                                        <li>
                                            <h6>Arama izin tarihi:</h6>
                                            <span>{formatDatetime(customer_allows_call_at)}</span>
                                        </li>
                                    )
                                }
                                
                                <li>
                                    <h6>SMS izin tarihi:</h6>
                                    <span>{formatDatetime(customer_allows_sms_at)}</span>
                                </li>
                                <li>
                                    <h6>E-POSTA izin tarihi:</h6>
                                    <span>{formatDatetime(customer_allows_email_at)}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="profile-action-buttons">
                    <button
                        type="button"
                        className="button is-danger"
                        onClick={this.props.removeCustomer}>
                        Bu Müşteriyi Sil
                    </button>
                    {
                        customer_allows_call_source && 
                        customer_allows_call ?
                            <button type="button" className="button is-danger" onClick={() => {
                                this.props.setMarketingPermissionForCustomer("call", false);
                            }}>
                                Arama iznini kaldır
                            </button>
                            :
                            customer_allows_call_source && 
                            <button type="button" className="button is-success" onClick={() => {
                                this.props.setMarketingPermissionForCustomer("call", true);
                            }}>
                                Arama izni ekle
                            </button>
                    }
                    {
                        customer_allows_sms ?
                            <button type="button" className="button is-danger" onClick={() => {
                                this.props.setMarketingPermissionForCustomer("sms", false);
                            }}>
                                SMS iznini kaldır
                            </button>
                            :
                            <button type="button" className="button is-success" onClick={() => {
                                this.props.setMarketingPermissionForCustomer("sms", true);
                            }}>
                                SMS izni ekle
                            </button>
                    }

                    {
                        customer_allows_email ?
                            <button type="button" className="button is-danger" onClick={() => {
                                this.props.setMarketingPermissionForCustomer("email", false);
                            }}>
                                EMAIL iznini kaldır
                            </button>
                            :
                            <button type="button" className="button is-success" onClick={() => {
                                this.props.setMarketingPermissionForCustomer("email", true);
                            }}>
                                EMAIL izni ekle
                            </button>
                    }
                </div>
            </div>
        );
    }
}
