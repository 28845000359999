/**
 * PushNotificationReport Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import getPrettyNumber from "../../../services/utils/get_pretty_number";

class PushNotificationReport extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        pushNotification: null
    };

    pushNotificationId = this.props.match.params["pushnotificationid"];

    componentDidMount() {

        this.getPushNotitificationAsync(); // Update subheader statically
    }

    async getPushNotitificationAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/marketing/push-notifications/${this.pushNotificationId}/report`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                pushNotification: data.pushNotification,
                report: data.report
            }));

            this.props.setBreadcrumb([{
                url: "/marketing/push-notifications",
                name: "Push Gönderimi"
            }, {
                url: `/marketing/push-notifications/${this.pushNotificationId}/report`,
                name: "Rapor"
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    render() {

        const pushNotification = this.state.pushNotification;
        const report = this.state.report;

        if (!pushNotification || !report) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-four-fifths-fullhd">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    {`RAPOR: ${pushNotification.push_notification_text_tr}`}
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Rapor, gönderimi takip eden 15 dakika içinde oluşmaktadır.",
                                "Anlık olarak veriler çekilmektedir."
                            ]}
                            />
                        </div>

                        <>
                            <div className="marketingReportList">
                                <h5>Gönderim:</h5>
                                <p>{`TR: ${pushNotification.push_notification_text_tr}`}</p>
                                <p>{`EN: ${pushNotification.push_notification_text_en}`}</p>
                            </div>


                            <div className="dashboard">
                                <h3 className="sectionTitle">Genel Bakış</h3>
                                <div className="columns is-multiline">
                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                        <div className="box dashboard-box">
                                            <div className="heading" lang="tr">Toplam Gönderim</div>
                                            <div className="title">{`${getPrettyNumber(pushNotification.push_notification_actual)} Cihaz`}</div>
                                        </div>
                                    </div>
                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                        <div className="box dashboard-box">
                                            <div className="heading" lang="tr">Başarılı Gönderim</div>
                                            <div className="title">{`${getPrettyNumber(report.successful)}`}</div>
                                        </div>
                                    </div>
                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                        <div className="box dashboard-box">
                                            <div className="heading" lang="tr">Hatalı Gönderim</div>
                                            <div className="title">{`${getPrettyNumber(report.errored)}`}</div>
                                        </div>
                                    </div>
                                </div>

                                <h3 className="sectionTitle">Platform Başarılı Gönderimleri</h3>
                                <div className="columns is-multiline">
                                    {
                                        report.platform_delivery_stats.ios &&
                                        <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                            <div className="box dashboard-box">
                                                <div className="heading" lang="tr">iOS</div>
                                                <div className="title">{`${getPrettyNumber(report.platform_delivery_stats.ios.successful)} Cihaz`}</div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        report.platform_delivery_stats.android &&
                                        <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                            <div className="box dashboard-box">
                                                <div className="heading" lang="tr">Android</div>
                                                <div className="title">{`${getPrettyNumber(report.platform_delivery_stats.android.successful)} Cihaz`}</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </>
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(PushNotificationReport);


