/**
 * Format type texts
 * @param str
 * @returns {*}
 */
export default (str) => {

    if (!str) {
        return str;
    }

    return str
        .replace(/\s\s+/g, " ")
        .replace(/[iİ]/g, "I")
        .replace(/[öÖ]/g, "O")
        .replace(/[üÜ]/g, "U")
        .replace(/[ğĞ]/g, "G")
        .replace(/[şŞ]/g, "S")
        .replace(/[çÇ]/g, "C")
        .toLocaleUpperCase()
        .replace(/[^ A-Z0-9-]+/g, "");
};
