/**
 * CustomerCheck Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";

import {CustomerForm, FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class CustomerUpdate extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        entryContext: PropTypes.shape({
            entryCustomer: PropTypes.shape.isRequired,
            entryEventType: PropTypes.shape.isRequired,
            setEntryCustomer: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {
        isLoading: false,
        isUpdateSuccess: false
    };

    componentDidMount() {

        // check if actual type matches.
        const entryType = this.props.entryContext.entryEventType;
        const customer = this.props.entryContext.entryCustomer;

        if (!entryType) {
            this.props.history.replace("/customer-entry");
            return;
        }

        if (!customer || !customer.customer_phone) {
            this.props.history.replace("/customer-entry/check");
            return;
        }

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/customer-entry",
            name: "Ziyaretçi Kayıt"
        }, {
            url: "/customer-entry/check",
            name: "Kayıt Başlangıç",
        }, {
            url: `/customer-entry/update/${customer._id}`,
            name: "Bilgi Girişi",
        }]);
    }

    updateAsync = async (payload, {setSubmitting}) => {

        // START LOADING
        this.props.setError(null);
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // Checking State
        this.setState((prevState) => ({
            ...prevState,
            isLoading: true
        }));

        const customer = this.props.entryContext.entryCustomer;

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/customer-entry/update/${customer._id}`,
                method: "POST",
                payload
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.entryContext.setEntryCustomer(data.customer);

            this.setState((prevState) => ({
                ...prevState,
                isLoading: false,
                isUpdateSuccess: true
            }));
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);

            // Checking State
            this.setState((prevState) => ({
                ...prevState,
                isLoading: false
            }));
            this.props.setError(e);
        }
    };

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.updateAsync(formData, formikBag);
    };

    render() {

        if (this.state.isUpdateSuccess) {
            return (
                <Redirect to="/customer-entry/success/update-customer"/>
            );
        }

        if (!this.props.entryContext.entryEventType || !this.props.entryContext.entryCustomer) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    {this.props.entryContext.entryEventType.event_type_name}
                                    {" (Mevcut Kayıt)"}
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Ziyaretçi CRM'e daha önceden kayıtlı.",
                                "Ziyaretçinin Profil bilgilerini aşağıdaki alandan güncelleyebilirsiniz",
                                "Veya sorgulama ekranına geri dönebilirsiniz."
                            ]}
                            />
                        </div>

                        <button
                            type="button"
                            disabled={this.state.isLoading}
                            style={{marginBottom: "1rem"}}
                            className={`button is-fullwidth ${this.state.isLoading ? "is-loading": ""}`}
                            onClick={() => {
                                this.props.entryContext.setEntryCustomer(null, () => {
                                    this.props.history.replace("/customer-entry/check");
                                });
                            }}>
                            <span className="icon is-small">
                                <i className="fa fa-arrow-left"/>
                            </span>
                            <span>Geri Dönün ve Başka Sorguya Geçin</span>
                        </button>

                        <div className="profileUpdateHeader">
                            <h2>Profili Güncelle</h2>
                        </div>

                        <CustomerForm
                            type="Update"
                            customer={this.props.entryContext.entryCustomer}
                            eventType={this.props.entryContext.entryEventType}
                            showConfirm={this.props.showConfirm}
                            handleSubmit={this.handleSubmit}
                            submitText="Güncelle"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CustomerUpdate);


