/**
 * Coupon Campaign Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import _ from "lodash";
import * as Yup from "yup";
import Memoize from "memoize-one";
import Moment from "moment-timezone";

import {FormElements} from "../../../components";
import apiCall from "../../../services/api";
import withContext from "../../../context/withContext";
import CampaignTargetTypes from "../../../../../common/campaign_target_types";
import CouponCodeSourceTypes from "../../../../../common/coupon_code_source_types";
import dateCheck from "../../../services/utils/date_check";

class CouponCampaignForm extends PureComponent {

    static propTypes = {
        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        isEdit: PropTypes.bool,
        couponCampaign: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    };

    state = {
        providers: null,
        types: null
    };

    getCouponProvidersForSelect = Memoize((providers) => providers.map((provider) => ({
        value: provider._id,
        label: provider.coupon_provider_name
    })));

    getCouponCampaignTypeForSelect = Memoize((types) => types.map((type) => ({
        value: type._id,
        label: type.coupon_campaign_type_name
    })));

    componentDidMount() {

        this.getItemsForCouponProviders();
        this.getItemsForCouponCampaignTypes();
    }

    async getItemsForCouponProviders() {

        this.props.loadingContext.setIsLoading(true);

        try {
            const {data} = await apiCall({
                url: "/campaign/coupon-providers"
            });

            this.setState((prevState) => ({
                ...prevState,
                providers: data || [],
            }));
        }
        catch (e) {
            console.error(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    async getItemsForCouponCampaignTypes() {

        this.props.loadingContext.setIsLoading(true);

        try {
            const {data} = await apiCall({
                url: "/campaign/types"
            });

            this.setState((prevState) => ({
                ...prevState,
                types: data || [],
            }));
        }
        catch (e) {
            console.error(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    render() {

        const {couponCampaign, isEdit} = this.props;
        const {providers, types} = this.state;

        if (!couponCampaign || !providers || !types) {
            return null;
        }

        const selectProviders = this.getCouponProvidersForSelect(providers);
        const selectCampaignTypes = this.getCouponCampaignTypeForSelect(types);

        const initialValues = {
            coupon_campaign_name_tr: couponCampaign.coupon_campaign_name_tr || "",
            coupon_campaign_name_en: couponCampaign.coupon_campaign_name_en || "",
            coupon_campaign_target_type: couponCampaign.coupon_campaign_target_type || "",
            coupon_campaign_type: couponCampaign.coupon_campaign_type || "",
            coupon_campaign_provider: couponCampaign.coupon_campaign_provider || undefined,
            coupon_campaign_coupon_source_type: couponCampaign.coupon_campaign_coupon_source_type || undefined,
            // coupon_campaign_image_1: couponCampaign.coupon_campaign_image_1,
            // coupon_campaign_image_2: couponCampaign.coupon_campaign_image_2,
            // coupon_campaign_image_1_en: couponCampaign.coupon_campaign_image_1_en,
            // coupon_campaign_image_2_en: couponCampaign.coupon_campaign_image_2_en,
            coupon_campaign_description_tr: couponCampaign.coupon_campaign_description_tr || "",
            coupon_campaign_description_en: couponCampaign.coupon_campaign_description_en || "",
            coupon_campaign_code_description_tr: couponCampaign.coupon_campaign_code_description_tr || "",
            coupon_campaign_code_description_en: couponCampaign.coupon_campaign_code_description_en || "",
            coupon_campaign_start_datetime: couponCampaign.coupon_campaign_start_datetime || "",
            coupon_campaign_end_datetime: couponCampaign.coupon_campaign_end_datetime || "",
            coupon_campaign_coupon_active_start_date: couponCampaign.coupon_campaign_coupon_active_start_date || "",
            coupon_campaign_coupon_active_end_date: couponCampaign.coupon_campaign_coupon_active_end_date || "",
            coupon_campaign_agreement_pdf_tr: couponCampaign.coupon_campaign_agreement_pdf_tr || "",
            coupon_campaign_agreement_pdf_en: couponCampaign.coupon_campaign_agreement_pdf_en || "",
            coupon_campaign_hide_in_list: !!couponCampaign.coupon_campaign_hide_in_list,
            coupon_campaign_show_in_tr: _.isUndefined(couponCampaign.coupon_campaign_show_in_tr) ? true : couponCampaign.coupon_campaign_show_in_tr,
            coupon_campaign_show_in_en: _.isUndefined(couponCampaign.coupon_campaign_show_in_en) ? true : couponCampaign.coupon_campaign_show_in_en,
            coupon_campaign_event_type_allowed: !!couponCampaign.coupon_campaign_event_type_allowed,
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.props.handleSubmit}
                validationSchema={() => Yup.object().shape({
                    coupon_campaign_name_tr: Yup.string()
                        .min(2, "Kampanya Başlığı en az iki karakter içermelidir.")
                        .required("Kampanya Başlığı zorunlu bir alandır."),
                    coupon_campaign_name_en: Yup.string()
                        .min(2, "Kampanya Başlığı en az iki karakter içermelidir.")
                        .required("Kampanya Başlığı zorunlu bir alandır."),
                    coupon_campaign_type: Yup.string()
                        .required("Kampanya Türü zorunlu bir alandır."),
                    coupon_campaign_target_type: Yup.string()
                        .required("Kampanya Hedef Türü zorunlu bir alandır."),
                    coupon_campaign_coupon_source_type: Yup.string()
                        .required("Kupon Kaynağı zorunlu bir alandır."),
                    coupon_campaign_provider: Yup
                        .string()
                        .when("coupon_campaign_target_type", {
                            is: "merchant",
                            then: Yup.string().required("Kupon Sağlayıcısı zorunlu alandır.")
                        }),
                    // coupon_campaign_image_1: Yup.mixed()
                    //     .required("Kampanya Görseli (2400x1600) TR zorunlu bir alandır."),
                    // coupon_campaign_image_1_en: Yup.mixed()
                    //     .required("Kampanya Görseli (2400x1600) EN zorunlu bir alandır."),
                    // coupon_campaign_image_2: Yup.mixed()
                    //     .required("Kampanya Görseli (2400x1000) TR zorunlu bir alandır."),
                    // coupon_campaign_image_2_en: Yup.mixed()
                    //     .required("Kampanya Görseli (2400x1000) EN zorunlu bir alandır."),
                    coupon_campaign_start_datetime: Yup.date()
                        .required("Yayına Alma Tarihi zorunlu bir alandır.")
                        .test("dateTimeStructureCheck", "Geçersiz bir tarih girdiniz. GG/AA/YYYY SS:dd formatında olmalıdır.", dateCheck.dateTimeStructureCheck),
                    coupon_campaign_end_datetime: Yup.date()
                        .required("Yayından Kaldırma Tarihi zorunlu bir alandır.")
                        .test("dateTimeStructureCheck", "Geçersiz bir tarih girdiniz. GG/AA/YYYY SS:dd formatında olmalıdır.", dateCheck.dateTimeStructureCheck)
                        .test("dateTimeRangeCheck", "Lütfen ileri tarihli bir seçim yapınız.", (dateCheck.dateTimeIsInFutureCheck))
                        .min(
                            Yup.ref("coupon_campaign_start_datetime"), "Lütfen Yayına Alma Tarihinden ileri tarihli bir seçim yapınız."
                        ),
                    coupon_campaign_coupon_active_start_date: Yup.string()
                        .required("Kupon Geçerliliği - Başlangıç Tarihi zorunlu bir alandır."),
                    coupon_campaign_coupon_active_end_date: Yup.string()
                        .required("Kupon Geçerliliği - Bitiş Tarihi zorunlu bir alandır."),
                })}
                render={({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    setSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form autoComplete="off" className="venuexForm couponCampaignForm" onSubmit={handleSubmit}>

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            error={touched.coupon_campaign_name_tr && errors.coupon_campaign_name_tr}
                            inputName="coupon_campaign_name_tr"
                            displayName="Kampanya Başlığı (TR) *"
                            isSubmitting={isSubmitting}
                            value={values.coupon_campaign_name_tr || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            error={touched.coupon_campaign_name_en && errors.coupon_campaign_name_en}
                            inputName="coupon_campaign_name_en"
                            displayName="Kampanya Başlığı (EN) *"
                            isSubmitting={isSubmitting}
                            value={values.coupon_campaign_name_en || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormElements.SelectInput
                            isDisabled={isSubmitting || isEdit}
                            displayName="Kampanya Türü *"
                            inputName="coupon_campaign_type"
                            error={touched.coupon_campaign_type && errors.coupon_campaign_type}
                            options={selectCampaignTypes}
                            value={values.coupon_campaign_type}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "No result"}
                            placeholder="Kampanya Türü Seçiniz"
                            onChange={(selectedOption) => {

                                setFieldValue("coupon_campaign_type", selectedOption.value);
                                setFieldTouched("coupon_campaign_type");

                                setFieldValue("coupon_campaign_provider", undefined);
                            }}
                            onBlur={() => {
                                setFieldTouched("coupon_campaign_type", true);
                            }}
                        />

                        <FormElements.SelectInput
                            isDisabled={isSubmitting || isEdit}
                            displayName="Kampanya Hedef Türü *"
                            inputName="coupon_campaign_target_type"
                            error={touched.coupon_campaign_target_type && errors.coupon_campaign_target_type}
                            options={CampaignTargetTypes}
                            value={values.coupon_campaign_target_type}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "No result"}
                            placeholder="Kampanya Hedef Türü Seçiniz"
                            onChange={(selectedOption) => {

                                setFieldValue("coupon_campaign_target_type", selectedOption.value);
                                setFieldTouched("coupon_campaign_target_type");

                                setFieldValue("coupon_campaign_provider", undefined);
                            }}
                            onBlur={() => {
                                setFieldTouched("coupon_campaign_target_type", true);
                            }}
                        />

                        {
                            values.coupon_campaign_target_type === "merchant" &&
                            <FormElements.SelectInput
                                isDisabled={isSubmitting || isEdit}
                                displayName="Kupon Sağlayıcısı *"
                                inputName="coupon_campaign_provider"
                                error={touched.coupon_campaign_provider && errors.coupon_campaign_provider}
                                options={selectProviders}
                                value={values.coupon_campaign_provider}
                                classNamePrefix="react-select"
                                noOptionsMessage={() => "No result"}
                                placeholder="Kupon Sağlayıcı Seçiniz."
                                onChange={(selectedOption) => {

                                    setFieldValue("coupon_campaign_provider", selectedOption.value);
                                    setFieldTouched("coupon_campaign_provider");

                                }}
                                onBlur={() => {
                                    setFieldTouched("coupon_campaign_provider", true);
                                }}
                            />
                        }

                        <FormElements.SelectInput
                            isDisabled={isSubmitting || isEdit}
                            displayName="Kupon Kaynağı *"
                            inputName="coupon_campaign_coupon_source_type"
                            error={touched.coupon_campaign_coupon_source_type && errors.coupon_campaign_coupon_source_type}
                            options={CouponCodeSourceTypes}
                            value={values.coupon_campaign_coupon_source_type}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "No result"}
                            placeholder="Kupon Kodu Türünü Seçiniz"
                            onChange={(selectedOption) => {

                                setFieldValue("coupon_campaign_coupon_source_type", selectedOption.value);
                                setFieldTouched("coupon_campaign_coupon_source_type");
                            }}
                            onBlur={() => {
                                setFieldTouched("coupon_campaign_coupon_source_type", true);
                            }}
                        />

                        {/* <div className="columns">
                            <div className="column">
                                <FormElements.ImageInput
                                    key="coupon_campaign_image_2"
                                    inputName="coupon_campaign_image_2"
                                    modelName="CouponCampaign"
                                    displayName="Kampanya Görseli - 2400x1000 (TR) *"
                                    imageThumbnailSize={{
                                        width: 600,
                                        height: 400
                                    }}
                                    value={values.coupon_campaign_image_2 || {}}
                                    isLoading={isSubmitting}
                                    setImage={(images) => {
                                        setFieldValue("coupon_campaign_image_2", images);
                                        setFieldTouched("coupon_campaign_image_2", true);
                                    }}
                                    setError={(error) => {
                                        console.error(error);
                                    }}
                                    uploadInProgress={setSubmitting}
                                    error={errors["coupon_campaign_image_2"]}
                                />
                            </div>
                            <div className="column">
                                <FormElements.ImageInput
                                    key="coupon_campaign_image_2_en"
                                    inputName="coupon_campaign_image_2_en"
                                    modelName="CouponCampaign"
                                    displayName="Kampanya Görseli - 2400x1000 (EN) *"
                                    imageThumbnailSize={{
                                        width: 600,
                                        height: 400
                                    }}
                                    value={values.coupon_campaign_image_2_en || {}}
                                    isLoading={isSubmitting}
                                    setImage={(images) => {
                                        setFieldValue("coupon_campaign_image_2_en", images);
                                        setFieldTouched("coupon_campaign_image_2_en", true);
                                    }}
                                    setError={(error) => {
                                        console.error(error);
                                    }}
                                    uploadInProgress={setSubmitting}
                                    error={errors["coupon_campaign_image_2_en"]}
                                />
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column">
                                <FormElements.ImageInput
                                    key="coupon_campaign_image_1"
                                    inputName="coupon_campaign_image_1"
                                    modelName="CouponCampaign"
                                    displayName="Kampanya Görseli - 2400x1600 (TR) *"
                                    imageThumbnailSize={{
                                        width: 600,
                                        height: 400
                                    }}
                                    value={values.coupon_campaign_image_1 || {}}
                                    isLoading={isSubmitting}
                                    setImage={(images) => {
                                        setFieldValue("coupon_campaign_image_1", images);
                                        setFieldTouched("coupon_campaign_image_1", true);
                                    }}
                                    setError={(error) => {
                                        console.error(error);
                                    }}
                                    uploadInProgress={setSubmitting}
                                    error={errors["coupon_campaign_image_1"]}
                                />
                            </div>
                            <div className="column">
                                <FormElements.ImageInput
                                    key="coupon_campaign_image_1_en"
                                    inputName="coupon_campaign_image_1_en"
                                    modelName="CouponCampaign"
                                    displayName="Kampanya Görseli - 2400x1600 (EN) *"
                                    imageThumbnailSize={{
                                        width: 600,
                                        height: 400
                                    }}
                                    value={values.coupon_campaign_image_1_en || {}}
                                    isLoading={isSubmitting}
                                    setImage={(images) => {
                                        setFieldValue("coupon_campaign_image_1_en", images);
                                        setFieldTouched("coupon_campaign_image_1_en", true);
                                    }}
                                    setError={(error) => {
                                        console.error(error);
                                    }}
                                    uploadInProgress={setSubmitting}
                                    error={errors["coupon_campaign_image_1_en"]}
                                />
                            </div>
                        </div> */}

                        <FormElements.DateInput
                            autoComplete="off"
                            displayName="Yayına Alma Tarihi *"
                            hint="Belirtilen tarih geldiğinde kampanya otomatik olarak YAYIN lanır."
                            hintAsLabelExplanation
                            inputName="coupon_campaign_start_datetime"
                            placeholder="GG/AA/YYYY HH:mm"
                            error={touched.coupon_campaign_start_datetime && errors.coupon_campaign_start_datetime}
                            isSubmitting={isSubmitting}
                            selectedValue={values.coupon_campaign_start_datetime}
                            onChange={(selectedDate) => {
                                setFieldValue("coupon_campaign_start_datetime", selectedDate);
                                setFieldTouched("coupon_campaign_start_datetime");
                            }}
                            onBlur={() => {
                                setFieldTouched("coupon_campaign_start_datetime", true);
                            }}
                            minDate={Moment().add(15, "minutes").toDate()}
                            maxDate={Moment().add(1, "years").toDate()}
                            selectsEnd
                            includeTime
                        />

                        <FormElements.DateInput
                            autoComplete="off"
                            displayName="Yayından Kaldırma Tarihi *"
                            hint="Belirtilen tarih geldiğinde kampanya otomatik olarak ARŞİV lenir."
                            hintAsLabelExplanation
                            inputName="coupon_campaign_end_datetime"
                            placeholder="GG/AA/YYYY HH:mm"
                            error={touched.coupon_campaign_end_datetime && errors.coupon_campaign_end_datetime}
                            isSubmitting={isSubmitting}
                            selectedValue={values.coupon_campaign_end_datetime}
                            onChange={(selectedDate) => {
                                setFieldValue("coupon_campaign_end_datetime", selectedDate);
                                setFieldTouched("coupon_campaign_end_datetime");
                            }}
                            onBlur={() => {
                                setFieldTouched("coupon_campaign_end_datetime", true);
                            }}
                            minDate={Moment().add(1, "days").toDate()}
                            maxDate={Moment().add(1, "years").toDate()}
                            selectsEnd
                            includeTime
                        />

                        <FormElements.DateInput
                            autoComplete="off"
                            displayName="Kupon Geçerliliği - Başlangıç Tarihi *"
                            inputName="coupon_campaign_coupon_active_start_date"
                            hint="UI üzerinde gösterilir. Müşteri hangi tarihten itibaren bu kuponu markada kullanabileceğini görür."
                            hintAsLabelExplanation
                            placeholder="GG/AA/YYYY"
                            error={touched.coupon_campaign_coupon_active_start_date && errors.coupon_campaign_coupon_active_start_date}
                            isSubmitting={isSubmitting}
                            selectedValue={values.coupon_campaign_coupon_active_start_date}
                            onChange={(selectedDate) => {
                                setFieldValue("coupon_campaign_coupon_active_start_date", selectedDate);
                                setFieldTouched("coupon_campaign_coupon_active_start_date");
                            }}
                            onBlur={() => {
                                setFieldTouched("coupon_campaign_coupon_active_start_date", true);
                            }}
                            minDate={Moment().subtract(1, "months").toDate()}
                            maxDate={Moment().add(3, "months").toDate()}
                            selectsEnd
                        />

                        <FormElements.DateInput
                            autoComplete="off"
                            displayName="Kupon Geçerliliği - Bitiş Tarihi *"
                            inputName="coupon_campaign_coupon_active_end_date"
                            placeholder="GG/AA/YYYY"
                            hint="UI üzerinde gösterilir. Müşteri hangi tarihe kadar bu kuponu markada kullanabileceğini görür."
                            hintAsLabelExplanation
                            error={touched.coupon_campaign_coupon_active_end_date && errors.coupon_campaign_coupon_active_end_date}
                            isSubmitting={isSubmitting}
                            selectedValue={values.coupon_campaign_coupon_active_end_date}
                            onChange={(selectedDate) => {
                                setFieldValue("coupon_campaign_coupon_active_end_date", selectedDate);
                                setFieldTouched("coupon_campaign_coupon_active_end_date");
                            }}
                            onBlur={() => {
                                setFieldTouched("coupon_campaign_coupon_active_end_date", true);
                            }}
                            minDate={Moment().add(1, "day").toDate()}
                            maxDate={Moment().add(1, "year").toDate()}
                            selectsEnd
                        />

                        {/* <FormElements.TextArea
                            type="text"
                            autoComplete="off"
                            error={touched.coupon_campaign_description_tr && errors.coupon_campaign_description_tr}
                            inputName="coupon_campaign_description_tr"
                            displayName="Açıklama Metni (TR)"
                            isSubmitting={isSubmitting}
                            value={values.coupon_campaign_description_tr || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormElements.TextArea
                            type="text"
                            autoComplete="off"
                            error={touched.coupon_campaign_description_en && errors.coupon_campaign_description_en}
                            inputName="coupon_campaign_description_en"
                            displayName="Açıklama Metni (EN)"
                            isSubmitting={isSubmitting}
                            value={values.coupon_campaign_description_en || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        /> */}

                        <div className="columns">
                            <div className="column">
                                <FormElements.TextArea
                                    type="text"
                                    autoComplete="off"
                                    error={touched.coupon_campaign_code_description_tr && errors.coupon_campaign_code_description_tr}
                                    inputName="coupon_campaign_code_description_tr"
                                    displayName="Kupon Açıklama Metni (TR)"
                                    isSubmitting={isSubmitting}
                                    value={values.coupon_campaign_code_description_tr || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="column">
                                <FormElements.TextArea
                                    type="text"
                                    autoComplete="off"
                                    error={touched.coupon_campaign_code_description_en && errors.coupon_campaign_code_description_en}
                                    inputName="coupon_campaign_code_description_en"
                                    displayName="Kupon Açıklama Metni (EN)"
                                    isSubmitting={isSubmitting}
                                    value={values.coupon_campaign_code_description_en || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>

                        {/* <div className="columns">
                            <div className="column">
                                <FormElements.FileInput
                                    key="coupon_campaign_agreement_pdf_tr"
                                    inputName="coupon_campaign_agreement_pdf_tr"
                                    modelName="CouponCampaign"
                                    displayName="Kupon Kullanım Şartları PDF (TR)"
                                    value={values.coupon_campaign_agreement_pdf_tr}
                                    isLoading={isSubmitting}
                                    setFile={(file) => {
                                        setFieldValue("coupon_campaign_agreement_pdf_tr", file);
                                        setFieldTouched("coupon_campaign_agreement_pdf_tr", true);
                                    }}
                                    setError={(error) => {
                                        console.error(error);
                                    }}
                                    uploadInProgress={setSubmitting}
                                    error={touched.coupon_campaign_agreement_pdf_tr && errors.coupon_campaign_agreement_pdf_tr}
                                />
                            </div>
                            <div className="column">
                                <FormElements.FileInput
                                    key="coupon_campaign_agreement_pdf_en"
                                    inputName="coupon_campaign_agreement_pdf_en"
                                    modelName="CouponCampaign"
                                    displayName="Kupon Kullanım Şartları PDF (EN)"
                                    value={values.coupon_campaign_agreement_pdf_en}
                                    isLoading={isSubmitting}
                                    setFile={(file) => {
                                        setFieldValue("coupon_campaign_agreement_pdf_en", file);
                                        setFieldTouched("coupon_campaign_agreement_pdf_en", true);
                                    }}
                                    setError={(error) => {
                                        console.error(error);
                                    }}
                                    uploadInProgress={setSubmitting}
                                    error={touched.coupon_campaign_agreement_pdf_en && errors.coupon_campaign_agreement_pdf_en}
                                />
                            </div>
                        </div> */}

                        <div className="columns" style={{marginTop: "1rem"}}>
                            <div className="column">
                                <FormElements.Switch
                                    error={touched.coupon_campaign_hide_in_list && errors.coupon_campaign_hide_in_list}
                                    inputName="coupon_campaign_hide_in_list"
                                    displayName="Liste de gizle ? (QR kod ile ulaşılabilir)"
                                    isSubmitting={isSubmitting}
                                    value={values.coupon_campaign_hide_in_list}
                                    onChange={(e) => {

                                        const val = e.target.checked;
                                        setFieldValue("coupon_campaign_hide_in_list", val);
                                        setFieldTouched("coupon_campaign_hide_in_list");
                                    }}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="column">
                                <FormElements.Switch
                                    error={touched.coupon_campaign_show_in_tr && errors.coupon_campaign_show_in_tr}
                                    inputName="coupon_campaign_show_in_tr"
                                    displayName="TR'de Göster ?"
                                    isSubmitting={isSubmitting}
                                    value={values.coupon_campaign_show_in_tr}
                                    onChange={(e) => {

                                        const val = e.target.checked;
                                        setFieldValue("coupon_campaign_show_in_tr", val);
                                        setFieldTouched("coupon_campaign_show_in_tr");
                                    }}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="column">
                                <FormElements.Switch
                                    error={touched.coupon_campaign_show_in_en && errors.coupon_campaign_show_in_en}
                                    inputName="coupon_campaign_show_in_en"
                                    displayName="EN'de Göster ?"
                                    isSubmitting={isSubmitting}
                                    value={values.coupon_campaign_show_in_en}
                                    onChange={(e) => {

                                        const val = e.target.checked;
                                        setFieldValue("coupon_campaign_show_in_en", val);
                                        setFieldTouched("coupon_campaign_show_in_en");
                                    }}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="column">
                                <FormElements.Switch
                                    error={touched.coupon_campaign_event_type_allowed && errors.coupon_campaign_event_type_allowed}
                                    inputName="coupon_campaign_event_type_allowed"
                                    displayName="CRM üzerinden Veri Girişine izin ver"
                                    isSubmitting={isSubmitting}
                                    value={values.coupon_campaign_event_type_allowed}
                                    onChange={(e) => {

                                        const val = e.target.checked;
                                        setFieldValue("coupon_campaign_event_type_allowed", val);
                                        setFieldTouched("coupon_campaign_event_type_allowed");
                                    }}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>

                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText={this.props.submitText}
                            isSubmitting={isSubmitting}
                        />
                    </form>
                )}
            />
        );
    }
}


export default withContext(CouponCampaignForm);
