/**
 * EmailNotification Add Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";

import {ExternalEmailListLink} from "../../../config.json";
import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class EmailNotificationAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/marketing/email-external-lists",
            name: "Harici E-posta Listeleri"
        }, {
            url: "/marketing/email-external-lists/new",
            name: "Yeni Harici List"
        }]);
    }


    async createEmailExternalListAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsFullScreenLoading(true);

        const formData = new FormData();
        formData.append("list_name", payload.list_name);
        formData.append("list_file", payload.list_file);

        // API REQUEST
        try {

            await apiCall({
                url: "/marketing/email-external-lists",
                method: "POST",
                payload: formData,
                toast: `Yeni e-posta listesi oluşturuluyor: ${payload.list_name}`
            });

            this.props.history.replace("/marketing/email-external-lists");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsFullScreenLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createEmailExternalListAsync(formData, formikBag);
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Yeni E-posta Listesi
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Liste ismini özenle seçiniz, daha sonraki işlemlerde bu isimle listeyi kullanacaksınız.",
                                "Oluşturulan listeler güncellenemez, isterseniz listeyi silip tekrar ekleyebilirsiniz.",
                            ]}
                            />
                        </div>

                        <Formik
                            initialValues={{
                                list_name: "",
                                list_file: ""
                            }}
                            onSubmit={this.handleSubmit}
                            validationSchema={() => Yup.object().shape({
                                list_name: Yup.string()
                                    .min(5, "Liste ismi en az beş karakter içermelidir.")
                                    .required("Liste ismi zorunlu bir alandır.")
                            })}
                            render={({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setSubmitting
                            }) => (
                                <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                    <FormElements.TextInput
                                        type="text"
                                        autoComplete="off"
                                        error={touched.list_name && errors.list_name}
                                        inputName="list_name"
                                        displayName="Liste İsmi *"
                                        isSubmitting={isSubmitting}
                                        value={values.list_name || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <div className="field" style={{margin: "1rem 0"}}>
                                        <p style={{fontSize: "0.9rem", marginTop: "0.3rem"}}>
                                            {"Örnek Excel dosyasını indirmek için "}
                                            <a
                                                href={ExternalEmailListLink}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                tıklayınız.
                                            </a>
                                            {" Sacece bu formattaki dosyalar desteklenmektedir. A1 hücresinden başlayıp e-postaları eklemelisiniz. Sadece tek bir sütun (A) aktif olmalıdır."}
                                        </p>
                                        <p style={{fontSize: "0.9rem", marginTop: "0.3rem"}}>
                                            {"Bir hücrede sadece bir e-posta adresi olabilir, birden cok e-posta adresini aynı hücreye eklemek için e-posta adreslerini ';' ile ayırın."}
                                            {"Örnek: xx@yy.com;aa@bb.com;cc@dd.com"}
                                        </p>
                                    </div>

                                    <FormElements.FileInputWithoutUpload
                                        inputName="list_file"
                                        modelName="EmailExternalList"
                                        displayName="Excel listesi *"
                                        hint="A1 hücresinden baslayarak, sütun seklinde tüm e-postalar eklenmelidir."
                                        value={values.list_file || ""}
                                        isLoading={isSubmitting}
                                        setFile={(file) => {

                                            setFieldValue("list_file", file);
                                            setFieldTouched("list_file", true);
                                        }}
                                        setError={(error) => {
                                            console.error(error);
                                        }}
                                        uploadInProgress={setSubmitting}
                                        error={errors["list_file"]}
                                    />

                                    <FormElements.Submit
                                        submitClasses={["full-width"]}
                                        submitText="KAYDET"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(EmailNotificationAdd);


