/**
 * ApiError component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import scrollToComponent from "react-scroll-to-component";

class ApiError extends Component {

    errorBlockRef = React.createRef();

    componentDidUpdate(prevProps) {

        if (this.props.error && this.errorBlockRef && (prevProps.error !== this.props.error)) { // error props updated.
            scrollToComponent(this.errorBlockRef);
        }
    }

    render() {

        if (!this.props.error) {
            return null;
        }

        const errorMsg = this.props.error.message || this.props.error.toString();
        const errors = this.props.error.errors || [];

        return (

            errorMsg
                ? (
                    <div
                        ref={(elem) => {
                            this.errorBlockRef = elem;
                        }}
                        className="notification is-danger formMessages">

                        {
                            this.props.clearError ? (
                                <button
                                    type="submit"
                                    className="delete"
                                    onClick={this.props.clearError}
                                />
                            ) : null
                        }

                        <p className="is-size-6">
                            {errorMsg}
                        </p>
                        {
                            errors && errors.length > 0 ? (
                                <ul className="formMessageList">
                                    {
                                        errors.map((error, index) => {

                                            if (error.key && error.msg) {
                                                return (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <li key={index}>
                                                        {error.key}
                                                        :
                                                        {" "}
                                                        {error.msg}
                                                    </li>
                                                );
                                            }

                                            if (error.message) {
                                                return (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <li key={index}>
                                                        {error.message}
                                                    </li>
                                                );
                                            }

                                            return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <li key={index}>
                                                    {error}
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            ) : null
                        }

                    </div>
                )
                : null
        );
    }
}

ApiError.propTypes = {

    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        message: PropTypes.string,
        errors: PropTypes.array
    })]),
    clearError: PropTypes.func
};

export default ApiError;
