module.exports = [{
  display: "E-posta kabul ediyor ?",
  field: "customer_allows_email",
  type: "boolean",
  value: true
}, {
  display: "Iletisim Izni ?",
  field: "customer_accepts_kvkk",
  type: "boolean",
  value: true
}];