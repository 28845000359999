/**
 * UploadProgress component
 */

import React from "react";
import PropTypes from "prop-types";

const UploadProgress = (props) => (

    <div className="progress-container" style={{height: props.previewHeight}}>
        <progress
            className="progress"
            value={props.progress}
            max={100}
            color="success"
        />
    </div>
);

UploadProgress.propTypes = {

    progress: PropTypes.number,
    previewHeight: PropTypes.number,
};

export default UploadProgress;
