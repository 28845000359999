/**
 * EventType Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import CampaignCampaignCategories from "../../../../../common/coupon_campaign_categories";
import CouponCampaignTypeSpendingPeriods from "../../../../../common/coupon_campaign_type_spending_periods";

class CampaignTypeForm extends PureComponent {
 
     static propTypes = {
 
         // Context
         loadingContext: PropTypes.shape({
             isLoading: PropTypes.bool.isRequired,
             setIsLoading: PropTypes.func.isRequired
         }),
 
         // react router
         history: PropTypes.shape({
             replace: PropTypes.func
         }),
 
         campaignType: PropTypes.shape().isRequired,
         submitText: PropTypes.string.isRequired,
         isEdit: PropTypes.bool.isRequired,
         handleSubmit: PropTypes.func.isRequired
     };
 
     static defaultProps = {
         customer: null,
         campaignType: {},
         isEdit: false,
         handleSubmit: () => console.log("handle submit unbinded.")
     };
 
     // Second parameter is formikBag, containing
     // access to formik properties
     handleCampaignTypeSubmit = (formData, formikBag) => {
 
         this.props.handleSubmit(formData, formikBag);
     };
 
     render() {
 
         const {campaignType, isEdit} = this.props;
         if (!campaignType) {
             return null;
         }
 
         return (
 
             <Formik
                 enableReinitialize
                 initialValues={{
                     coupon_campaign_type_name: campaignType.coupon_campaign_type_name || "",
                     coupon_campaign_spending_threshold: campaignType.coupon_campaign_spending_threshold || 0,
                     coupon_campaign_category: campaignType.coupon_campaign_category || ""
                 }}
                 validationSchema={() => Yup.object().shape({
                     coupon_campaign_category: Yup.string()
                         .required("Kampanya tür bilgisi zorunludur."),
                     coupon_campaign_type_name: Yup.string()
                         .min(3, "İsim bilgisi en az 3 karakter içermelidir.")
                         .required("İsim bilgisi zorunludur."),
                     coupon_campaign_spending_threshold: Yup.number()
                         .min(100, "Harcama eşik miktarı en az 100 birim olabilir.")
                         .required("Harcama bilgisi zorunludur."),
                 })}
                 onSubmit={this.handleCampaignTypeSubmit}
                 render={({
                     touched,
                     values,
                     errors,
                     setFieldValue,
                     setFieldTouched,
                     handleSubmit,
                     isSubmitting,
                     handleBlur
                 }) => (
                     <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>
 
                         <FormElements.TextInput
                             type="text"
                             autoComplete="off"
                             displayName="Kampanya Türü İsmi *"
                             inputName="coupon_campaign_type_name"
                             error={touched.coupon_campaign_type_name && errors.coupon_campaign_type_name}
                             isSubmitting={isSubmitting}
                             value={values.coupon_campaign_type_name || ""}
                             onChange={(e) => {
 
                                 setFieldValue("coupon_campaign_type_name", e.target.value);
                                 setFieldTouched("coupon_campaign_type_name");
                             }}
                             onBlur={handleBlur}
                         />
                         
                         <FormElements.SelectInput
                             isDisabled={isSubmitting || isEdit}
                             displayName="Kampanya Tipi *"
                             inputName="coupon_campaign_category"
                             error={touched.coupon_campaign_category && errors.coupon_campaign_category}
                             options={CampaignCampaignCategories}
                             value={values.coupon_campaign_category}
                             classNamePrefix="react-select"
                             noOptionsMessage={() => "No result"}
                             placeholder="Kampanya Türü Seçiniz"
                             onChange={(selectedOption) => {

                                 setFieldValue("coupon_campaign_category", selectedOption.value);
                                 setFieldTouched("coupon_campaign_category");
                             }}
                             onBlur={() => {
                                 setFieldTouched("coupon_campaign_category", true);
                             }}
                         />
 
                         <FormElements.SelectInput
                             isDisabled={isSubmitting || isEdit}
                             displayName="Kampanya Harcama Kontrol Periyodu *"
                             inputName="coupon_campaign_spending_control_period"
                             error={touched.coupon_campaign_spending_control_period && errors.coupon_campaign_spending_control_period}
                             options={CouponCampaignTypeSpendingPeriods}
                             value={values.coupon_campaign_spending_control_period}
                             classNamePrefix="react-select"
                             noOptionsMessage={() => "No result"}
                             placeholder="Kampanya Harcama Kontrol Periyodunu Seçiniz"
                             onChange={(selectedOption) => {

                                 setFieldValue("coupon_campaign_spending_control_period", selectedOption.value);
                                 setFieldTouched("coupon_campaign_spending_control_period");
                             }}
                             onBlur={() => {
                                 setFieldTouched("coupon_campaign_spending_control_period", true);
                             }}
                         />

                         <FormElements.TextInput
                             type="number"
                             isDisabled={isEdit}
                             autoComplete="off"
                             displayName="Kampanya Harcama Limiti"
                             inputName="coupon_campaign_spending_threshold"
                             error={touched.coupon_campaign_spending_threshold && errors.coupon_campaign_spending_threshold}
                             isSubmitting={isSubmitting}
                             value={values.coupon_campaign_spending_threshold || ""}
                             onChange={(e) => {
 
                                 setFieldValue("coupon_campaign_spending_threshold", e.target.value);
                                 setFieldTouched("coupon_campaign_spending_threshold");
                             }}
                             onBlur={handleBlur}
                         />
 
                         <FormElements.Submit
                             submitClasses={["full-width"]}
                             submitText={this.props.submitText}
                             isSubmitting={isSubmitting}
                         />
                     </form>
                 )}
             />
         );
     }
}
 
export default withContext(CampaignTypeForm);
 