/**
 * CustomerCheck Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import apiCall from "../../../services/api";
import withContext from "../../../context/withContext";
import CustomerInformation from "./CustomerInformation";
import CustomerEvents from "./CustomerEvents";
import CustomerEventsPayments from "./CustomerEventsPayments";
import CustomerFeedbacks from "./CustomerFeedbacks";
import CustomerHistory from "./CustomerHistory";
import CustomerCoupons from "./CustomerCoupons";

class CustomerProfile extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        }),
        location: PropTypes.shape({
            state: PropTypes.shape()
        })
    };

    state = {
        customer: null,
        activeTab: "profile"
    };

    customerId = this.props.match.params["customerid"];

    componentDidMount() {

        const breadcrumb = [];

        if (this.props.location.state && this.props.location.state.fromFeedback) {
            breadcrumb.push({
                url: "/feedback",
                name: "Geri Bildirim Listesi"
            });
        }

        if (this.props.location.state && this.props.location.state.fromCustomerList) {
            breadcrumb.push({
                url: "/customers/list",
                name: "Müşteri Listesi"
            });
        }

        if (this.props.location.state && this.props.location.state.fromCustomerQuery) {
            breadcrumb.push({
                url: "/customers/query",
                name: "Müşteri Sorgulama"
            });
        }

        breadcrumb.push({
            url: `/customers/profile/${this.customerId}`,
            name: "Müşteri Profili"
        });

        // Update subheader statically
        this.props.setBreadcrumb(breadcrumb);

        this.getCustomerAsync("profile");
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeTab !== this.state.activeTab) {
            this.getCustomerAsync(this.state.activeTab);
        }
    }

    async getCustomerAsync(information) {

        // START LOADING
        this.props.setError(null);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/customers/${information}/${this.customerId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                customer: data
            }));

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);

        }
        catch (e) {

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);

            // Checking State
            this.setState((prevState) => ({
                ...prevState,
                isChecking: false
            }));

            this.props.setError(e);
        }
    }

    removeCustomer = () => {

        this.props.showConfirm({
            title: this.state.customer.customer_phone,
            message: "Bu müşteriyi silmek istediğinize emin misiniz? İşlemin geri dönüşü yoktur, tüm data'larıyla beraber sistemden silinecektir.",
            buttons: [{
                title: "Tamamdır, SİL!",
                asyncFn: async () => {

                    // START LOADING
                    this.props.setError(null);
                    this.props.loadingContext.setIsLoading(true);

                    try {

                        await apiCall({
                            url: `/customers/profile/${this.state.customer._id}`,
                            method: "DELETE",
                            toast: `${this.state.customer.customer_phone} telefon numaralı müşteri sistemden silindi.`
                        });

                        this.props.loadingContext.setIsLoading(false);

                        if (this.props.location.state && this.props.location.state.fromCustomerList) {
                            return this.props.history.replace("/customers/list");
                        }
                        else if (this.props.location.state && this.props.location.state.fromCustomerQuery) {
                            return this.props.history.replace("/customers/query");
                        }
                        else {
                            return this.props.history.replace("/");
                        }
                    }
                    catch (e) {

                        // STOP LOADING
                        this.props.loadingContext.setIsLoading(false);
                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    setMarketingPermissionForCustomer = async (type, value) => {

        if (type !== "sms" && type !== "email" && type!== "call") {
            return;
        }

        // START LOADING
        this.props.setError(null);
        this.props.loadingContext.setIsLoading(true);

        try {

            await apiCall({
                url: `/customers/profile/${this.state.customer._id}/marketing-permissions`,
                method: "POST",
                payload: {
                    type,
                    value
                },
                toast: `${this.state.customer.customer_phone} telefon numaralı müşterinin ${type.toUpperCase()} marketing izni ${value ? "EVET" : "HAYIR"} olarak değiştirildi.`
            });

            this.setState((prevState) => ({
                ...prevState,
                customer: {
                    ...prevState.customer,
                    [`customer_allows_${type}`]: !!value
                }
            }));

            this.props.loadingContext.setIsLoading(false);
        }
        catch (e) {

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    };

    tabChanged = (e, activeTab) => {
        e.preventDefault();
        this.setState((prevState) => ({
            ...prevState,
            activeTab
        }));
    };

    render() {

        const customer = this.state.customer;
        if (!customer) {
            return null;
        }

        return (
            <>
                <div className="tabs is-boxed profileTab">
                    <ul>
                        <li className={this.state.activeTab === "profile" ? "is-active" : ""}>
                            <a href="#" onClick={(e) => this.tabChanged(e, "profile")}>Profil</a>
                        </li>
                        <li className={this.state.activeTab === "payment" ? "is-active" : ""}>
                            <a href="#" onClick={(e) => this.tabChanged(e, "payment")}>Faturalar</a>
                        </li>
                        <li className={this.state.activeTab === "coupon" ? "is-active" : ""}>
                            <a href="#" onClick={(e) => this.tabChanged(e, "coupon")}>Kuponlar</a>
                        </li>
                        <li className={this.state.activeTab === "event" ? "is-active" : ""}>
                            <a href="#" onClick={(e) => this.tabChanged(e, "event")}>Aktivite Kayıtları</a>
                        </li>
                        <li className={this.state.activeTab === "feedback" ? "is-active" : ""}>
                            <a href="#" onClick={(e) => this.tabChanged(e, "feedback")}>Geri Bildirim Kayıtları</a>
                        </li>
                        <li className={this.state.activeTab === "history" ? "is-active" : ""}>
                            <a href="#" onClick={(e) => this.tabChanged(e, "history")}>Yapılan Değişiklikler</a>
                        </li>
                    </ul>
                </div>
                {
                    this.state.activeTab === "profile" ? (
                        <CustomerInformation
                            customer={this.state.customer}
                            removeCustomer={this.removeCustomer}
                            setMarketingPermissionForCustomer={this.setMarketingPermissionForCustomer}
                        />
                    ) : null
                }
                {
                    this.state.activeTab === "payment" && this.state.customer.customer_events_payments ? (
                        <CustomerEventsPayments customer={this.state.customer}/>
                    ) : null
                }
                {
                    this.state.activeTab === "coupon" && this.state.customer.customer_coupons ? (
                        <CustomerCoupons 
                            showConfirm={this.props.showConfirm}
                            customer={this.state.customer}
                        />
                    ) : null
                }
                {
                    this.state.activeTab === "event" && this.state.customer.customer_events ? (
                        <CustomerEvents customer={this.state.customer}/>
                    ) : null
                }
                {
                    this.state.activeTab === "feedback" && this.state.customer.customer_feedbacks ? (
                        <CustomerFeedbacks customer={this.state.customer}/>
                    ) : null
                }
                {
                    this.state.activeTab === "history" && this.state.customer.customer_histories ? (
                        <CustomerHistory customer={this.state.customer}/>
                    ) : null
                }
            </>
        );
    }
}


export default withContext(CustomerProfile);


