/**
 * SideMenu component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import {NavLink, withRouter} from "react-router-dom";
import Collapsible from "react-collapsible";

import withContext from "../../context/withContext";
import RoleAuth from "../RoleAuth/RoleAuth";

class SideMenu extends Component {

    static propTypes = {

        authContext: PropTypes.shape({
            admin: PropTypes.shape()
        }),

        // react router
        match: PropTypes.shape({
            path: PropTypes.string
        })
    };

    render() {

        // Only one level nesting supported
        return (
            <div className={`sideMenu ${this.props.isMobileActive ? "mobileActive" : ""}`}>
                <aside className="menu">

                    {/*Dashboard -> */}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">
                        <ul className="menu-list">
                            <li>
                                <NavLink to="/dashboard">
                                    <span className="icon is-small">
                                        <i className="fa fa-tachometer-alt"/>
                                    </span>
                                    Dashboard
                                </NavLink>
                            </li>
                        </ul>
                    </RoleAuth>
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmCustomerDesk">
                        <ul className="menu-list">
                            <li>
                                <NavLink to="/customer-entry">
                                    <span className="icon is-small">
                                        <i className="fa fa-pencil-alt"/>
                                    </span>
                                    Ziyaretçi Kayıt
                                </NavLink>
                            </li>
                        </ul>
                    </RoleAuth>

                    {/*Marketing*/}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">
                        <ul className="menu-list">
                            <li>
                                <Collapsible
                                    open={this.props.match && this.props.match.path.startsWith("/marketing")}
                                    caption="Marketing"
                                    trigger={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-child"/>
                                            </span>
                                            Marketing
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-down"/>
                                            </span>
                                        </a>
                                    )}
                                    triggerWhenOpen={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-child"/>
                                            </span>
                                            Marketing
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-up"/>
                                            </span>
                                        </a>
                                    )}
                                    easing="ease-in-out">
                                    <ul>
                                        <li>
                                            <NavLink to="/marketing/push-notifications">
                                                Push Gönderimi
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/marketing/email-notifications">
                                                E-posta Gönderimi
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/marketing/email-external-lists">
                                                Harici E-posta Listeleri
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/marketing/sms-notifications">
                                                SMS Gönderimi
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </li>
                        </ul>
                    </RoleAuth>

                    {/*Customers*/}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">
                        <ul className="menu-list">
                            <li>

                                <Collapsible
                                    open={this.props.match && this.props.match.path.startsWith("/customers")}
                                    caption="Müşteriler"
                                    trigger={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-user"/>
                                            </span>
                                            Müşteriler
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-down"/>
                                            </span>
                                        </a>
                                    )}
                                    triggerWhenOpen={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-user"/>
                                            </span>
                                            Müşteriler
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-up"/>
                                            </span>
                                        </a>
                                    )}
                                    easing="ease-in-out">
                                    <ul>
                                        <li>
                                            <NavLink to="/customers/list">
                                                <span className="icon is-small">
                                                    <i className="fa fa-filter"/>
                                                </span>
                                                Liste / Filtreleme
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/customers/query">
                                                <span className="icon is-small">
                                                    <i className="fa fa-phone"/>
                                                </span>
                                                Telefon ile Sorgulama
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </li>
                        </ul>
                    </RoleAuth>

                    {/*Kampanyalar*/}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">

                        <ul className="menu-list">
                            <li>
                                <Collapsible
                                    open={this.props.match && this.props.match.path.startsWith("/campaign")}
                                    caption="Kampanyalar"
                                    trigger={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-hand-pointer"/>
                                            </span>
                                            Kampanyalar
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-down"/>
                                            </span>
                                        </a>
                                    )}
                                    triggerWhenOpen={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-hand-pointer"/>
                                            </span>
                                            Kampanyalar
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-up"/>
                                            </span>
                                        </a>
                                    )}
                                    easing="ease-in-out">
                                    <ul>
                                        <li>
                                            <NavLink to="/campaign/coupon-campaigns">
                                                Kupon Kampanyaları
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/campaign/coupon-providers">
                                                Kupon Sağlayıcılar
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/campaign/types">
                                                Kampanya Türleri
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </li>
                        </ul>
                    </RoleAuth>

                    {/* Exports */}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">
                        <ul className="menu-list">
                            <li>
                                <Collapsible
                                    open={this.props.match && this.props.match.path.startsWith("/export-logs")}
                                    caption="Export Log"
                                    trigger={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-database"/>
                                            </span>
                                            Export Log
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-down"/>
                                            </span>
                                        </a>
                                    )}
                                    triggerWhenOpen={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-database"/>
                                            </span>
                                            Export Log
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-up"/>
                                            </span>
                                        </a>
                                    )}
                                    easing="ease-in-out">
                                    <ul>
                                        <li>
                                            <NavLink to="/export-logs/list">
                                                <span className="icon is-small">
                                                    <i className="fa fa-tag"/>
                                                </span>
                                                Log Listesi
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/export-logs/companies/list">
                                                <span className="icon is-small">
                                                    <i className="fa fa-tag"/>
                                                </span>
                                                Şirket Listesi
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/export-logs/companies/staffers">
                                                <span className="icon is-small">
                                                    <i className="fa fa-comments"/>
                                                </span>
                                                Şirket Personelleri
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </li>
                        </ul>
                    </RoleAuth>

                    {/*VeriTipleri*/}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">
                        <ul className="menu-list">
                            <li>
                                <Collapsible
                                    open={this.props.match && this.props.match.path.startsWith("/data")}
                                    caption="Veri Tipleri"
                                    trigger={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-database"/>
                                            </span>
                                            Veri Tipleri
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-down"/>
                                            </span>
                                        </a>
                                    )}
                                    triggerWhenOpen={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-database"/>
                                            </span>
                                            Veri Tipleri
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-up"/>
                                            </span>
                                        </a>
                                    )}
                                    easing="ease-in-out">
                                    <ul>
                                        <li>
                                            <NavLink to="/data/tag-types">
                                                <span className="icon is-small">
                                                    <i className="fa fa-tag"/>
                                                </span>
                                                Müşteri Etiketleri
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/data/feedback-tag-types">
                                                <span className="icon is-small">
                                                    <i className="fa fa-comments"/>
                                                </span>
                                                Geri Bildirim Etiketleri
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/data/event-types">
                                                <span className="icon is-small">
                                                    <i className="fa fa-bolt"/>
                                                </span>
                                                Veri Giriş Yerleri
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapsible>
                            </li>
                        </ul>
                    </RoleAuth>

                    {/* Geri Bildirim -> */}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmCustomerDesk">
                        <ul className="menu-list">
                            <li>
                                <NavLink to="/feedback">
                                    <span className="icon is-small">
                                        <i className="fa fa-comments"/>
                                    </span>
                                    Geri Bildirim
                                </NavLink>
                            </li>
                        </ul>
                    </RoleAuth>

                    {/*Raporlar*/}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">
                        <ul className="menu-list">
                            <li>
                                <Collapsible
                                    open={this.props.match && this.props.match.path.startsWith("/reports")}
                                    caption="Veri Tipleri"
                                    trigger={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-chart-bar"/>
                                            </span>
                                            Raporlar
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-down"/>
                                            </span>
                                        </a>
                                    )}
                                    triggerWhenOpen={(
                                        <a href='#'>
                                            <span className="icon is-small">
                                                <i className="fa fa-chart-bar"/>
                                            </span>
                                            Raporlar
                                            <span className="floatRight icon is-small">
                                                <i className="fa fa-angle-up"/>
                                            </span>
                                        </a>
                                    )}
                                    easing="ease-in-out">
                                    <ul>
                                        <li>
                                            <NavLink to="/reports/monthly-reports">
                                                Aylık Rapor
                                            </NavLink>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <NavLink to="/reports/email-notifications">*/}
                                        {/*        E-mailing Raporları*/}
                                        {/*    </NavLink>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <NavLink to="/reports/sms-notifications">*/}
                                        {/*        SMS Raporları*/}
                                        {/*    </NavLink>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <NavLink to="/reports/push-notifications">*/}
                                        {/*        Push Notification Raporları*/}
                                        {/*    </NavLink>*/}
                                        {/*</li>*/}
                                    </ul>
                                </Collapsible>
                            </li>
                        </ul>
                    </RoleAuth>
                    {/* Customer Import Excel - User */}
                    <RoleAuth admin={this.props.authContext.admin} minRole="CrmCustomerDesk">
                        <ul className="menu-list">
                            <li>
                                <NavLink to="/customer-import">
                                    <span className="icon is-small">
                                        <i className="fa fa-file"/> 
                                    </span>
                                    Müşteri Aktarımı
                                </NavLink>
                            </li>
                        </ul>
                    </RoleAuth>
                </aside>
            </div>
        );
    }
}

SideMenu.propTypes = {
    isMobileActive: PropTypes.bool,
};

export default withRouter(withContext(SideMenu));
