import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Formik} from "formik";
import {Redirect} from "react-router-dom";

import {uiTitle} from "../../../config.json";
import {ApiError, FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class ResetPassword extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        authContext: PropTypes.shape({
            clearAdmin: PropTypes.func.isRequired,
        }),

        // React router
        location: PropTypes.shape({
            state: PropTypes.shape()
        }),
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        // API,
        tokenError: null,
        resetPasswordError: null,
        isTokenCheckCompleted: false,
        isResetPasswordSuccess: false,
    };

    params = this.props.match.params;

    constructor(props) {

        super(props);

        this.props.authContext.clearAdmin();
        this.validateTokenAsync();
    }

    async validateTokenAsync() {

        // API REQUEST
        try {

            await apiCall({
                url: `/auth/reset-password/check/${this.params.token}`,
                withAuth: false
            });
        }
        catch (e) {

            this.setState((prevState) => ({
                ...prevState,
                tokenError: e
            }));
        }

        this.setState((prevState) => ({
            ...prevState,
            isTokenCheckCompleted: true
        }));
    }

    async resetPasswordAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);

        // Reset State
        this.setState((prevState) => ({
            ...prevState,
            resetPasswordError: null
        }));

        // API REQUEST
        try {

            await apiCall({
                url: "/auth/reset-password",
                method: "POST",
                payload,
                withAuth: false
            });

            this.setState((prevState) => ({
                ...prevState,
                isResetPasswordSuccess: true
            }));
        }
        catch (e) {

            this.setState((prevState) => ({
                ...prevState,
                resetPasswordError: e
            }));
        }

        // STOP LOADING
        setSubmitting(false);
    }

    handleSubmit = (formData, formikBag) => {

        this.resetPasswordAsync({
            token: this.params.token,
            ...formData
        }, formikBag);
    };

    render() {

        if (!this.state.isTokenCheckCompleted) {
            // If isTokenCheckCompleted do not show anything yet.
            return null;
        }


        if (this.state.tokenError) {
            return (
                <ApiError error={this.state.tokenError}/>
            );
        }

        if (this.state.isResetPasswordSuccess) {
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: {
                        successMsg: "Şifrenizi değiştirdiniz. Şimdi yeni şifrenizi kullanarak giriş yapabilirsiniz."
                    }
                }}
                />
            );
        }
        return (

            <>

                <h1 className="title is-4">{`${uiTitle} - Şifre Güncelleme`}</h1>

                {
                    this.state.resetPasswordError ? (
                        <ApiError error={this.state.resetPasswordError} clearError={() => this.setState((prevState) => ({...prevState, resetPasswordError: null}))}/>
                    ) : null
                }

                <Formik
                    initialValues={{
                        admin_password: "",
                        admin_password_again: ""
                    }}
                    onSubmit={this.handleSubmit}
                    validationSchema={() => Yup.object().shape({
                        admin_password: Yup.string()
                            .min(6, "Şifreniz en az 6 karakter içermelidir.")
                            .required("Şifre zorunlu bir alan."),
                        admin_password_again: Yup.string()
                            .required("Şifre (tekrar) zorunlu bir alan.")
                            .oneOf([Yup.ref("admin_password")], "Şifreler uyuşmuyor.")
                    })}
                    render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                            <FormElements.TextInput
                                type="password"
                                autoComplete="off"
                                error={touched.admin_password && errors.admin_password}
                                inputName="admin_password"
                                displayName="Şifre"
                                isSubmitting={isSubmitting}
                                value={values.admin_password || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="password"
                                autoComplete="off"
                                error={touched.admin_password_again && errors.admin_password_again}
                                inputName="admin_password_again"
                                displayName="Şifre (Tekrar)"
                                isSubmitting={isSubmitting}
                                value={values.admin_password_again || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.Submit
                                submitClasses={["full-width"]}
                                submitText="Güncelle"
                                isSubmitting={isSubmitting}
                            />
                        </form>
                    )}
                />
            </>
        );
    }
}

export default withContext(ResetPassword);
