/**
 * FormElements
 */

import ImageInput from "./ImageInput/ImageInput";
import TextInput from "./TextInput/TextInput";
import TextArea from "./TextArea/TextArea";
import Button from "./Button";
import Submit from "./Submit/Submit";
import InlineError from "./InlineError/InlineError";
import FormExplanation from "./FormExplanation/FormExplanation";
import FileInput from "./FileInput/FileInput";
import FileInputWithoutUpload from "./FileInput/FileInputWithoutUpload";
import SmsCodeValidation from "./SmsCodeValidation/SmsCodeValidation";
import SelectInput from "./SelectInput/SelectInput";
import PhoneInput from "./PhoneInput/PhoneInput";
import Switch from "./Switch/Switch";
import Radio from "./Radio/Radio";
import DateInput from "./DateInput/DateInput";
import SelectInputAsync from "./SelectInputAsync/SelectInputAsync";
import CleaveInput from "./CleaveInput/CleaveInput";


export default {
    ImageInput,
    InlineError,
    TextInput,
    TextArea,
    Button,
    Submit,
    SelectInput,
    FileInput,
    FileInputWithoutUpload,
    FormExplanation,
    Switch,
    Radio,
    PhoneInput,
    CleaveInput,
    DateInput,
    SmsCodeValidation,
    SelectInputAsync
};
