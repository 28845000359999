/**
 * Coupon Campaign Add Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {toast as showToast} from "react-toastify";

import {FormElements, ToastContent} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import {Formik} from "formik";
import * as Yup from "yup";

class CouponCodeUpload extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/campaign/coupon-campaigns",
            name: "Kupon Kampanyaları"
        }, {
            url: "/campaign/coupon-campaigns/new",
            name: "Yeni Kupon Kodu Ekle"
        }]);
    }

    async uploadCouponCodesAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const formData = new FormData();
            formData.append("file", payload.list_file);
            formData.append("couponCampaignId", this.props.match.params["campaignid"]);

            const {data} = await apiCall({
                url: "/campaign/coupon-codes/code-parser",
                method: "POST",
                payload: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                options: {
                    onUploadProgress: (progressEvent) => {

                        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({
                            progress: percentCompleted
                        });
                    }
                }
            });

            showToast.success(<ToastContent message={data.message}/>);

            this.props.history.replace("/campaign/coupon-campaigns");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.uploadCouponCodesAsync(formData, formikBag);
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Kupon Kodlarını Yükleyin
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Kupon kodları tek bir dosya içerisinde olmalıdır.",
                                "Dosyanın uzantısı .txt veya .csv olabilir.",
                                "Dosyada her satırda 1 adet kupon kodu olmalıdır.",
                                "Maksimum 10.000 adet kupon yükleyebilirsiniz.",
                                "Minimum 5 adet kupon yükleyebilirsiniz."
                            ]}
                            />
                        </div>

                        <Formik
                            initialValues={{list_file: ""}}
                            onSubmit={this.handleSubmit}
                            validationSchema={() => Yup.object().shape({
                                list_file: Yup.string()
                                    .required("Dosyayı Seçiniz zorunlu bir alandır."),
                            })}
                            render={({
                                values,
                                errors,
                                setFieldValue,
                                setFieldTouched,
                                setSubmitting,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>


                                    <FormElements.FileInputWithoutUpload
                                        inputName="list_file"
                                        modelName="CouponCode"
                                        displayName="Kupon Kodu Listesi *"
                                        value={values.list_file || ""}
                                        isLoading={isSubmitting}
                                        extensions={["txt", "csv"]}
                                        mimeTypes={["text/plain", "text/csv"]}
                                        setFile={(file) => {

                                            setFieldValue("list_file", file);
                                            setFieldTouched("list_file", true);
                                        }}
                                        setError={(error) => {
                                            console.error(error);
                                        }}
                                        uploadInProgress={setSubmitting}
                                        error={errors["list_file"]}
                                    />

                                    <FormElements.Submit
                                        submitClasses={["full-width"]}
                                        submitText="Kaydet"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CouponCodeUpload);


