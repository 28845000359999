/**
 * Priority means role importance
 *
 * 98 priority cant delete 99 priorty etc.
 */
// This file also referenced from the frontend code, be careful for any change.
// Also frontend does not do babel transform for this file !! So dont use newer features.
module.exports = [{
  value: "sponsorluk_sozlesmesi",
  label: "Sponsorluk Sözleşmesi Gereği"
}, {
  value: "mailing",
  label: "Mailing"
}, {
  value: "reklam_planlamasi",
  label: "Reklam Planlaması"
}];