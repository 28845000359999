/**
 * FeedbackAdd Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../components";
import withContext from "../../context/withContext";
import {Formik} from "formik";
import {isValidPhoneNumber} from "react-phone-number-input";
import apiCall from "../../services/api";
import FeedbackForm from "./FeedbackForm";
import {Redirect} from "react-router-dom";

class FeedbackAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {

        isSuccess: false,
        customerPhone: null,
        customer: null
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/feedback",
            name: "Geri Bildirim Listesi"
        }, {
            url: "/feedback/new",
            name: "Yeni Geri Bildirim"
        }]);
    }

    async checkPhoneAsync(customer_phone, {setSubmitting}) {

        // START LOADING
        this.props.setError(null);
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customers/check/phone",
                method: "POST",
                payload: {
                    customer_phone
                }
            });

            console.log("customer_phone: ", customer_phone, data);

            this.setState((prevState) => ({
                ...prevState,
                customerPhone: customer_phone,
                // If check returns customer data
                customer: (data && data._id) ? data : null
            }));

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);

        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    async createFeedbackAsync(payload, {setSubmitting}) {

        // START LOADING
        this.props.setError(null);
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: "/feedback",
                method: "POST",
                payload
            });

            this.setState((prevState) => ({
                ...prevState,
                isSuccess: true
            }));

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);

        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }

    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handlePhoneSubmit = (formData, formikBag) => {

        const {customer_phone} = formData;

        if (!isValidPhoneNumber(customer_phone)) {
            formikBag.setFieldError("customer_phone", "Geçersiz bir telefon numarası girdiniz");
            formikBag.setSubmitting(false);
            return;
        }

        this.checkPhoneAsync(customer_phone, formikBag);
    };

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createFeedbackAsync(formData, formikBag);
    };

    renderPhoneForm = () => {

        return (
            <Formik
                enableReinitialize
                initialValues={{
                    customer_phone: ""
                }}
                onSubmit={this.handlePhoneSubmit}
                render={({
                    values,
                    errors,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                    submitForm
                }) => (
                    <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                        <FormElements.PhoneInput
                            type="text"
                            autoComplete="off"
                            displayName="Cep Telefonu"
                            inputName="customer_phone"
                            error={errors.customer_phone}
                            isSubmitting={isSubmitting}
                            value={values.customer_phone || ""}
                            onChange={(phoneNumber) => {

                                setFieldValue("customer_phone", phoneNumber);
                                setFieldTouched("customer_phone");

                                if (phoneNumber && phoneNumber.startsWith("+90") && phoneNumber.length === 13) {
                                    submitForm();
                                }
                            }}
                        />

                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText="Sorgula"
                            isSubmitting={isSubmitting}
                        />
                    </form>
                )}
            />
        );
    };

    render() {

        const {customerPhone, customer, isSuccess} = this.state;

        if (isSuccess) {

            return (
                <Redirect to="/feedback"/>
            );
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Geri Bildirim
                                </span>
                            </h5>
                            {
                                !customerPhone ? (
                                    <FormElements.FormExplanation messages={[
                                        "Lütfen önce ziyaretçinin telefon numarasını sorunuz ve aşağıdaki alana giriniz."
                                    ]}
                                    />
                                ) : (
                                    <button type="button" className="button is-fullwidth" onClick={() => {
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            customerPhone: null,
                                            customer: null
                                        }));
                                    }}>
                                        <span className="icon is-small">
                                            <i className="fa fa-arrow-left"/>
                                        </span>
                                        <span>Geri Dönün ve Başka Bir Telefon Numarası Girin</span>
                                    </button>
                                )
                            }
                        </div>
                        {
                            !customerPhone
                                ? this.renderPhoneForm()
                                :
                                <FeedbackForm
                                    customerPhone={customerPhone}
                                    customer={customer}
                                    feedback={{}}
                                    submitText="Kaydet"
                                    handleSubmit={this.handleSubmit}
                                />
                        }
                    </div>
                </div>
            </div>
        );

        // return (
        //     <div className="columns is-centered">
        //         <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
        //             <div className="box knsForm">
        //                 <div className="content">
        //                     <h5>
        //                         <span className="icon is-small">
        //                             <i className="fa fa-angle-double-right"/>
        //                         </span>
        //                         <span>
        //                             Geri Bildirim
        //                         </span>
        //                     </h5>
        //                     <FormElements.FormExplanation messages={[
        //                         "Etiket Tipi sadece ingilizce karakterler, kısa çizgi, boşluk ve rakam içerebilir.",
        //                         "Etiket Tipi sistem genelinde benzersiz olmalıdır, aynı isme sahip ikinci etiket tipini ekleyemezsiniz.",
        //                         "Örnek etiket Tipleri (TAG): 'WIFI', 'AVM ICI ETKINLIKLER', 'MOBILE APP'",
        //                         "Tüm kurallar sistemde tanımlamıştır, aşağıda test edebilir, hata almadığınız her değeri girebilirsiniz."
        //                     ]}
        //                     />
        //                 </div>
        //
        //                 <FeedbackForm
        //                     tagType={{}}
        //                     handleSubmit={this.handleSubmit}
        //                     submitText="Kaydet"
        //                 />
        //             </div>
        //         </div>
        //     </div>
        // );
    }
}

export default withContext(FeedbackAdd);


