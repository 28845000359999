/**
 * EventType Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";

import {FormElements} from "../../../components";
import Memoize from "memoize-one";
import apiCall from "../../../services/api";
import withContext from "../../../context/withContext";

class CouponProviderForm extends PureComponent {

    static propTypes = {
        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        provider: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    state = {
        stores: null
    };

    getStoresForSelect = Memoize((stores) => stores.map((store) => ({
        value: store._id,
        label: store.store_name
    })));

    componentDidMount() {

        this.getStoresAsync();
    }

    async getStoresAsync() {

        this.props.loadingContext.setIsLoading(true);

        try {

            const {data} = await apiCall({
                url: "/data/stores"
            });

            this.setState((prevState) => ({
                ...prevState,
                stores: data,
            }));
        }
        catch (e) {
            console.error(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    handleSubmit = (formData, formikBag) => {

        this.props.handleSubmit(formData, formikBag);
    };

    render() {

        const provider = this.props.provider;
        const {stores} = this.state;

        if (!provider || !stores) {
            return null;
        }

        const selectStores = this.getStoresForSelect(stores);

        const initialValues = {
            coupon_provider_store_id: provider.coupon_provider_store_id
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                validationSchema={() => Yup.object().shape({
                    coupon_provider_store_id: Yup.string()
                        .required("Kupon sağlayıcısı zorunlu bir alandır."),
                })}
                render={({
                    values,
                    errors,
                    touched,
                    setFieldTouched,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>


                        <FormElements.SelectInput
                            isDisabled={isSubmitting}
                            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                            value={values.coupon_provider_store_id}
                            menuPosition="fixed"
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            displayName="Kupon sağlayıcısı"
                            placeholder="Mağaza seçebilirsiniz"
                            options={selectStores}
                            isMenuOpen
                            isSearchable
                            error={touched.coupon_provider_store_id && errors.coupon_provider_store_id}
                            inputName="coupon_provider_store_id"
                            classNamePrefix="react-select"
                            onChange={(selectedOption) => {

                                setFieldValue("coupon_provider_store_id", selectedOption);
                                setFieldTouched("coupon_provider_store_id");
                            }}
                            onBlur={() => {
                                setFieldTouched("coupon_provider_store_id");
                            }}
                        />

                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText={this.props.submitText}
                            isSubmitting={isSubmitting}
                        />

                    </form>
                )}
            />
        );
    }
}


export default withContext(CouponProviderForm);
