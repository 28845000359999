/**
 * Invites Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";

import apiCall from "../../../services/api";
import withContext from "../../../context/withContext";
import {CrmTable} from "../../../components";

class Invites extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        invites: null,

        // COMPONENT
        searchText: ""
    };

    filterInvites = Memoize((invites, searchText) => {

        if (!invites) {
            return invites;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return invites;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return invites.filter((invite) => invite.admin_invite_email.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([
            {
                url: "/invites",
                name: "Kullanıcı Davetleri"
            }
        ]);

        this.getInvitesAsync();
    }

    async getInvitesAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/invites"});

            this.setState((prevState) => ({
                ...prevState,
                invites: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    showDeleteConfirm = (_id, title) => {

        this.props.showConfirm({
            title,
            message: "Bu daveti silmek istediğinize emin misiniz?",
            buttons: [{
                title: "Tamamdır, SİL!",
                asyncFn: async () => {

                    try {

                        await apiCall({
                            url: `/invites/${_id}`,
                            method: "DELETE",
                            toast: `${title} adresine gönderilen davet sistemden silindi.`
                        });

                        this.getInvitesAsync();
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    showResendConfirm = (_id, title) => {

        this.props.showConfirm({
            title,
            message: "Bu daveti tekrar göndermek istediğinize emin misiniz?",
            buttons: [{
                title: "Tamamdır, Tekrar Gönder!",
                asyncFn: async () => {

                    try {

                        await apiCall({
                            url: `/invites/${_id}/resend`,
                            method: "POST",
                            toast: `${title} adresine davet e-postası tekrar gönderildi.`
                        });

                        this.getInvitesAsync();
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    handleSearch = (e) => {

        console.log(e.x.x);

        const searchText = e.target.value.trim();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    getInviteTableColumns() {
        return [
            {
                Header: "E-posta",
                accessor: "admin_invite_email",
                sortable: false
            },
            {

                Header: "Yetki Seviyesi",
                id: "admin_invite_role",
                accessor: (props) => props,
                Cell: (props) => (
                    <>
                        <p>
                            {props.value.admin_invite_role}
                        </p>
                    </>
                )
            },
            {
                Header: "Davet Eden",
                id: "admin_invited_by",
                accessor: (props) => props,
                sortable: true,
                Cell: (props) => (
                    <>
                        <p>
                            {props.value.admin_invited_by}
                        </p>
                    </>
                )
            },
            {
                Header: "Davet Tarihi",
                accessor: "createdAt",
                sortable: true
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 120,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (

                    <div className="level">
                        <div className="level-item">
                            <a tabIndex="0" className="button" href="#" onClick={() => {
                                this.showResendConfirm(props.value._id, props.value.admin_invite_email);
                            }}>
                                <span className="icon is-small"><i className="fa fa-sync-alt"/></span>
                            </a>
                        </div>
                        <div className="level-item">
                            <a tabIndex="0" className="button" href="#" onClick={() => {
                                this.showDeleteConfirm(props.value._id, props.value.admin_invite_email);
                            }}>
                                <span className="icon is-small"><i className="fa fa-trash"/></span>
                            </a>
                        </div>
                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.invites) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="E-posta ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/invites/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Kullanıcı Daveti</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getInviteTableColumns()}
                    data={this.filterInvites(this.state.invites, this.state.searchText)}
                    defaultSorted={[{
                        id: "user_invite_token_created_at",
                        desc: false
                    }]}
                />
            </>
        );
    }
}

export default withContext(Invites);
