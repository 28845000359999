/**
 * CustomerTagAdd Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import TagTypeForm from "./TagTypeForm";

class TagTypeEdit extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        tagType: null
    };

    tagTypeId = this.props.match.params["tagtypeid"];

    componentDidMount() {

        this.getTagTypeAsync();// Update subheader statically
    }

    async getTagTypeAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/data/tag-types/${this.tagTypeId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                tagType: data
            }));

            this.props.setBreadcrumb([{
                url: "/data/tag-types",
                name: "Müşteri Etiketleri (Tags)"
            }, {
                url: `/data/tag-types/${this.tagTypeId}/edit`,
                name: data.tag_type_name
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    async updateTagTypeAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/data/tag-types/${this.tagTypeId}`,
                method: "PUT",
                payload,
                toast: `Etiket tipi güncelledi: ${payload.tag_type_name}`
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/data/tag-types");
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.updateTagTypeAsync(formData, formikBag);
    };

    render() {

        if (!this.state.tagType) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Etiket Tipi Güncelleme
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Etiket Tipi sadece ingilizce karakterler, kısa çizgi, boşluk ve rakam içerebilir.",
                                "Etiket Tipi sistem genelinde benzersiz olmalıdır, aynı isme sahip ikinci etkinlik tipini ekleyemezsiniz.",
                                "Örnek etkinlik tipleri: 'MAKEUP FESTIVAL - KASIM 2018', 'KAHRAMANLAR OKULA DONUYOR - EYLUL 2018'",
                                "Tüm kurallar sistemde tanımlamıştır, aşağıda test edebilir, hata almadığınız her değeri girebilirsiniz."
                            ]}
                            />
                        </div>

                        <TagTypeForm
                            tagType={this.state.tagType}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(TagTypeEdit);


