/* eslint-disable */
import React from "react";
import {Editor, RichUtils} from "draft-js";
import "./RichEditor.css";

export default class RichEditor extends React.Component {

    onChange = (editorState) => {
        this.props.onChange(editorState);
    };

    focus = () => this.refs.editor.focus();

    handleKeyCommand = (command) => {
        const {editorState} = this.props;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    };

    onTab = (e) => {
        const maxDepth = 4;
        this.onChange(RichUtils.onTab(e, this.props.editorState, maxDepth));
    };
    toggleBlockType = (blockType) => {
        this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
    };
    toggleInlineStyle = (inlineStyle) => {
        this.onChange(
            RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle)
        );
    };

    render() {

        const {editorState} = this.props;
        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let"s just hide it now.
        let className = "RichEditor-editor";
        const contentState = editorState.getCurrentContent();

        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== "unstyled") {
                className += " RichEditor-hidePlaceholder";
            }
        }

        return (
            <div className="RichEditor-root">
                <BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                />
                <div className={className} onClick={this.focus}>
                    <Editor
                        blockStyleFn={getBlockStyle}
                        editorState={editorState}
                        handleKeyCommand={this.handleKeyCommand}
                        onChange={this.onChange}
                        onTab={this.onTab}
                        placeholder="Tell a story..."
                        ref="editor"
                        spellCheck={true}
                    />
                </div>
            </div>
        );
    }
}

function getBlockStyle(block) {
    switch (block.getType()) {
        case "blockquote":
            return "RichEditor-blockquote";
        default:
            return null;
    }
}

class StyleButton extends React.Component {

    constructor(props) {

        super(props);

        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {

        let className = "RichEditor-styleButton";
        if (this.props.active) {
            className += " RichEditor-activeButton";
        }
        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    {label: "Başlık", style: "header-three"},
    {label: "Alt Başlık", style: "header-five"},
    {label: "Alıntı", style: "blockquote"},
    {label: "Liste", style: "unordered-list-item"},
    {label: "Numaralı Liste", style: "ordered-list-item"}
];

const BlockStyleControls = (props) => {

    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    return (
        <div className="RichEditor-controls">
            {
                BLOCK_TYPES.map((type) => (
                    <StyleButton
                        key={type.label}
                        active={type.style === blockType}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                ))
            }
        </div>
    );
};

const INLINE_STYLES = [
    {label: "Bold", style: "BOLD"},
    {label: "Italic", style: "ITALIC"},
    {label: "Underline", style: "UNDERLINE"}
];

const InlineStyleControls = (props) => {

    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {
                INLINE_STYLES.map((type) => (
                    <StyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={props.onToggle}
                        style={type.style}
                    />
                ))
            }
        </div>
    );
};
