/**
 * PushNotification Add Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import PushNotificationForm from "./PushNotificationForm";

class PushNotificationAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/marketing/push-notifications",
            name: "Push Gönderimi"
        }, {
            url: "/marketing/push-notifications/new",
            name: "Yeni Push Gönderimi"
        }]);
    }

    async createPushNotificationAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        try {

            await apiCall({
                url: "/marketing/push-notifications",
                method: "POST",
                payload,
                toast: `Yeni push gönderimi oluşturuldu: ${payload.push_notification_text_tr}`
            });

            this.props.history.replace("/marketing/push-notifications");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createPushNotificationAsync(formData, formikBag);
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Yeni Push Gönderimi
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Push notification, mobil uygulamayı indirmiş, hiç giriş yapmamış, CRM le ilişkisi bulunmayan, app kullanıcılarına da gönderilebilir.",
                                "Herkese göndermek istiyorsanız, Gönderim tipini 'Broadcast' seçmelisiniz.",
                                "İleri tarihli gönderimlerin sonradan tarih bilgisini değiştiremezsiniz, tarih değiştirmek istiyorsanız silip tekrar eklemelisiniz.",
                            ]}
                            />
                        </div>

                        <PushNotificationForm
                            setError={this.props.setError}
                            pushNotification={{}}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(PushNotificationAdd);


