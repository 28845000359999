import React from "react";
import PropTypes from "prop-types";

import formatDatetime from "../../../services/utils/format_datetime";
import apiCall from "../../../services/api";

export default class CustomerCoupons extends React.Component {

    static get propTypes() {
        return {
            customer: PropTypes.object.isRequired,
            showConfirm: PropTypes.func.isRequired,
        };
    }

    state = {
        couponPdfUrl: ""
    }

    onCouponPrint = (blobUrl) => {

        this.setState((prevState) => ({
            ...prevState,
            couponPdfUrl: blobUrl
        }));
    }

    handlePrint = async (coupon) => {

        const response = await apiCall({
            url: `/campaign/coupon-codes/${coupon._id}/pdf`,
            method: "GET",
            withAuth: true,
            options: {responseType: "arraybuffer"}
        });     
        
        const couponPdfUrl = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
        this.onCouponPrint(couponPdfUrl);
    }

    showPrintConfirm = (coupon) => {
        const {showConfirm} = this.props;

        showConfirm({
            title: "Bu kuponu yazdırmak istediğinize emin misiniz?",
            message: `${coupon.campaign_title && coupon.campaign_title.tr} kampanyasına ait ${coupon.code} referans numaralı kuponu yazdırıyorsunuz`,
            buttons: [{
                title: "Tamamdır, YAZDIR!",
                asyncFn: () => this.handlePrint(coupon)
            }]
        });
    }

    render() {
        return (
            <div className="tabContainer customerActivities">
                <div className="timelineSection">
                    <div className="timelineLine"/>
                    {
                        this.props.customer.customer_coupons
                            .map((customerCoupon) => {

                                return (
                                    <div key={customerCoupon._id} className="timelineItem">
                                        <div className="timelineIcon">
                                            <span><i className="fa fa-user"/></span>
                                        </div>
                                        <div className="timelinePreHeader">{formatDatetime(customerCoupon.given_at)}</div>
                                        <div className="timelineContent">
                                            <div className="rows">
                                                <div className="row">

                                                    <ul>
                                                        <li>{customerCoupon.campaign_title.tr}</li>
                                                        {
                                                            customerCoupon.store_name && 
                                                            <li>{customerCoupon.store_name}</li>
                                                        }
                                                        <li>{customerCoupon.code}</li>
                                                    </ul>
                                                </div>
                                                <div className="row">
                                                    <button
                                                        type="button"
                                                        className="button payment-item-remove"
                                                        onClick={() => this.showPrintConfirm(customerCoupon)}>
                                                        <span className="icon is-small"><i className="fa fa-print"/></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
                {this.state.couponPdfUrl && <iframe onLoad={(e) => e.target.contentWindow.print()} id="iframe" src={this.state.couponPdfUrl} style={{display: "none"}}></iframe>}
            </div>
        );
    }
}
