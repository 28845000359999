import Moment from "moment-timezone";

/**
 * Format phone string
 *
 */

export default (date) => {

    if (!date) {
        return "";
    }

    return Moment(date).format("DD/MM/YYYY HH:mm");
};
