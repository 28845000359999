/**
 * EmailNotification Add Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Moment from "moment-timezone";

import {FormElements, CrmTable} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import getPrettyNumber from "../../../services/utils/get_pretty_number";

class EmailNotificationReport extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        emailNotification: null
    };

    emailNotificationId = this.props.match.params["emailnotificationid"];

    componentDidMount() {

        this.getEmailNotitificationAsync(); // Update subheader statically
    }

    async getEmailNotitificationAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

        
            const {data} = await apiCall({
                url: `/marketing/email-notifications/${this.emailNotificationId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                emailNotification: data
            }));

            this.props.setBreadcrumb([{
                url: "/marketing/email-notifications",
                name: "E-posta Gönderimi"
            }, {
                url: `/marketing/email-notifications/${this.emailNotificationId}/report`,
                name: `Rapor: ${data.email_notification_subject}`
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    getSendingDetailsTableColumn() {
        return [
            {
                Header: "Gönderim Tarihi",
                accessor: "SendDate",
                sortable: false,
            },
            {
                Header: "Alıcı",
                accessor: "Receiver",
                sortable: false,
            },
            {
                Header: "Gönderim Durumu",
                accessor: "SendStatus",
                sortable: false,
            }
        ];
    }

    getUnsubDetailsTableColumn() {
        return [
            {
                Header: "İşlem Tarihi",
                accessor: "recordTime"
            },
            {
                Header: "Alıcı",
                accessor: "receiver",
            },
            {
                Header: "Sebep",
                accessor: "reason"
            }
        ];
    }

    render() {

        const emailNotification = this.state.emailNotification;

        if (!emailNotification) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-four-fifths-fullhd">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    {`RAPOR: ${emailNotification.email_notification_subject}`}
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                // "Rapor, gönderimi takip eden 15 dakika içinde oluşmaktadır.",
                                "3 gün boyunca saatlik aralıklarla veriler güncellenmektedir."
                            ]}
                            />
                        </div>


                        {
                            emailNotification && (
                                <>
                                    <div className="marketingReportList">
                                        <h5>Rapor Güncellenme Tarihi</h5>
                                        <span>{Moment(emailNotification.email_notification_report_at).format("DD/MM/YYYY HH:mm")}</span>
                                        <h5>Gönderim Adı</h5>
                                        <span>{emailNotification.email_notification_subject}</span>
                                    </div>

                                    <div className="dashboard">
                                        { // Summary
                                            emailNotification.summary &&
                                            <>
                                                <h3 className="sectionTitle">Genel Bakış</h3>
                                                <div className="columns is-multiline">
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Alıcı Sayısı</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.ReceiverCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Toplam Gönderim</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.SentCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Okundu</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.ReadCount)}`}</div>
                                                        </div>
                                                    </div>             
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Abone Sayısı</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.sub_counts.subscriberCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Abonelikten Çıkan</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.sub_counts.unsubscriberCount)}`}</div>
                                                        </div>
                                                    </div>                                      
                                              
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Geçersiz Format</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.InvalidFormatCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Tekrarlanmış Alıcı </div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.RepeatedReceiveCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Bulunmayan Alıcı </div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.UnexistingReceiverCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Bloklayan Alıcı </div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.BlockedReceiverCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Zamanı Geçmiş</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.ExpiredCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Bulunmayan Red</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.RejectedUnexistCount)}`}</div>
                                                        </div>
                                                    </div>
                                                    <div className="column is-4-desktop is-6-tablet is-12 dashboard-box">
                                                        <div className="box dashboard-box">
                                                            <div className="heading" lang="tr">Diğer Red</div>
                                                            <div className="title">{`${getPrettyNumber(emailNotification.summary.RejectedOtherCount)}`}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }     

                                        { 
                                            emailNotification.details &&
                                            <>
                                                <h3 className="sectionTitle">Gönderim Detayları</h3>
                                                <CrmTable
                                                    maxHeight="100"
                                                    columns={this.getSendingDetailsTableColumn()}
                                                    data={this.state.emailNotification.details}
                                                    defaultSorted={[{
                                                        id: "SendDate",
                                                        desc: false
                                                    }]}
                                                />
                                            </>
                                        }
                                        { 
                                            emailNotification.unsub_report &&
                                            <>
                                                <h3 className="sectionTitle">Abonelik Çıkış Detayları</h3>
                                                <CrmTable
                                                    maxHeight="100"
                                                    columns={this.getUnsubDetailsTableColumn()}
                                                    data={this.state.emailNotification.unsub_report}
                                                    defaultSorted={[{
                                                        id: "recordTime",
                                                        desc: false
                                                    }]}
                                                />
                                            </>
                                        }                                                
                                    </div>                                    
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(EmailNotificationReport);


