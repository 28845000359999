/**
 * Company Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";

class CompanyForm extends PureComponent {

    static propTypes = {
        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        company: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    state = {};

    handleSubmit = (formData, formikBag) => {

        this.props.handleSubmit(formData, formikBag);
    };

    render() {

        const {company} = this.props;

        if (!company) {
            return null;
        }

        const initialValues = {
            company_name: company.company_name
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                validationSchema={() => Yup.object().shape({
                    company_name: Yup.string()
                        .required("Şirket Adı zorunlu bir alandır."),
                })}
                render={({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    isSubmitting
                }) => (
                    <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            error={touched.company_name && errors.company_name}
                            inputName="company_name"
                            displayName="Şirket Adı *"
                            isSubmitting={isSubmitting}
                            value={values.company_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText={this.props.submitText}
                            isSubmitting={isSubmitting}
                        />

                    </form>
                )}
            />
        );
    }
}

export default withContext(CompanyForm);
