/**
 * Coupon Campaign Edit Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import CouponCampaignForm from "./CouponCampaignForm";

class CouponCampaignEdit extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        couponCampaign: null
    };

    campaignId = this.props.match.params["campaignid"];

    componentDidMount() {

        this.getCampaignAsync(); // Update subheader statically
    }

    async getCampaignAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/campaign/coupon-campaigns/${this.campaignId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                couponCampaign: data
            }));

            this.props.setBreadcrumb([{
                url: "/campaign/coupon-campaigns",
                name: "Kupon Kampanyaları"
            }, {
                url: `/campaign/coupon-campaigns/${this.campaignId}/edit`,
                name: data.coupon_campaign_name_tr
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    async updateCouponCampaignAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/campaign/coupon-campaigns/${this.campaignId}`,
                method: "PUT",
                payload,
                toast: `Kupon Kampanyası güncelledi: ${payload.coupon_campaign_name_tr}`
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/campaign/coupon-campaigns");
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        console.log(formData);
        this.updateCouponCampaignAsync(formData, formikBag);
    };

    render() {

        if (!this.state.couponCampaign) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Kupon Kampanyası Güncelleme
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                // "Bilet Stok Kodu ve Biletin bağlı olduğu Mağaza/Etkinlik daha sonra değiştirelemez, silip yeniden eklemeniz gerekir.",
                                // "Bilet Tutarı da otomatik olarak NET1 'den gelmektedir, NET1 tarafında güncelleme yaparsanız, " +
                                // "~1 saat içinde otomatik olarak sistem ekli biletler arasında gezecek ve fiyat güncellemesi yapacaktır."
                            ]}
                            />
                        </div>
                        <CouponCampaignForm
                            isEdit
                            couponCampaign={this.state.couponCampaign}
                            handleSubmit={this.handleSubmit}
                            submitText="Güncelle"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CouponCampaignEdit);


