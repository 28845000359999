export function getFilterOptions(filterItem) {

    let label = filterItem.label || filterItem.value || "";

    if (filterItem.type === "startsWith") {
        return `"${label}" ile başlayan`;
    }
    else if (filterItem.type === "contains") {
        return `"${label}" içeren`;
    }
    else if (filterItem.type === "exists") {
        return "Herhangi bir değer içeren";
    }
    else if (filterItem.type === "greater") {
        return `"${label}" büyük sayılar`;
    }
    else if (filterItem.type === "less") {
        return `"${label}" küçük sayılar`;
    }
    else if (filterItem.type === "exists") {
        return "Herhangi bir değer içeren";
    }
    else if (filterItem.type === "equals") {
        return `Eşittir: "${label}"`;
    }
    else if (filterItem.type === "includes") {
        return `İçeren: "${label}"`;
    }
    else if (filterItem.type === "not-includes") {
        return `İçermeyen: "${label}"`;
    }
    else if (filterItem.type === "boolean") {
        return `Değer: "${label}"`;
    }
    else if (filterItem.type === "before") {
        return `Öncesinde: "${label}"`;
    }
    else if (filterItem.type === "after") {
        return `Sonrasında: "${label}"`;
    }
    return "";
}

export function getPrettyTextForFilter(filter) {

    if (filter === "startsWith") {
        return "Başlayan";
    }
    else if (filter === "contains") {
        return "İçeren";
    }
    else if (filter === "exists") {
        return "Herhangi Bir Değer";
    }
    else if (filter === "greater") {
        return "Büyüktür";
    }
    else if (filter === "less") {
        return "Küçüktür";
    }
    else if (filter === "equals") {
        return "Eşittir";
    }
    else if (filter === "includes") {
        return "İçeren";
    }
    else if (filter === "not-includes") {
        return "İçermeyen";
    }
    else if (filter === "boolean") {
        return "Değer";
    }
    else if (filter === "before") {
        return "Öncesinde";
    }
    else if (filter === "after") {
        return "Sonrasında";
    }
    throw new Error(`${filter} is not defined in: getPrettyTextForFilter`);
}
