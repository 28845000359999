import React from "react";

const LoadingContext = React.createContext({
    isLoading: false,
    setIsLoading: () => {},
    isFullScreenLoading: false,
    setIsFullScreenLoading: () => {}
});

export default LoadingContext;
