/**
 * Company Staff Add Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import CompanyStaffForm from "./CompanyStaffForm";

class CompanyStaffAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/export-logs/companies/staffers",
            name: "Şirket Personelleri"
        }, {
            url: "/export-logs/companies/staffers/new",
            name: "Yeni Personel Ekle"
        }]);
    }

    async createStaffAsync({company_id, ...payload}, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/export-logs/company/${company_id}/staff`,
                method: "POST",
                payload,
                toast: `Personel oluşturuldu: ${payload.company_staff_name} ${payload.company_staff_surname}`
            });

            this.props.history.replace("/export-logs/companies/staffers");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createStaffAsync(formData, formikBag);
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Yeni Personel
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                            ]}
                            />
                        </div>

                        <CompanyStaffForm
                            staff={{}}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CompanyStaffAdd);


