/**
 * Confirm modal component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

class ConfirmWithName extends Component {

    static propTypes = {

        isVisible: PropTypes.bool.isRequired,
        onConfirmAsync: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
        onClose: PropTypes.func,
        validateText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
        operationInProgress: PropTypes.bool,

        title: PropTypes.string,
        confirmText: PropTypes.string
    };

    static defaultProps = {

        title: "Emin misiniz?",
        confirmText: "Tamamdır, SİL!"
    };

    _isMounted = false;

    state = {
        operationInProgress: false,
        isMatch: false,
        typedText: ""
    };

    componentDidMount() {

        this._isMounted = true;
    }

    componentWillUnmount() {

        this._isMounted = false;
    }

    onClose = async (isConfirmed) => {

        if (isConfirmed && this.props.onConfirmAsync) {

            this.setState((prevState) => ({
                ...prevState,
                operationInProgress: true
            }));

            await this.props.onConfirmAsync();

            if (this._isMounted) {
                this.setState((prevState) => ({
                    ...prevState,
                    typedText: "",
                    operationInProgress: false
                }));
            }
        }

        if (this.props.onClose && this._isMounted) {
            return this.props.onClose(isConfirmed);
        }
    };

    render() {

        if (!this.props.isVisible) {
            return null;
        }

        return (

            <div className="modal-container">
                <div
                    className={`modal confirm-modal is-active ${this.state.operationInProgress ? "operation-in-progress" : ""}`}>
                    <div
                        role="presentation"
                        className="modal-background"
                        onClick={() => {
                            if (!this.props.operationInProgress) {
                                this.onClose(false);
                            }
                        }}
                    />
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{this.props.title}</p>
                            <button
                                type="button"
                                tabIndex="0"
                                className="delete hot-fix"
                                onClick={() => {
                                    if (!this.props.operationInProgress) {
                                        this.onClose(false);
                                    }
                                }}
                            />
                        </header>
                        <section className="modal-card-body">
                            {this.props.children}
                            <div className="control">
                                <input
                                    className="input is-medium"
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    value={this.state.typedText}
                                    disabled={this.props.operationInProgress}
                                    onChange={(e) => {

                                        const {value} = e.currentTarget;

                                        this.setState((prevState) => ({
                                            ...prevState,
                                            typedText: value,
                                            isMatch: value === this.props.validateText
                                        }));
                                    }}
                                />
                            </div>
                        </section>
                        <footer className="modal-card-foot" style={{
                            alignItems: "center",
                            justifyContent: "flex-end"
                        }}>
                            <button
                                type="button"
                                color="button is-danger"
                                className={`button is-fullwidth is-size-5 has-text-weight-bold is-danger ${this.state.operationInProgress ? "is-loading" : null}`}
                                onClick={() => {
                                    if (!this.props.operationInProgress) {
                                        this.onClose(true);
                                    }
                                }}
                                disabled={!this.state.isMatch || this.props.operationInProgress}>
                                {this.props.confirmText}
                            </button>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConfirmWithName;
