/**
 * ImageInputPreview component
 */
/**
 *  component
 */

import React from "react";

import PropTypes from "prop-types";

const ImageInputPreview = (props) => (
    <div className="image-thumb-container" style={{height: props.previewHeight}}>
        <img src={props.image} alt=""/>
        {
            props.image
                ? (
                    <button
                        type="button"
                        className="button delete-button"
                        disabled={props.isLoading}
                        onClick={props.clearImage}>
                        <span className="icon is-small has-text-danger">
                            <i className="fa fa-trash"/>
                        </span>
                    </button>
                )
                : null
        }
    </div>
);

ImageInputPreview.propTypes = {

    previewHeight: PropTypes.number,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    clearImage: PropTypes.func,
    isLoading: PropTypes.bool
};

export default ImageInputPreview;
