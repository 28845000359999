/**
 * CustomerCheck Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";

import {CustomerForm, FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class CustomerRegister extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        entryContext: PropTypes.shape({
            entryCustomer: PropTypes.shape.isRequired,
            entryEventType: PropTypes.shape.isRequired,
            setEntryCustomer: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {
        isSaving: false,
        saveResult: null
    };


    componentDidMount() {

        // check if actual type matches.
        const entryEventType = this.props.entryContext.entryEventType;
        const customer = this.props.entryContext.entryCustomer;

        if (!entryEventType) {
            this.props.history.replace("/customer-entry");
            return;
        }

        if (!customer || !customer.customer_phone) {
            this.props.history.replace("/customer-entry/check");
            return;
        }

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/customer-entry",
            name: "Ziyaretçi Kayıt"
        }, {
            url: "/customer-entry/check",
            name: "Kayıt Başlangıç",
        }, {
            url: "/customer-entry/register",
            name: "Bilgi Girişi",
        }]);
    }

    registerAsync = async (payload, {setSubmitting}) => {

        // START LOADING
        this.props.setError(null);
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // Checking State
        this.setState((prevState) => ({
            ...prevState,
            isSaving: true
        }));

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customer-entry/register",
                method: "POST",
                payload
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);

            this.props.entryContext.setEntryCustomer(data.customer);
            this.setState((prevState) => ({
                ...prevState,
                isSaving: false,
                saveResult: data.type
            }));
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);

            // Checking State
            this.setState((prevState) => ({
                ...prevState,
                isSaving: false
            }));
            this.props.setError(e);
        }
    };

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        const payload = {
            ...formData,
            eventType: this.props.entryContext.entryEventType
        };

        this.registerAsync(payload, formikBag);
    };

    render() {

        if (this.state.saveResult === "sms-validation") {
            return (
                <Redirect to="/customer-entry/sms-validation"/>
            );
        }

        if (this.state.saveResult === "success") {
            return (
                <Redirect to="/customer-entry/success/register-customer"/>
            );
        }

        if (!this.props.entryContext.entryEventType || !this.props.entryContext.entryCustomer) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    {this.props.entryContext.entryEventType.event_type_name}
                                    {" (Yeni Kayıt)"}
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "İSİM / SOYİSİM / CİNSİYET bilgisi zorunludur.",
                                "Ziyaretçiye diğer bilgileri de mutlaka sorulmalıdır."
                            ]}
                            />
                        </div>

                        <CustomerForm
                            type="Register"
                            customer={this.props.entryContext.entryCustomer}
                            eventType={this.props.entryContext.entryEventType}
                            showConfirm={this.props.showConfirm}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CustomerRegister);


