/**
 * SmsCodeValidation component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

class SmsCodeValidation extends Component {

    static propTypes = {
        isSubmitting: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
    };

    state = {
        activeInput: "0",
        inputs: {
            "0": "",
            "1": "",
            "2": "",
            "3": "",
        }
    };

    textInputRefs = [
        React.createRef(),
        React.createRef(),
        React.createRef(),
        React.createRef()
    ];

    componentDidUpdate() {

        this.textInputRefs[this.state.activeInput].current.focus();
    }

    onChange = () => {

        const inputKeys = Object.keys(this.state.inputs);
        let isValid = true;

        for (const inputKey of inputKeys) {
            if (!this.state.inputs[inputKey]) {
                isValid = false;
            }
        }

        const val = Object.keys(this.state.inputs).map((inputKey) => (this.state.inputs[inputKey])).join("");
        this.props.onChange(val, isValid);
    };

    render() {

        return (
            <div className="smsValidation">
                {
                    Object.keys(this.state.inputs).map((inputKey) => (
                        <input
                            autoFocus={inputKey === "0"}
                            ref={this.textInputRefs[parseInt(inputKey)]}
                            key={inputKey}
                            autoComplete="off"
                            type="text"
                            disabled={this.props.isSubmitting}
                            className="input"
                            name={`sms-validation-${inputKey}`}
                            value={this.state.inputs[inputKey]}
                            onFocus={(e) => {

                                e.target.select();

                                this.setState((prevState) => ({
                                    ...prevState,
                                    activeInput: inputKey
                                }));
                            }}
                            onChange={(e) => {

                                let val = e.target.value;
                                if (val && val.length > 1) {
                                    val = val[0];
                                }

                                if (val && !/[0-9]/.test(val)) {
                                    return;
                                }

                                if (val) { // set next for focus

                                    const inputKeyNumber = parseInt(inputKey);
                                    if (inputKeyNumber !== 3) {
                                        this.setState((prevState) => ({
                                            ...prevState,
                                            activeInput: `${inputKeyNumber + 1}`
                                        }));
                                    }
                                }
                                else {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        activeInput: inputKey
                                    }));
                                }

                                this.setState((prevState) => ({
                                    ...prevState,
                                    inputs: {
                                        ...prevState.inputs,
                                        [inputKey]: val
                                    }
                                }), () => {

                                    this.onChange();
                                });


                            }}
                        />
                    ))
                }
            </div>
        );
    }
}

export default SmsCodeValidation;
