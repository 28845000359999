/**
 * EmailNotifications Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";

import apiCall from "../../../services/api";
import RemoveItem from "../../../services/utils/remove_item";
import withContext from "../../../context/withContext";
import {CrmTable, TestModal} from "../../../components";

class EmailExternalLists extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showNamedConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        emailExternalLists: null,

        // COMPONENT
        showTestModal: false,
        searchText: ""
    };

    filterEmailExternalLists = Memoize((emailExternalLists, searchText) => {

        if (!emailExternalLists) {
            return emailExternalLists;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return emailExternalLists;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return emailExternalLists.filter((emailExternalList) => emailExternalList.list_name.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/marketing/email-external-lists",
            name: "Harici E-posta Listeleri"
        }]);

        this.getEmailExternalListsAsync();
    }

    async getEmailExternalListsAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/marketing/email-external-lists"});

            this.setState((prevState) => ({
                ...prevState,
                emailExternalLists: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }


    showDeleteConfirm = (emailExternalList) => {

        this.props.showNamedConfirm({
            validateText: emailExternalList.list_name,
            children: (
                <>
                    <div className="notification is-warning is-fixed-to-body">
                        {"Bu silme işlemi "}
                        <strong>GERİ ALINAMAZ.</strong>
                    </div>
                    <p>
                        {"Bu listeyi: ("}
                        <strong>{emailExternalList.list_name}</strong>
                        {") silerek, external kullanıcıları sistemden sileceksiniz."}
                    </p>
                    <p>
                        {"Harici listeyi silmek için aşağıdaki alana liste ismini giriniz."}
                    </p>
                </>
            ),
            asyncFunction: async () => {

                try {

                    await apiCall({
                        url: `/marketing/email-external-lists/${emailExternalList._id}`,
                        method: "DELETE",
                        toast: `E-posta listesi: '${emailExternalList.list_name}' silindi.`
                    });

                    this.setState((prevState) => ({
                        ...prevState,
                        emailExternalLists: RemoveItem(prevState.emailExternalLists, emailExternalList._id)
                    }));
                }
                catch (e) {

                    this.props.setError(e);
                }
            }
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    getEmailExternaListTableColumns() {
        return [
            {
                Header: "Liste İsmi",
                accessor: "list_name",
                sortable: true,
            },
            {
                Header: "Durum",
                id: "list_status",
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (
                    <>
                        {
                            props.value.list_status === "STARTED" && (
                                <>
                                    <p style={{color: "red"}}>E-postalar MailChimp (E-mail Provider) sistemine import ediliyor. Sayfayı yenileyerek güncel durumu görebilirsiniz.</p>
                                    <br/>
                                    <p style={{color: "red"}}>Ortalama 2000 eposta, 10 dakika sürmektedir.</p>
                                </>
                            )
                        }
                        {
                            props.value.list_status === "COMPLETED" && (
                                <p>E-postalar işlendi ve kullanıma hazır durumda.</p>
                            )
                        }
                    </>
                )

            },
            {
                Header: "E-posta Sayısı",
                accessor: "list_count",
                sortable: true,
            },
            {
                Header: "Yüklenen Excel",
                id: "list_file",
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (
                    <a target="_href" href={props.value.list_file}>
                        İndirmek için tıklayın
                    </a>
                )
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 350,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (

                    <div className="level">
                        <div className="level-item">
                            <a tabIndex="0" className="button" href="#" onClick={() => {
                                this.showDeleteConfirm(props.value);
                            }}>
                                <span className="icon is-small"><i className="fa fa-trash"/></span>
                            </a>
                        </div>
                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.emailExternalLists) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Liste ismi ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/marketing/email-external-lists/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Harici Liste</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getEmailExternaListTableColumns()}
                    data={this.filterEmailExternalLists(this.state.emailExternalLists, this.state.searchText)}
                    defaultSorted={[{
                        id: "-createdAt",
                        desc: false
                    }]}
                />

                {
                    this.state.showTestModal ? (

                        <TestModal
                            type="Email"
                            isVisible={!!this.state.showTestModal}
                            onClose={() => {

                                this.setState((prevState) => ({
                                    ...prevState,
                                    showTestModal: false
                                }));
                            }}
                            onSendAsync={this.state.sendTestEmail}
                        />

                    ) : null
                }
            </>
        );
    }
}

export default withContext(EmailExternalLists);
