/**
 * CustomerCheck Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import withContext from "../../../context/withContext";

class CustomerEntrySuccess extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        entryContext: PropTypes.shape({
            entryEventType: PropTypes.shape().isRequired,
            entryCustomer: PropTypes.shape().isRequired,
            setEntryCustomer: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    successType = this.props.match.params["successtype"];

    componentDidMount() {

        // check if actual type matches.
        const entryEventType = this.props.entryContext.entryEventType;
        const customer = this.props.entryContext.entryCustomer;

        if (!entryEventType) {
            this.props.history.replace("/customer-entry");
            return;
        }

        if (!customer || !customer.customer_phone) {
            this.props.history.replace("/customer-entry/check");
            return;
        }

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/customer-entry",
            name: "Ziyaretçi Kayıt"
        }, {
            url: "/customer-entry/check",
            name: "Kayıt Başlangıç",
        }, {
            url: `/customer-entry/success/${this.successType}`,
            name: "Kayıt Başarılı",
        }]);
    }

    render() {


        if (!this.props.entryContext.entryEventType || !this.props.entryContext.entryCustomer) {
            return null;
        }

        const customer = this.props.entryContext.entryCustomer;

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box customerDataDisplay">

                        <h1 className="has-text-link has-text-centered">KAYIT BAŞARILI!</h1>
                        <p style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "0.9rem"
                        }}>
                            {
                                this.successType === "register-customer" ? "Yeni ziyaretçi kaydı oluşturup, bu kayda aktivite eklediniz." : null
                            }
                            {
                                this.successType === "update-customer" ? "Sistemde bulunan ziyaretçi bilgilerini güncelleyip, bu kayda aktivite eklediniz." : null
                            }
                        </p>
                        <ul>
                            <li>
                                <h5>TELEFON:</h5>
                                <span>{customer.customer_phone}</span>
                            </li>
                            <li>
                                <h5>İSİM:</h5>
                                <span>{customer.customer_name}</span>
                            </li>
                            <li>
                                <h5>SOYİSİM:</h5>
                                <span>{customer.customer_surname}</span>
                            </li>
                            <li>
                                <h5>CİNSİYET:</h5>
                                {
                                    customer.customer_gender === "male" ? (
                                        <span>ERKEK</span>
                                    ) : null
                                }
                                {
                                    customer.customer_gender === "female" ? (
                                        <span>KADIN</span>
                                    ) : null
                                }
                            </li>
                            <li>
                                <h5>E-POSTA:</h5>
                                <span>{customer.customer_email}</span>
                            </li>
                            <li>
                                <h5>DOĞUM TARİHİ:</h5>
                                <span>{customer.customer_birthdate}</span>
                            </li>
                        </ul>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <a
                                className="button is-info full-width"
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.entryContext.setEntryCustomer(null);
                                    this.props.history.replace("/customer-entry/check");
                                }}>
                                YENİ KAYIT
                            </a>
                        </div>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "1rem"
                        }}>
                            <Link
                                style={{
                                    color: "#555",
                                    fontSize: "0.9rem"
                                }}
                                replace
                                to={`/customer-entry/update/${customer._id}`}>
                                Bu ziyaretçiyi güncelle
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CustomerEntrySuccess);


