/**
 * PushNotification Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import Moment from "moment-timezone";

import {CustomerFilter, FormElements} from "../../../components";
import dateCheck from "../../../services/utils/date_check";
import apiCall from "../../../services/api";
import withContext from "../../../context/withContext";
import preDefinedPushFilters from "../../../../../common/pre_defined_push_filters";

const ContentTypeOptions = [{
    name: "Broadcast",
    value: "BROADCAST"
}, {
    name: "Filtreler",
    value: "FILTER"
}];

class PushNotificationForm extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        setError: PropTypes.func.isRequired,

        pushNotification: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isEdit: PropTypes.bool
    };

    state = {
        isFilterActive: this.props.pushNotification && this.props.pushNotification.push_notification_type === "FILTER",
        filters: this.props.pushNotification.push_notification_filters || [],
        filteredCustomerCount: 0,
        isSubmitting: false
    };

    componentDidMount() {

        this.getFilteredCustomerCount();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.filters !== this.state.filters) {
            this.getFilteredCustomerCount();
        }
    }

    getFilterText = () => {

        if (!this.state.filters || this.state.filters.length === 0) {

            return (
                // eslint-disable-next-line
                <span>Push Filtresi <strong>Aktif</strong>. Tahmini gönderim yapılacak müşteri sayısı: <strong>{this.state.filteredCustomerCount}</strong></span>
            );
        }

        return (
            // eslint-disable-next-line
            <span>Push Filtresi ve Özelleştirilmiş Filtre <strong>Aktif</strong>. Tahmini gönderim yapılacak müşteri sayısı: <strong>{this.state.filteredCustomerCount}</strong></span>
        );
    };

    handleSubmit = (formData, formikBag) => {

        // Transform Select input data to just ids
        const transformedPayload = {
            ...formData,
            push_notification_filters: this.state.filters,
            push_notification_potential: this.state.filteredCustomerCount,
        };

        this.props.handleSubmit(transformedPayload, formikBag);
    };

    async getFilteredCustomerCount() {

        this.props.setError(null);
        this.props.loadingContext.setIsFullScreenLoading(true);

        const filter = [...this.state.filters, ...preDefinedPushFilters];

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customers/count",
                method: "POST",
                payload: {
                    filter
                }
            });

            this.setState((prevState) => ({
                ...prevState,
                filteredCustomerCount: data.filteredCustomerCount
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsFullScreenLoading(false);
    }

    render() {

        const pushNotification = this.props.pushNotification;
        if (!pushNotification) {
            return null;
        }

        const {isFilterActive} = this.state;

        return (

            <>

                {
                    isFilterActive && (
                        <div style={{marginBottom: "2rem"}}>
                            <CustomerFilter
                                getTitle={this.getFilterText}
                                filters={this.state.filters}
                                addFilter={(filter) => {

                                    this.setState((prevState) => ({
                                        ...prevState,
                                        filters: [filter, ...prevState.filters]
                                    }));
                                }}
                                removeFilter={(filterIndex) => {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        filters: [
                                            ...prevState.filters.slice(0, filterIndex),
                                            ...prevState.filters.slice(filterIndex + 1)
                                        ]
                                    }));
                                }}
                                clearFilters={() => {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        filters: []
                                    }));
                                }}
                            />
                        </div>
                    )
                }

                <Formik
                    initialValues={{
                        push_notification_text_tr: pushNotification.push_notification_text_tr,
                        push_notification_text_en: pushNotification.push_notification_text_en,
                        push_notification_type: pushNotification.push_notification_type || "BROADCAST",
                        push_notification_filter: pushNotification.push_notification_filter,
                        push_notification_scheduled_for_datetime: pushNotification.push_notification_scheduled_for_datetime
                    }}
                    onSubmit={this.handleSubmit}
                    validationSchema={() => Yup.object().shape({
                        push_notification_text_tr: Yup.string()
                            .min(10, "Push İçeriği en az 10 karakter içermelidir.")
                            .max(120, "Push İçeriği en fazla 120 karakter içermelidir.")
                            .required("Push İçeriği (TR) zorunlu bir alandır."),
                        push_notification_text_en: Yup.string()
                            .min(10, "Push İçeriği en az 10 karakter içermelidir.")
                            .max(120, "Push İçeriği en fazla 120 karakter içermelidir.")
                            .required("Push İçeriği (EN) zorunlu bir alandır."),
                        push_notification_type: Yup.string()
                            .required("Push Gönderim Tipi zorunlu bir alandır."),
                        push_notification_scheduled_for_datetime: Yup.date()
                            .test("dateTimeStructureCheck", "Geçersiz bir tarih girdiniz. GG/AA/YYYY SS:dd formatında olmalıdır.", dateCheck.dateTimeStructureCheck)
                            .test("dateTimeRangeCheck", "Lütfen ileri tarihli bir seçim yapınız.", dateCheck.dateTimeIsInFutureCheck)
                    })}
                    render={({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        // setSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                            <FormElements.Radio
                                displayName="Gönderim Türü *"
                                inputName="push_notification_type"
                                options={ContentTypeOptions}
                                error={touched.push_notification_type && errors.push_notification_type}
                                isSubmitting={isSubmitting}
                                value={values.push_notification_type || "BROADCAST"}
                                onChange={(e) => {

                                    this.setState((prevState) => ({
                                        ...prevState,
                                        isFilterActive: e.target.value === "FILTER"
                                    }));

                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                error={touched.push_notification_text_tr && errors.push_notification_text_tr}
                                inputName="push_notification_text_tr"
                                displayName="Push İçeriği (TR) *"
                                isSubmitting={isSubmitting}
                                value={values.push_notification_text_tr || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                error={touched.push_notification_text_en && errors.push_notification_text_en}
                                inputName="push_notification_text_en"
                                displayName="Push İçeriği (TR) *"
                                isSubmitting={isSubmitting}
                                value={values.push_notification_text_en || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.DateInput
                                disabled={this.props.isEdit}
                                autoComplete="off"
                                includeTime={true}
                                displayName="İleri Tarih İçin Gönderim Ayarla"
                                inputName="push_notification_scheduled_for_datetime"
                                placeholder="GG/AA/YYYY SS:dd"
                                error={touched.push_notification_scheduled_for_datetime && errors.push_notification_scheduled_for_datetime}
                                isSubmitting={isSubmitting}
                                selectedValue={values.push_notification_scheduled_for_datetime}
                                onChange={(selectedDate) => {
                                    setFieldValue("push_notification_scheduled_for_datetime", selectedDate);
                                    setFieldTouched("push_notification_scheduled_for_datetime");
                                }}
                                onBlur={() => {
                                    setFieldTouched("push_notification_scheduled_for_datetime", true);
                                }}
                                hint="GG/AA/YYYY SS:dd şeklinde input girişi yapılmalıdır, örnek: 01/01/2019 16:30"
                                minDate={Moment().add(1, "day").toDate()}
                                maxDate={Moment().add(1, "months").toDate()}
                                selectsEnd
                            />

                            <FormElements.Submit
                                submitClasses={["full-width"]}
                                submitText={this.props.submitText}
                                isSubmitting={isSubmitting}
                            />
                        </form>
                    )}
                />
            </>
        );
    }
}

export default withContext(PushNotificationForm);


