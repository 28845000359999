const apiUrl = {
    development: "http://localhost:7301",
    test:"http://192.168.1.50:7301",
    production: "https://apicrm.vadistanbul.com"
};

export const HTTP = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE"
};

export const endPoint = process.env.REACT_APP_ENV === "TEST_SERVER" ? (
    apiUrl.test
): (
    process.env.NODE_ENV === "development" ? apiUrl.development : apiUrl.production
);

export const keys = {
    Token: "VADI_ISTANBUL__JWT",
    Refresh: "VADI_ISTANBUL__REFRESH",
    Admin: "VADI_ISTANBUL__ADMIN",
    TokenNeeded: "Protected resource, TokenNeeded: jwt malformed"
};

export const PAGINATION = {
    DEFAULT_PAGINATION: {
        limit: 10,
        currentPage: 1,
        total: 0
    },
    DEFAULT_PAGE_SIZE: 10,
    PAGE_SIZE_OPTIONS: [10, 25, 50, 100]
};
