import React from "react";
import PropTypes from "prop-types";
import {getFilterOptions} from "../CustomerFilter/filterDefinitions";

const NotificationFilterDetails = (props) => {
    return (
        <div>
            {
                props.status === "SENT" ? (
                    <p>
                        <strong>Gönderim sayısı:</strong>
                        {" "}
                        {props.actual}
                    </p>
                ) : (
                    <p>
                        <strong>Tahmini gönderim sayısı:</strong>
                        {" "}
                        {props.potential}
                    </p>
                )
            }
            <p>
                <strong>Filtreler:</strong>
            </p>

            <ul className="is-12-mobile notification-filters">
                <li className="notification-filter-item">
                    {
                        props.type === "SMS" && (
                            <h5>Default SMS Filtresi</h5>
                        )
                    }
                    {
                        props.type === "EMAIL" && (
                            <h5>Default E-mail Filtresi</h5>
                        )
                    }
                    {
                        props.type === "PUSH" && (
                            <h5>Default Push Filtresi</h5>
                        )
                    }
                </li>
                {
                    props.filters && props.filters.map((filter) => (

                        <li key={filter.display} className="notification-filter-item">
                            <h5>{filter.display}</h5>
                            <p>{getFilterOptions(filter)}</p>
                        </li>
                    ))
                }
            </ul>

            {
                props.externalLists && props.externalLists.length > 0 ? (
                    <>
                        <p>
                            <strong>Harici Listeler:</strong>
                        </p>
                        <ul className="is-12-mobile notification-filters">
                            {
                                props.externalLists.map((externalList) => (

                                    <li key={externalList.name} className="notification-filter-item">
                                        <h5>{externalList.name}</h5>
                                        <p>{externalList.count}</p>
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                ) : null

            }
        </div>
    );
};

NotificationFilterDetails.propTypes = {
    status: PropTypes.oneOf([
        "CREATED",
        "SCHEDULED",
        "SEND-IN-PROGRESS",
        "SENT"
    ]),
    type: PropTypes.oneOf([
        "SMS",
        "EMAIL",
        "PUSH"
    ]),
    filters: PropTypes.arrayOf(PropTypes.shape()),
    potential: PropTypes.number,
    actual: PropTypes.number,
    externalLists: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        count: PropTypes.number
    }))
};

export default NotificationFilterDetails;
