import React from "react";
import PropTypes from "prop-types";
import Moment from "moment-timezone";

const NotificationStatus = ({status, error, scheduledFor, createdAt, createdBy, sentAt, sentBy}) => {

    return (
        <>
            {
                createdBy &&
                <>
                    <p>
                        <strong>Oluşturan:</strong>
                        {` ${createdBy}`}
                    </p>
                    {
                        createdAt &&
                        <p>
                            {Moment(createdAt).format("DD/MM/YYYY HH:mm Z")}
                        </p>
                    }
                </>
            }
            {
                sentBy &&
                <>
                    <p>
                        <strong>Gönderen:</strong>
                        {` ${sentBy}`}
                    </p>
                    {
                        sentAt &&
                        <p>
                            {Moment(sentAt).format("DD/MM/YYYY HH:mm Z")}
                        </p>
                    }
                </>
            }
            <br/>
            {
                status === "CREATED" &&
                <a className="button notification-status-button is-info">
                    <span className="icon">
                        <i className="fas fa-ban"/>
                    </span>
                    <span className="status-scheduled-button">
                        Gönderim daha yapılmadı.
                    </span>
                </a>
            }
            {
                status === "SCHEDULED" &&
                <a
                    className="button notification-status-button is-info tooltip is-tooltip-warning is-tooltip-top"
                    data-tooltip={`${Moment(scheduledFor).format("DD/MM/YYYY HH:mm Z")} tarihinde gonderilecek.`}>
                    <span className="icon">
                        <i className="fas fa-exclamation-triangle"/>
                    </span>
                    <span className="status-scheduled-button">
                        Gönderim zamanı bekleniyor.
                    </span>
                </a>
            }
            {
                status === "WAITING-FOR-APPROVAL" &&
                <a
                    className="button notification-status-button is-warning tooltip is-tooltip-warning is-tooltip-top"
                    data-tooltip="Onay maili 1 saat geçerli olacaktır.">
                    <span className="icon">
                        <i className="fas fa-info-circle"/>
                    </span>
                    <span className="status-scheduled-button">
                        Onay bekleniyor.
                    </span>
                </a>
            }
            {
                status === "SEND-IN-PROGRESS" &&
                <a
                    className="button notification-status-button is-warning">
                    <span className="icon">
                        <i className="fas fa-spinner"/>
                    </span>
                    <span className="status-sent-button">
                        Gönderim yapılıyor, lütfen bekleyiniz...
                    </span>
                </a>
            }
            {
                status === "SENT" &&
                <a
                    className="button notification-status-button is-success tooltip is-tooltip-success is-tooltip-top"
                    data-tooltip={`${Moment(sentAt).format("DD/MM/YYYY HH:mm Z")} tarihinde gönderildi.`}>
                    <span className="icon">
                        <i className="fas fa-check-square"/>
                    </span>
                    <span className="status-sent-button">
                        Gönderildi.
                    </span>
                </a>
            }
            {
                status === "ERROR" &&
                <a
                    className="button notification-status-button is-danger tooltip is-tooltip-danger is-tooltip-top"
                    data-tooltip={error}>
                    <span className="icon">
                        <i className="fas fa-exclamation-triangle"/>
                    </span>
                    <span className="status-scheduled-button">
                        Gönderim sırasında hata oluştu.
                    </span>
                </a>
            }
        </>
    );

};

NotificationStatus.propTypes = {
    status: PropTypes.oneOf([
        "CREATED",
        "SCHEDULED",
        "SEND-IN-PROGRESS",
        "SENT",
        "ERROR"
    ]),
    error: PropTypes.string,
    createdBy: PropTypes.string,
    createdAt: PropTypes.string,
    sentBy: PropTypes.string,
    sentAt: PropTypes.string,
    scheduledFor: PropTypes.string
};

export default NotificationStatus;
