/**
 * CrmTable component
 */

import React from "react";
// Import React Table
import ReactTable from "react-table";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {CrmTable} from "../index";
import PropTypes from "prop-types";

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class DragTrComponent extends React.Component {

    render() {

        const {children = null, rowInfo} = this.props;
        if (rowInfo) {
            const {original, index} = rowInfo;
            const draggableId = JSON.stringify(original);

            return (
                <Draggable key={draggableId} index={index} draggableId={draggableId}>
                    {(draggableProvided) => (
                        <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}>

                            <ReactTable.defaultProps.TrComponent style={{width: "100%"}}>
                                {children}
                            </ReactTable.defaultProps.TrComponent>
                        </div>
                    )}
                </Draggable>
            );
        }
        else {
            return (
                <ReactTable.defaultProps.TrComponent>
                    {children}
                </ReactTable.defaultProps.TrComponent>
            );
        }
    }
}

DragTrComponent.propTypes = {
    rowInfo: PropTypes.any
};

class DropTbodyComponent extends React.Component {

    render() {

        const {children = null} = this.props;

        return (
            <Droppable droppableId="droppable">
                {(droppableProvided) => (
                    <div ref={droppableProvided.innerRef}>
                        <ReactTable.defaultProps.TbodyComponent>
                            {children}
                        </ReactTable.defaultProps.TbodyComponent>
                    </div>
                )}
            </Droppable>
        );
    }
}

class CrmDndTable extends React.Component {

    handleDragEnd = (result) => {

        const {onOrdered, data} = this.props;


        if (!result.destination) {
            return;
        }

        if (onOrdered) {
            const orderedData = reorder(data, result.source.index, result.destination.index);

            onOrdered(orderedData);
        }
    };

    getTrProps = (state, rowInfo) => {

        return {rowInfo};
    };

    render() {
        return (
            <div>
                <DragDropContext onDragEnd={this.handleDragEnd}>
                    <CrmTable
                        {...this.props}
                        showPagination={false}
                        TrComponent={DragTrComponent}
                        TbodyComponent={DropTbodyComponent}
                        getTrProps={this.getTrProps}
                        className="-striped -highlight"
                        resizable={false}
                        sortable={false}
                    />
                </DragDropContext>
            </div>
        );
    }
}

CrmDndTable.defaultProps = {};

CrmDndTable.propTypes = {
    onOrdered: PropTypes.func,
    data: PropTypes.any,
};

export default CrmDndTable;
