/**
 * Invites Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";

import apiCall from "../../services/api";
import withContext from "../../context/withContext";
import {CrmTable} from "../../components";

class Admins extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        authContext: PropTypes.shape({
            admin: PropTypes.shape().isRequired
        }),
        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        admins: null,

        // COMPONENT
        searchText: ""
    };

    filterAdmins = Memoize((invites, searchText) => {

        if (!invites) {
            return invites;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return invites;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return invites.filter((invite) => invite.admin_email.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([
            {
                url: "/admins",
                name: "Kullanıcılar"
            }
        ]);

        this.getAdminsAsync();
    }

    async getAdminsAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/admins"});

            this.setState((prevState) => ({
                ...prevState,
                admins: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    showDeleteConfirm = (_id, title) => {

        this.props.showConfirm({
            title,
            message: "Bu kullanıcıyı silmek istediğinize emin misiniz?",
            buttons: [{
                title: "Tamamdır, SİL!",
                asyncFn: async () => {

                    try {

                        await apiCall({
                            url: `/admins/${_id}`,
                            method: "DELETE",
                            toast: `Kullanıcı silindi: ${title}.`
                        });

                        this.getAdminsAsync();
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trim();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    getAdminTableColumns() {
        return [
            {

                Header: "İsim",
                id: "admin_name",
                accessor: (props) => props,
                Cell: (props) => (
                    <>
                        <p>
                            {`${props.value.admin_name} ${props.value.admin_surname}`}
                        </p>
                    </>
                )
            },
            {
                Header: "E-posta",
                accessor: "admin_email",
                sortable: false
            },
            {

                Header: "Yetki Seviyesi",
                id: "admin_role",
                accessor: (props) => props,
                Cell: (props) => (
                    <>
                        <p>
                            {props.value.admin_role}
                        </p>
                    </>
                )
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 120,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => {

                    if (props.value._id === this.props.authContext.admin._id) {
                        return null;
                    }

                    return (

                        <div className="level">
                            <div className="level-item">
                                <a tabIndex="0" className="button" href="#" onClick={() => {
                                    this.showDeleteConfirm(props.value._id, props.value.admin_email);
                                }}>
                                    <span className="icon is-small"><i className="fa fa-trash"/></span>
                                </a>
                            </div>
                        </div>
                    );
                }
            }
        ];
    }

    render() {

        if (!this.state.admins) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="E-posta ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getAdminTableColumns()}
                    data={this.filterAdmins(this.state.admins, this.state.searchText)}
                    defaultSorted={[{
                        id: "admin_name",
                        desc: false
                    }]}
                />
            </>
        );
    }
}

export default withContext(Admins);
