import React from "react";

import {PAGINATION} from "../services/constants";

const CustomerContext = React.createContext({
    customerFilters: [],
    customerPagination: PAGINATION.DEFAULT_PAGINATION
});

export default CustomerContext;
