/**
 * ImageInput component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

import FileInputPreview from "./FileInputPreview/FileInputPreview";
import UploadProgress from "../UploadProgress/UploadProgress";

import InlineError from "../InlineError/InlineError";

import apiCall from "../../../services/api";

const BYTE_TO_MB = 1024 * 1024; // 1MB
const MAX_FILE_SIZE_BYTE = 1024 * 1024 * 50; // 50MB

const FIXED_PREVIEW_SIZE = 320;

export default class FileInput extends Component {

    static defaultProps = {
        placeholder: "Yeni dosya seçiniz..."
    };

    static propTypes = {
        disabled: PropTypes.bool,
        inputName: PropTypes.string.isRequired,
        modelName: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        displayName: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        hint: PropTypes.string,
        setFile: PropTypes.func,
        uploadInProgress: PropTypes.func,
        error: PropTypes.string
    };

    state = {
        file: this.props.value,
        fileName: null,
        error: null,
        uploading: false,
        progress: 0
    };

    clearFile = () => {

        this.props.setFile(undefined);

        this.setState((prevState) => ({
            ...prevState,
            uploading: false,
            progress: 0,
            file: null,
            fileName: null,
            error: null
        }));
    };

    fileChanged = async (event) => {

        const file = event.currentTarget.files[0];

        if (!file) {

            this.setState((prevState) => ({
                ...prevState,
                error: "Something is wrong with the input file"
            }));
            // break and return
            return;
        }

        if (file.size > MAX_FILE_SIZE_BYTE) {

            const fileSizeInMegabyte = (file.size / BYTE_TO_MB).toFixed(2);
            const maxFileSizeInMegabyte = (MAX_FILE_SIZE_BYTE / BYTE_TO_MB).toFixed(2);

            this.setState((prevState) => ({
                ...prevState,
                error: `File is too big: ${fileSizeInMegabyte}MB. Maximum allowed size is: ${maxFileSizeInMegabyte}MB`
            }));
            // break and return
            return;
        }

        const multipartData = new FormData();
        multipartData.set(this.props.inputName, file);

        this.setState((prevState) => ({
            ...prevState,
            uploading: true,
            error: null,
            progress: 0
        }));

        this.props.uploadInProgress(true);

        try {

            const {data} = await apiCall({
                url: `/upload/file/${this.props.modelName}`,
                payload: multipartData,
                method: "post",
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                options: {
                    onUploadProgress: (progressEvent) => {

                        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({
                            progress: percentCompleted
                        });
                    }
                }
            });

            const fileUrl = data[this.props.inputName];
            this.addFilePreview(file, fileUrl);

        }
        catch (e) {

            const error = (e.response && e.response.data) || e;

            this.setState((prevState) => ({
                ...prevState,
                uploading: false,
                error: error.message ? error.message : error.toString(),
                progress: 0
            }));
        }

        this.props.uploadInProgress(false);
    };

    isDisabled = () => {
        return this.props.isLoading || this.state.uploading || this.props.isSubmitting || this.props.disabled;
    };

    addFilePreview(file, fileUrl) {

        this.setState({
            uploading: false,
            progress: 0,
            file: fileUrl,
            fileName: file.name,
            error: null
        });

        // Notify formik to set file input!
        this.props.setFile(fileUrl);
    }

    render() {

        // We want to show filePreview field with fixed aspect ratio and size,
        // even if file is empty.

        return (

            <div className={`field fileInput ${this.props.error ? "is-danger" : ""}`}>

                <label className="label" htmlFor={this.props.inputName}>
                    <span>
                        {this.props.displayName}
                    </span>
                    {
                        this.props.hint
                            ? (
                                <span className="icon has-text-info tooltip is-tooltip-multiline is-tooltip-left" data-tooltip={this.props.hint}>
                                    <i className="fas fa-info-circle"/>
                                </span>
                            ) : null
                    }
                </label>
                <div className="control">

                    {
                        this.state.uploading

                            ? (
                                <UploadProgress
                                    progress={this.state.progress}
                                />
                            )

                            : (
                                <FileInputPreview
                                    file={this.state.file}
                                    clearFile={this.clearFile}
                                    isLoading={this.props.isLoading}
                                />
                            )
                    }

                    <div className={`file ${this.isDisabled() ? "disabled": ""}`} style={{width: FIXED_PREVIEW_SIZE, maxWidth: "100%"}}>
                        <label className="file-label" htmlFor={this.props.inputName} style={{width: "100%"}}>
                            <input
                                className="file-input"
                                type="file"
                                name={this.props.inputName}
                                id={this.props.inputName}
                                onChange={this.fileChanged}
                                disabled={this.props.isLoading || this.state.uploading || this.props.disabled}
                            />
                            <span className="file-cta" style={{width: "100%"}}>
                                <span className="file-icon">
                                    <i className="fa fa-upload"/>
                                </span>
                                <span className="file-label">
                                    {this.state.fileName || this.props.placeholder}
                                </span>
                            </span>
                        </label>
                    </div>

                </div>

                <InlineError error={this.state.error}/>
                <InlineError error={this.props.error}/>
            </div>
        );
    }
}
