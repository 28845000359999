/**
 * Feedbacks Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";

import apiCall from "../../services/api";
import withContext from "../../context/withContext";
import {CrmTable} from "../../components";
import {Link} from "react-router-dom";
import RemoveItem from "../../services/utils/remove_item";

class Feedbacks extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showNamedConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        feedbackList: null,

        // COMPONENT
        searchText: ""
    };

    filterFeedbackList = Memoize((feedbackList, searchText) => {

        if (!feedbackList) {
            return feedbackList;
        }
        
        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return feedbackList;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return feedbackList.filter((feedback) => feedback.feedback_content.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/feedback",
            name: "Geri Bildirim Listesi"
        }]);

        this.getFeedbackListAsync();
    }

    async getFeedbackListAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/feedback"});

            this.setState((prevState) => ({
                ...prevState,
                feedbackList: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    showDeleteConfirm = (feedback) => {

        const firstChars = feedback.feedback_content.substring(0, 10);

        this.props.showNamedConfirm({
            validateText: firstChars,
            children: (
                <>
                    <div className="notification is-warning is-fixed-to-body">
                        {"Bu silme işlemi "}
                        <strong>GERİ ALINAMAZ.</strong>
                    </div>
                    <p>
                        {"Bu etiketi: ("}
                        <strong>{firstChars}</strong>
                        {") silerek, ekli müşteri profillerinden bu etiketi kaldıracaksınız."}
                    </p>
                    <p>
                        {"Etiketi silmek için aşağıdaki alana etiketin tam ismini giriniz."}
                    </p>
                </>
            ),
            asyncFunction: async () => {

                try {

                    await apiCall({
                        url: `/feedback/${feedback._id}`,
                        method: "DELETE",
                        toast: `Geri Bildirim: '${firstChars}...' silindi.`
                    });

                    this.setState((prevState) => ({
                        ...prevState,
                        feedbackList: RemoveItem(prevState.feedbackList, feedback._id)
                    }));
                }
                catch (e) {

                    this.props.setError(e);
                }
            }
        });
    };

    getTableColumns() {
        return [
            {
                Header: "Mesaj",
                accessor: "feedback_content",
                sortable: true,
            },
            {
                Header: "Durum?",
                id: "feedback_tag",
                className: "will-overflow",
                maxWidth: 300,
                sortable: false,
                accessor: (props) => props,
                Cell: ({value}) => (
                    <>
                        <p>
                            <strong>Geri Bildirim Etiketi: </strong>
                            {value.tag.feedback_tag_type_name}
                        </p>
                        <p>
                            <strong>Müşteri: </strong>
                            {
                                value.customer ?
                                    `${value.customer.customer_name} ${value.customer.customer_surname}`
                                    : "Müşteri sistemden silinmiştir."
                            }
                        </p>
                        <p>
                            <strong>Çözüldü mü: </strong>
                            {value.feedback_is_resolved ? "EVET" : "HAYIR"}
                        </p>
                    </>
                )
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 250,
                sortable: false,
                accessor: (props) => props,
                Cell: ({value}) => (

                    <div className="level">
                        <div className="level-item">
                            {
                                value.customer ? (
                                    <Link tabIndex="0" className="button" to={{
                                        pathname: `/customers/profile/${value.customer._id}`,
                                        state: {
                                            fromFeedback: true
                                        }
                                    }}>
                                        <span className="icon is-small"><i className="fa fa-eye"/></span>
                                    </Link>
                                ) : null
                            }
                        </div>
                        <div className="level-item">
                            <Link tabIndex="0" className="button" to={`/feedback/${value._id}/edit`}>
                                <span className="icon is-small"><i className="fa fa-edit"/></span>
                            </Link>
                        </div>
                        <div className="level-item">
                            <a tabIndex="0" className="button" href="#" onClick={() => {
                                this.showDeleteConfirm(value);
                            }}>
                                <span className="icon is-small"><i className="fa fa-trash"/></span>
                            </a>
                        </div>
                    </div>

                )
            }
        ];
    }

    render() {

        if (!this.state.feedbackList) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Konu başlığıyla ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/feedback/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Geri Bildirim</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getTableColumns()}
                    data={this.filterFeedbackList(this.state.feedbackList, this.state.searchText)}
                    defaultSorted={[{
                        id: "-createdAt",
                        desc: false
                    }]}
                />
            </>
        );
    }
}

export default withContext(Feedbacks);
