/**
 * Import from node modules
 */
import React from "react";
import {render} from "react-dom";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import Moment from "moment-timezone";
import TrLocale from "moment/locale/tr";
// CSS
import "@fortawesome/fontawesome-free-webfonts/css/fa-brands.css";
import "@fortawesome/fontawesome-free-webfonts/css/fa-regular.css";
import "@fortawesome/fontawesome-free-webfonts/css/fa-solid.css";
import "@fortawesome/fontawesome-free-webfonts/css/fontawesome.css";
import "react-toastify/dist/ReactToastify.css";
import "react-table/react-table.css";
import "bulma-tooltip/dist/css/bulma-tooltip.min.css";
import "bulma-switch/dist/css/bulma-switch.min.css";
import "bulma-checkradio/dist/css/bulma-checkradio.min.css";
import "react-phone-number-input/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/styles/main.sass";

import {PrivateRoute} from "./components";
import RouteHandler from "./routes/RouteHandler/RouteHandler";
import GeneralProvider from "./context/GeneralProvider";

import FullScreenLayout from "./layouts/FullScreenLayout";
import GeneralLayout from "./layouts/GeneralLayout";
// Redirect
import Home from "./routes/Home/Home";
// Content
import Dashboard from "./routes/Dashboard/Dashboard";
// Auth & Invite & Admins
import Login from "./routes/Admin/Login/Login";
import ForgetPassword from "./routes/Admin/ForgetPassword/ForgetPassword";
import Invites from "./routes/Admin/Invites/Invites";
import Profile from "./routes/Admin/Profile/Profile";
import ChangePassword from "./routes/Admin/ChangePassword/ChangePassword";
import InviteAdd from "./routes/Admin/Invites/InviteAdd";
import Admins from "./routes/Admin/Admins";
import Register from "./routes/Admin/Register/Register";
import ResetPassword from "./routes/Admin/ResetPassword/ResetPassword";
// Event Types
import EventTypes from "./routes/Types/EventTypes/EventTypes";
import EventTypeAdd from "./routes/Types/EventTypes/EventTypeAdd";
import EventTypeEdit from "./routes/Types/EventTypes/EventTypeEdit";
// Tag Types
import TagTypes from "./routes/Types/TagTypes/TagTypes";
import TagTypeAdd from "./routes/Types/TagTypes/TagTypeAdd";
import TagTypeEdit from "./routes/Types/TagTypes/TagTypeEdit";
// Feedback Tag Types
import FeedbackTagTypes from "./routes/Types/FeedbackTagTypes/FeedbackTagTypes";
import FeedbackTagTypeAdd from "./routes/Types/FeedbackTagTypes/FeedbackTagTypeAdd";
import FeedbackTagTypeEdit from "./routes/Types/FeedbackTagTypes/FeedbackTagTypeEdit";
// Customer Entry
import CustomerEntry from "./routes/CustomerEntry/CustomerEntry";
import CustomerCheck from "./routes/CustomerEntry/CustomerCheck/CustomerCheck";
import CustomerRegister from "./routes/CustomerEntry/CustomerRegister/CustomerRegister";
import CustomerUpdate from "./routes/CustomerEntry/CustomerUpdate/CustomerUpdate";
import CustomerSmsValidation from "./routes/CustomerEntry/CustomerSmsValidation/CustomerSmsValidation";
import CustomerEntrySuccess from "./routes/CustomerEntry/CustomerEntrySuccess/CustomerEntrySuccess";
// Customer routes
import CustomerList from "./routes/Customers/CustomerList/CustomerList";
import CustomerQuery from "./routes/Customers/CustomerQuery/CustomerQuery";
import CustomerProfile from "./routes/Customers/CustomerProfile/CustomerProfile";
// Marketing
import EmailExternalLists from "./routes/Marketing/EmailExternalLists/EmailExternalLists";
import EmailExternalListAdd from "./routes/Marketing/EmailExternalLists/EmailExternalListAdd";

import EmailNotifications from "./routes/Marketing/EmailNotifications/EmailNotifications";
import EmailNotificationAdd from "./routes/Marketing/EmailNotifications/EmailNotificationAdd";
import EmailNotificationEdit from "./routes/Marketing/EmailNotifications/EmailNotificationEdit";
import EmailNotificationReport from "./routes/Marketing/EmailNotifications/EmailNotificationReport";
import SmsNotifications from "./routes/Marketing/SmsNotifications/SmsNotifications";
import SmsNotificationAdd from "./routes/Marketing/SmsNotifications/SmsNotificationAdd";
import SmsNotificationEdit from "./routes/Marketing/SmsNotifications/SmsNotificationEdit";
import PushNotifications from "./routes/Marketing/PushNotifications/PushNotifications";
import PushNotificationAdd from "./routes/Marketing/PushNotifications/PushNotificationAdd";
import PushNotificationEdit from "./routes/Marketing/PushNotifications/PushNotificationEdit";
import PushNotificationReport from "./routes/Marketing/PushNotifications/PushNotificationReport";
// Feedback
import Feedbacks from "./routes/Feedback/Feedbacks";
import FeedbackAdd from "./routes/Feedback/FeedbackAdd";
import FeedbackEdit from "./routes/Feedback/FeedbackEdit";
// Reports
import MonthlyReports from "./routes/Reports/MonthlyReports/MonthlyReports";

import {bugSnag, version} from "../package.json";
// companies
import ExportLogList from "./routes/Exports/ExportLogList/ExportLogList";
import Companies from "./routes/Exports/Company/Companies";
import CompanyAdd from "./routes/Exports/Company/CompanyAdd";
import CompanyStaffers from "./routes/Exports/CompanyStaff/CompanyStaffers";
import CompanyStaffAdd from "./routes/Exports/CompanyStaff/CompanyStaffAdd";

// Customer Import
import CustomerImport from "./routes/CustomerImport/CustomerImport";


// // campaigns
import CouponProviders from "./routes/Campaigns/CouponProviders/CouponProviders";
import CouponProviderAdd from "./routes/Campaigns/CouponProviders/CouponProviderAdd";
import CampaignTypes from "./routes/Campaigns/CampaignTypes/CampaignTypes";
import CampaignTypeAdd from "./routes/Campaigns/CampaignTypes/CampaignTypeAdd";
import CouponCampaigns from "./routes/Campaigns/CouponCampaign/CouponCampaigns";
import CouponCampaignAdd from "./routes/Campaigns/CouponCampaign/CouponCampaignAdd";
import CouponCampaignEdit from "./routes/Campaigns/CouponCampaign/CouponCampaignEdit";
import CouponCodeUpload from "./routes/Campaigns/CouponCampaign/CouponCodeUpload";
import UsedCouponCodeUpload from "./routes/Campaigns/CouponCampaign/UsedCouponCodeUpload";
import CouponCodeGenerate from "./routes/Campaigns/CouponCampaign/CouponCodeGenerate";

// // mall campaigns
// import MallCampaigns from "./routes/Campaigns/MallCampaign/MallCampaigns";
// import MallCampaignAdd from "./routes/Campaigns/MallCampaign/MallCampaignAdd";
// import MallCampaignEdit from "./routes/Campaigns/MallCampaign/MallCampaignEdit";
// import MallCouponCodeUpload from "./routes/Campaigns/MallCampaign/MallCouponCodeUpload";
// import UsedMallCouponCodeUpload from "./routes/Campaigns/MallCampaign/UsedMallCouponCodeUpload";
// // daily campaigns
// import DailyCampaignGroups from "./routes/Campaigns/DailyCampaign/DailyCampaignGroups";
// import DailyCampaignGroupAdd from "./routes/Campaigns/DailyCampaign/DailyCampaignGroupAdd";
// import DailyCampaignGroupEdit from "./routes/Campaigns/DailyCampaign/DailyCampaignGroupEdit";
// import DailyCampaigns from "./routes/Campaigns/DailyCampaign/DailyCampaigns";
// import DailyCampaignAdd from "./routes/Campaigns/DailyCampaign/DailyCampaignAdd";
// import DailyCampaignEdit from "./routes/Campaigns/DailyCampaign/DailyCampaignEdit";
// import DailyCodeUpload from "./routes/Campaigns/DailyCampaign/DailyCouponCodeUpload";
// import UsedDailyCodeUpload from "./routes/Campaigns/DailyCampaign/DailyUsedCouponCodeUpload";

Moment.locale("tr", TrLocale);

const bugsnagClient = bugsnag({
    appVersion: version,
    apiKey: bugSnag.apiKey,
    releaseStage: process.env.NODE_ENV,
    notifyReleaseStages: ["production"]
});

bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin("react");

// Safari fix, babel cant handle this.
String.prototype.trimStart = String.prototype.trimStart ? String.prototype.trimStart : function () {

    if (String.prototype.trimLeft) {
        return this.trimLeft();
    }
    else if (String.prototype.trim) {
        const trimmed = this.trim();
        const indexOfWord = this.indexOf(trimmed);
        return this.slice(indexOfWord, this.length);
    }
};

render(
    <ErrorBoundary>
        <GeneralProvider>
            <BrowserRouter>
                <RouteHandler>
                    <Switch>
                        <Route exact path="/login" component={FullScreenLayout(Login)}/>
                        <Route exact path="/register/:token" component={FullScreenLayout(Register)}/>
                        <Route exact path="/forget-password" component={FullScreenLayout(ForgetPassword)}/>
                        <Route exact path="/reset-password/:token" component={FullScreenLayout(ResetPassword)}/>

                        <PrivateRoute exact path="/" component={GeneralLayout(Home)}/>
                        <PrivateRoute exact path="/dashboard" component={GeneralLayout(Dashboard)}/>
                        <PrivateRoute exact path="/invites" component={GeneralLayout(Invites)}/>
                        <PrivateRoute exact path="/invites/new" component={GeneralLayout(InviteAdd)}/>
                        <PrivateRoute exact path="/profile" component={GeneralLayout(Profile)}/>
                        <PrivateRoute exact path="/change-password" component={GeneralLayout(ChangePassword)}/>
                        <PrivateRoute exact path="/admins" component={GeneralLayout(Admins)}/>

                        <PrivateRoute exact path="/marketing/email-notifications" component={GeneralLayout(EmailNotifications)}/>
                        <PrivateRoute exact path="/marketing/email-notifications/new" component={GeneralLayout(EmailNotificationAdd)}/>
                        <PrivateRoute exact path="/marketing/email-notifications/:emailnotificationid/edit" component={GeneralLayout(EmailNotificationEdit)}/>
                        <PrivateRoute exact path="/marketing/email-notifications/:emailnotificationid/report" component={GeneralLayout(EmailNotificationReport)}/>

                        <PrivateRoute exact path="/marketing/email-external-lists" component={GeneralLayout(EmailExternalLists)}/>
                        <PrivateRoute exact path="/marketing/email-external-lists/new" component={GeneralLayout(EmailExternalListAdd)}/>

                        <PrivateRoute exact path="/marketing/sms-notifications" component={GeneralLayout(SmsNotifications)}/>
                        <PrivateRoute exact path="/marketing/sms-notifications/new" component={GeneralLayout(SmsNotificationAdd)}/>
                        <PrivateRoute exact path="/marketing/sms-notifications/:smsnotificationid/edit" component={GeneralLayout(SmsNotificationEdit)}/>

                        <PrivateRoute exact path="/marketing/push-notifications" component={GeneralLayout(PushNotifications)}/>
                        <PrivateRoute exact path="/marketing/push-notifications/new" component={GeneralLayout(PushNotificationAdd)}/>
                        <PrivateRoute exact path="/marketing/push-notifications/:pushnotificationid/edit" component={GeneralLayout(PushNotificationEdit)}/>
                        <PrivateRoute exact path="/marketing/push-notifications/:pushnotificationid/report" component={GeneralLayout(PushNotificationReport)}/>

                        <PrivateRoute exact path="/data/event-types" component={GeneralLayout(EventTypes)}/>
                        <PrivateRoute exact path="/data/event-types/new" component={GeneralLayout(EventTypeAdd)}/>
                        <PrivateRoute exact path="/data/event-types/:eventtypeid/edit" component={GeneralLayout(EventTypeEdit)}/>

                        {/* Tag Types */}
                        <PrivateRoute exact path="/data/tag-types" component={GeneralLayout(TagTypes)}/>
                        <PrivateRoute exact path="/data/tag-types/new" component={GeneralLayout(TagTypeAdd)}/>
                        <PrivateRoute exact path="/data/tag-types/:tagtypeid/edit" component={GeneralLayout(TagTypeEdit)}/>

                        {/* Feedback Tag Types */}
                        <PrivateRoute exact path="/data/feedback-tag-types" component={GeneralLayout(FeedbackTagTypes)}/>
                        <PrivateRoute exact path="/data/feedback-tag-types/new" component={GeneralLayout(FeedbackTagTypeAdd)}/>
                        <PrivateRoute exact path="/data/feedback-tag-types/:feedbacktagtypeid/edit" component={GeneralLayout(FeedbackTagTypeEdit)}/>

                        <PrivateRoute exact path="/customer-entry" component={GeneralLayout(CustomerEntry)}/>
                        <PrivateRoute exact path="/customer-entry/check" component={GeneralLayout(CustomerCheck)}/>
                        <PrivateRoute exact path="/customer-entry/register" component={GeneralLayout(CustomerRegister)}/>
                        <PrivateRoute exact path="/customer-entry/update/:customerid" component={GeneralLayout(CustomerUpdate)}/>
                        <PrivateRoute exact path="/customer-entry/sms-validation" component={GeneralLayout(CustomerSmsValidation)}/>
                        <PrivateRoute exact path="/customer-entry/success/:successtype" component={GeneralLayout(CustomerEntrySuccess)}/>

                        <PrivateRoute exact path="/customers/list" component={GeneralLayout(CustomerList)}/>
                        <PrivateRoute exact path="/customers/query" component={GeneralLayout(CustomerQuery)}/>
                        <PrivateRoute exact path="/customers/profile/:customerid" component={GeneralLayout(CustomerProfile)}/>

                        <PrivateRoute exact path="/feedback" component={GeneralLayout(Feedbacks)}/>
                        <PrivateRoute exact path="/feedback/new" component={GeneralLayout(FeedbackAdd)}/>
                        <PrivateRoute exact path="/feedback/:feedbackid/edit" component={GeneralLayout(FeedbackEdit)}/>

                        <PrivateRoute exact path="/reports/monthly-reports" component={GeneralLayout(MonthlyReports)}/>

                        {/*Export logs*/}
                        <PrivateRoute exact path="/export-logs/list" component={GeneralLayout(ExportLogList)}/>
                        <PrivateRoute exact path="/export-logs/companies/list" component={GeneralLayout(Companies)}/>
                        <PrivateRoute exact path="/export-logs/companies/new" component={GeneralLayout(CompanyAdd)}/>

                        <PrivateRoute exact path="/export-logs/companies/staffers" component={GeneralLayout(CompanyStaffers)}/>
                        <PrivateRoute exact path="/export-logs/companies/staffers/add" component={GeneralLayout(CompanyStaffAdd)}/>

                        {/*Campaign*/}
                        {/*Coupon Campaign*/}
                        <PrivateRoute exact path="/campaign/coupon-campaigns" component={GeneralLayout(CouponCampaigns)}/>
                        <PrivateRoute exact path="/campaign/coupon-campaigns/new" component={GeneralLayout(CouponCampaignAdd)}/>
                        <PrivateRoute exact path="/campaign/coupon-campaigns/:campaignid/edit" component={GeneralLayout(CouponCampaignEdit)}/>

                        {/*Coupon Codes*/}
                        <PrivateRoute exact path="/campaign/coupon-codes/:campaignid/upload-codes" component={GeneralLayout(CouponCodeUpload)}/>
                        <PrivateRoute exact path="/campaign/coupon-codes/:campaignid/generate-codes" component={GeneralLayout(CouponCodeGenerate)}/>
                        <PrivateRoute exact path="/campaign/coupon-codes/:campaignid/upload-used-codes" component={GeneralLayout(UsedCouponCodeUpload)}/>


                        <PrivateRoute exact path="/campaign/coupon-providers" component={GeneralLayout(CouponProviders)}/>
                        <PrivateRoute exact path="/campaign/coupon-providers/new" component={GeneralLayout(CouponProviderAdd)}/>

                        <PrivateRoute exact path="/campaign/types" component={GeneralLayout(CampaignTypes)}/>
                        <PrivateRoute exact path="/campaign/types/new" component={GeneralLayout(CampaignTypeAdd)}/>

                        {/*Import User*/}
                        <PrivateRoute exact path="/customer-import" component={GeneralLayout(CustomerImport)}/>
                        {/*Daily Campaigns*/}
                        {/*<PrivateRoute exact path="/campaign/daily-campaign-groups/:groupid/campaigns/:campaignid/upload-used-codes" component={GeneralLayout(UsedDailyCodeUpload)}/>*/}
                        {/*<PrivateRoute exact path="/campaign/daily-campaign-groups" component={GeneralLayout(DailyCampaignGroups)}/>*/}
                        {/*<PrivateRoute exact path="/campaign/daily-campaign-groups/new" component={GeneralLayout(DailyCampaignGroupAdd)}/>*/}
                        {/*<PrivateRoute exact path="/campaign/daily-campaign-groups/:groupid/edit" component={GeneralLayout(DailyCampaignGroupEdit)}/>*/}

                        {/*<PrivateRoute exact path="/campaign/daily-campaign-groups/:groupid/campaigns" component={GeneralLayout(DailyCampaigns)}/>*/}
                        {/*<PrivateRoute exact path="/campaign/daily-campaign-groups/:groupid/campaigns/new" component={GeneralLayout(DailyCampaignAdd)}/>*/}
                        {/*<PrivateRoute exact path="/campaign/daily-campaign-groups/:groupid/campaigns/:campaignid/edit" component={GeneralLayout(DailyCampaignEdit)}/>*/}

                        {/*<PrivateRoute exact path="/campaign/daily-campaign-groups/:groupid/campaigns/:campaignid/upload-codes" component={GeneralLayout(DailyCodeUpload)}/>*/}

                        {/*Mall Campaign*/}
                        {/*<PrivateRoute exact path="/campaign/mall-campaigns" component={GeneralLayout(MallCampaigns)}/>*/}
                        {/*<PrivateRoute exact path="/campaign/mall-campaigns/new" component={GeneralLayout(MallCampaignAdd)}/>*/}
                        {/*<PrivateRoute exact path="/campaign/mall-campaigns/:campaignid/edit" component={GeneralLayout(MallCampaignEdit)}/>*/}

                        {/*<PrivateRoute exact path="/campaign/mall-coupon-codes/:campaignid/upload-coupon-codes" component={GeneralLayout(MallCouponCodeUpload)}/>*/}
                        {/*<PrivateRoute exact path="/campaign/mall-coupon-codes/:campaignid/upload-used-coupon-codes" component={GeneralLayout(UsedMallCouponCodeUpload)}/>*/}

                        <Redirect from="*" to="/"/>
                    </Switch>
                </RouteHandler>
            </BrowserRouter>
        </GeneralProvider>
        <ToastContainer/>
    </ErrorBoundary>
    , document.getElementById("root")
);
