/**
 * Invites Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";

import apiCall from "../../../services/api";
import RemoveItem from "../../../services/utils/remove_item";
import withContext from "../../../context/withContext";
import {CrmTable} from "../../../components";

class TagTypes extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showNamedConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        tagTypes: null,

        // COMPONENT
        searchText: ""
    };

    filterTagTypes = Memoize((tagTypes, searchText) => {

        if (!tagTypes) {
            return tagTypes;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return tagTypes;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return tagTypes.filter((tagType) => tagType.tag_type_name.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/data/tag-types",
            name: "Müşteri Etiketleri (Tags)"
        }]);

        this.getTagTypesAsync();
    }

    async getTagTypesAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/data/tag-types"});

            this.setState((prevState) => ({
                ...prevState,
                tagTypes: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    showDeleteConfirm = (tagType) => {

        this.props.showNamedConfirm({
            validateText: tagType.tag_type_name,
            children: (
                <>
                    <div className="notification is-warning is-fixed-to-body">
                        {"Bu silme işlemi "}
                        <strong>GERİ ALINAMAZ.</strong>
                    </div>
                    <p>
                        {"Bu etiketi: ("}
                        <strong>{tagType.tag_type_name}</strong>
                        {") silerek, ekli müşteri profillerinden bu etiketi kaldıracaksınız."}
                    </p>
                    <p>
                        {"Etiketi silmek için aşağıdaki alana etiketin tam ismini giriniz."}
                    </p>
                </>
            ),
            asyncFunction: async () => {

                try {

                    await apiCall({
                        url: `/data/tag-types/${tagType._id}`,
                        method: "DELETE",
                        toast: `Etiket: '${tagType.tag_type_name}' silindi.`
                    });

                    this.setState((prevState) => ({
                        ...prevState,
                        tagTypes: RemoveItem(prevState.tagTypes, tagType._id)
                    }));
                }
                catch (e) {

                    this.props.setError(e);
                }
            }
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    getTagTypeTableColumns() {
        return [
            {
                Header: "Etiket",
                accessor: "tag_type_name",
                style: {"whiteSpace": "unset"},
                sortable: true,
            },
            {

                Header: "Açıklama",
                accessor: "tag_type_exp",
                style: {"whiteSpace": "unset"},
                sortable: false
            },
            {

                Header: "Müşteri Sayısı",
                accessor: "customer_count",
                sortable: false,
                maxWidth: 200
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 120,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (

                    <div className="level">
                        <div className="level-item">
                            <Link tabIndex="0" className="button" to={`/data/tag-types/${props.value._id}/edit`}>
                                <span className="icon is-small"><i className="fa fa-edit"/></span>
                            </Link>
                        </div>
                        <div className="level-item">
                            <a tabIndex="0" className="button" href="#" onClick={() => {
                                this.showDeleteConfirm(props.value);
                            }}>
                                <span className="icon is-small"><i className="fa fa-trash"/></span>
                            </a>
                        </div>
                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.tagTypes) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Etiket ismi ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/data/tag-types/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Müşteri Etiketi</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getTagTypeTableColumns()}
                    data={this.filterTagTypes(this.state.tagTypes, this.state.searchText)}
                    defaultSorted={[{
                        id: "tag_type_name",
                        desc: false
                    }]}
                />
            </>
        );
    }
}

export default withContext(TagTypes);
