/**
 * Invites Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import apiCall from "../../services/api";
import withContext from "../../context/withContext";
import Memoize from "memoize-one";

class CustomerEntry extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        entryContext: PropTypes.shape({
            setEntryEventType: PropTypes.func.isRequired,
            clearEntryEventType: PropTypes.func.isRequired,
            clearEntryCustomer: PropTypes.func.isRequired,
        }),

        // React router
        history: PropTypes.shape({
            push: PropTypes.func,
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {
        // API
        searchText: "",
        eventTypes: null,
        tagTypes: null
    };

    filterEventTypes = Memoize((eventTypes, searchText) => {

        if (!eventTypes) {
            return eventTypes;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return eventTypes;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return eventTypes.filter((eventType) => eventType.event_type_name.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/customer-entry",
            name: "Ziyaretçi Kayıt"
        }]);

        this.props.entryContext.clearEntryCustomer();
        this.props.entryContext.clearEntryEventType();
        this.getCustomerEntryAsync();
    }

    async getCustomerEntryAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const [eventData, tagData] = await Promise.all([
                apiCall({url: "/data/event-types"}),
                apiCall({url: "/data/tag-types"})
            ]);

            this.setState((prevState) => ({
                ...prevState,
                eventTypes: eventData.data.filter((eventType) => eventType.event_type_ui_allowed),
                tagTypes: tagData.data.filter((tagType) => tagType.tag_type_ui_allowed),
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    render() {

        if (!this.state.eventTypes || !this.state.tagTypes) {
            return null;
        }

        const eventTypes = this.filterEventTypes(this.state.eventTypes, this.state.searchText);

        return (
            <div className="customerEntry">

                <div className="customerEntryHeader">
                    <h1 className="customerEntryHeaderTitle">Ziyaretçi Kayıt</h1>
                    <div className="field customerEntryHeaderSearch">
                        <div className="control has-icons-left">

                            <input
                                type="text"
                                className="input"
                                placeholder="Veri Giriş Yeri ile arayın"
                                onChange={this.handleSearch}
                                value={this.state.searchText}
                            />

                            <span className="icon is-small is-left">
                                <i className="fa fa-search"/>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="customerEntryList columns is-multiline">
                    {
                        eventTypes.map((eventType) => (
                            <div key={eventType._id} className="column is-3-desktop is-6-tablet is-12-mobile">
                                <div className="customerEntryItem">
                                    {
                                        eventType.event_type_tag_types.length > 0 &&
                                        <span className="tag is-light is-normal">
                                            {eventType.event_type_tag_types.map((tagType) => tagType.tag_type_name).join(" - ")}
                                        </span>
                                    }
                                    <a href="#" onClick={(e) => {

                                        e.preventDefault();
                                        this.props.entryContext.setEntryEventType(eventType);
                                        this.props.history.push("/customer-entry/check");
                                    }}>
                                        {eventType.event_type_name}
                                    </a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default withContext(CustomerEntry);
