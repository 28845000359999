/**
 * FileInputPreview component
 */
/**
 *  component
 */

import React from "react";

import PropTypes from "prop-types";

const FileInputPreview = (props) => (

    <div className="file-thumb-container">
        {
            props.file
                ? (
                    <>
                        {
                            props.file === "#"
                                ? <span>
                                    Güncel Dosya
                                </span>
                                : <a href={props.file} target="_blank" rel="noopener noreferrer">
                                    Güncel Dosya
                                </a>
                        }

                        <button
                            type="button"
                            className="button delete-button"
                            disabled={props.isLoading}
                            onClick={props.clearFile}>
                            <span className="icon is-small has-text-danger">
                                <i className="fa fa-trash"/>
                            </span>
                        </button>
                    </>
                )
                : (
                    <span>
                        Dosya bulunmuyor.
                    </span>
                )
        }
    </div>
);

FileInputPreview.propTypes = {

    file: PropTypes.string,
    clearFile: PropTypes.func,
    isLoading: PropTypes.bool
};

export default FileInputPreview;
