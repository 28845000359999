/**
 * Update item in array,
 * key is "_id"
 *
 */

export default (items, updateItem) => {

    if (!items || items.length === 0) {
        return items;
    }

    if (!updateItem) {
        console.error("Requested: UPDATE but failed to pass updateItem");
        return items;
    }

    const itemIndex = items.findIndex((item) => item._id === updateItem._id);

    if (itemIndex === -1) {
        console.error("Requested: UPDATE but failed to find index");
        console.error(updateItem);
        return items;
    }

    return [
        ...items.slice(0, itemIndex),
        updateItem,
        ...items.slice(itemIndex + 1)
    ];
};
