import React, {Component} from "react";

import AuthContext from "./AuthContext";
import LoadingContext from "./LoadingContext";
import CustomerEntryContext from "./CustomerEntryContext";
import CustomerContext from "./CustomerContext";
import storage from "../services/storage";
import {PAGINATION} from "../services/constants";

export default class GeneralProvider extends Component {

    state = {

        // AuthContext
        admin: storage.getAdmin(),

        // LoadingContext
        isLoading: false,
        isFullScreenLoading: false,

        // CustomerEntryContext
        entryEventType: null,
        entryCustomer: null,

        // CustomerContext
        customer: null,
        customers: null,
        customerFilters: [],
        customerPagination: PAGINATION.DEFAULT_PAGINATION
    };

    setAdmin = (payload) => {

        this.setState((prevState) => ({
            ...prevState,
            admin: payload.admin
        }));

        storage.setAuth(payload);
    };

    clearAdmin = () => {

        storage.removeAuth();

        this.setState((prevState) => ({
            ...prevState,
            admin: null
        }));
    };

    setIsLoading = (isLoading) => {

        this.setState((prevState) => ({
            ...prevState,
            isLoading
        }));
    };

    setIsFullScreenLoading = (isFullScreenLoading) => {

        this.setState((prevState) => ({
            ...prevState,
            isFullScreenLoading
        }));
    };

    setEntryCustomer = (entryCustomer, callback) => {

        this.setState((prevState) => ({
            ...prevState,
            entryCustomer
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    setEntryEventType = (entryEventType, callback) => {

        this.setState((prevState) => ({
            ...prevState,
            entryEventType
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    clearEntryCustomer = (callback) => {

        this.setState((prevState) => ({
            ...prevState,
            entryCustomer: null,
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    clearEntryEventType = (callback) => {

        this.setState((prevState) => ({
            ...prevState,
            entryEventType: null,
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    setCustomerPagination = (customerPagination, callback) => {

        this.setState((prevState) => ({
            ...prevState,
            customerPagination
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    addCustomerFilter = (customerFilter, callback) => {

        this.setState((prevState) => ({
            ...prevState,
            customerFilters: [customerFilter, ...prevState.customerFilters]
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    removeCustomerFilter = (customerFilterIndex, callback) => {

        this.setState((prevState) => ({
            ...prevState,
            customerFilters: [
                ...prevState.customerFilters.slice(0, customerFilterIndex),
                ...prevState.customerFilters.slice(customerFilterIndex + 1)
            ]
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    clearCustomerFilters = (callback) => {

        this.setState((prevState) => ({
            ...prevState,
            customerFilters: []
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    clearPagination = (callback) => {

        this.setState((prevState) => ({
            ...prevState,
            customerPagination: PAGINATION.DEFAULT_PAGINATION
        }), () => {
            if (callback) {
                return callback();
            }
        });
    };

    render() {

        return (
            <AuthContext.Provider value={{
                admin: this.state.admin,
                setAdmin: this.setAdmin,
                clearAdmin: this.clearAdmin,
            }}>
                <LoadingContext.Provider value={{
                    isLoading: this.state.isLoading,
                    isFullScreenLoading: this.state.isFullScreenLoading,
                    setIsLoading: this.setIsLoading,
                    setIsFullScreenLoading: this.setIsFullScreenLoading
                }}>
                    <CustomerEntryContext.Provider value={{
                        entryCustomer: this.state.entryCustomer,
                        entryEventType: this.state.entryEventType,
                        setEntryCustomer: this.setEntryCustomer,
                        setEntryEventType: this.setEntryEventType,
                        clearEntryCustomer: this.clearEntryCustomer,
                        clearEntryEventType: this.clearEntryEventType
                    }}>
                        <CustomerContext.Provider value={{
                            customerFilters: this.state.customerFilters,
                            customerPagination: this.state.customerPagination,
                            addCustomerFilter: this.addCustomerFilter,
                            removeCustomerFilter: this.removeCustomerFilter,
                            clearCustomerFilters: this.clearCustomerFilters,
                            setCustomerPagination: this.setCustomerPagination,
                            clearPagination: this.clearPagination,
                        }}>
                            {
                                this.props.children
                            }
                        </CustomerContext.Provider>
                    </CustomerEntryContext.Provider>
                </LoadingContext.Provider>
            </AuthContext.Provider>
        );
    }
}
