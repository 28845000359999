/**
 * CustomerForm component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import {toast as showToast} from "react-toastify";

import dateCheck from "../../services/utils/date_check";
import FormElements from "../FormElements";
import {lowerCaseText, upperCaseText} from "../../services/utils/text_transforms";
import genderOptions from "../../services/utils/gender_options";
import withContext from "../../context/withContext";
import {ToastContent} from "../../components";

import apiCall from "../../services/api";
import PaymentItem from "./PaymentItem";
import PaymentHistoryItem from "./PaymentHistoryItem";
import CouponItem from "./CouponItem";

class CustomerForm extends Component {

    static propTypes = {
        type: PropTypes.oneOf(["Register", "Update"]).isRequired,
        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        showConfirm: PropTypes.func.isRequired,

        eventType: PropTypes.shape().isRequired,
        customer: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    state = {

        // payment
        showAddPaymentModal: false,
        stores: [],
        paymentHistory: [],
        customerCoupons: [],
        customerCouponInfo: {},
        paymentAmount: 0,
        couponPdfUrl: ""
    };

    componentDidMount() {

        const eventType = this.props.eventType;

        if (eventType.event_type_payment_form_allowed && this.props.customer && this.props.customer._id) {
            this.getStoresAsync();
            this.getEventPaymentHistoryAsync();
            this.getCustomerCouponsAsync();
        }
    }

    onPaymentAdded = (paymentData) => {

        this.setState((prevState) => ({
            ...prevState,
            paymentHistory: [paymentData, ...prevState.paymentHistory],
            paymentAmount: prevState.paymentAmount + paymentData.payment_amount
        }));

        this.getCustomerCouponsAsync();
    };

    onPaymentRemoved = (paymentData) => {

        this.setState((prevState) => ({
            ...prevState,
            paymentHistory: _.filter(prevState.paymentHistory, (item) => item._id !== paymentData._id),
            paymentAmount: prevState.paymentAmount - paymentData.payment_amount
        }));

        this.getCustomerCouponsAsync();
    };

    onCouponPrint = (blobUrl) => {

        this.setState((prevState) => ({
            ...prevState,
            couponPdfUrl: blobUrl
        }));
    }

    getEventPaymentHistoryAsync = async () => {

        const customer = this.props.customer;
        const eventType = this.props.eventType;

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customers/events/payment-history",
                method: "POST",
                payload: {
                    customerid: customer._id,
                    eventid: eventType._id
                }
            });

            const {customer_total_event_payment} = this.props.customer;

            this.setState((prevState) => ({
                ...prevState,
                paymentAmount: (customer_total_event_payment && customer_total_event_payment.total) || 0,
                paymentHistory: data
            }));

        }
        catch (e) {

            console.error(e);
        }
    };

    getCustomerCouponsAsync = async () => {
        const customer = this.props.customer;
        const eventType = this.props.eventType;

        try {
            const {data} = await apiCall({
                url: `/campaign/event/${eventType._id}/coupon-codes/customer/${customer._id}/report`,
            });

            this.setState((prevState) => ({
                ...prevState,
                customerCoupons: data.coupons,
                customerCouponInfo: data.stats
            }));
        }
        catch (e) {

            console.error(e);
        }
    }

    handleCouponRequest = async (formData, {setSubmitting}) => {
        const customer = this.props.customer;
        const eventType = this.props.eventType;

        try {
            const {data} = await apiCall({
                url: `/campaign/event/${eventType._id}/coupon-codes/customer/${customer._id}/request`,
                method: "POST"
            });

            const couponCount = data.coupons.length;
            const errorCoupon = data.coupons.find(({status}) => status === "error");
            const successCouponCount = data.coupons.filter(({status}) => status === "success").length;

            if (couponCount > 1) {

                const message = `${errorCoupon.message} Sadece ${successCouponCount}/${couponCount} adet kupon verildi.`;
                showToast(<ToastContent message={message}/>, {
                    autoClose: false,
                    type: "warning"
                });
            } 
            else if (data.coupons.some(({status}) => status === "success")){
                showToast(<ToastContent message="Kupon alındı."/>, {
                    autoClose: true,
                    type: "success"
                });
            } 
            else {
                showToast(<ToastContent message={errorCoupon.message}/>, {
                    autoClose: true,
                    type: "error"
                });
            }

            this.getCustomerCouponsAsync();

        }
        catch (e) {

            console.error(e);
        }
        finally {
            setSubmitting(false);

        }
    }

    getStoresAsync = async () => {

        const eventType = this.props.eventType;

        const {data} = await apiCall({url: `/data/stores?eventId=${eventType._id}`});

        this.setState((prevState) => ({
            ...prevState,
            stores: data.map((s) => (s.store_commercial_title ? {label: `${s.store_name} - ${s.store_commercial_title}`, value: s._id} : {label: s.store_name, value: s._id})),
        }));
    }

    render() {

        const eventType = this.props.eventType;
        const customer = this.props.customer;

        if (!eventType || !customer) {
            return null;
        }

        const {stores, paymentHistory, customerCouponInfo, customerCoupons} = this.state;
        const isOfflineFormAllowed = eventType.event_type_offline_form;
        const isPaymentFormAllowed = eventType.event_type_payment_form_allowed;
        const isCouponFormAllowed = eventType.event_type_coupon_form_allowed && !_.isEmpty(customerCouponInfo);
        const isCustomerExist = customer && customer._id;

        const initialValues = {
            customer_phone: this.props.customer.customer_phone,
            customer_name: this.props.customer.customer_name || "",
            customer_surname: this.props.customer.customer_surname || "",
            customer_gender: this.props.customer.customer_gender || "",
            customer_email: this.props.customer.customer_email || "",
            customer_birthdate: this.props.customer.customer_birthdate || "",
            //customer_form_id: this.props.customer.customer_form_id || "",
            //customer_areas_of_interest: this.props.customer.customer_areas_of_interest || "",
            customer_is_parent: !!this.props.customer.customer_is_parent,
            customer_from_hardcopy: false
        };

        return (
            <>

                <Formik
                    initialValues={initialValues}
                    validationSchema={() => Yup.object().shape({
                        customer_name: Yup.string()
                            .min(2, "İsim bilgisi en az 2 karakter içermelidir.")
                            .required("İsim bilgisi zorunludur."),
                        customer_surname: Yup.string()
                            .min(2, "Soyisim bilgisi en az 2 karakter içermelidir.")
                            .required("Soyisim bilgisi zorunludur."),
                        customer_gender: Yup.string()
                            .test("genderEnumCheck", "Cinsiyet bilgisi zorunludur.", (gender) => gender && (gender === "male" || gender === "female")),
                        customer_email: Yup.string()
                            .email("E-posta adresi geçersiz"),
                        customer_birthdate: Yup.string()
                            .test("birthDateStructureCheck", "Geçersiz bir doğum tarihi girdiniz. GG/AA/YYYY formatında olmalıdır.", dateCheck.dateStructureCheck)
                            .test("birthYearRangeCheck", "Doğum yılı 1920 - 2014 arası olmalıdır.", dateCheck.birthYearRangeCheck),
                    })}
                    onSubmit={this.props.handleSubmit}
                    render={(
                        {
                            touched,
                            values,
                            errors,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            isSubmitting,
                            handleChange,
                            handleBlur
                        }
                    ) => (

                        <Form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                            <FormElements.TextInput
                                type="text"
                                disabled
                                autoComplete="off"
                                displayName="Cep Telefonu"
                                inputName="customer_phone"
                                error={touched.customer_phone && errors.customer_phone}
                                isSubmitting={isSubmitting}
                                value={values.customer_phone || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                displayName="İsim *"
                                inputName="customer_name"
                                error={touched.customer_name && errors.customer_name}
                                isSubmitting={isSubmitting}
                                value={values.customer_name || ""}
                                onChange={(e) => {

                                    const val = e.target.value;
                                    setFieldValue("customer_name", upperCaseText(val));
                                    setFieldTouched("customer_name");
                                }}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                displayName="Soyisim *"
                                inputName="customer_surname"
                                error={touched.customer_surname && errors.customer_surname}
                                isSubmitting={isSubmitting}
                                value={values.customer_surname || ""}
                                onChange={(e) => {

                                    const val = e.target.value;
                                    setFieldValue("customer_surname", upperCaseText(val));
                                    setFieldTouched("customer_surname");
                                }}
                                onBlur={handleBlur}
                            />

                            <FormElements.Radio
                                displayName="Cinsiyet *"
                                inputName="customer_gender"
                                options={genderOptions}
                                error={touched.customer_gender && errors.customer_gender}
                                isSubmitting={isSubmitting}
                                value={values.customer_gender || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                displayName="E-posta"
                                inputName="customer_email"
                                error={touched.customer_email && errors.customer_email}
                                isSubmitting={isSubmitting}
                                value={values.customer_email || ""}
                                onChange={(e) => {

                                    const val = e.target.value;
                                    setFieldValue("customer_email", lowerCaseText(val));
                                    setFieldTouched("customer_email");
                                }}
                                onBlur={handleBlur}
                            />

                            <FormElements.DateInput
                                autoComplete="off"
                                displayName="Doğum Tarihi"
                                inputName="customer_birthdate"
                                placeholder="GG/AA/YYYY"
                                error={touched.customer_birthdate && errors.customer_birthdate}
                                isSubmitting={isSubmitting}
                                selectedValue={values.customer_birthdate}
                                onChange={(selectedDate) => {
                                    setFieldValue("customer_birthdate", selectedDate);
                                    setFieldTouched("customer_birthdate");
                                }}
                                onBlur={() => {
                                    setFieldTouched("customer_birthdate", true);
                                }}
                                hint="GG/AA/YYYY şeklinde input girişi yapılmalıdır, örnek: 01/01/1980"
                            />

                            {/* <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                displayName="Form ID"
                                inputName="customer_form_id"
                                error={touched.customer_form_id && errors.customer_form_id}
                                isSubmitting={isSubmitting}
                                value={values.customer_form_id || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            /> */}

                            {/* <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                displayName="İlgilendiği Alanlar"
                                inputName="customer_areas_of_interest"
                                error={touched.customer_areas_of_interest && errors.customer_areas_of_interest}
                                isSubmitting={isSubmitting}
                                value={values.customer_areas_of_interest || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            /> */}

                            <FormElements.Switch
                                error={touched.customer_is_parent && errors.customer_is_parent}
                                inputName="customer_is_parent"
                                displayName="Ziyaretçinin Çocuğu var mı?"
                                isSubmitting={isSubmitting}
                                value={values.customer_is_parent}
                                onChange={(e) => {

                                    const val = e.target.checked;
                                    setFieldValue("customer_is_parent", val);
                                    setFieldTouched("customer_is_parent");
                                }}
                                onBlur={handleBlur}
                            />

                            {
                                isOfflineFormAllowed && this.props.type === "Register" ? (
                                    <FormElements.Switch
                                        hint="!!! Bu seçeneği sadece ve sadece KVKK formunda ıslak imzası bulunan ziyaretçiler için kullanın. !!!"
                                        error={touched.customer_from_hardcopy && errors.customer_from_hardcopy}
                                        inputName="customer_from_hardcopy"
                                        displayName="Ziyaretçi Girişi Kağıttan mı Yapılıyor ?"
                                        isSubmitting={isSubmitting}
                                        value={values.customer_from_hardcopy}
                                        onChange={(e) => {

                                            const val = e.target.checked;
                                            setFieldValue("customer_from_hardcopy", val);
                                            setFieldTouched("customer_from_hardcopy");
                                        }}
                                        onBlur={handleBlur}
                                    />
                                ) : null
                            }

                            <FormElements.Submit
                                submitClasses={["full-width"]}
                                submitText={this.props.submitText}
                                isSubmitting={isSubmitting}
                            />
                        </Form>
                    )}
                />

                {
                    isPaymentFormAllowed && isCustomerExist ? (
                        <div className="payment-items">

                            <div className="profileUpdateHeader">
                                <h2>Harcama Ekle</h2>
                                <p>{`Toplam Harcama Tutarı: ${parseFloat(this.state.paymentAmount).toFixed(2)} TL`}</p>
                            </div>

                            <PaymentItem
                                {...this.props}
                                stores={stores}
                                eventType={eventType}
                                onPaymentAdded={this.onPaymentAdded}
                            />

                            <div className="profileUpdateHeader">
                                <h2>Harcama Geçmişi</h2>
                            </div>

                            <div className="payment-history">
                                <div className="columns is-multiline">
                                    {
                                        paymentHistory.map((item) => (
                                            <PaymentHistoryItem
                                                key={item._id}
                                                showConfirm={this.props.showConfirm}
                                                eventType={eventType}
                                                item={item}
                                                onPaymentRemoved={this.onPaymentRemoved}
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    isCouponFormAllowed && isCustomerExist ? (
                        <div>
                            <div className="payment-items">

                                <div className="payment-history">

                                    {this.state.couponPdfUrl && <iframe onLoad={(e) => e.target.contentWindow.print()} id="iframe" src={this.state.couponPdfUrl} style={{display: "none"}}></iframe>}

                                    <div className="profileUpdateHeader">
                                        <h2>Kuponlar</h2>
                                        <p>{`Toplam Kupon Sayısı: ${customerCouponInfo.total} Adet`}</p>
                                        <p>{`Talep Edilebilecek Kupon Sayısı: ${customerCouponInfo.free} Adet`}</p>
                                        <p>{`Kupon Kazanmak İçin Gerekli Harcama: ${customerCouponInfo.less.toFixed(2)} TL`}</p>
                                    </div>
                                    <Formik
                                        onSubmit={this.handleCouponRequest}
                                        render={(
                                            {
                                                handleSubmit,
                                                isSubmitting
                                            }
                                        ) => (
                                            <form autoComplete="off" className="coupon-history-form" onSubmit={handleSubmit}>

                                                <FormElements.Button
                                                    loading={isSubmitting}
                                                    disabled={customerCouponInfo.free < 1}
                                                    classNames={["is-link", "full-width", "is-outlined"]}
                                                    type="submit">
                                                    Yeni Kupon Talep Et
                                                </FormElements.Button>

                                            </form>
                                        )}
                                    />
                                    <div className="columns is-multiline">
                                        {
                                            customerCoupons.map((coupon) => (
                                                <CouponItem
                                                    key={coupon._id}
                                                    showConfirm={this.props.showConfirm}
                                                    coupon={coupon}
                                                    onCouponPrint={this.onCouponPrint}
                                                />
                                            ))
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    ): null
                }
                {
                    isPaymentFormAllowed && !isCustomerExist ? (
                        <div className="payment-items">

                            <div className="profileUpdateHeader">
                                <h2>Harcama Ekle / Sil</h2>
                                <div className="payment-item-form">
                                    <p>
                                        Yeni müşteri kaydı sırasında harcama formu gözükmez. Kaydın son adımında
                                        <strong>
                                            {" Bu Ziyaretçiyi Güncelle "}
                                        </strong>
                                        seçeneği ile harcama ekleyebilirsiniz.
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </>
        );
    }
}

export default withContext(CustomerForm);
