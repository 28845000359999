/**
 * Inline Error component for form elements
 */

import React from "react";
import PropTypes from "prop-types";

const InlineError = ({error}) => (

    error
        ? (
            <p className="help is-danger">
                {error}
            </p>
        )
        : null
);

InlineError.propTypes = {
    error: PropTypes.string
};

export default InlineError;
