/**
 * PushNotification Edit Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import PushNotificationForm from "./PushNotificationForm";

class PushNotificationEdit extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        pushNotification: null
    };

    pushNotificationId = this.props.match.params["pushnotificationid"];

    componentDidMount() {

        this.getPushNotitificationAsync(); // Update subheader statically
    }

    async getPushNotitificationAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/marketing/push-notifications/${this.pushNotificationId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                pushNotification: data
            }));

            this.props.setBreadcrumb([{
                url: "/marketing/push-notifications",
                name: "Push Gönderimi"
            }, {
                url: `/marketing/push-notifications/${this.pushNotificationId}/edit`,
                name: "Push"
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    async updatePushNotificationAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/marketing/push-notifications/${this.pushNotificationId}`,
                method: "PUT",
                payload,
                toast: `Push Gönderimi güncelledi: ${payload.push_notification_text_tr}`
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/marketing/push-notifications");
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.updatePushNotificationAsync(formData, formikBag);
    };

    render() {

        if (!this.state.pushNotification) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Push Gönderimi Güncelleme
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Push notification, mobil uygulamayı indirmiş, hiç giriş yapmamış, CRM le ilişkisi bulunmayan, app kullanıcılarına da gönderilebilir.",
                                "Herkese göndermek istiyorsanız, Gönderim tipini 'Broadcast' seçmelisiniz.",
                                "İleri tarihli gönderimlerin sonradan tarih bilgisini değiştiremezsiniz, tarih değiştirmek istiyorsanız silip tekrar eklemelisiniz.",
                            ]}
                            />
                        </div>
                        <PushNotificationForm
                            isEdit={true}
                            pushNotification={this.state.pushNotification}
                            setError={this.props.setError}
                            handleSubmit={this.handleSubmit}
                            submitText="Güncelle"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(PushNotificationEdit);


