/**
 * FilterModal component
 */

import React, {Component} from "react";
import {createPortal} from "react-dom";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import Moment from "moment-timezone";

import FormElements from "../../FormElements";
import FilterRadio from "../FilterRadio/FilterRadio";
import {getPrettyTextForFilter} from "../filterDefinitions";

export default class FilterModal extends Component {

    static propTypes = {
        filterSchema: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        onClose: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    };

    state = {
        selectedFilterSchema: null,
        selectedFilter: null,
        errorText: ""
    };

    componentDidMount() {
        document.body.classList.add("modal-open");
    }

    componentWillUnmount() {
        document.body.classList.remove("modal-open");
    }

    closeModal = () => {

        this.props.onClose();
    };

    filterSchemaClicked = (selectedFilterSchema) => {

        // If only one filter option exist, automatically select
        let selectedFilter = null;
        if (selectedFilterSchema.filters && selectedFilterSchema.filters.length === 1) {
            selectedFilter = {
                type: selectedFilterSchema.filters[0].type
            };
        }

        this.setState((prevState) => ({
            ...prevState,
            selectedFilterSchema,
            selectedFilter
        }));
    };

    addFilter = (e) => {

        e.preventDefault();

        if (!this.state.selectedFilter || !this.state.selectedFilter.type) {
            this.setState((prevState) => ({
                ...prevState,
                errorText: "Opsiyon seçmediniz."
            }));
            return;
        }

        const availableFilters = this.state.selectedFilterSchema.filters;
        const selectedFilterSchema = availableFilters.find((availableFilter) => availableFilter.type === this.state.selectedFilter.type);

        let filter = this.state.selectedFilter;

        if (filter.type === "boolean" && !filter.hasOwnProperty("value")) {

            // Input exist but value is not exist! Set default value
            filter = {
                ...filter,
                value: false,
                label: "Hayır"
            };
        }

        if (selectedFilterSchema.input && selectedFilterSchema.input !== "BooleanSwitch" && !filter.value) {
            // Input exist but value is not exist! Set default value
            this.setState((prevState) => ({
                ...prevState,
                errorText: "Değer girişi yapmadınız."
            }));
            return;
        }

        this.props.onSubmit({
            filter,
            schema: this.state.selectedFilterSchema
        });
    };

    filterDisabled = (filterSchemaItem) => {

        const field = filterSchemaItem.field;

        for (const filter of this.props.filters) {

            if (filter.field === field && !filterSchemaItem.allowsMultiple) {
                return true;
            }

            if (filterSchemaItem.allowsMultiple && filterSchemaItem.max) {

                const existingItems = this.props.filters.filter((existingFilter) => existingFilter.field === filterSchemaItem.field);

                if (existingItems.length >= filterSchemaItem.max) {
                    return true;
                }
            }
        }

        return false;
    };

    getTextInput(type = "text") {
        return (
            <input
                autoComplete="off"
                type={type}
                className="input"
                name="filter_value"
                id="filter_value"
                value={(this.state.selectedFilter && this.state.selectedFilter.value) || ""}
                placeholder="Değer"
                onChange={(e) => {

                    const value = e.target.value;
                    this.setState((prevState) => ({
                        ...prevState,
                        selectedFilter: {
                            ...prevState.selectedFilter,
                            value,
                            label: value
                        }
                    }));
                }}
            />
        );
    }

    getDateInput() {

        let selected;
        if (this.state.selectedFilter && this.state.selectedFilter.value) {
            selected = Moment(this.state.selectedFilter.value, "DD/MM/YYYY").toDate();
        }

        const CalendarContainer = ({children}) => {

            const PopperContainer = document.body;

            if (!children) {
                return null;
            }

            return createPortal(children, PopperContainer);
        };

        return (
            <DatePicker
                className="input"
                name="filter_value"
                id="filter_value"
                selected={selected}
                placeholderText="DD/MM/YYYY"
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                dropdownMode="select"
                popperContainer={CalendarContainer}
                minDate={Moment().subtract(3, "years").toDate()}
                maxDate={Moment().toDate()}
                onChange={(selectedDate) => {

                    const selectedDateStr = Moment(selectedDate).format("DD/MM/YYYY");

                    this.setState((prevState) => ({
                        ...prevState,
                        selectedFilter: {
                            ...prevState.selectedFilter,
                            value: selectedDateStr,
                            label: selectedDateStr
                        }
                    }));
                }}
            />
        );
    }

    getAsyncSelect(filterItem) {

        return (
            <FormElements.SelectInputAsync
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                menuPosition="fixed"
                menuPlacement="auto"
                menuPortalTarget={document.body}
                defaultOptions
                isMenuOpen
                endpoint={filterItem.endpoint}
                foreignField={filterItem.foreignField}
                displayName="Değer"
                placeholder="Değer"
                inputName="filter_value"
                classNamePrefix="react-select"
                onChange={(selectedOption) => {

                    this.setState((prevState) => ({
                        ...prevState,
                        selectedFilter: {
                            ...prevState.selectedFilter,
                            value: selectedOption.value,
                            label: selectedOption.label
                        }
                    }));
                }}
            />
        );
    }

    getSelect(filterItem) {

        return (
            <FormElements.SelectInput
                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                options={filterItem.options}
                noOptionsMessage={() => "No result"}
                displayName="Değer"
                placeholder="Değer"
                classNamePrefix="react-select"
                inputName="filter_value"
                menuPosition="fixed"
                menuPlacement="auto"
                menuPortalTarget={document.body}
                defaultOptions
                isMenuOpen
                onChange={(selectedOption) => {

                    this.setState((prevState) => ({
                        ...prevState,
                        selectedFilter: {
                            ...prevState.selectedFilter,
                            value: selectedOption.value,
                            label: selectedOption.label
                        }
                    }));
                }}
            />
        );
    }

    getGenderRadio() {

        return (
            <div style={{display: "flex"}}>
                <div style={{float: "left"}}>
                    <input
                        type="radio"
                        className="is-checkradio is-info"
                        value="female"
                        name="filter_value"
                        id="filter_value_1"
                        checked={(this.state.selectedFilter && this.state.selectedFilter.value) === "female"}
                        onChange={() => {

                            this.setState((prevState) => ({
                                ...prevState,
                                selectedFilter: {
                                    ...prevState.selectedFilter,
                                    value: "female",
                                    label: "Kadın"
                                }
                            }));
                        }}
                    />
                    <label className="label" htmlFor="filter_value_1">
                        Kadın
                    </label>
                </div>
                <div style={{float: "left"}}>
                    <input
                        type="radio"
                        className="is-checkradio is-info"
                        value="male"
                        name="filter_value"
                        id="filter_value_2"
                        checked={(this.state.selectedFilter && this.state.selectedFilter.value) === "male"}
                        onChange={() => {

                            this.setState((prevState) => ({
                                ...prevState,
                                selectedFilter: {
                                    ...prevState.selectedFilter,
                                    value: "male",
                                    label: "Erkek"
                                }
                            }));
                        }}
                    />
                    <label className="label" htmlFor="filter_value_2">
                        Erkek
                    </label>
                </div>
            </div>
        );
    }

    getBooleanSwitch() {

        return (
            <div style={{marginTop: "0.5rem"}}>
                <input
                    type="checkbox"
                    className="switch is-info"
                    name="filter_value"
                    id="filter_value_1"
                    checked={!!(this.state.selectedFilter && this.state.selectedFilter.value)}
                    onChange={(e) => {

                        const val = e.target.checked;

                        this.setState((prevState) => ({
                            ...prevState,
                            selectedFilter: {
                                ...prevState.selectedFilter,
                                value: val,
                                label: "Evet"
                            }
                        }));
                    }}
                />
                <label className="label" htmlFor="filter_value_1"/>
            </div>
        );
    }

    getFilterList() {

        return (
            <div className="filterStep1">
                <h5>Filtre uygulamak istediğiniz alanı seçin.</h5>
                <ul className="filterList">
                    {
                        this.props.filterSchema.map((filterSchemaItem) => (
                            <li
                                key={filterSchemaItem.field}
                                className={this.filterDisabled(filterSchemaItem) ? "is-disabled" : ""}
                                onClick={() => {
                                    if (!this.filterDisabled(filterSchemaItem)) {
                                        this.filterSchemaClicked(filterSchemaItem);
                                    }
                                }}>
                                {filterSchemaItem.display}
                                <span className="floatRight">
                                    {
                                        filterSchemaItem.allowsMultiple ?
                                            `Çoklu Seçim ${filterSchemaItem.max ? `(Max: ${filterSchemaItem.max})` : ""}`
                                            : "Tekli Seçim"
                                    }
                                </span>
                            </li>
                        ))
                    }
                </ul>
            </div>
        );
    }

    getFilterItem() {

        const selectedFilterSchema = this.state.selectedFilterSchema;

        return (
            <div className="filterStep2">
                <h5 className="filterTitle">
                    {`'${selectedFilterSchema.display}' `}
                    <span>için bir filtre ekleyin.</span>
                </h5>
                <form className="filterForm" onSubmit={this.addFilter}>

                    {
                        this.getFilterOptions()
                    }
                    {
                        this.state.errorText ? (
                            <p className="has-text-danger" style={{marginTop: "0.75rem"}}>{this.state.errorText}</p>
                        ) : null
                    }
                    <FormElements.Submit
                        submitClasses={["full-width"]}
                        submitText="Ekle"
                    />
                </form>
            </div>
        );
    }

    getFilterOptions() {

        let filters = [];

        const selectedFilterSchema = this.state.selectedFilterSchema;

        for (const filter of selectedFilterSchema.filters) {

            let input;
            if (filter.input === "TextInput") {
                input = this.getTextInput("text");
            }
            else if (filter.input === "DateInput") {
                input = this.getDateInput();
            }
            else if (filter.input === "NumberInput") {
                input = this.getTextInput("number");
            }
            else if (filter.input === "GenderRadio") {
                input = this.getGenderRadio();
            }
            else if (filter.input === "AsyncSelect") {
                input = this.getAsyncSelect(filter);
            }
            else if (filter.input === "Select") {
                input = this.getSelect(filter);
            }
            else if (filter.input === "BooleanSwitch") {
                input = this.getBooleanSwitch(filter);
            }

            filters.push({
                name: getPrettyTextForFilter(filter.type),
                type: filter.type,
                input,
            });
        }

        return (

            <FilterRadio
                inputName="filter_radio"
                filters={filters}
                selectedFilter={this.state.selectedFilter && this.state.selectedFilter.type}
                onOptionSelected={(val) => {

                    this.setState((prevState) => ({
                        ...prevState,
                        selectedFilter: {
                            type: val
                        }
                    }));
                }}
            />
        );
    }

    render() {

        return (
            <div className="modal-container">
                <div
                    className="modal is-active">
                    <div role="presentation" className="modal-background"/>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{`Filtre Ekleme (${!this.state.selectedFilterSchema ? 1 : 2}/2)`}</p>
                            <button className="delete" type="button" aria-label="close" onClick={this.closeModal}/>
                        </header>
                        <section className="modal-card-body">
                            {
                                // selectedFilterSchema is empty so show selector first.
                                this.state.selectedFilterSchema ? this.getFilterItem() : this.getFilterList()
                            }
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}
