/**
 * TextArea component
 */
import React from "react";
import PropTypes from "prop-types";

import InlineError from "../InlineError/InlineError";
import Hint from "../Hint/Hint";

const TextArea = (props) => (

    <div className={`field ${props.error ? "is-danger" : ""}`}>
        <label className="label" htmlFor={props.inputName}>
            <span>
                {props.displayName}
            </span>
            {
                props.labelExplanation ? (
                    <span className="label-explanation">{props.labelExplanation}</span>
                ) : null
            }
            <Hint hint={props.hint}/>
        </label>

        <div className="control">
            <textarea
                className="textarea"
                name={props.inputName}
                placeholder={props.placeholder || props.displayName}
                id={props.inputName}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                disabled={props.isLoading}
            />
        </div>

        <InlineError error={props.error}/>

    </div>
);

TextArea.propTypes = {

    inputName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    hint: PropTypes.string,
    labelExplanation: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,

};

export default TextArea;
