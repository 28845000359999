/**
 * CrmTable component
 */

import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import {PAGINATION} from "../../services/constants";

const CrmTable = (props) => (

    <ReactTable
        {...props}
        pageSizeOptions={props.pagination.pageSizeOptions}
        defaultPageSize={props.pagination.defaultPageSize}
        showPaginationBottom
        className="-striped -highlight"
        previousText="Önceki"
        nextText="Sonraki"
        loadingText="Yükleniyor"
        noDataText="Kayıt bulunamadı"
        pageText="Sayfa"
        rowsText="Kayıt"
        ofText="/"
    />
);

CrmTable.defaultProps = {
    pagination: {
        pageSizeOptions: PAGINATION.PAGE_SIZE_OPTIONS,
        defaultPageSize: PAGINATION.DEFAULT_PAGE_SIZE
    }
};

CrmTable.propTypes = {
    pagination: PropTypes.shape({
        pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
        defaultPageSize: PropTypes.number
    })
};

export default CrmTable;
