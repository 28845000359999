/**
 * Hint component
 */

import React from "react";
import PropTypes from "prop-types";

const Hint = (props) => {

    if (!props.hint) {
        return null;
    }

    if (!props.hintAsLabelExplanation) {
        return (
            <span
                className="icon tooltip is-tooltip-multiline is-tooltip-left"
                data-tooltip={props.hint}
                style={{
                    marginLeft: "0.25rem",
                    width: "1rem",
                    height: "1rem",
                    color: "#c1c1c1"
                }}>
                <i className="fas fa-info-circle"/>
            </span>
        );
    }
    else {
        return (
            <span className="label-explanation">
                {props.hint}
            </span>
        );
    }
};

Hint.propTypes = {
    hint: PropTypes.string,
    hintAsLabelExplanation: PropTypes.bool
};

export default Hint;
