/**
 * Coupon Campaign Add Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import CouponCampaignForm from "./CouponCampaignForm";

class CouponCampaignAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/campaign/coupon-campaigns",
            name: "Kupon Kampanyaları"
        }, {
            url: "/campaign/coupon-campaigns/new",
            name: "Yeni Kupon Kampanyası"
        }]);
    }

    async createCouponCampaignAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: "/campaign/coupon-campaigns",
                method: "POST",
                payload: {
                    ...payload,
                    "coupon_campaign_status": "draft"
                },
                toast: `Kupon Kampanyası oluşturuldu: ${payload.coupon_campaign_name_tr}`
            });

            this.props.history.replace("/campaign/coupon-campaigns");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createCouponCampaignAsync(formData, formikBag);
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Yeni Kupon Kampanyası
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Kupon kampanyası oluşturmadan önce lütfen ilgili dökümanı okuyunuz.",
                                "Özetle; şu adımlardan oluşmaktadır:",
                                "1. Kampanya 'TASLAK' olarak oluşturulur. (Bu sayfa)",
                                "2. Taslak kampanyaya 'KUPON KODLARI' eklenir. Kampanya 'HAZIR' duruma geçer.",
                                "3. Kampanya 'YAYINLA' butonuyla yayınlanır.",
                                "Kupon kodu tükenen kampanyalar veya yayından kaldırma tarihi gelen kampanyalar otomatik 'ARŞİV' lenir."
                            ]}
                            />
                        </div>

                        <CouponCampaignForm
                            couponCampaign={{}}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CouponCampaignAdd);


