/**
 * ScrollToTop on route changes!
 */

import {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import withContext from "../../context/withContext";

class RouteHandler extends Component {

    static propTypes = {

        // React router
        location: PropTypes.shape({
            pathname: PropTypes.string
        }),

        customerContext: PropTypes.shape({
            customerFilters: PropTypes.arrayOf(PropTypes.shape()),
            clearCustomerFilters: PropTypes.func.isRequired,
            clearPagination: PropTypes.func.isRequired,
        }),
    };

    componentDidUpdate(prevProps) {

        if (this.props.location && this.props.location.pathname) {

            if (this.props.location.pathname !== prevProps.location.pathname) {
                window.scrollTo(0, 0);
            }

            const currentPathName = this.props.location.pathname;
            if (this.props.customerContext.customerFilters && this.props.customerContext.customerFilters.length > 0
                && !currentPathName.startsWith("/customers/profile") && !currentPathName.startsWith("/customers/list")) {

                this.props.customerContext.clearCustomerFilters();
                this.props.customerContext.clearPagination();
            }
        }
    }

    render() {
        return (
            this.props.children
        );
    }
}

export default withContext(withRouter(RouteHandler));
