import React, {Component} from "react";
import PropTypes from "prop-types";

import apiCall from "../../services/api";
import formatDatetime from "../../services/utils/format_datetime";

class CouponItem extends Component {
    static propTypes = {
        coupon: PropTypes.shape({
            code: PropTypes.string,
            given_at: PropTypes.string,
            store_name: PropTypes.string,
            start_date: PropTypes.string,
            end_date: PropTypes.string,
        }),
        showConfirm: PropTypes.func.isRequired,
        onCouponPrint: PropTypes.func.isRequired
    };

    static defaultProps = {
        coupon: {}
    };

    handlePrint = async () => {

        const {coupon, onCouponPrint} = this.props;
        
        const response = await apiCall({
            url: `/campaign/coupon-codes/${coupon._id}/pdf`,
            method: "GET",
            withAuth: true,
            options: {responseType: "arraybuffer"}
        });     
        
        const couponPdfUrl = window.URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
        onCouponPrint(couponPdfUrl);
    }

    showPrintConfirm = () => {

        const {coupon, showConfirm} = this.props;
        
        showConfirm({
            title: "Bu kuponu yazdırmak istediğinize emin misiniz?",
            message: `${coupon.campaign_title && coupon.campaign_title.tr} kampanyasına ait ${coupon.code} referans numaralı kuponu yazdırıyorsunuz`,
            buttons: [{
                title: "Tamamdır, YAZDIR!",
                asyncFn: this.handlePrint
            }]
        });
    }

    render() {

        const {coupon} = this.props;
        return (
            <div className="column is-6-tablet is-12-mobile">
                <ul className="payment-item-history">
                    <button
                        type="button"
                        className="button payment-item-remove"
                        onClick={this.showPrintConfirm}>
                        <span className="icon is-small"><i className="fa fa-print"/></span>
                    </button>
                    <li>
                        <span>Kupon Kodu</span>
                        <h5>{coupon.code}</h5>
                    </li>
                    <li>
                        <span>Verilme Tarihi</span>
                        <h5>{formatDatetime(coupon.given_at)}</h5>
                    </li>
                    {coupon.store_name && <li>
                        <span> Mağaza İsmi </span>
                        <h5>{coupon.store_name}</h5>
                    </li>}
                    <li>
                        <span> Başlangıç Tarihi </span>
                        <h5>{coupon.start_date}</h5>
                    </li>
                    <li>
                        <span> Bitiş Tarihi </span>
                        <h5>{coupon.end_date}</h5>
                    </li>
                </ul>
            </div>
        );
    }
}

export default CouponItem;