import Numeral from "numeral";

export default (number) => {

    if (typeof number === "undefined" || number === null) {
        return "-";
    }

    return Numeral(number).format("0,0");
};
