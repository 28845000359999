/**
 * FileInput without upload component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

import FileInputPreview from "./FileInputPreview/FileInputPreview";
import UploadProgress from "../UploadProgress/UploadProgress";

import InlineError from "../InlineError/InlineError";

const BYTE_TO_MB = 1024 * 1024; // 1MB
const MAX_FILE_SIZE_BYTE = 1024 * 1024 * 50; // 50MB

const FIXED_PREVIEW_SIZE = 320;

export default class FileInputWithoutUpload extends Component {

    static defaultProps = {
        placeholder: "Yeni dosya seçiniz..."
    };

    static propTypes = {
        disabled: PropTypes.bool,
        inputName: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        displayName: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        hint: PropTypes.string,
        extensions: PropTypes.arrayOf(PropTypes.string),
        mimeTypes: PropTypes.arrayOf(PropTypes.string),
        setFile: PropTypes.func,
        error: PropTypes.string
    };

    state = {
        file: null,
        fileName: null,
        error: null,
        uploading: false,
        progress: 0
    };

    clearFile = () => {

        this.props.setFile(undefined);

        this.setState((prevState) => ({
            ...prevState,
            uploading: false,
            progress: 0,
            file: null,
            fileName: null,
            error: null
        }));
    };

    fileChanged = async (event) => {

        const file = event.currentTarget.files[0];

        if (!file) {

            this.setState((prevState) => ({
                ...prevState,
                error: "Something is wrong with the input file"
            }));
            // break and return
            return;
        }

        const fileName = file.name;
        const mimeType = file.type;

        if (this.props.extensions && this.props.extensions.length > 0) {

            const extension = fileName.split(".").pop();

            if (!this.props.extensions.includes(extension)) {
                this.setState((prevState) => ({
                    ...prevState,
                    error: `Dosya uzantısı geçersiz. Beklenen: '${this.props.extensions.join(",")}'. Yüklenen: '${extension}'`
                }));
                // break and return
                return;
            }
        }

        if (this.props.mimeTypes && this.props.mimeTypes.length > 0) {

            if (!this.props.mimeTypes.includes(mimeType)) {
                this.setState((prevState) => ({
                    ...prevState,
                    error: `Dosya tipip geçersiz. Beklenen: '${this.props.mimeTypes.join(",")}'. Yüklenen: '${mimeType}'`
                }));
                // break and return
                return;
            }
        }

        if (file.size > MAX_FILE_SIZE_BYTE) {

            const fileSizeInMegabyte = (file.size / BYTE_TO_MB).toFixed(2);
            const maxFileSizeInMegabyte = (MAX_FILE_SIZE_BYTE / BYTE_TO_MB).toFixed(2);

            this.setState((prevState) => ({
                ...prevState,
                error: `File is too big: ${fileSizeInMegabyte}MB. Maximum allowed size is: ${maxFileSizeInMegabyte}MB`
            }));
            // break and return
            return;
        }

        // const fileUrl = data[this.props.inputName];
        this.addFilePreview(file, "#");
    };

    isDisabled = () => {
        return this.props.isLoading || this.state.uploading || this.props.isSubmitting || this.props.disabled;
    };

    addFilePreview(file, fileUrl) {

        this.setState({
            uploading: false,
            progress: 0,
            file: fileUrl,
            fileName: file.name,
            error: null
        });

        // Notify formik to set file input!
        this.props.setFile(file);
    }

    render() {

        // We want to show filePreview field with fixed aspect ratio and size,
        // even if file is empty.

        return (

            <div className={`field fileInput ${this.props.error ? "is-danger" : ""}`}>

                <label className="label" htmlFor={this.props.inputName}>
                    <span>
                        {this.props.displayName}
                    </span>
                    {
                        this.props.hint
                            ? (
                                <span className="icon has-text-info tooltip is-tooltip-multiline is-tooltip-left" data-tooltip={this.props.hint}>
                                    <i className="fas fa-info-circle"/>
                                </span>
                            ) : null
                    }
                </label>
                <div className="control">

                    {
                        this.state.uploading

                            ? (
                                <UploadProgress
                                    progress={this.state.progress}
                                />
                            )

                            : (
                                <FileInputPreview
                                    file={this.state.file}
                                    clearFile={this.clearFile}
                                    isLoading={this.props.isLoading}
                                />
                            )
                    }

                    <div className={`file ${this.isDisabled() ? "disabled": ""}`} style={{width: FIXED_PREVIEW_SIZE, maxWidth: "100%"}}>
                        <label className="file-label" htmlFor={this.props.inputName} style={{width: "100%"}}>
                            <input
                                className="file-input"
                                type="file"
                                name={this.props.inputName}
                                id={this.props.inputName}
                                onChange={this.fileChanged}
                                disabled={this.props.isLoading || this.state.uploading || this.props.disabled}
                            />
                            <span className="file-cta" style={{width: "100%"}}>
                                <span className="file-icon">
                                    <i className="fa fa-upload"/>
                                </span>
                                <span className="file-label">
                                    {this.state.fileName || this.props.placeholder}
                                </span>
                            </span>
                        </label>
                    </div>

                </div>

                <InlineError error={this.state.error}/>
                <InlineError error={this.props.error}/>
            </div>
        );
    }
}
