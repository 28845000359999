/**
 * CrmBigTable component
 */

import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

import {PAGINATION} from "../../services/constants";

const CrmBigTable = (props) => {

    const style = {};
    if (props.fixedHeight) {
        style.height = `${props.fixedHeight}px`; // This will force the table body to overflow and scroll
    }

    return (
        <ReactTable
            {...props}
            pageSizeOptions={PAGINATION.PAGE_SIZE_OPTIONS}
            defaultPageSize={PAGINATION.DEFAULT_PAGE_SIZE}
            page={props.pagination.currentPage - 1}
            pages={Math.ceil(props.pagination.total / props.pagination.limit)}
            pageSize={props.pagination.limit}
            onPageChange={(page) => {
                props.onPageChange(page);
            }}
            onPageSizeChange={props.onPageSizeChange}
            showPaginationBottom
            manual
            className="-striped -highlight"
            previousText="Önceki"
            nextText="Sonraki"
            loadingText="Yükleniyor"
            noDataText="Kayıt bulunamadı"
            pageText="Sayfa"
            rowsText="Kayıt"
            ofText="/"
            style={style}
        />
    );
};

CrmBigTable.propTypes = {
    pagination: PropTypes.shape({
        limit: PropTypes.number,
        currentPage: PropTypes.number,
        total: PropTypes.number
    }),
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    fixedHeight: PropTypes.number
};

export default CrmBigTable;
