/**
 * InviteAdd Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Formik} from "formik";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class InviteAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        authContext: PropTypes.shape({
            admin: PropTypes.shape()
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/invites",
            name: "Kullanıcı Davetleri"
        }, {
            url: "/invites/new",
            name: "Yeni Davet"
        }]);
    }

    async createInviteAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: "/invites",
                method: "POST",
                payload,
                toast: `Davet gönderildi: ${payload.admin_invite_email}`
            });

            this.props.history.replace("/invites");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createInviteAsync(formData, formikBag);
    };

    getAvailableRoles = () => {

        const admin = this.props.authContext.admin;

        return [
            {
                label: "CRM Admin",
                value: "CrmAdmin",
                isDisabled: !(admin.admin_role === "SystemAdmin" || admin.admin_role === "GeneralAdmin" || admin.admin_role === "CrmAdmin")
            },
            {
                label: "Customer Desk (Şikayet, Fatura, Rezervasyon, Ziyaretçi Kayıt)",
                value: "CrmCustomerDesk",
                isDisabled: !(admin.admin_role === "SystemAdmin" || admin.admin_role === "GeneralAdmin" || admin.admin_role === "CrmAdmin")
            },

            {
                label: "Veri Giriş Görevlisi (Ziyaretçi Kayıt)",
                value: "CrmDataCollector",
                isDisabled: !(admin.admin_role === "SystemAdmin" || admin.admin_role === "GeneralAdmin" || admin.admin_role === "CrmAdmin")
            }
        ];
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Yeni Davet
                                </span>
                            </h5>
                        </div>

                        <Formik
                            initialValues={{
                                admin_invite_email: "",
                                admin_invite_role: ""
                            }}
                            onSubmit={this.handleSubmit}
                            validationSchema={() => Yup.object().shape({
                                admin_invite_email: Yup.string()
                                    .email("Geçersiz bir e-posta adresi.")
                                    .required("E-posta zorunlu bir alan."),
                                admin_invite_role: Yup.string()
                                    .min(1)
                                    .required("Yetki seviyesi zorunlu bir alan.")
                            })}
                            render={({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                    <FormElements.SelectInput
                                        isDisabled={isSubmitting}
                                        displayName="Yetki Seviyesi"
                                        inputName="admin_invite_role"
                                        error={errors["admin_invite_role"]}
                                        options={this.getAvailableRoles()}
                                        classNamePrefix="react-select"
                                        noOptionsMessage={() => "No result"}
                                        placeholder="Select Role"
                                        onChange={(selectedOption) => {

                                            setFieldValue("admin_invite_role", selectedOption.value);
                                            setFieldTouched("admin_invite_role");
                                        }}
                                        onBlur={() => {
                                            setFieldTouched("admin_invite_role", true);
                                        }}
                                    />

                                    <FormElements.TextInput
                                        type="email"
                                        autoComplete="off"
                                        error={touched.admin_invite_email && errors.admin_invite_email}
                                        inputName="admin_invite_email"
                                        displayName="E-posta"
                                        isSubmitting={isSubmitting}
                                        value={values.admin_invite_email || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormElements.Submit
                                        submitClasses={["full-width"]}
                                        submitText="Gönder"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(InviteAdd);


