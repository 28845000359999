/**
 * CustomerCheck Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class CustomerSmsValidation extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        entryContext: PropTypes.shape({
            entryEventType: PropTypes.shape().isRequired,
            entryCustomer: PropTypes.shape().isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,

        // react router
        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {

        smsCode: "",
        smsCodeError: null,
        smsCodeValid: false,
        isSuccess: false,

        resendError: null,
        isResendSuccess: false,

        isLoading: false,
    };

    componentDidMount() {

        // check if actual type matches.
        const entryEventType = this.props.entryContext.entryEventType;
        const customer = this.props.entryContext.entryCustomer;

        if (!entryEventType) {
            this.props.history.replace("/customer-entry");
            return;
        }

        if (!customer || !customer.customer_phone) {
            this.props.history.replace("/customer-entry/check");
            return;
        }

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/customer-entry",
            name: "Ziyaretçi Kayıt"
        }, {
            url: "/customer-entry/check",
            name: "Kayıt Başlangıç",
        }, {
            url: "/customer-entry/sms-validation",
            name: "SMS Doğrulama",
        }]);
    }

    checkSmsValidationAsync = async (event) => {

        if (event) {
            event.preventDefault();
        }

        if (!this.state.smsCodeValid) {

            this.setState((prevState) => ({
                ...prevState,
                smsCodeError: "Lütfen 4 rakamıda giriniz."
            }));

            return;
        }

        this.setState((prevState) => ({
            ...prevState,
            smsCodeError: null,
            isLoading: true
        }));

        const customer = this.props.entryContext.entryCustomer;

        // API REQUEST
        try {

            // 200 means sms code status is success.
            await apiCall({
                url: "/customer-entry/sms-validation",
                method: "POST",
                payload: {
                    customer_sms_validation_code: this.state.smsCode,
                    customer_phone: customer.customer_phone
                }
            });

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);
            this.setState((prevState) => ({
                ...prevState,
                smsCodeError: null,
                isLoading: false,
                isSuccess: true
            }));
        }
        catch (e) {

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);

            // Checking State
            this.setState((prevState) => ({
                ...prevState,
                isLoading: false,
                smsCodeError: e.message || "Bir şeyler yanlış gidiyor, lütfen daha sonra tekrar deneyiniz."
            }));
        }
    };

    sendSmsAgain = async () => {

        this.setState((prevState) => ({
            ...prevState,
            resendError: null,
            isResendSuccess: false,
            isLoading: true
        }));

        const customer = this.props.entryContext.entryCustomer;

        // API REQUEST
        try {

            // 200 means sms code status is success.
            await apiCall({
                url: "/customer-entry/sms-resend",
                method: "POST",
                payload: {
                    customer_phone: customer.customer_phone
                }
            });

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);
            this.setState((prevState) => ({
                ...prevState,
                resendError: null,
                isResendSuccess: true,
                isLoading: false
            }));
        }
        catch (e) {

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);

            // Checking State
            this.setState((prevState) => ({
                ...prevState,
                isLoading: false,
                isResendSuccess: false,
                resendError: e.message || "Bir şeyler yanlış gidiyor, lütfen daha sonra tekrar deneyiniz."
            }));
        }
    };

    render() {

        if (this.state.isSuccess) {
            return (
                <Redirect to="/customer-entry/success/register-customer"/>
            );
        }

        if (!this.props.entryContext.entryEventType || !this.props.entryContext.entryCustomer) {
            return null;
        }

        const phone = this.props.entryContext.entryCustomer && this.props.entryContext.entryCustomer.customer_phone;

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    SMS Doğrulama:
                                    {this.props.entryContext.entryEventType.event_type_name}
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                `4 rakamdan oluşan doğrulama kodu SMS olarak ziyaretçinin telefonuna (${phone}) gönderildi.`
                            ]}
                            />
                        </div>

                        <form autoComplete="off" className="smsValidationForm" onSubmit={this.checkSmsValidationAsync}>

                            <FormElements.SmsCodeValidation
                                disabled={this.state.isLoading}
                                onChange={(smsCode, smsCodeValid) => {

                                    this.setState((prevState) => ({
                                        ...prevState,
                                        smsCode,
                                        smsCodeValid
                                    }), () => {

                                        if (smsCodeValid) {
                                            this.checkSmsValidationAsync();
                                        }
                                    });
                                }}
                            />

                            {
                                this.state.smsCodeError ? (
                                    <div className="smsValidationError">
                                        <p className="has-text-danger">{this.state.smsCodeError}</p>
                                    </div>
                                ) : null
                            }

                            <FormElements.Submit
                                submitClasses={["full-width"]}
                                submitText="Doğrula"
                                isSubmitting={this.state.isLoading}
                            />
                        </form>

                        <div className="smsExtraContrainer">
                            <div className="smsExtraItem">
                                <button
                                    type="button"
                                    disabled={this.state.isLoading}
                                    className={`button is-small ${this.state.isLoading ? "is-loading": ""}`}
                                    onClick={this.sendSmsAgain}>
                                    SMS kodunu tekrar gönder
                                </button>
                            </div>
                            {
                                this.state.resendError ? (
                                    <div className="smsValidationError">
                                        <p className="has-text-danger">{this.state.resendError}</p>
                                    </div>
                                ) : null
                            }
                            {
                                this.state.isResendSuccess ? (
                                    <div className="smsValidationError">
                                        <p className="has-text-info">SMS doğrulama kodu yeniden gönderilmiştir.</p>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CustomerSmsValidation);


