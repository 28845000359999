/**
 * TeachSectionForm component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import Moment from "moment-timezone";
import apiCall from "../../services/api";

class PaymentHistoryItem extends Component {

    static propTypes = {

        eventType: PropTypes.object.isRequired,
        item: PropTypes.shape({
            _id: PropTypes.string,
            payment_amount: PropTypes.number,
            payment_receipt_no: PropTypes.string,
            createdAt: PropTypes.string,
        }),
        onPaymentRemoved: PropTypes.func,
        // layout hoc.
        showConfirm: PropTypes.func.isRequired,
    };

    static defaultProps = {
        item: {},
    };

    state = {
        error: null,
        showConfirm: false,
    };

    handleDelete = async () => {

        const {eventType, item} = this.props;

        this.setState((prevState) => ({
            ...prevState,
            operationInProgress: true,
            error: null
        }));

        try {

            const {data} = await apiCall({
                url: `/data/event-types/${eventType._id}/payments/${item._id}`,
                method: "DELETE",
                toast: "Harcama Silindi.",
                withAuth: true
            });

            this.props.onPaymentRemoved(data);
        }
        catch (e) {

            console.error(e);

            this.setState((prevState) => ({
                ...prevState,
                error: e.message || e,
                operationInProgress: false
            }));

            return;
        }

        if (this._isMounted) {
            this.setState((prevState) => ({
                ...prevState,
                operationInProgress: false
            }));
        }
    };

    showDeleteConfirm = () => {

        const {item, showConfirm} = this.props;

        showConfirm({
            title: "Bu harcama bildirimini silmek istediğinize emin misiniz?",
            message: `${item.payment_store_name} kategorisindeki ait ${item.payment_amount} tutarındaki harcamayı sistemden siliyorsunuz`,
            buttons: [{
                title: "Tamamdır, SİL!",
                asyncFn: this.handleDelete
            }]
        });
    };

    render() {

        const {item} = this.props;

        return (
            <div className="column is-6-tablet is-12-mobile">
                <ul className="payment-item-history">
                    {
                        Moment(item.payment_date).isSame(Moment(), "day") &&
                        <button
                            type="button"
                            className="button payment-item-remove"
                            onClick={this.showDeleteConfirm}>
                            <span className="icon is-small"><i className="fa fa-trash"/></span>
                        </button>
                    }
                    <li>
                        <span>Tutar:</span>
                        <h5>{`${item.payment_amount.toFixed(2)} TL`}</h5>
                    </li>
                    <li>
                        <span>Mağaza:</span>
                        <h5>{item.payment_store_name || "Mağasız tanımsız"}</h5>
                    </li>
                    <li>
                        <span>Kategori:</span>
                        <h5>{item.payment_category_names.join(", ") || "Kategori tanımsız"}</h5>
                    </li>
                    <li>
                        <span>Fatura No:</span>
                        <h5>{item.payment_receipt_no}</h5>
                    </li>
                    <li>
                        <span>Fatura Tarihi:</span>
                        <h5>{Moment(item.payment_date).format("DD/MM/YYYY - HH:mm")}</h5>
                    </li>
                    <li>
                        <span>Kayıt Tarihi:</span>
                        <h5>{Moment(item.createdAt).format("DD/MM/YYYY - HH:mm")}</h5>
                    </li>
                </ul>
            </div>
        );
    }
}

export default PaymentHistoryItem;
