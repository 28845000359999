/**
 * Invites Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {toast as showToast} from "react-toastify";

import apiCall from "../../../services/api";
import withContext from "../../../context/withContext";
import {CrmBigTable, CustomerFilter, ToastContent} from "../../../components";
import CurstomerExportForm from "./CustomerExportForm";
import formatPrice from "../../../../../common/format_price";

class Customers extends PureComponent {

    static propTypes = {

        // Context
        authContext: PropTypes.shape({
            admin: PropTypes.shape()
        }),

        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        customerContext: PropTypes.shape({
            customerPagination: PropTypes.shape().isRequired,
            customerFilters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
            addCustomerFilter: PropTypes.func.isRequired,
            removeCustomerFilter: PropTypes.func.isRequired,
            clearCustomerFilters: PropTypes.func.isRequired,
            setCustomerPagination: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {

        // API
        isLoading: false,
        isCustomersSuccess: false,
        stats: {}
    };

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/customers/list",
            name: "Müşteri Listesi"
        }]);

        this.getCustomersAsync();
        this.getStatsAsync();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.customerContext.customerFilters !== this.props.customerContext.customerFilters) {
            this.getCustomersAsync();
            this.getStatsAsync();
        }
    }

    async getCustomersAsync() {

        this.props.setError(null);
        this.props.loadingContext.setIsLoading(true);
        this.setState((prevState) => ({
            ...prevState,
            isLoading: true
        }));

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customers/filter",
                method: "POST",
                payload: {
                    pagination: this.props.customerContext.customerPagination,
                    filter: this.props.customerContext.customerFilters
                }
            });

            this.props.customerContext.setCustomerPagination(data.pagination, () => {

                this.setState((prevState) => ({
                    ...prevState,
                    customers: data.data,
                    isCustomersSuccess: true
                }));
            });
        }
        catch (e) {

            this.props.setError(e);
        }

        this.setState((prevState) => ({
            ...prevState,
            isLoading: false
        }));

        this.props.loadingContext.setIsLoading(false);
    }

    async getStatsAsync() {

        this.props.setError(null);
        this.props.loadingContext.setIsLoading(true);
        this.setState((prevState) => ({
            ...prevState,
            isLoading: true
        }));

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/data/events/info",
                method: "POST",
                payload: {
                    filter: this.props.customerContext.customerFilters
                }
            });

            this.setState((prevState) => ({
                ...prevState,
                stats: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.setState((prevState) => ({
            ...prevState,
            isLoading: false
        }));

        this.props.loadingContext.setIsLoading(false);
    }

    showDeleteConfirm = ({_id, customer_phone}) => {

        this.props.showConfirm({
            title: customer_phone,
            message: "Bu müşteriyi silmek istediğinize emin misiniz? İşlemin geri dönüşü yoktur, tüm data`larıyla beraber sistemden silinecektir.",
            buttons: [{
                title: "Tamamdır, SİL!",
                asyncFn: async () => {

                    // START LOADING
                    this.props.setError(null);
                    this.props.loadingContext.setIsLoading(true);
                    this.setState((prevState) => ({
                        ...prevState,
                        isLoading: true
                    }));

                    try {

                        await apiCall({
                            url: `/customers/profile/${_id}`,
                            method: "DELETE",
                            toast: `${customer_phone} telefon numaralı müşteri sistemden silindi.`
                        });

                        this.getCustomersAsync();
                        this.getStatsAsync();
                    }
                    catch (e) {

                        // STOP LOADING
                        this.setState((prevState) => ({
                            ...prevState,
                            isLoading: false
                        }));
                        this.props.loadingContext.setIsLoading(false);
                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    excelExportAction = async (formData, setSubmitting) => {

        // Okay clicked, request excel and update link in extraBody
        setSubmitting(true);

        try {

            await apiCall({
                url: "/customers/export",
                method: "POST",
                payload: {
                    ...formData,
                    filter: this.props.customerContext.customerFilters
                },
                options: {
                    responseType: "arraybuffer",
                },
                headers: {
                    "Content-Type": "application/json"
                },
                download: true,
                filename: "customer-list.xlsx",
            });
        }
        catch (e) {

            console.log("e: ", e);

            this.props.setError(e);
        }

        this.props.showConfirm(false);

        setSubmitting(false);
    };

    handleExportForm = async (formData, {setSubmitting}) => {

        this.excelExportAction(formData, setSubmitting);
    };

    requestExcel = () => {

        const pagination = this.props.customerContext.customerPagination;
        if (!pagination) {
            return showToast(<ToastContent message="Bir şeyler yanlış gidiyor, lütfen daha sonra tekrar deneyiniz."/>, {
                autoClose: false,
                type: "error"
            });
        }

        if (pagination.total === 0) {
            return showToast(<ToastContent message="Filtre sonucu 0 müşteri bulunmuştur, lütfen filtrelerinizi azaltın."/>, {
                autoClose: false,
                type: "error"
            });
        }

        if (pagination.total > 100000) {
            return showToast(<ToastContent message={`Excel ile alınabilecek maksimum müşteri sayısı: 20.000'dir. Şu an: ${pagination.total}. Filtrelerinizi arttırın.`}/>, {
                autoClose: false,
                type: "error"
            });
        }

        this.props.showConfirm({
            title: "Excel Çıktısı",
            message: "Bu işlem dosyanın boyutuna göre 5 dakika kadar sürebilmektedir. Tarayıcınızı kapatmayın.",
            extraBody: (
                <CurstomerExportForm
                    handleSubmit={this.handleExportForm}
                    submitText="Tamamdır, DEVAM!"
                />
            )
        });
    };

    getFilterText = () => {

        if (!this.props.customerContext.customerFilters || this.props.customerContext.customerFilters.length === 0) {

            return (
                // eslint-disable-next-line
                <span>Filtre <strong>Aktif Değil</strong>. Toplam müşteri sayısı: <strong>{this.props.customerContext.customerPagination.total}</strong></span>
            );
        }

        return (
            // eslint-disable-next-line
            <span>Filtre <strong>Aktif</strong>. Filtrelenmiş müşteri sayısı: <strong>{this.props.customerContext.customerPagination.total}</strong></span>
        );
    };

    geCustomerTableColumns() {
        return [
            {

                Header: "İsim",
                id: "customer_fullname",
                sortable: false,
                accessor: (props) => `${props.customer_name} ${props.customer_surname}`
            },
            {
                Header: "Telefon",
                accessor: "customer_phone",
                sortable: false,
            },
            {
                Header: "E-posta",
                accessor: "customer_email",
                sortable: false,
            },
            {
                Header: "Cinsiyet",
                id: "customer_gender",
                sortable: false,
                accessor: (props) => {
                    if (props.customer_gender && props.customer_gender === "male") {
                        return "Erkek";
                    }
                    if (props.customer_gender && props.customer_gender === "female") {
                        return "Kadın";
                    }
                    return "";
                },
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 160,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (

                    <div className="level">
                        <div className="level-item">
                            <Link tabIndex="0" className="button" to={{
                                pathname: `/customers/profile/${props.value._id}`,
                                state: {
                                    fromCustomerList: true
                                }
                            }}>
                                <span className="icon is-small"><i className="fa fa-eye"/></span>
                            </Link>
                        </div>
                        <div className="level-item">
                            <a tabIndex="0" className="button" href="#" onClick={() => {
                                this.showDeleteConfirm(props.value);
                            }}>
                                <span className="icon is-small"><i className="fa fa-trash"/></span>
                            </a>
                        </div>
                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.customers) {
            return null;
        }

        return (
            <>

                <div className="columns customerListFilter">
                    <div className="column">
                        <CustomerFilter
                            isCollapsable
                            getTitle={this.getFilterText}
                            filters={this.props.customerContext.customerFilters}
                            addFilter={this.props.customerContext.addCustomerFilter}
                            removeFilter={this.props.customerContext.removeCustomerFilter}
                            clearFilters={this.props.customerContext.clearCustomerFilters}
                        />
                    </div>
                    <div className="column exportAsExcel">
                        <button type="button" className="button" onClick={this.requestExcel}>
                            <span className="icon is-small">
                                <i className="fa fa-share"/>
                            </span>
                            <span>Excel Çıktısı</span>
                        </button>
                    </div>
                </div>

                <div className="columns is-multiline">
                    <div className="column is-2-desktop is-6-tablet is-12 dashboard-box">
                        <div className="box dashboard-box">
                            <div className="heading" lang="tr">Günlük Fatura Sayısı</div>
                            <div className="title">{this.state.stats.paymentCountInToday}</div>
                        </div>
                    </div>
                    <div className="column is-2-desktop is-6-tablet is-12 dashboard-box">
                        <div className="box">
                            <div className="heading" lang="tr">Günlük Fatura Tutarı</div>
                            <div className="title">{formatPrice(this.state.stats.paymentTotalSumInToday)}</div>
                        </div>
                    </div>
                    <div className="column is-2-desktop is-6-tablet is-12 dashboard-box">
                        <div className="box">
                            <div className="heading" lang="tr">Günlük Kupon Sayısı</div>
                            <div className="title">{this.state.stats.couponsCountInToday}</div>
                        </div>
                    </div>
                    <div className="column is-2-desktop is-6-tablet is-12 dashboard-box">
                        <div className="box dashboard-box">
                            <div className="heading" lang="tr">Toplam Fatura Sayısı</div>
                            <div className="title">{this.state.stats.paymentCountInTotal}</div>
                        </div>
                    </div>
                    <div className="column is-2-desktop is-6-tablet is-12 dashboard-box">
                        <div className="box">
                            <div className="heading" lang="tr">Toplam Fatura Tutarı</div>
                            <div className="title">{formatPrice(this.state.stats.paymentTotalSumInTotal)}</div>
                        </div>
                    </div>
                    <div className="column is-2-desktop is-6-tablet is-12 dashboard-box">
                        <div className="box">
                            <div className="heading" lang="tr">Toplam Kupon Sayısı</div>
                            <div className="title">{this.state.stats.couponsCountInTotal}</div>
                        </div>
                    </div>
                </div>

                <CrmBigTable
                    fixedHeight={750}
                    columns={this.geCustomerTableColumns()}
                    data={this.state.customers}
                    loading={this.state.isLoading}
                    pagination={this.props.customerContext.customerPagination}
                    onPageChange={(page) => {

                        const currentPage = page + 1;

                        if (currentPage > Math.ceil(this.props.customerContext.customerPagination.total / this.props.customerContext.customerPagination.limit)) {
                            return;
                        }

                        this.props.customerContext.setCustomerPagination({
                            ...this.props.customerContext.customerPagination,
                            currentPage
                        }, () => {
                            this.getCustomersAsync();
                        });
                    }}
                    onPageSizeChange={(limit) => {
                        this.props.customerContext.setCustomerPagination({
                            ...this.props.customerContext.customerPagination,
                            limit
                        }, () => {
                            this.getCustomersAsync();
                        });
                    }}
                />
            </>
        );
    }
}

export default withContext(Customers);
