/**
 * Coupon Campaign Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";
import Moment from "moment-timezone";

import apiCall from "../../../services/api";
import RemoveItem from "../../../services/utils/remove_item";
import withContext from "../../../context/withContext";
import {CrmDndTable, CrmTable, FormElements, ToastContent} from "../../../components";
import CampaignTargetTypes from "../../../../../common/campaign_target_types";
import {toast as showToast} from "react-toastify";

const status = (is, value) => _.includes(is || [], value.coupon_campaign_status);

class CouponCampaigns extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {

        // API
        couponCampaigns: null,
        coupon_campaign_status: "published",
        onlyArchived: false,

        // COMPONENT
        searchText: "",

        showSortView: false
    };

    filterCouponCampaigns = Memoize((couponCampaigns, searchText) => {

        if (!couponCampaigns) {
            return couponCampaigns;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return couponCampaigns;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return couponCampaigns.filter((couponCampaign) => couponCampaign.coupon_campaign_name_tr.match(regex) || couponCampaign.coupon_campaign_name_en.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/campaign/coupon-campaigns",
            name: "Kupon Kampanyaları"
        }]);

        this.getCampaignsAsync();
    }

    excelExportActionAsync = async (campaign) => {

        // Okay clicked, request excel and update link in extraBody
        this.props.loadingContext.setIsFullScreenLoading(true);

        try {

            const {data} = await apiCall({
                url: `/campaign/coupon-campaigns/${campaign._id}/coupons/export`,
                method: "POST",
                options: {
                    responseType: "arraybuffer",
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${campaign.coupon_campaign_name_en}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch (e) {

            this.props.setError(new Error("Service is not available right now."));
        }

        this.props.loadingContext.setIsFullScreenLoading(false);
    };

    updateCouponCampaignAsync = async ({couponCampaignId, payload, toast = false}) => {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/campaign/coupon-campaigns/${couponCampaignId}`,
                method: "PUT",
                payload,
                toast
            });

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/campaign/coupon-campaigns");
        }
        catch (e) {

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    };

    getCampaignsAsync = async () => {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {
            const {data} = await apiCall({
                url: `/campaign/coupon-campaigns?onlyArchived=${this.state.onlyArchived}`
            });

            this.setState((prevState) => ({
                ...prevState,
                couponCampaigns: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    };

    updateOrdersAsync = async (payload) => {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/campaign/coupon-campaigns/order",
                method: "POST",
                payload,
            });

            showToast.success(<ToastContent message={data.message}/>);

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/campaign/coupon-campaigns");
        }
        catch (e) {

            // STOP LOADING
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    };

    showDeleteConfirm = (couponCampaign) => {

        this.props.showConfirm({
            title: `${couponCampaign.coupon_campaign_name_tr}`,
            message: `Bu kampanya taslağını (${couponCampaign.coupon_campaign_name_tr}) silmek istediğinize emin misiniz?`,
            buttons: [{
                title: "Tamamdır, DEVAM!",
                asyncFn: async () => {
                    try {

                        await apiCall({
                            url: `/campaign/coupon-campaigns/${couponCampaign._id}`,
                            method: "DELETE",
                            toast: `Kampanya: '${couponCampaign.coupon_campaign_name_tr}' silindi.`
                        });

                        this.setState((prevState) => ({
                            ...prevState,
                            couponCampaigns: RemoveItem(prevState.couponCampaigns, couponCampaign._id)
                        }));
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    showPublishConfirm = (couponCampaign) => {

        this.props.showConfirm({
            title: "Kupon Kampanyasını Yayınla",
            message: `Bu Kupon Kampanyasını (${couponCampaign.coupon_campaign_name_tr}) yayınlamak istediğinize emin misiniz?`,
            buttons: [{
                title: "Tamamdır, DEVAM!",
                asyncFn: async () => {

                    try {

                        await this.updateCouponCampaignAsync({
                            couponCampaignId: couponCampaign._id,
                            payload: {
                                coupon_campaign_status: "published"
                            },
                            toast: `Kupon Kampanyası Yayınlandı: ${couponCampaign.coupon_campaign_name_tr}`
                        });

                        await this.getCampaignsAsync();
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    showRepublishConfirm = (couponCampaign) => {

        this.props.showConfirm({
            title: `${couponCampaign.coupon_campaign_name_tr}`,
            message: `Bu kampanya (${couponCampaign.coupon_campaign_name_tr}) tekrar yayına almak istediğinize emin misiniz?`,
            buttons: [{
                title: "Tamamdır, DEVAM!",
                asyncFn: async () => {
                    try {

                        const campaignEndDate = couponCampaign.coupon_campaign_end_datetime && Moment(couponCampaign.coupon_campaign_end_datetime);
                        if (!campaignEndDate || (campaignEndDate && campaignEndDate.isBefore(Moment()))) {
                            showToast.error(
                                <ToastContent 
                                    message={`Bu kampanya (${couponCampaign.coupon_campaign_name_tr}) yayından kaldırma tarihi geçmiştir. İlk önce kampanya yayından kaldırma tarihi girilmelidir.`}
                                />
                            );
                            return;
                        }

                        await this.updateCouponCampaignAsync({
                            couponCampaignId: couponCampaign._id,
                            payload: {
                                coupon_campaign_status: "published"
                            },
                            toast: `Kupon Kampanyası Yayınlandı: ${couponCampaign.coupon_campaign_name_tr}`
                        });

                        await this.getCampaignsAsync();
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    showArchiveConfirm = (couponCampaign) => {

        this.props.showConfirm({
            title: "Kupon Kampanyasını Arşivle",
            message: `Bu Kupon Kampanyasını (${couponCampaign.coupon_campaign_name_tr}) arşivlemek istediğinize emin misiniz?`,
            buttons: [{
                title: "Tamamdır, DEVAM!",
                asyncFn: async () => {

                    try {

                        await this.updateCouponCampaignAsync({
                            couponCampaignId: couponCampaign._id,
                            payload: {
                                coupon_campaign_status: "archived"
                            },
                            toast: `Kupon Kampanyası Arşivlendi: ${couponCampaign.coupon_campaign_name_tr}`
                        });

                        await this.getCampaignsAsync();
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    showClearErrorConfirm = (couponCampaign) => {

        this.props.showConfirm({
            title: "Hataları temizle.",
            message: `Bu AVM Kampanyasına (${couponCampaign.coupon_campaign_name_tr}) hataları istediğinize emin misiniz?`,
            buttons: [{
                title: "Tamamdır, DEVAM!",
                asyncFn: async () => {

                    try {

                        await this.updateCouponCampaignAsync({
                            couponCampaignId: couponCampaign._id,
                            payload: {
                                coupon_campaign_status: couponCampaign.coupon_campaign_before_fork_status || "draft",
                                coupon_campaign_fork_error: null
                            },
                            toast: `Hatalar temizlendi: ${couponCampaign.coupon_campaign_name_tr}`
                        });

                        await this.getCampaignsAsync();
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    onChangeStatus = (value) => {

        this.setState((prevState) => ({
            ...prevState,
            couponCampaigns: [],
            coupon_campaign_status: value,
            onlyArchived: value === "archived"
        }), () => {

            this.getCampaignsAsync();
        });
    };

    onOrdered = (data) => {

        // todo save db.
        this.setState((prevState) => ({
            ...prevState,
            couponCampaigns: data
        }), async () => {

            const {couponCampaigns} = this.state;

            const len = couponCampaigns.length;
            const items = [];

            for (let i = 0; i < len; i++) {
                items.push({
                    _id: couponCampaigns[i]._id,
                    coupon_campaign_order: i
                });
            }

            await this.updateOrdersAsync({items});
        });
    };

    renderAddCouponCodeButton = (props) => {
        const couponSourceType = props.value.coupon_campaign_coupon_source_type;

        const options = couponSourceType === "internal" 
            ? {
                label: "Kupon Kodu Oluştur",
                endpoint: "/generate-codes"
            }
        
            : {
                label: "Kupon Kod Yükle",
                endpoint: "/upload-codes"
            };

        return (
            <Link
                tabIndex="0"
                className="button is-outlined is-fullwidth"
                to={`/campaign/coupon-codes/${props.value._id}${options.endpoint}`}>
                {options.label}
            </Link>
        );
    }

    renderAddUsedCouponCodeButton = (props) => (

        <Link
            tabIndex="0"
            className="button is-outlined is-fullwidth"
            to={`/campaign/coupon-codes/${props.value._id}/upload-used-codes`}>
            Kullanılan Kupon Kodu Ekle
        </Link>
    );

    renderClearErrorButton = ({value}) => (

        <a
            tabIndex="0"
            className="button is-outlined is-fullwidth"
            onClick={() => this.showClearErrorConfirm(value)}>
            Hatayı Temizle
        </a>
    );

    excelExportModal = (campaign) => {

        this.props.showConfirm({
            title: "Excel Çıktısı",
            message: "Bu işlem dosyanın boyutuna göre 5 dakika kadar sürebilmektedir. Tarayıcınızı kapatmayın.",
            buttons: [{
                title: "Tamamdır, DEVAM!"
            }],
            onClose: (isConfirm) => {

                if (isConfirm) {
                    this.excelExportActionAsync(campaign);
                }
            }
        });
    };

    onChangeSort = (e) => {
        const showSortView = e.target.checked;

        this.setState((prevState) => ({
            ...prevState,
            showSortView
        }));
    };

    renderOrderSwitch = () => (
        <div className="field">
            <input
                id="switchSmall"
                type="checkbox"
                name="switchSmall"
                className="switch is-info"
                onChange={this.onChangeSort}
            />
            <label htmlFor="switchSmall">Sırala</label>
        </div>
    );

    getCouponCampaignTableColumns() {

        return [
            {
                Header: "Kampanya Başlığı",
                accessor: "coupon_campaign_name_tr",
                width: 300,
                style: {whiteSpace: "unset"},
                sortable: false,
            },
            {
                Header: "Tür",
                id: "type-and-qr",
                accessor: (props) => props,
                sortable: false,
                maxWidth: 225,
                Cell: (props) => {

                    const couponCampaignTargetType = _.find(CampaignTargetTypes, {value: props.value.coupon_campaign_target_type});

                    if (couponCampaignTargetType) {

                        if (props.value.coupon_campaign_target_type === "merchant") {
                            return `Mağaza - ${props.value.coupon_campaign_provider.coupon_provider_name}`;
                        }

                        return couponCampaignTargetType.label;

                    }

                    return "Bilinmeyen Tür";
                }
            },
            {
                Header: "Kampanya QR Code",
                accessor: "_id",
                sortable: true,
                width: 300
            },
            {
                Header: "Kupon Durumu",
                id: "couponStatus",
                accessor: (props) => props,
                sortable: false,
                width: 240,
                Cell: (props) => (
                    <div className="cell">

                        {
                            props.value.coupon_campaign_total_coupons > 0 && (
                                <button
                                    type="button"
                                    tabIndex="0"
                                    className="button is-outlined is-fullwidth"
                                    onClick={() => this.excelExportModal(props.value)}>
                                    Kupon Kodu Raporu
                                </button>
                            )
                        }
                        <article className="is-fullwidth">
                            {`Toplam Kupon: ${props.value.coupon_campaign_total_coupons}`}
                        </article>
                        <article className="is-fullwidth">
                            {`Verilen Kupon: ${props.value.coupon_campaign_given_coupons}`}
                        </article>
                        <article className="is-fullwidth">
                            {`Kullanılan Kupon: ${props.value.coupon_campaign_used_coupons}`}
                        </article>
                    </div>
                )
            },
            {
                Header: "Kampanya Durumu",
                id: "coupon_campaign_status",
                sortable: false,
                width: 240,
                accessor: (props) => props,
                Cell: (props) => (
                    <div className="cell">
                        {
                            (status(["generating-code-error"], props.value) || status(["parsing-coupon-error"], props.value)) &&
                            <>
                                <article className="notification is-small is-danger has-text-centered">
                                    {props.value.coupon_campaign_fork_error || "Bilinmeyen hata oluştu!"}
                                </article>

                                {this.renderClearErrorButton(props)}
                            </>
                        }
                        {
                            status(["generating-code"], props.value) &&
                            <>
                                <article className="notification is-small is-warning has-text-centered">
                                    Referans Kodları Hazırlanıyor
                                </article>
                            </>
                        }
                        {
                            status(["parsing-coupon"], props.value) &&
                            <>
                                <article className="notification is-small is-warning has-text-centered">
                                    Kupon Kodları Ayrıştırılıyor
                                </article>
                            </>
                        }
                        {
                            status(["draft"], props.value) &&
                            <>
                                <article className="notification is-small is-warning has-text-centered">
                                    Taslak
                                </article>
                                {props.value.coupon_source_type !== "website" && this.renderAddCouponCodeButton(props)}
                            </>
                        }
                        {
                            status(["ready"], props.value) &&
                            <>
                                <article className="notification is-small is-info has-text-centered">
                                    Hazır
                                </article>
                                {props.value.coupon_source_type !== "website" && this.renderAddCouponCodeButton(props)}
                                <a
                                    tabIndex="0"
                                    className="button is-danger is-outlined is-fullwidth"
                                    onClick={() => this.showPublishConfirm(props.value)}>
                                    Şimdi Yayınla
                                </a>
                            </>
                        }
                        {
                            status(["published"], props.value) &&
                            <>
                                <article className="notification is-small is-success has-text-centered">
                                    Yayında
                                </article>
                                {props.value.coupon_source_type !== "website" && this.renderAddCouponCodeButton(props)}
                                {props.value.coupon_source_type !== "website" && this.renderAddUsedCouponCodeButton(props)}
                                <a
                                    href="#"
                                    className="button is-outlined is-fullwidth"
                                    onClick={() => {
                                        this.showArchiveConfirm(props.value);
                                    }}>
                                    Arşivle
                                </a>
                            </>
                        }
                        {
                            status(["archived"], props.value) &&
                            <>
                                <article className="notification is-small has-background-grey-light has-text-centered">
                                    Arşivlendi
                                </article>
                            </>
                        }
                    </div>
                )
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 120,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (

                    <div className="level">
                        <div className="level-item">
                            <Link tabIndex="1" className="button" to={`/campaign/coupon-campaigns/${props.value._id}/edit`}>
                                <span className="icon is-small"><i className="fa fa-edit"/></span>
                            </Link>
                        </div>
                        {
                            status(["draft", "ready"], props.value) &&
                            <div className="level-item">
                                <a tabIndex="2" className="button" href="#" onClick={() => {
                                    this.showDeleteConfirm(props.value);
                                }}>
                                    <span className="icon is-small"><i className="fa fa-trash"/></span>
                                </a>
                            </div>
                        }
                        {
                            status(["archived"], props.value) &&
                            <div className="level-item">
                                <a tabIndex="2" className="button" href="#" onClick={() => {
                                    this.showRepublishConfirm(props.value);
                                }}>
                                    <span className="icon is-small"><i className="fa fa-reply"/></span>
                                </a>
                            </div>
                        }
                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.couponCampaigns) {
            return null;
        }

        const {showSortView} = this.state;

        return (
            <div className="couponCampaigns">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="İçeriklerde arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            {this.renderOrderSwitch()}
                        </div>
                        <div className="level-item">
                            <FormElements.SelectInput
                                isDisabled={this.props.loadingContext.isLoading}
                                inputName="options"
                                value={this.state.coupon_campaign_status}
                                options={[
                                    {label: "Aktif", value: "published"},
                                    {label: "Arşiv", value: "archived"}
                                ]}
                                classNamePrefix="react-select"
                                onChange={(selectedOption) => {
                                    this.onChangeStatus(selectedOption.value);
                                }}
                            />
                        </div>
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/campaign/coupon-campaigns/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Kampanya</span>
                            </Link>
                        </div>
                    </div>
                </div>

                {
                    showSortView ?
                        <CrmDndTable
                            columns={this.getCouponCampaignTableColumns()}
                            onOrdered={this.onOrdered}
                            data={this.filterCouponCampaigns(this.state.couponCampaigns, this.state.searchText)}
                            defaultSorted={[{
                                id: "-coupon_campaign_order",
                                desc: false
                            }]}
                        />
                        :
                        <CrmTable
                            columns={this.getCouponCampaignTableColumns()}
                            onOrdered={this.onOrdered}
                            data={this.filterCouponCampaigns(this.state.couponCampaigns, this.state.searchText)}
                            defaultSorted={[{
                                id: "-coupon_campaign_order",
                                desc: false
                            }]}
                        />
                }

            </div>
        );
    }
}

export default withContext(CouponCampaigns);
