import Moment from "moment-timezone";

export default {
    dateStructureCheck: (date) => {

        if (!date) {
            return true;
        }

        const day = Moment(date, "DD/MM/YYYY");

        // ex: 12/02/2010
        return day && day.isValid();
    },

    dateTimeStructureCheck: (date) => {

        if (!date) {
            return true;
        }

        const daytime = Moment(date);
        // ex: 12/02/2010 13:55
        return daytime && daytime.isValid();
    },

    birthYearRangeCheck: (date) => {
        if (!date) {
            return true;
        }

        const birthday = Moment(date, "DD/MM/YYYY");

        try {
            const birthYear = birthday.year();
            // TODO check 2014 degil de 18 yas kontrolu?
            return !(birthYear > 2014 || birthYear < 1920);

        }
        catch (e) {
            return false;
        }
    },

    dateTimeIsInFutureCheck(date) {

        if (!date) {
            return true;
        }

        // ex: 12/02/2010 13:55
        const daytime = Moment(date);
        const now = Moment();

        try {
            return now.valueOf() <= daytime.valueOf();
        }
        catch (e) {
            return false;
        }
    }
};
