/**
 * Transform initial values of select area, mostly backend directly returns populated data
 * as {_id: "39819", xxxx_yyyy: "TEST"}
 * But Select input accepts {label: value:} pair.
 *
 * @param initialValue
 * @param foreignField
 * @param [idField]
 *
 * @returns {*}
 */
export default (initialValue, foreignField, idField = "_id") => {

    if (!initialValue) {
        return;
    }

    let transformedValue;

    if (Array.isArray(initialValue)) {

        transformedValue = initialValue.map((initialValueItem) => (_transformIndividualValue(initialValueItem, foreignField, idField)));
    }
    else {

        transformedValue = _transformIndividualValue(initialValue, foreignField, idField);
    }

    return transformedValue;
};

function _transformIndividualValue(initialValue, foreignField, idField) {

    return {
        value: initialValue[idField],
        label: initialValue[foreignField]
    };
}
