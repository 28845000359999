import React from "react";
import PropTypes from "prop-types";
import feedbackTypes from "../../../services/utils/feedback_types";
import formatDatetime from "../../../services/utils/format_datetime";

export default class CustomerFeedbacks extends React.Component {

    static get propTypes() {
        return {
            customer: PropTypes.object.isRequired
        };
    }

    getPrettyFeedbackType(feedbackTypeValue) {

        const feedbackType = feedbackTypes.find((feedbackTypeItem) => feedbackTypeItem.value === feedbackTypeValue);
        return feedbackType ? feedbackType.label : "";
    }

    render() {
        return (
            <div className="tabContainer customerActivities">
                <div className="timelineSection">
                    <div className="timelineLine"/>
                    {
                        this.props.customer.customer_feedbacks.map((customerFeedback) => {

                            return (
                                <div key={customerFeedback._id} className="timelineItem">
                                    <div className="timelineIcon">
                                        <span><i className="fa fa-comment"/></span>
                                    </div>
                                    <div className="timelinePreHeader">{formatDatetime(customerFeedback.updatedAt)}</div>
                                    <div className="timelinePreHeader">{this.getPrettyFeedbackType(customerFeedback.feedback_type)}</div>
                                    <div className="timelineContent">
                                        <p>
                                            {
                                                customerFeedback.feedback_content
                                            }
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}
