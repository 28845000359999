/**
 * CustomerTagAdd Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import withContext from "../../context/withContext";
import apiCall from "../../services/api";
import FeedbackForm from "./FeedbackForm";

class FeedbackEdit extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        feedback: null
    };

    feedbackId = this.props.match.params["feedbackid"];

    componentDidMount() {

        this.getTagTypeAsync();// Update subheader statically
    }

    async getTagTypeAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/feedback/${this.feedbackId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                feedback: data
            }));

            this.props.setBreadcrumb([{
                url: "/feedback",
                name: "Geri Bildirim Listesi"
            }, {
                url: `/feedback/${this.feedbackId}/edit`,
                name: "Geri Bildirim Güncelleme"
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    async updateFeedbackAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/feedback/${this.feedbackId}`,
                method: "PUT",
                payload,
                toast: "Geri Bildirim güncelledi"
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/feedback");
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.updateFeedbackAsync(formData, formikBag);
    };

    render() {

        const {feedback} = this.state;

        if (!feedback) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Etiket Tipi Güncelleme
                                </span>
                            </h5>
                        </div>

                        <FeedbackForm
                            customerPhone={feedback.customer.customer_phone}
                            customer={feedback.customer}
                            feedback={feedback}
                            submitText="Kaydet"
                            isEdit
                            handleSubmit={this.handleSubmit}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withContext(FeedbackEdit);


