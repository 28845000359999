/**
 * ImageInput component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

import ImageInputPreview from "./ImageInputPreview/ImageInputPreview";
import ImageUploadProgress from "../UploadProgress/UploadProgress";

import InlineError from "../InlineError/InlineError";

import apiCall from "../../../services/api";

const ALLOWED_IMAGE_TYPES = [
    "image/jpeg",
    "image/jpg",
    "image/png"
];

const BYTE_TO_MB = 1024 * 1024; // 1MB
const MAX_FILE_SIZE_BYTE = 1024 * 1024 * 10; // 10MB

const FIXED_PREVIEW_SIZE = 320;

export default class ImageInput extends Component {

    static defaultProps = {
        placeholder: "Fotoğraf seçin..."
    };

    static propTypes = {

        inputName: PropTypes.string.isRequired,
        modelName: PropTypes.string.isRequired,
        imageThumbnailSize: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number
        }),
        isLoading: PropTypes.bool,
        displayName: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.shape(),
        hint: PropTypes.string,
        setImage: PropTypes.func,
        uploadInProgress: PropTypes.func,
        error: PropTypes.string
    };

    state = {
        imageName: null,
        error: null,
        uploading: false,
        progress: 0
    };

    clearImage = () => {

        this.props.setImage(undefined);

        this.setState({
            uploading: false,
            progress: 0,
            imageName: null,
            error: null
        });
    };

    imageChanged = async (event) => {

        const file = event.currentTarget.files[0];

        if (!file) {

            this.setState({
                error: "Something is wrong with the input file"
            });
            // break and return
            return;
        }

        const fileType = file.type;

        if (!ALLOWED_IMAGE_TYPES.includes(fileType)) {

            this.setState({
                error: `Image type is not supported: ${fileType}`
            });
            // break and return
            return;
        }

        if (file.size > MAX_FILE_SIZE_BYTE) {

            const fileSizeInMegabyte = (file.size / BYTE_TO_MB).toFixed(2);
            const maxFileSizeInMegabyte = (MAX_FILE_SIZE_BYTE / BYTE_TO_MB).toFixed(2);

            this.setState({
                error: `Image is too big: ${fileSizeInMegabyte}MB. Maximum allowed size is: ${maxFileSizeInMegabyte}MB`
            });
            // break and return
            return;
        }

        const multipartData = new FormData();
        multipartData.set(this.props.inputName, file);

        this.setState({
            uploading: true,
            error: null,
            progress: 0
        });

        this.props.uploadInProgress(true);

        try {

            const {data} = await apiCall({
                url: `/upload/image/${this.props.modelName}`,
                payload: multipartData,
                method: "post",
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                options: {
                    onUploadProgress: (progressEvent) => {

                        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
                        this.setState({
                            progress: percentCompleted
                        });
                    }
                }
            });

            const images = data[this.props.inputName];
            this.addImagePreview(file, images);
        }
        catch (e) {

            const error = (e.response && e.response.data) || e;

            this.setState({
                uploading: false,
                progress: 0,
                error: error.message ? error.message : error.toString()
            });
        }

        this.props.uploadInProgress(false);
    };

    addImagePreview(file, data) {

        const reader = new FileReader();
        reader.onload = () => {

            this.setState({
                uploading: false,
                progress: 0,
                imageName: file.name,
                error: null
            });

            // Notify formik to set image input!
            this.props.setImage(data);
        };

        reader.readAsDataURL(file);
    }

    render() {

        // We want to show imagePreview field with fixed aspect ratio,
        // even if image is empty.
        let previewHeight = FIXED_PREVIEW_SIZE;

        if (this.props.imageThumbnailSize) {
            previewHeight /= this.props.imageThumbnailSize.width / this.props.imageThumbnailSize.height;
        }

        return (
            <div className={`field ${this.props.error ? "is-danger" : null}`}>
                <label className="label" htmlFor={this.props.inputName}>
                    <span>
                        {this.props.displayName}
                    </span>
                    {
                        this.props.hint
                            ? (
                                <span className="icon has-text-info tooltip is-tooltip-multiline is-tooltip-left" data-tooltip={this.props.hint}>
                                    <i className="fas fa-info-circle"/>
                                </span>
                            ) : null
                    }
                </label>
                <div className="control">

                    {
                        this.state.uploading
                            ? (
                                <ImageUploadProgress
                                    progress={this.state.progress}
                                    previewHeight={previewHeight}
                                />
                            )
                            : (
                                <ImageInputPreview
                                    image={this.props.value && this.props.value["main"]}
                                    clearImage={this.clearImage}
                                    previewHeight={previewHeight}
                                    isLoading={this.props.isLoading}
                                />
                            )
                    }

                    <div className="file" style={{width: FIXED_PREVIEW_SIZE, maxWidth: "100%"}}>
                        <label className="file-label" htmlFor={this.props.inputName} style={{width: "100%"}}>
                            <input
                                className="file-input"
                                type="file"
                                accept="image/*"
                                name={this.props.inputName}
                                id={this.props.inputName}
                                onChange={this.imageChanged}
                                disabled={this.props.isLoading || this.state.uploading}
                            />
                            <span className="file-cta" style={{width: "100%"}}>
                                <span className="file-icon">
                                    <i className="fa fa-upload"/>
                                </span>
                                <span className="file-label">
                                    {this.state.imageName || this.props.placeholder}
                                </span>
                            </span>
                        </label>
                    </div>

                </div>

                {
                    this.state.error
                        ? <InlineError error={this.state.error}/>
                        : null
                }
                {
                    this.props.error
                        ? <InlineError error={this.props.error}/>
                        : null
                }
            </div>
        );
    }
}
