import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {ApiError, Backdrop, Breadcrumb, Confirm, ConfirmWithName, Header, SideMenu, Spinner} from "../components";
import withContext from "../context/withContext";

const GeneralLayout = (Child) => {

    class _GeneralLayout extends PureComponent {

        static displayName = "GeneralLayout";

        static propTypes = {
            setBreadcrumb: PropTypes.func,
            setError: PropTypes.func,
            authContext: PropTypes.shape({
                admin: PropTypes.shape()
            }),
            loadingContext: PropTypes.shape({
                isFullScreenLoading: PropTypes.bool.isRequired,
            }),
        };

        state = {
            isMobileMenuOpen: false,
            breadcrumb: [],
            error: null,
            showNamedConfirm: false,
            showConfirm: false
        };

        setBreadcrumb = (breadcrumb) => {

            this.setState((prevState) => ({
                ...prevState,
                breadcrumb
            }));
        };

        setError = (error) => {

            this.setState((prevState) => ({
                ...prevState,
                error
            }));
        };

        clearError = () => {

            this.setState((prevState) => ({
                ...prevState,
                error: null
            }));
        };

        showConfirm = (showConfirm) => {

            this.setState((prevState) => ({
                ...prevState,
                showConfirm
            }));
        };

        showNamedConfirm = (showNamedConfirm) => {

            this.setState((prevState) => ({
                ...prevState,
                showNamedConfirm
            }));
        };

        toggleMobileMenu = () => {

            this.setState((prevState) => ({
                ...prevState,
                isMobileMenuOpen: !prevState.isMobileMenuOpen
            }));
        };

        render() {

            let breadcrumb = null;
            if (this.state.breadcrumb && this.state.breadcrumb.length > 0) {
                // breadcrumb exist & we add default "home" breadcrumb
                breadcrumb = [{
                    url: "/",
                    name: "Ana Sayfa"
                }, ...this.state.breadcrumb];
            }

            let layout = "simpleLayout";
            const admin = this.props.authContext.admin;

            if (admin && admin.admin_role !== "CrmDataCollector") {
                layout = "extendedLayout";
            }

            return (
                <div className="crm-fixed-navbar">

                    <Header layout={layout} handleDrawerToggle={this.toggleMobileMenu}/>

                    {
                        layout === "extendedLayout" ? (
                            <>
                                <SideMenu isMobileActive={this.state.isMobileMenuOpen}/>
                                <Backdrop isActive={this.state.isMobileMenuOpen} handleDrawerToggle={this.toggleMobileMenu}/>
                            </>
                        ) : null
                    }

                    <Breadcrumb layout={layout} items={breadcrumb}/>

                    <section className={`section ${layout === "extendedLayout" ? "main-section" : "simple-section"}`}>
                        <div className="container is-fluid">

                            <ApiError clearError={this.clearError} error={this.state.error}/>

                            <Child
                                error={this.state.error}
                                setBreadcrumb={this.setBreadcrumb}
                                showConfirm={this.showConfirm}
                                showNamedConfirm={this.showNamedConfirm}
                                setError={this.setError}
                                {...this.props}
                            />
                        </div>
                    </section>

                    {
                        this.state.showConfirm ? (
                            <Confirm
                                isVisible={!!this.state.showConfirm}
                                title={this.state.showConfirm && this.state.showConfirm.title}
                                message={this.state.showConfirm && this.state.showConfirm.message}
                                onClose={(isConfirmed, index) => {

                                    if (this.state.showConfirm.onClose) {
                                        this.state.showConfirm.onClose(isConfirmed, index);
                                    }

                                    this.setState((prevState) => ({
                                        ...prevState,
                                        showConfirm: null
                                    }));
                                }}
                                hideCloseButton={this.state.showConfirm && this.state.showConfirm.hideCloseButton}
                                extraBody={this.state.showConfirm && this.state.showConfirm.extraBody}
                                buttons={this.state.showConfirm && this.state.showConfirm.buttons}
                            />
                        ) : null
                    }

                    {
                        this.state.showNamedConfirm ? (
                            <ConfirmWithName
                                onClose={() => {
                                    this.setState((prevState) => ({
                                        ...prevState,
                                        showNamedConfirm: null
                                    }));
                                }}
                                title={this.state.showNamedConfirm && this.state.showNamedConfirm.title}
                                confirmText={this.state.showNamedConfirm && this.state.showNamedConfirm.confirmText}
                                validateText={this.state.showNamedConfirm && this.state.showNamedConfirm.validateText}
                                onConfirmAsync={this.state.showNamedConfirm && this.state.showNamedConfirm.asyncFunction}
                                isVisible={!!this.state.showNamedConfirm}>
                                {this.state.showNamedConfirm && this.state.showNamedConfirm.children}
                            </ConfirmWithName>
                        ): null
                    }

                    {
                        this.props.loadingContext.isFullScreenLoading ? (
                            <Spinner/>
                        ) : null
                    }

                </div>
            );
        }
    }

    return withContext(_GeneralLayout);
};

export default GeneralLayout;
