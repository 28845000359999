/**
 * Company Staff Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import Memoize from "memoize-one";

import {FormElements} from "../../../components";
import apiCall from "../../../services/api";
import withContext from "../../../context/withContext";

class CompanyStaffForm extends PureComponent {

    static propTypes = {
        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        isEdit: PropTypes.bool,
        staff: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
    };

    state = {
        companies: null,
        staff: null
    };

    getCompaniesForSelect = Memoize((companies) => companies.map((company) => ({
        value: company._id,
        label: company.company_name
    })));

    componentDidMount() {

        this.getCompaniesAsync();
    }

    async getCompaniesAsync() {

        this.props.loadingContext.setIsLoading(true);

        try {
            const {data} = await apiCall({
                url: "/export-logs/company"
            });

            this.setState((prevState) => ({
                ...prevState,
                companies: data || [],
            }));
        }
        catch (e) {
            console.error(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    render() {

        const {staff, isEdit} = this.props;
        const {companies} = this.state;

        if (!companies || !staff) {
            return null;
        }

        const selectCompanies = this.getCompaniesForSelect(companies);

        const initialValues = {
            company_id: staff.company_id || "",
            company_staff_name: staff.company_staff_name || "",
            company_staff_surname: staff.company_staff_surname || "",
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.props.handleSubmit}
                validationSchema={() => Yup.object().shape({
                    company_staff_name: Yup.string()
                        .min(2, "Çalışan Adı en az iki karakter içermelidir.")
                        .max(64, "Çalışan Adı en fazla altmış dört karakter içermelidir.")
                        .required("Kampanya Başlığı zorunlu bir alandır."),
                    company_staff_surname: Yup.string()
                        .min(2, "Kampanya Başlığı en az iki karakter içermelidir.")
                        .max(64, "Kampanya Başlığı en fazla altmış dört karakter içermelidir.")
                        .required("Kampanya Başlığı zorunlu bir alandır."),
                    company_id: Yup
                        .string(),
                })}
                render={({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form autoComplete="off" className="venuexForm couponCampaignForm" onSubmit={handleSubmit}>

                        <FormElements.SelectInput
                            isDisabled={isSubmitting || isEdit}
                            displayName="Şirket *"
                            inputName="company_id"
                            error={touched.company_id && errors.company_id}
                            options={selectCompanies}
                            value={values.company_id}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "No result"}
                            placeholder="Şirket Seçiniz."
                            onChange={(selectedOption) => {

                                setFieldValue("company_id", selectedOption.value);
                                setFieldTouched("company_id");

                            }}
                            onBlur={() => {
                                setFieldTouched("company_id", true);
                            }}
                        />

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            error={touched.company_staff_name && errors.company_staff_name}
                            inputName="company_staff_name"
                            displayName="Personel Adı *"
                            isSubmitting={isSubmitting}
                            value={values.company_staff_name || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            error={touched.company_staff_surname && errors.company_staff_surname}
                            inputName="company_staff_surname"
                            displayName="Personel Soyadı *"
                            isSubmitting={isSubmitting}
                            value={values.company_staff_surname || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText={this.props.submitText}
                            isSubmitting={isSubmitting}
                        />
                    </form>
                )}
            />
        );
    }
}

export default withContext(CompanyStaffForm);
