import React from "react";
import PropTypes from "prop-types";
import InlineError from "../InlineError/InlineError";

export default class Radio extends React.PureComponent {

    static propTypes = {
        inputName: PropTypes.string.isRequired,
        options: PropTypes.arrayOf(PropTypes.shape()),
        displayName: PropTypes.string,
        disabled: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        value: PropTypes.string,
        error: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        isVertical: PropTypes.bool
    };

    render() {

        const options = this.props.options.map((option, index) => (
            <div key={option.name} style={{float: "left", marginBottom: `${this.props.isVertical ? "0.5rem": ""}`}}>
                <input
                    type="radio"
                    disabled={this.props.isSubmitting || this.props.disabled}
                    className="is-checkradio is-info"
                    value={option.value}
                    name={this.props.inputName}
                    id={`${this.props.inputName}_${index}`}
                    checked={option.value === this.props.value}
                    onChange={this.props.onChange}
                />
                <label className="label" htmlFor={`${this.props.inputName}_${index}`}>
                    {option.name}
                </label>
            </div>
        ));

        return (

            <div className={`field ${this.props.error ? "is-danger" : ""}`}>
                {
                    this.props.displayName ? (
                        <label className="label" htmlFor={this.props.inputName}>
                            {this.props.displayName}
                        </label>
                    ) : null
                }
                <div style={{display: "flex", flexDirection: `${this.props.isVertical ? "column" : "row"}`}}>
                    {options}
                </div>
                <InlineError error={this.props.error}/>
            </div>
        );
    }
}
