/**
 * Backdrop component
 */

import React from "react";
import PropTypes from "prop-types";

const Backdrop = (props) => {

    if (!props.isActive) {
        return null;
    }

    return (
        <div
            style={{
                position: "fixed",
                width: "100%",
                height: "100%",
                background: "rgba(0,0,0,0.3)",
                zIndex: 31,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                // Navbar -> 30 , Sidebar -> 32
            }}
            onClick={props.handleDrawerToggle}
        />
    );
};

Backdrop.propTypes = {
    isActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func.isRequired
};

export default Backdrop;
