/**
 * TextInput component
 */
import React from "react";
import PropTypes from "prop-types";
import ReactPhoneInput from "react-phone-number-input";

import InlineError from "../InlineError/InlineError";

const PhoneInput = (props) => (


    <div className={`field ${props.error ? "is-danger" : ""}`}>
        <label className="label" htmlFor={props.inputName}>
            <span>
                {props.displayName}
            </span>
            {
                props.hint
                    ? (
                        <span className="icon has-text-info tooltip is-tooltip-multiline is-tooltip-left" data-tooltip={props.hint}>
                            <i className="fas fa-info-circle"/>
                        </span>
                    ) : null
            }
        </label>
        <div className="control">
            <ReactPhoneInput   
                defaultCountry="TR"
                international={true}
                withCountryCallingCode={true}
                limitMaxLength={true}
                autoComplete={props.autoComplete}
                type={props.type}
                disabled={props.isSubmitting || props.disabled}
                className="input"
                name={props.inputName}
                id={props.inputName}
                value={props.value}
                onChange={props.onChange}
            />
        
        </div>

        <InlineError error={props.error}/>
    </div>
);

PhoneInput.defaultProps = {
    type: "text",
    autoComplete: "off"
};

PhoneInput.propTypes = {
    type: PropTypes.string,
    autoComplete: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    value: PropTypes.string,
    hint: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default PhoneInput;
