/**
 * Backdrop component
 */

import React from "react";
import PropTypes from "prop-types";

const ActivityCard = ({title, value, tag, exp}) => {

    return (
        <div className="column is-2-fullhd is-3-desktop is-6-tablet is-12-mobile dashboard-box">
            <article className="box">
                {
                    tag &&
                    <span className="tag is-info is-light">{tag}</span>
                }
                {
                    exp &&
                    <span
                        className="icon tooltip is-tooltip-multiline is-tooltip-bottom"
                        data-tooltip={exp}>
                        <i className="fas fa-info-circle"/>
                    </span>
                }
                <p className="title">{value}</p>
                <p className="subtitle">{title}</p>
            </article>
        </div>
    );
};

ActivityCard.propTypes = {
    title: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    tag: PropTypes.string,
    exp: PropTypes.string,
};

export default ActivityCard;
