/**
 * Remove item from array,
 * key is "_id"
 *
 * removeItem either object or just plain string _id
 *
 */

export default (items, removeItem) => {

    if (!items || items.length === 0) {
        return items;
    }

    if (!removeItem) {
        console.error("Requested: REMOVE but failed to pass removeItem");
        return items;
    }

    const removeItemId = (typeof removeItem === "string" || removeItem instanceof String) ? removeItem : removeItem._id;

    if (!removeItemId) {
        console.error("Requested: DELETE but failed to find index");
        console.error(removeItem);
    }

    const itemIndex = items.findIndex((item) => item._id === removeItemId);
    if (itemIndex === -1) {
        console.error("Requested: DELETE but failed to find index");
        console.error(removeItem);
    }

    return [
        ...items.slice(0, itemIndex),
        ...items.slice(itemIndex + 1)
    ];
};
