/**
 * FormExplanation component
 */

import React from "react";
import PropTypes from "prop-types";

const FormExplanation = (props) => (
    props.messages && props.messages.length > 0
        ? (
            <div className="notification formMessages">
                <ul>
                    {
                        props.messages.map((message, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                            <li key={index}>
                                {message}
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
        : null
);

FormExplanation.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.string)
};

export default FormExplanation;
