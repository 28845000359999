/**
 * CustomerTagAdd Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import FeedbackTagTypeForm from "./FeedbackTagTypeForm";

class FeedbackTagTypeEdit extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        feedbackTagType: null
    };

    feedbackTagTypeId = this.props.match.params["feedbacktagtypeid"];

    componentDidMount() {

        this.getFeedbackTagTypeAsync();// Update subheader statically
    }

    async getFeedbackTagTypeAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/data/feedback-tag-types/${this.feedbackTagTypeId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                feedbackTagType: data
            }));

            this.props.setBreadcrumb([{
                url: "/data/feedback-tag-types",
                name: "Geri Bildirim Etiketleri (Tags)"
            }, {
                url: `/data/feedback-tag-types/${this.feedbackTagTypeId}/edit`,
                name: data.feedback_tag_type_name
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    async updateFeedbackTagTypeAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/data/feedback-tag-types/${this.feedbackTagTypeId}`,
                method: "PUT",
                payload,
                toast: `Etiket tipi güncelledi: ${payload.feedback_tag_type_name}`
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/data/feedback-tag-types");
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.updateFeedbackTagTypeAsync(formData, formikBag);
    };

    render() {

        if (!this.state.feedbackTagType) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Geri Bildirim Etiket Tipi Güncelleme
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Geri Bildirim Etiket Tipi sadece ingilizce karakterler, kısa çizgi, boşluk ve rakam içerebilir.",
                                "Geri Bildirim Etiket Tipi sistem genelinde benzersiz olmalıdır, aynı isme sahip ikinci etiket tipini ekleyemezsiniz.",
                                "Örnek Geri Bildirim Etiket Tipleri (TAG): 'DILEK', 'SIKAYET', 'ONERI'",
                                "Tüm kurallar sistemde tanımlamıştır, aşağıda test edebilir, hata almadığınız her değeri girebilirsiniz."
                            ]}
                            />
                        </div>

                        <FeedbackTagTypeForm
                            tagType={this.state.feedbackTagType}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withContext(FeedbackTagTypeEdit);
