export default [{
    label: "Şikayet",
    value: "COMPLAINT"
}, {
    label: "Bilgi Talebi",
    value: "INFORMATION_REQUEST"
}, {
    label: "Öneri",
    value: "SUGGESTION"
}, {
    label: "Değerlendirme",
    value: "REVIEW"
}];
