import React from "react";
import Axios from "axios";
import storage from "./storage";
import {endPoint, HTTP, keys} from "./constants";
import {toast as showToast} from "react-toastify";
import {ToastContent} from "../components";

const expired = (expiresAt, minutes = 1) => {

    const timespan = (new Date().getTime() / 1000);
    return (expiresAt - (minutes * 60)) <= timespan;
};

const refreshToken = async (refresh) => {

    const {data} = await Axios({
        url: `${endPoint}auth/token`,
        data: refresh,
        method: HTTP.POST
    });

    return data;
};

const getToken = async () => {

    const jwt = await storage.getAuth();

    if (jwt.token && jwt.token.value && jwt.refresh && jwt.refresh.value && jwt.admin && jwt.admin._id) {

        if (expired(jwt.token.expiresAt)) {

            const data = await refreshToken({
                _id: jwt.admin._id,
                refresh: jwt.refresh.value
            });
            storage.setAuth(data);
            return data.token.value;
        }

        return jwt.token.value;
    }

    return null;
};

const createDownloadLink = (filename, data) => {

    const blobUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
};

const apiCall = async ({
    url = "",
    payload,
    method = HTTP.GET,
    headers = {},
    options = {},
    withAuth = true,
    download = false,
    filename = "file.xlsx",
    toast
}) => {

    let token = null;

    if (withAuth) {
        token = await getToken();
    }

    let transformedUrl;

    if (download) {
        options.responseType = "arraybuffer";
        headers["Content-Type"] = "application/json";
    }

    const tzOffset = new Date().getTimezoneOffset();
    if (url.includes("?")) { // query string exist;
        transformedUrl = `${url}&tzOffset=${tzOffset}`;
    }
    else {
        transformedUrl = `${url}?tzOffset=${tzOffset}`;
    }

    try {

        const response = await Axios({
            url: `${endPoint}${transformedUrl}`,
            data: payload,
            method,
            headers: {
                ...headers,
                "Authorization": `Bearer ${withAuth ? token : ""}`,
                "Accept-Language": "tr"
            },
            ...options
        });

        // IF toast exist; show it.
        if (toast) {
            showToast(<ToastContent message={toast}/>, {
                autoClose: 3000,
                type: "success"
            });
        }

        if (download && response.data) {

            createDownloadLink(filename, response.data);
        }

        return response;
    }
    catch (e) {

        console.log("---");
        console.error(e);
        console.log("---");

        const error = (e.response && e.response.data) || e;

        let toastTitle = "Error";
        let toastMessage = "";

        if (error.errors && Array.isArray(error.errors)) {
            toastMessage = error.errors.map((errorMsg) => errorMsg.message).join(", ");
            toastTitle = error.message;
        }
        else if (error.message) {
            console.log(error);
            toastMessage = error.message;
        }
        else {
            toastMessage = error.toString();
        }

        showToast(<ToastContent title={toastTitle} message={toastMessage}/>, {
            autoClose: 3000,
            type: "error"
        });

        throw error;
    }
};

Axios.interceptors.response.use((response) => response, (error) => {

    if (
        (!error.response || error.response.status === 401)
        || (error.response.status === 400 && error.response.data.message === keys.TokenNeeded)
    ) {

        storage.removeAuth();
        window.location.href = "/";
        return;
    }

    return Promise.reject(error);
});

export default apiCall;
