import React from "react";

import AuthContext from "./AuthContext";
import LoadingContext from "./LoadingContext";
import CustomerEntryContext from "./CustomerEntryContext";
import CustomerContext from "./CustomerContext";

export default function withContext(Child) {

    return function WrapperComponent(props) {
        return (
            <AuthContext.Consumer>
                {(authState) => (
                    <LoadingContext.Consumer>
                        {(loadingState) => (
                            <CustomerEntryContext.Consumer>
                                {(entryState) => (
                                    <CustomerContext.Consumer>
                                        {(customerState) => (
                                            <Child {...props} authContext={authState} loadingContext={loadingState} entryContext={entryState} customerContext={customerState}/>
                                        )}
                                    </CustomerContext.Consumer>
                                )}
                            </CustomerEntryContext.Consumer>
                        )}
                    </LoadingContext.Consumer>
                )}
            </AuthContext.Consumer>
        );
    };
}
