/**
 * Invites Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";
import _find from "lodash/find";

import apiCall from "../../../services/api";
import RemoveItem from "../../../services/utils/remove_item";
import withContext from "../../../context/withContext";
import {CrmTable} from "../../../components";
import CouponCampaignTypeSpendingPeriods from "../../../../../common/coupon_campaign_type_spending_periods";

class CampaignTypes extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showNamedConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        types: null,

        // COMPONENT
        searchText: ""
    };

    filterTypes = Memoize((types, searchText) => {

        if (!types) {
            return types;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return types;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return types.filter((type) => type.coupon_campaign_type_name.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/campaign/types",
            name: "Kampanya Türleri"
        }]);

        this.getTypesAsync();
    }

    async getTypesAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/campaign/types"});

            this.setState((prevState) => ({
                ...prevState,
                types: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    showDeleteConfirm = (type) => {

        this.props.showNamedConfirm({
            validateText: type.coupon_campaign_type_name,
            children: (
                <>
                    <div className="notification is-warning is-fixed-to-body">
                        {"Bu silme işlem "}
                        <strong>GERİ ALINAMAZ.</strong>
                    </div>
                    <p>
                        {"Kupon sağlayıcısını silmek için aşağıdaki alana tam ismini giriniz."}
                    </p>
                    <div className="notification is-warning is-fixed-to-body">
                        {"Kupon Sağlayıcı: "}
                        <strong>{type.coupon_campaign_type_name}</strong>
                    </div>
                </>
            ),
            asyncFunction: async () => {

                try {

                    await apiCall({
                        url: `/campaign/types/${type._id}`,
                        method: "DELETE",
                        toast: `Kupon Sağlayıcı: '${type.coupon_campaign_type_name}' silindi.`
                    });

                    this.setState((prevState) => ({
                        ...prevState,
                        types: RemoveItem(prevState.types, type._id)
                    }));
                }
                catch (e) {

                    this.props.setError(e);
                }
            }
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    getTypeTableColumns() {

        return [
            {
                Header: "Kampanya Türü İsmi",
                accessor: "coupon_campaign_type_name",
                sortable: true,
            },
            {
                Header: "Harcama Kontrol Periyodu",
                id: "spend-period",
                accessor: (props) => props,
                Cell: (props) => {

                    const couponCampaignTypeSpendPeriod = _find(CouponCampaignTypeSpendingPeriods, {value: props.value.coupon_campaign_spending_control_period});

                    if (couponCampaignTypeSpendPeriod) {

                        return couponCampaignTypeSpendPeriod.label;
                    }

                    return "Bilinmeyen Tür";
                }
            },
            {
                Header: "Harcama Eşiği",
                accessor: "coupon_campaign_spending_threshold",
            },
            {
                Header: "TypeId (API KEY)",
                accessor: "_id",
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 120,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (

                    <div className="level">
                        <div className="level-item">
                            <a tabIndex="0" className="button" href="#" onClick={() => {
                                this.showDeleteConfirm(props.value);
                            }}>
                                <span className="icon is-small"><i className="fa fa-trash"/></span>
                            </a>
                        </div>
                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.types) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Kupon türü ismi ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/campaign/types/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Kampanya Türü</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getTypeTableColumns()}
                    data={this.filterTypes(this.state.types, this.state.searchText)}
                    defaultSorted={[{
                        id: "coupon_campaign_type_name",
                        desc: false
                    }]}
                />
            </>
        );
    }
}

export default withContext(CampaignTypes);
