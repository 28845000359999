/**
 * Profile Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Formik} from "formik";
import {Link} from "react-router-dom";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import storage from "../../../services/storage";

class Profile extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        authContext: PropTypes.shape({
            admin: PropTypes.shape(),
            setAdmin: PropTypes.func.isRequired,
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,
    };

    componentDidMount() {

        this.props.setBreadcrumb([
            {
                url: "/profile",
                name: "Hesabım"
            }
        ]);
    }

    async updateProfileAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);
        this.setState((prevState) => ({
            ...prevState,
            error: null
        }));

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/auth/profile",
                method: "PUT",
                payload,
                toast: "Profiliniz güncellendi!"
            });

            this.props.authContext.setAdmin(data);
        }
        catch (e) {
            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    handleSubmit = (formData, formikBag) => {

        // Since profile information stored in a jwt in the user interface,
        // We have to send refresh token like we do in login request.
        // Profile update returns new tokens along with user information
        const refreshToken = storage.getRefresh().value;

        this.updateProfileAsync({
            ...formData,
            refreshToken
        }, formikBag);
    };

    render() {

        const initialValues = {
            admin_email: this.props.authContext.admin.admin_email,
            admin_name: this.props.authContext.admin.admin_name,
            admin_surname: this.props.authContext.admin.admin_surname,
            admin_profile_photo: this.props.authContext.admin.admin_profile_photo,
        };

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box profileForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Profiliniz
                                </span>
                            </h5>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={this.handleSubmit}
                            validationSchema={() => Yup.object().shape({
                                admin_email: Yup.string()
                                    .email("Geçersiz bir e-posta adresi.")
                                    .required("E-posta zorunlu bir alan."),
                                admin_name: Yup.string()
                                    .min(2, "İsim bilgisi en az 2 karakter içermelidir.")
                                    .required("İsim bilgisi zorunludur."),
                                admin_surname: Yup.string()
                                    .min(2, "Soyisim bilgisi en az 2 karakter içermelidir.")
                                    .required("Soyisim bilgisi zorunludur."),
                            })}
                            render={({
                                values,
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setSubmitting
                            }) => (
                                <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                    <FormElements.TextInput
                                        disabled
                                        type="email"
                                        autoComplete="off"
                                        error={touched.admin_email && errors.admin_email}
                                        inputName="admin_email"
                                        displayName="E-posta"
                                        isSubmitting={isSubmitting}
                                        value={values.admin_email || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <FormElements.TextInput
                                        type="text"
                                        autoComplete="off"
                                        error={touched.admin_name && errors.admin_name}
                                        inputName="admin_name"
                                        displayName="İsim"
                                        isSubmitting={isSubmitting}
                                        value={values.admin_name || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <FormElements.TextInput
                                        type="text"
                                        autoComplete="off"
                                        error={touched.admin_surname && errors.admin_surname}
                                        inputName="admin_surname"
                                        displayName="Soyisim"
                                        isSubmitting={isSubmitting}
                                        value={values.admin_surname || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <FormElements.ImageInput
                                        key="admin_profile_photo"
                                        inputName="admin_profile_photo"
                                        modelName="Admin"
                                        displayName="Profil Fotoğrafı (1024 x 1024)"
                                        imageThumbnailSize={{
                                            width: 1024,
                                            height: 1024
                                        }}
                                        hint="En iyi görünüm için, kare görsel yüklemelisiniz."
                                        value={values.admin_profile_photo || {}}
                                        isLoading={isSubmitting}
                                        setImage={(images) => {

                                            setFieldValue("admin_profile_photo", images);
                                            setFieldTouched("admin_profile_photo", true);
                                        }}
                                        setError={(error) => {
                                            console.error(error);
                                        }}
                                        uploadInProgress={setSubmitting}
                                        error={errors["admin_profile_photo"]}
                                    />

                                    <FormElements.Submit
                                        submitClasses={["full-width"]}
                                        submitText="Güncelle"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            )}
                        />

                        <div style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "2rem"
                        }}>
                            <Link to="/change-password" style={{color: "#999", fontSize: "0.9rem"}}>Şifre Güncelleme</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withContext(Profile);
