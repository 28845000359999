/**
 * CustomerCheck Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import {isValidPhoneNumber} from "react-phone-number-input";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class CustomerQuery extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            push: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/customers/query",
            name: "Müşteri Sorgulama"
        }]);
    }

    async checkPhoneAsync(customer_phone, {setSubmitting, setFieldError}) {

        // START LOADING
        this.props.setError(null);
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // Checking State
        this.setState((prevState) => ({
            ...prevState,
            isChecking: true
        }));

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customers/check/phone",
                method: "POST",
                payload: {
                    customer_phone
                }
            });

            if (!data || !data._id) {
                setFieldError("customer_phone", "Girilen telefon numarası için kayıt bulunamadı.");
            }
            else {
                this.props.history.push(`/customers/profile/${data._id}`, {
                    fromCustomerQuery: true
                });
            }

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);

            // Checking State
            this.setState((prevState) => ({
                ...prevState,
                isChecking: false
            }));

            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        const {customer_phone} = formData;

        if (!isValidPhoneNumber(customer_phone)) {
            formikBag.setFieldError("customer_phone", "Geçersiz bir telefon numarası girdiniz");
            formikBag.setSubmitting(false);
            return;
        }

        this.checkPhoneAsync(customer_phone, formikBag);
    };

    render() {


        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                Müşteri Sorgulama
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Profilini görüntülemek istediğiniz müşterinin telefon numarasını giriniz."
                            ]}
                            />
                        </div>

                        <Formik
                            initialValues={{
                                customer_phone: ""
                            }}
                            onSubmit={this.handleSubmit}
                            render={({
                                values,
                                errors,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched,
                                isSubmitting,
                                submitForm
                            }) => (
                                <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                    <FormElements.PhoneInput
                                        type="text"
                                        autoComplete="off"
                                        displayName="Cep Telefonu"
                                        inputName="customer_phone"
                                        error={errors.customer_phone}
                                        isSubmitting={isSubmitting}
                                        value={values.customer_phone || ""}
                                        onChange={async (phoneNumber) => {

                                            await setFieldValue("customer_phone", phoneNumber);
                                            await setFieldTouched("customer_phone");

                                            if (phoneNumber && phoneNumber.startsWith("+90") && phoneNumber.length === 13) {
                                                submitForm();
                                            }
                                        }}
                                    />

                                    <FormElements.Submit
                                        submitClasses={["full-width"]}
                                        submitText="Sorgula"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CustomerQuery);


