/**
 * Profile Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Formik} from "formik";
import {Link} from "react-router-dom";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class Profile extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        authContext: PropTypes.shape({
            admin: PropTypes.shape(),
            setAdmin: PropTypes.func.isRequired,
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
    };

    componentDidMount() {

        this.props.setBreadcrumb([
            {
                url: "/profile",
                name: "Hesabım"
            },
            {
                url: "/change-password",
                name: "Şifre Güncelleme"
            }
        ]);
    }

    async changePasswordAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);
        this.setState((prevState) => ({
            ...prevState,
            error: null
        }));

        // API REQUEST
        try {

            await apiCall({
                url: "/auth/change-password",
                method: "PUT",
                payload,
                toast: "Şifreniz güncellendi!"
            });

            // TODO what to do? success message?
            this.props.history.replace("/profile");
        }
        catch (e) {
            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    handleSubmit = (formData, formikBag) => {

        this.changePasswordAsync({
            ...formData,
            // add email data for extra protection :p
            // ugur will say: lol delete this!
            email: this.props.authContext.admin.admin_email
        }, formikBag);
    };

    render() {

        const initialValues = {
            old_password: "",
            password: "",
            password_confirmation: ""
        };

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box profileForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Şifre Güncelleme
                                </span>
                            </h5>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={this.handleSubmit}
                            validationSchema={() => Yup.object().shape({
                                old_password: Yup.string()
                                    .required("Güncel şifrenizi girmeniz zorunludur."),
                                password: Yup.string()
                                    .min(6, "Şifreniz en az 6 karakter içermelidir.")
                                    .required("Yeni şifrenizi girmeniz zorunludur."),
                                password_confirmation: Yup.string()
                                    .required("Yeni şifrenizi tekrar girmeniz zorunludur.")
                                    .oneOf([Yup.ref("password")], "Şifreler uyuşmuyor.")
                            })}
                            render={({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                    <FormElements.TextInput
                                        type="password"
                                        autoComplete="off"
                                        error={touched.old_password && errors.old_password}
                                        inputName="old_password"
                                        displayName="Güncel Şifre"
                                        isSubmitting={isSubmitting}
                                        value={values.old_password || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <FormElements.TextInput
                                        type="password"
                                        autoComplete="off"
                                        error={touched.password && errors.password}
                                        inputName="password"
                                        displayName="Yeni Şifre"
                                        isSubmitting={isSubmitting}
                                        value={values.password || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <FormElements.TextInput
                                        type="password"
                                        autoComplete="off"
                                        error={touched.password_confirmation && errors.password_confirmation}
                                        inputName="password_confirmation"
                                        displayName="Yeni Şifre (Tekrar)"
                                        isSubmitting={isSubmitting}
                                        value={values.password_confirmation || ""}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />

                                    <FormElements.Submit
                                        submitClasses={["full-width"]}
                                        submitText="Güncelle"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            )}
                        />

                        <div style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "2rem"
                        }}>
                            <Link to="/profile" style={{color: "#999", fontSize: "0.9rem"}}>Vazgeç</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function equalTo(ref, msg) {
    return Yup.mixed().test({
        name: "equalTo",
        exclusive: false,
        // eslint-disable-next-line
        message: msg || "${path} must be the same as ${reference}",
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return value === this.resolve(ref);
        },
    });
}
Yup.addMethod(Yup.string, "equalTo", equalTo);

export default withContext(Profile);
