/**
 * PushNotifications Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";

import apiCall from "../../../services/api";
import RemoveItem from "../../../services/utils/remove_item";
import withContext from "../../../context/withContext";
import {CrmTable, NotificationFilterDetails, NotificationStatus, TestModal} from "../../../components";

class PushNotifications extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showNamedConfirm: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        pushNotifications: null,

        // COMPONENT
        showTestModal: false,
        searchText: ""
    };

    filterPushNotifications = Memoize((pushNotifications, searchText) => {

        if (!pushNotifications) {
            return pushNotifications;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return pushNotifications;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return pushNotifications.filter((pushNotification) => pushNotification.push_notification_text_en.match(regex) || pushNotification.push_notification_text_tr.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/marketing/push-notifications",
            name: "Push Gönderimi"
        }]);

        this.getPushNotificationsAsync();
    }

    async getPushNotificationsAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/marketing/push-notifications"});

            this.setState((prevState) => ({
                ...prevState,
                pushNotifications: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    showDeleteConfirm = (pushNotification) => {

        this.props.showNamedConfirm({
            validateText: pushNotification.push_notification_text_tr,
            children: (
                <>
                    <div className="notification is-warning is-fixed-to-body">
                        {"Bu silme işlemi "}
                        <strong>GERİ ALINAMAZ.</strong>
                    </div>
                    <p>
                        {"Bu gönderimi: ("}
                        <strong>{pushNotification.push_notification_text_tr}</strong>
                        {") silerek, gönderim yapıldıysa raporlar dahil, tüm sistemden sileceksiniz."}
                    </p>
                    <p>
                        {"Push gönderimini silmek için aşağıdaki alana push metnini giriniz."}
                    </p>
                </>
            ),
            asyncFunction: async () => {

                try {

                    await apiCall({
                        url: `/marketing/push-notifications/${pushNotification._id}`,
                        method: "DELETE",
                        toast: `Push Gönderimi: '${pushNotification.push_notification_text_tr}' silindi.`
                    });

                    this.setState((prevState) => ({
                        ...prevState,
                        pushNotifications: RemoveItem(prevState.pushNotifications, pushNotification._id)
                    }));
                }
                catch (e) {

                    this.props.setError(e);
                }
            }
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    showTestModal = (pushNotification) => {

        this.setState((prevState) => ({
            ...prevState,
            showTestModal: true,
            sendTestPush: (inputValue) => {

                return apiCall({
                    url: `/marketing/push-notifications/${pushNotification._id}/test`,
                    method: "PUT",
                    payload: {
                        phones: inputValue
                    },
                    toast: "Test gönderimi başarılı. İletim süresi 15 dakikayı bulabilir."
                });
            }
        }));
    };

    showSendConfirm = (pushNotification) => {

        this.props.showConfirm({
            title: pushNotification.push_notification_text_tr,
            message: "Bu kampanya için GERÇEK gönderim yapacaksınız, onaylıyor musunuz? (Daha önce test göndermediyseniz, ilk önce test etmelisiniz.)",
            buttons: [{
                title: "Tamamdır, ŞİMDİ GÖNDER!",
                asyncFn: async () => {

                    // START LOADING
                    this.props.loadingContext.setIsFullScreenLoading(true);

                    try {

                        await apiCall({
                            url: `/marketing/push-notifications/${pushNotification._id}/send`,
                            method: "PUT",
                            toast: "Gönderim işlemi başladı, tüm gönderimin tamamlanması 15dk kadar sürebilir."
                        });
                    }
                    catch (e) {
                        this.props.setError(e);
                    }

                    // STOP LOADING
                    this.props.loadingContext.setIsFullScreenLoading(false);
                }
            }]
        });
    };

    getPushNotificationTableColumns() {
        return [
            {
                Header: "Push İçeriği",
                id: "push_notification_text",
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (
                    <>
                        <p>
                            <strong>TR:</strong>
                            {" "}
                            {props.value.push_notification_text_tr}
                        </p>
                        <p>
                            <strong>EN:</strong>
                            {" "}
                            {props.value.push_notification_text_en}
                        </p>
                    </>
                )
            },
            {
                Header: "Filtreler",
                id: "push_notification_filters",
                maxWidth: 300,
                accessor: (props) => props,
                sortable: false,
                Cell: (props) => (
                    <NotificationFilterDetails
                        type="PUSH"
                        filters={props.value.push_notification_filters}
                        status={props.value.push_notification_status}
                        potential={props.value.push_notification_potential}
                        actual={props.value.push_notification_actual}
                    />
                )
            },
            {
                Header: "Durum?",
                id: "push_notification_sent_at",
                className: "will-overflow",
                maxWidth: 300,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (
                    <NotificationStatus
                        error={props.value.push_notification_error}
                        status={props.value.push_notification_status}
                        sentAt={props.value.push_notification_sent_at}
                        scheduledFor={props.value.push_notification_scheduled_for_datetime}
                    />
                )
            },
            {
                Header: "Aksiyon",
                id: "actions",
                width: 200,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (

                    <div className="columns is-multiline notification-list-actions">

                        {
                            props.value.push_notification_status === "CREATED" || props.value.push_notification_status === "SCHEDULED" ? (
                                <>
                                    <div className="column is-12">
                                        <button type="button" tabIndex="0" className="button is-primary" onClick={() => {
                                            this.showTestModal(props.value);
                                        }}>
                                            Test Gönder
                                        </button>
                                    </div>

                                    <div className="column is-12">
                                        <button type="button" tabIndex="0" className="button is-link" onClick={() => {
                                            this.showSendConfirm(props.value);
                                        }}>
                                            Şimdi Gönder
                                        </button>
                                    </div>

                                    <div className="column is-12">
                                        <Link tabIndex="0" className="button" to={`/marketing/push-notifications/${props.value._id}/edit`}>
                                            Düzenle
                                        </Link>
                                    </div>
                                </>
                            ) : null
                        }

                        {

                            props.value.push_notification_status === "SENT" ? (
                                <div className="column is-12">
                                    <Link tabIndex="0" className="button is-primary" to={`/marketing/push-notifications/${props.value._id}/report`}>
                                        Rapor
                                    </Link>
                                </div>
                            ) : null
                        }

                        <div className="column is-12">
                            <button type="button" tabIndex="0" className="button is-danger" onClick={() => {
                                this.showDeleteConfirm(props.value);
                            }}>
                                Sil
                            </button>
                        </div>
                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.pushNotifications) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="İçeriklerde arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/marketing/push-notifications/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Push Gönderimi</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getPushNotificationTableColumns()}
                    data={this.filterPushNotifications(this.state.pushNotifications, this.state.searchText)}
                    defaultSorted={[{
                        id: "-createdAt",
                        desc: false
                    }]}
                />

                {
                    this.state.showTestModal ? (

                        <TestModal
                            type="Push"
                            isVisible={!!this.state.showTestModal}
                            onClose={() => {

                                this.setState((prevState) => ({
                                    ...prevState,
                                    showTestModal: false
                                }));
                            }}
                            onSendAsync={this.state.sendTestPush}
                        />

                    ) : null
                }
            </>
        );
    }
}

export default withContext(PushNotifications);
