/**
 * Confirm component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

export default class Confirm extends Component {

    static defaultProps = {
        title: "Confirmation",
        closeText: "İptal",
        hideCloseButton: false
    };

    static propTypes = {

        isVisible: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
        message: PropTypes.string,
        extraBody: PropTypes.element,

        closeText: PropTypes.string,
        onClose: PropTypes.func,
        hideCloseButton: PropTypes.bool,

        buttons: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            title: PropTypes.string,
            asyncFn: PropTypes.func
        })),
    };

    _isMounted = false;

    state = {
        operationInProgress: false
    };

    componentDidMount() {

        this._isMounted = true;
    }

    componentWillUnmount() {

        this._isMounted = false;
    }

    onConfirm = async (asyncFn, buttonIndex) => {

        if (asyncFn) {

            this.setState((prevState) => ({
                ...prevState,
                operationInProgress: true
            }));

            await asyncFn();

            if (this._isMounted) {
                this.setState((prevState) => ({
                    ...prevState,
                    operationInProgress: false
                }));
            }
        }

        if (this._isMounted) {
            return this.props.onClose(true, buttonIndex);
        }
    };

    render() {

        if (!this.props.isVisible) {
            return null;
        }

        return (
            <div className="modal-container">
                <div
                    className={`modal confirm-modal is-active ${this.state.operationInProgress ? "operation-in-progress" : ""}`}>
                    <div role="presentation" className="modal-background"/>
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{this.props.title}</p>
                        </header>
                        <section className="modal-card-body">
                            <p className="has-text-danger">
                                {this.props.message}
                            </p>
                            {this.props.extraBody}
                        </section>
                        {
                            (this.props.buttons || !this.props.hideCloseButton) &&
                            <footer className="modal-card-foot" style={{
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "flex-end"
                            }}>
                                {
                                    this.props.buttons && this.props.buttons.map((button, index) => (
                                        <button
                                            key={button.title}
                                            type="button"
                                            className={`button is-fullwidth is-${button.type || "danger"} ${this.state.operationInProgress ? "is-loading" : null}`}
                                            onClick={() => {
                                                this.onConfirm(button.asyncFn, index);
                                            }}
                                            disabled={this.state.operationInProgress}>
                                            {button.title}
                                        </button>
                                    ))
                                }
                                {
                                    !this.props.hideCloseButton ? (
                                        <button
                                            type="button"
                                            className="button is-fullwidth"
                                            onClick={async () => {
                                                return this.props.onClose(false);
                                            }}
                                            disabled={this.state.operationInProgress}>
                                            {this.props.closeText}
                                        </button>
                                    ) : null
                                }
                            </footer>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
