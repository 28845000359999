/**
 * ExportLogList Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";

import apiCall from "../../../services/api";
import withContext from "../../../context/withContext";
import {CrmTable} from "../../../components";
import { registerLocale } from "react-datepicker";

class ExportLogList extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showNamedConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        companies: null,

        // COMPONENT
        searchText: ""
    };

    filterCompanies = Memoize((companies, searchText) => {

        if (!companies) {
            return [];
        }

        // explicitly replace + with space so regex does not return error
        const trimmedSearchText = searchText.replace("+","");
        
        // by default list does not have search test on load
        // this also checks when user clears search bar
        if (!trimmedSearchText) {
            return companies;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");

        // run regex search on companies field spefic data
        return companies.filter(({
            reason_for_sharing,
            shared_on_date,
            admin_full_name,
            staff_full_name
        }) => {
            if (trimmedSearchText.length && 
                `${reason_for_sharing}
                ${shared_on_date}
                ${admin_full_name}
                ${staff_full_name}`.match(regex)){
                return true;
            }
        });
    
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/export-logs/list",
            name: "Export Log Listesi"
        }]);

        this.getExportLogsAsync();
    }

    async getExportLogsAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/export-logs"});

            this.setState((prevState) => ({
                ...prevState,
                companies: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    handleSearch = (e) => {

        //const searchText = e.target.value.trimStart();
        const searchText = e.target.value;
        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    getProviderTableColumn() {

        return [
            {
                Header: "Paylaşım Nedeni",
                accessor: "reason_for_sharing",
                sortable: true,
            },
            {
                Header: "Paylaşım Tarihi",
                accessor: "shared_on_date",
                sortable: true,
            },
            {
                Header: "Paylaşan Yönetici",
                accessor: "admin_full_name",
                sortable: true,
            },
            {
                Header: "Paylaşılan Şirket Adı",
                accessor: "company_name",
                sortable: true,
            },
            {
                Header: "Paylaşılan Personel",
                accessor: "staff_full_name",
                sortable: true,
            },
        ];
    }

    render() {

        if (!this.state.companies) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Şirket adı ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getProviderTableColumn()}
                    data={this.filterCompanies(this.state.companies, this.state.searchText)}
                    defaultSorted={[{
                        id: "coupon_provider_name",
                        desc: false
                    }]}
                />
            </>
        );
    }
}

export default withContext(ExportLogList);
