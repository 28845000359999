/**
 * RoleAuth component
 */
// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";

// This file actually belongs to backend. So be careful
import ROLES from "../../../../common/admin_roles";

const RoleAuth = (props) => {

    const adminRole = ROLES.find((role) => role.name === props.admin.admin_role);

    if (props.minRole) {

        const minRole = ROLES.find((role) => role.name === props.minRole);
        if (minRole.priority > adminRole.priority) {
            return null;
        }
    }

    if (props.maxRole) {

        const maxRole = ROLES.find((role) => role.name === props.maxRole);
        if (maxRole.priority < adminRole.priority) {
            return null;
        }
    }

    // Dont add any tag that extra div can broke so many part.
    return (
        <>
            {props.children}
        </>
    );
};

RoleAuth.propTypes = {
    admin: PropTypes.shape().isRequired,
    minRole: PropTypes.string,
    maxRole: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

export default RoleAuth;
