/**
 * Submit component contains
 * submit & cancel buttons.
 */

/**
 * Submit component
 */

import React from "react";
import PropTypes from "prop-types";

const Submit = (props) => {

    const buttonClasses = ["button", "submitButton", "is-link", ...props.submitClasses];
    if (props.isSubmitting) {
        buttonClasses.push("is-loading");
    }

    return (

        <div className="field" style={{paddingTop: "1rem"}}>
            <div className="control">
                <button
                    type="submit"
                    className={buttonClasses.join(" ")}>
                    {props.submitText}
                </button>
            </div>
        </div>
    );
};

Submit.defaultProps = {
    submitText: "Submit",
    submitClasses: []
};

Submit.propTypes = {

    submitClasses: PropTypes.arrayOf(PropTypes.string),
    submitText: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool
};

export default Submit;
