/**
 * Company Staff Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";

import apiCall from "../../../services/api";
import RemoveItem from "../../../services/utils/remove_item";
import withContext from "../../../context/withContext";
import CrmTable from "../../../components/CrmTable/CrmTable";

class CompanyStaffers extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {

        // API
        staffers: null,

        // COMPONENT
        searchText: "",
    };

    filterStaffers = Memoize((staffers, searchText) => {

        if (!staffers) {
            return [];
        }

        // explicitly replace + with space so regex does not return error
        const trimmedSearchText = searchText.replace("+","");
        
        // by default list does not have search test on load
        // this also checks when user clears search bar
        if (!trimmedSearchText) {
            return staffers;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");

        // run regex search on company staffs field spefic data
        return staffers.filter(({
            _id,
            company_name,
            company_staff_name,
            company_staff_surname
        }) => {
            if (trimmedSearchText.length && 
                `${_id}
                ${company_name}
                ${company_staff_name}
                ${company_staff_surname}`.match(regex)){
                return true;
            }
        });
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/export-logs/companies/staffers",
            name: "Şirket Personelleri"
        }]);

        this.getStaffersAsync();
    }

    getStaffersAsync = async () => {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {
            const {data} = await apiCall({
                url: "/export-logs/company/all/staff"
            });

            this.setState((prevState) => ({
                ...prevState,
                staffers: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    };

    showDeleteConfirm = (staff) => {

        const {_id: companyId} = staff.company_id;

        this.props.showConfirm({
            title: `${staff.company_staff_name} ${staff.company_staff_surname}`,
            message: `Bu personeli (${staff.company_staff_name} ${staff.company_staff_surname}) silmek istediğinize emin misiniz?`,
            buttons: [{
                title: "Tamamdır, DEVAM!",
                asyncFn: async () => {
                    try {

                        await apiCall({
                            url: `/export-logs/company/${companyId}/staff/${staff._id}`,
                            method: "DELETE",
                            toast: `Personel: '${staff.company_staff_name} ${staff.company_staff_surname}' silindi.`
                        });

                        this.setState((prevState) => ({
                            ...prevState,
                            staffers: RemoveItem(prevState.staffers, staff._id)
                        }));
                    }
                    catch (e) {

                        this.props.setError(e);
                    }
                }
            }]
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    getTableColumns() {

        return [
            {
                Header: "Şirket Adı",
                accessor: "company_id.company_name",
                style: {whiteSpace: "unset"},
                sortable: false,
            },
            {
                Header: "Personel Adı",
                accessor: "company_staff_name",
                sortable: true,
                width: 400
            },
            {
                Header: "Personel Soyadı",
                accessor: "company_staff_surname",
                sortable: true,
                width: 400
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 120,
                sortable: false,
                accessor: (props) => props,
                Cell: ({value}) => (

                    <div className="level">
                        <div className="level-item">
                            <a tabIndex="2" className="button" href="#" onClick={() => {
                                this.showDeleteConfirm(value);
                            }}>
                                <span className="icon is-small"><i className="fa fa-trash"/></span>
                            </a>
                        </div>
                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.staffers) {
            return null;
        }

        return (
            <div className="staffers">
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="İçeriklerde arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/export-logs/companies/staffers/add">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Çalışan Ekle</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getTableColumns()}
                    onOrdered={this.onOrdered}
                    data={this.filterStaffers(this.state.staffers, this.state.searchText)}
                    defaultSorted={[{
                        id: "-coupon_campaign_order",
                        desc: false
                    }]}
                />
            </div>
        );
    }
}

export default withContext(CompanyStaffers);
