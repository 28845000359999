/**
 * EmailNotification Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import Moment from "moment-timezone";

import {EmailPreviewText, SingleContentLink} from "../../../config.json";
import {CustomerFilter, FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import dateCheck from "../../../services/utils/date_check";
import apiCall from "../../../services/api";
import preDefinedEmailFilters from "../../../../../common/pre_defined_email_filters";
import transformSelectInitialValue from "../../../services/utils/transform_select_initial_value";

const ContentTypeOptions = [{
    name: "Tek Görsel",
    value: "SINGLE"
}, {
    // name: "Newsletter",
    // value: "NEWSLETTER"
    // }, {
    name: "Html",
    value: "HTML"
}];

class EmailNotificationForm extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        setError: PropTypes.func.isRequired,

        emailNotification: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isEdit: PropTypes.bool
    };

    state = {
        filters: this.props.emailNotification.email_notification_filters || [],
        filteredCustomerCount: 0,
        isSubmitting: false
    };

    componentDidMount() {

        this.getFilteredCustomerCount();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.filters !== this.state.filters) {
            this.getFilteredCustomerCount();
        }
    }

    getFilterText = () => {

        if (!this.state.filters || this.state.filters.length === 0) {

            return (
                // eslint-disable-next-line
                <span>E-posta Filtresi <strong>Aktif</strong>. Tahmini gönderim yapılacak müşteri sayısı: <strong>{this.state.filteredCustomerCount}</strong></span>
            );
        }

        return (
            // eslint-disable-next-line
            <span>E-posta Filtresi ve Özelleştirilmiş Filtre <strong>Aktif</strong>. Tahmini gönderim yapılacak müşteri sayısı: <strong>{this.state.filteredCustomerCount}</strong></span>
        );
    };

    handleSubmit = (formData, formikBag) => {

        // Transform Select input data to just ids
        const transformedPayload = {
            ...formData,
            email_external_lists: formData["email_external_lists"] ? formData["email_external_lists"].map((email_external_list) => email_external_list.value) : undefined,
            newsletter_content_events: formData["newsletter_content_events"] ? formData["newsletter_content_events"].map((newsletter_content_event) => newsletter_content_event.value) : undefined,
            newsletter_content_promotions: formData["newsletter_content_promotions"] ?
                formData["newsletter_content_promotions"].map((newsletter_content_promotion) => newsletter_content_promotion.value) :
                undefined,
            email_notification_filters: this.state.filters,
            email_notification_potential: this.state.filteredCustomerCount,
        };

        this.props.handleSubmit(transformedPayload, formikBag);
    };

    async getFilteredCustomerCount() {

        this.props.setError(null);
        this.props.loadingContext.setIsFullScreenLoading(true);

        const filter = [...this.state.filters, ...preDefinedEmailFilters];

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customers/count",
                method: "POST",
                payload: {
                    filter
                }
            });

            this.setState((prevState) => ({
                ...prevState,
                filteredCustomerCount: data.filteredCustomerCount
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsFullScreenLoading(false);
    }

    render() {

        const emailNotification = this.props.emailNotification;
        if (!emailNotification) {
            return null;
        }

        return (
            <>
                <CustomerFilter
                    getTitle={this.getFilterText}
                    filters={this.state.filters}
                    addFilter={(filter) => {

                        this.setState((prevState) => ({
                            ...prevState,
                            filters: [filter, ...prevState.filters]
                        }));
                    }}
                    removeFilter={(filterIndex) => {
                        this.setState((prevState) => ({
                            ...prevState,
                            filters: [
                                ...prevState.filters.slice(0, filterIndex),
                                ...prevState.filters.slice(filterIndex + 1)
                            ]
                        }));
                    }}
                    clearFilters={() => {
                        this.setState((prevState) => ({
                            ...prevState,
                            filters: []
                        }));
                    }}
                />

                <Formik
                    initialValues={{
                        email_notification_subject: emailNotification.email_notification_subject || "",
                        email_notification_preview_text: emailNotification.email_notification_preview_text || "",
                        email_notification_scheduled_for_datetime: emailNotification.email_notification_scheduled_for_datetime || "",
                        email_notification_content_type: emailNotification.email_notification_content_type || "SINGLE",
                        email_external_lists: transformSelectInitialValue(emailNotification.email_external_lists, "list_name"),
                        html_content: emailNotification.html_content || "",
                        single_content_image: emailNotification.single_content_image || "",
                        single_content_link: emailNotification.single_content_link || SingleContentLink,
                        newsletter_content_image: emailNotification.newsletter_content_image || "",
                        newsletter_content_title: emailNotification.newsletter_content_title || "",
                        newsletter_content_description: emailNotification.newsletter_content_description || "",
                        newsletter_content_link: emailNotification.single_content_link || SingleContentLink,
                        newsletter_content_events: transformSelectInitialValue(emailNotification.newsletter_content_events, "event_title_tr"),
                        newsletter_content_promotions: transformSelectInitialValue(emailNotification.newsletter_content_promotions, "promotion_title_tr"),
                    }}
                    onSubmit={this.handleSubmit}
                    validationSchema={() => Yup.object().shape({
                        email_notification_subject: Yup.string()
                            .min(5, "E-posta Konu Başlığı en az 5 karakter içermelidir.")
                            .required("E-posta Konu Başlığı zorunlu bir alandır."),
                        email_notification_preview_text: Yup.string()
                            .min(5, "Önizleme Yazısı en az 5 içermelidir.")
                            .required("Önizleme Yazısı zorunlu bir alandır."),
                        email_notification_scheduled_for_datetime: Yup.date()
                            .test("dateTimeStructureCheck", "Geçersiz bir tarih girdiniz. GG/AA/YYYY SS:dd formatında olmalıdır.", dateCheck.dateTimeStructureCheck)
                            .test("dateTimeRangeCheck", "Lütfen ileri tarihli bir seçim yapınız.", dateCheck.dateTimeIsInFutureCheck),
                        email_notification_content_type: Yup.string()
                            .test("contentTypeCheck", "İçerik Türü bilgisi zorunludur.", (contentType) => {
                                const contentTypes = ContentTypeOptions.map((contentTypeOption) => contentTypeOption.value);
                                return contentTypes.includes(contentType);
                            }),
                        single_content_link: Yup.string()
                            .test("singleContentUrlCheck", "URL zorunludur.", function (link) {

                                if (this.parent["email_notification_content_type"] === "SINGLE") {
                                    return link && link.length > 0;
                                }

                                return true;
                            }),
                        single_content_image: Yup.mixed()
                            .test("singleContentImageCheck", "Görsel zorunludur.", function (images) {

                                if (this.parent["email_notification_content_type"] === "SINGLE") {
                                    return images && images.main;
                                }

                                return true;
                            }),
                        newsletter_content_link: Yup.string()
                            .test("newsletterContentUrlCheck", "URL zorunludur.", function (link) {

                                if (this.parent["email_notification_content_type"] === "NEWSLETTER") {
                                    return link && link.length > 0;
                                }

                                return true;
                            }),
                        newsletter_content_title: Yup.string()
                            .test("newsletterContentTitleCheck", "Başlık zorunludur.", function (title) {

                                if (this.parent["email_notification_content_type"] === "NEWSLETTER") {
                                    return title && title.length > 0;
                                }

                                return true;
                            }),
                        newsletter_content_description: Yup.string()
                            .test("newsletterContentDescriptionCheck", "Açıklama metni zorunludur.", function (description) {

                                if (this.parent["email_notification_content_type"] === "NEWSLETTER") {
                                    return description && description.length > 0;
                                }

                                return true;
                            }),
                        newsletter_content_image: Yup.mixed()
                            .test("newsletterContentImageCheck", "Görsel zorunludur.", function (images) {

                                if (this.parent["email_notification_content_type"] === "NEWSLETTER") {
                                    return images && images.main;
                                }

                                return true;
                            })
                    })}
                    render={({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        setSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit} style={{marginTop: "1rem"}}>

                            <FormElements.SelectInputAsync
                                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                value={values.email_external_lists}
                                menuPosition="fixed"
                                menuPlacement="auto"
                                menuPortalTarget={document.body}
                                defaultOptions
                                isMenuOpen
                                error={touched.email_external_lists && errors.email_external_lists}
                                hint={
                                    "Harici E-posta Listesi Seçebilirsiniz. " +
                                    "Yukarda belirttiğiniz filtreye ek olarak sadece bu e-posta adreslerine gönderilecektir."
                                }
                                endpoint="/marketing/email-external-lists"
                                foreignField="list_name"
                                displayName="Harici E-posta Listeleri"
                                placeholder="Eklenmesini istediğiniz harici e-posta listeleri"
                                inputName="email_external_lists"
                                classNamePrefix="react-select"
                                isMulti={true}
                                onChange={(selectedOption) => {

                                    setFieldValue("email_external_lists", selectedOption);
                                    setFieldTouched("email_external_lists");
                                }}
                                onBlur={() => {
                                    setFieldTouched("email_external_lists");
                                }}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                error={touched.email_notification_subject && errors.email_notification_subject}
                                inputName="email_notification_subject"
                                displayName="E-posta Konu Başlığı *"
                                isSubmitting={isSubmitting}
                                value={values.email_notification_subject || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                error={touched.email_notification_preview_text && errors.email_notification_preview_text}
                                inputName="email_notification_preview_text"
                                displayName="Önizleme Yazısı"
                                isSubmitting={isSubmitting}
                                value={values.email_notification_preview_text || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            {/* <div className="field" style={{margin: "-1rem 0px 1rem"}}>
                                <p style={{fontSize: "0.8rem"}}>
                                    {"Önizleme yazısının ne anlama geldiğini görmek için lütfen "}
                                    <a
                                        href={EmailPreviewText}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        tıklayınız.
                                    </a>
                                    {" En az 20 karakterden oluşmalıdır."}
                                </p>
                            </div> */}

                            <FormElements.DateInput
                                disabled={this.props.isEdit}
                                autoComplete="off"
                                includeTime={true}
                                displayName="İleri Tarih İçin Gönderim Ayarla"
                                inputName="email_notification_scheduled_for_datetime"
                                placeholder="GG/AA/YYYY SS:dd"
                                error={touched.email_notification_scheduled_for_datetime && errors.email_notification_scheduled_for_datetime}
                                isSubmitting={isSubmitting}
                                selectedValue={values.email_notification_scheduled_for_datetime}
                                onChange={(selectedDate) => {
                                    setFieldValue("email_notification_scheduled_for_datetime", selectedDate);
                                    setFieldTouched("email_notification_scheduled_for_datetime");
                                }}
                                onBlur={() => {
                                    setFieldTouched("email_notification_scheduled_for_datetime", true);
                                }}
                                hint="GG/AA/YYYY SS:dd şeklinde input girişi yapılmalıdır, örnek: 01/01/2019 16:30"
                                minDate={Moment().add(1, "day").toDate()}
                                maxDate={Moment().add(1, "months").toDate()}
                                selectsEnd
                            />

                            <FormElements.Radio
                                displayName="İçerik Türü *"
                                inputName="email_notification_content_type"
                                options={ContentTypeOptions}
                                error={touched.email_notification_content_type && errors.email_notification_content_type}
                                isSubmitting={isSubmitting}
                                value={values.email_notification_content_type || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />


                            {
                                values.email_notification_content_type === "SINGLE" && (

                                    <div className="emailNotificationContentBlock">

                                        <FormElements.ImageInput
                                            key="single_content_image"
                                            inputName="single_content_image"
                                            modelName="EmailNotification"
                                            displayName="E-posta Görseli (640px genişliğinde)"
                                            imageThumbnailSize={{
                                                width: 400,
                                                height: 400
                                            }}
                                            value={values.single_content_image || {}}
                                            isLoading={isSubmitting}
                                            setImage={(images) => {
                                                setFieldValue("single_content_image", images);
                                                setFieldTouched("single_content_image", true);
                                            }}
                                            setError={(error) => {
                                                console.error(error);
                                            }}
                                            uploadInProgress={setSubmitting}
                                            error={errors["single_content_image"]}
                                        />

                                        <FormElements.TextInput
                                            type="text"
                                            autoComplete="off"
                                            error={touched.single_content_link && errors.single_content_link}
                                            inputName="single_content_link"
                                            displayName="Görsele tıklayınca açılacak URL *"
                                            isSubmitting={isSubmitting}
                                            value={values.single_content_link || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>

                                )
                            }

                            {
                                values.email_notification_content_type === "HTML" && (

                                    <div className="emailNotificationContentBlock">

                                        <FormElements.FileInput
                                            key="html_content"
                                            inputName="html_content"
                                            modelName="EmailNotification"
                                            displayName="E-posta HTML"
                                            value={values.html_content || ""}
                                            isLoading={isSubmitting}
                                            setFile={(file) => {
                                                setFieldValue("html_content", file);
                                                setFieldTouched("html_content", true);
                                            }}
                                            setError={(error) => {
                                                console.error(error);
                                            }}
                                            uploadInProgress={setSubmitting}
                                            error={errors["html_content"]}
                                        />

                                    </div>

                                )
                            }

                            {
                                values.email_notification_content_type === "NEWSLETTER" && (

                                    <div className="emailNotificationContentBlock">

                                        <FormElements.ImageInput
                                            key="newsletter_content_image"
                                            inputName="newsletter_content_image"
                                            modelName="EmailNotification"
                                            displayName="Ana Banner Görseli (1280px genişliğinde)"
                                            imageThumbnailSize={{
                                                width: 640,
                                                height: 200
                                            }}
                                            value={values.newsletter_content_image || {}}
                                            isLoading={isSubmitting}
                                            setImage={(images) => {
                                                setFieldValue("newsletter_content_image", images);
                                                setFieldTouched("newsletter_content_image", true);
                                            }}
                                            setError={(error) => {
                                                console.error(error);
                                            }}
                                            uploadInProgress={setSubmitting}
                                            error={errors["newsletter_content_image"]}
                                        />

                                        <FormElements.TextInput
                                            type="text"
                                            autoComplete="off"
                                            error={touched.newsletter_content_link && errors.newsletter_content_link}
                                            inputName="newsletter_content_link"
                                            displayName="Banner'a tıklayınca açılacak URL *"
                                            isSubmitting={isSubmitting}
                                            value={values.newsletter_content_link || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        <FormElements.TextInput
                                            type="text"
                                            autoComplete="off"
                                            error={touched.newsletter_content_title && errors.newsletter_content_title}
                                            inputName="newsletter_content_title"
                                            displayName="Banner Başlığı *"
                                            isSubmitting={isSubmitting}
                                            value={values.newsletter_content_title || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        <FormElements.TextInput
                                            type="text"
                                            autoComplete="off"
                                            error={touched.newsletter_content_description && errors.newsletter_content_description}
                                            inputName="newsletter_content_description"
                                            displayName="Banner Açıklama Metni *"
                                            isSubmitting={isSubmitting}
                                            value={values.newsletter_content_description || ""}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        <FormElements.SelectInputAsync
                                            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                            value={values.newsletter_content_events}
                                            menuPosition="fixed"
                                            menuPlacement="auto"
                                            menuPortalTarget={document.body}
                                            defaultOptions
                                            isMenuOpen
                                            error={touched.newsletter_content_events && errors.newsletter_content_events}
                                            hint={
                                                "Birden fazla seçim yapabilirsiniz. " +
                                                "Email içerisinde seçtiğiniz sıraya göre gözükecektir "
                                            }
                                            endpoint="/data/events"
                                            foreignField="event_title_tr"
                                            displayName="Bağlı Etkinlikler"
                                            placeholder="Eklenmesini istediğiniz etkinlikler"
                                            inputName="newsletter_content_events"
                                            classNamePrefix="react-select"
                                            isMulti={true}
                                            onChange={(selectedOption) => {

                                                setFieldValue("newsletter_content_events", selectedOption);
                                                setFieldTouched("newsletter_content_events");
                                            }}
                                            onBlur={() => {
                                                setFieldTouched("newsletter_content_events");
                                            }}
                                        />

                                        <FormElements.SelectInputAsync
                                            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                            value={values.newsletter_content_promotions}
                                            menuPosition="fixed"
                                            menuPlacement="auto"
                                            menuPortalTarget={document.body}
                                            defaultOptions
                                            isMenuOpen
                                            error={touched.newsletter_content_promotions && errors.newsletter_content_promotions}
                                            hint={
                                                "Birden fazla seçim yapabilirsiniz. " +
                                                "Email içerisinde seçtiğiniz sıraya göre gözükecektir "
                                            }
                                            endpoint="/data/promotions"
                                            foreignField="promotion_title_tr"
                                            displayName="Bağlı Fırsatlar"
                                            placeholder="Eklenmesini istediğiniz fırsatlar"
                                            inputName="newsletter_content_promotions"
                                            classNamePrefix="react-select"
                                            isMulti={true}
                                            onChange={(selectedOption) => {

                                                setFieldValue("newsletter_content_promotions", selectedOption);
                                                setFieldTouched("newsletter_content_promotions");
                                            }}
                                            onBlur={() => {
                                                setFieldTouched("newsletter_content_promotions");
                                            }}
                                        />
                                    </div>
                                )
                            }

                            <FormElements.Submit
                                submitClasses={["full-width"]}
                                submitText={this.props.submitText}
                                isSubmitting={isSubmitting}
                            />
                        </form>
                    )}
                />
            </>

        );
    }
}

export default withContext(EmailNotificationForm);

