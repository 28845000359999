/**
 * DateInput component
 */
import React, {Component} from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import Moment from "moment-timezone";

import InlineError from "../InlineError/InlineError";
import Hint from "../Hint/Hint";

export default class DateInput extends Component {

    pickerRef = React.createRef();

    static propTypes = {
        includeTime: PropTypes.bool,
        dateFormat: PropTypes.string,
        timeFormat: PropTypes.string,
        timeIntervals: PropTypes.number,
        inputName: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        subDisplayName: PropTypes.string,
        disabled: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        placeholder: PropTypes.string,
        selectedValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
        ]),
        hint: PropTypes.string,
        hintAsLabelExplanation: PropTypes.bool,
        error: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func.isRequired
    };

    componentDidMount() {

    }

    handleChange = (selectedDate) => {

        let date;

        if (selectedDate && this.props.includeTime) {
            date = selectedDate;
        }
        else if (selectedDate) {
            date = Moment(selectedDate).format("DD/MM/YYYY");
        }
        else {
            date = "";
        }

        // TODO min ve max date'e göre manuel girişlerin engellenmesi gerekiyor.
        // regex format: (day)(month)(year)(hour)(minute)
        // ^(\d{2})(\d{2})(\d{4})(\d{2})(\d{2})$
        this.props.onChange(date);
    };

    handleRawChange = ({target: {value}}) => {

        if (!value) {
            return;
        }

        const {includeTime} = this.props;

        let date;

        // not include time and 8-digit
        if (!includeTime && /^(\d{8})$/.test(value)) {
            date = Moment(value, "DDMMYYYY");
        }
        // include time and 12-digit numbers
        else if (includeTime && /^(\d{12})$/.test(value)) {
            date = Moment(value, "DDMMYYYYHHmm");
        }

        if (date) {

            this.pickerRef.current.setOpen(false);

            if (date.isValid()) {

                this.handleChange(date.toDate());
            }
            else {

                this.handleChange("");
            }
        }
    };

    render() {

        const {dateFormat, timeFormat, selectedValue, timeIntervals} = this.props;

        const datePickerOptions = {
            dateFormat: dateFormat || "dd/MM/yyyy",

            ...(
                this.props.includeTime && {
                    dateFormat: dateFormat || "dd/MM/yyyy HH:mm",
                    showTimeSelect: true,
                    timeFormat: timeFormat || "HH:mm",
                    timeIntervals: timeIntervals || 30,
                    timeCaption: "Saat"
                }
            )
        };

        const selectedDate = selectedValue ? (this.props.includeTime ? Moment(selectedValue).toDate() : Moment(selectedValue, "DD/MM/YYYY").toDate()) : null;

        return (
            <>
                <div className={`field ${this.props.error ? "is-danger" : ""}`}>
                    <label className="label" htmlFor={this.props.inputName}>
                        <span>
                            {this.props.displayName}
                        </span>
                        <Hint hint={this.props.hint} hintAsLabelExplanation={this.props.hintAsLabelExplanation}/>
                    </label>
                    <div className="control">
                        <DatePicker
                            ref={this.pickerRef}
                            {...this.props}
                            disabled={this.props.isSubmitting || this.props.disabled}
                            className="input"
                            name={this.props.inputName}
                            id={this.props.inputName}
                            selected={selectedDate}
                            placeholderText={this.props.placeholder || this.props.displayName}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            {...datePickerOptions}
                            onChange={this.handleChange}
                            onChangeRaw={this.handleRawChange}
                            onBlur={this.props.onBlur}
                        />
                    </div>

                    <InlineError error={this.props.error}/>
                </div>
            </>
        );
    }
}
