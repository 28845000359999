/**
 * Invites Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";

import apiCall from "../../../services/api";
import RemoveItem from "../../../services/utils/remove_item";
import withContext from "../../../context/withContext";
import {CrmTable} from "../../../components";

class EventTypes extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,
        showNamedConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        eventTypes: null,

        // COMPONENT
        searchText: ""
    };

    filterEventTypes = Memoize((eventTypes, searchText) => {

        if (!eventTypes) {
            return eventTypes;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return eventTypes;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return eventTypes.filter((eventType) => eventType.event_type_name.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/data/event-types",
            name: "Veri Giriş Yerleri"
        }]);

        this.getEventTypesAsync();
    }

    async getEventTypesAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/data/event-types"});

            this.setState((prevState) => ({
                ...prevState,
                eventTypes: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    participatedCustomers = () => {
    
        this.props.showConfirm({
            title: "Excel Çıktısı",
            message: "Bu işlem dosyanın boyutuna göre 5 dakika kadar sürebilmektedir. Tarayıcınızı kapatmayın.",
            buttons: [{
                title: "Tamamdır, DEVAM!"
            }],
            onClose: (isConfirm) => {
    
                if (isConfirm) {
                    this.excelExportAction();
                }
            }
        });
    
    };

    excelExportAction = async (eventType) => {
    
        // Okay clicked, request excel and update link in extraBody
        this.props.loadingContext.setIsFullScreenLoading(true);
    
        try {
    
            await apiCall({
                url: `/customers/export/${eventType._id}`,
                method: "POST",
                options: {
                    responseType: "arraybuffer",
                },
                headers: {
                    "Content-Type": "application/json"
                },
                download: true,
                filename: `${eventType.event_type_name}.xlsx`,
            });
        }
        catch (e) {
            this.props.setError(e);
        }
    
        this.props.loadingContext.setIsFullScreenLoading(false);
    };

    dailyExcelExportAction = async (eventType) => {

        // Okay clicked, request excel and update link in extraBody
        this.props.loadingContext.setIsFullScreenLoading(true);

        try {

            const {data} = await apiCall({
                url: `/customers/export/daily/${eventType._id}`,
                method: "POST",
                options: {
                    responseType: "arraybuffer",
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${eventType.event_type_name}.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch (e) {
            this.props.setError(e);
        }

        this.props.loadingContext.setIsFullScreenLoading(false);
    };

    showDeleteConfirm = (eventType) => {

        this.props.showNamedConfirm({
            validateText: eventType.event_type_name,
            children: (
                <>
                    <div className="notification is-warning is-fixed-to-body">
                        {"Bu silme işlemi "}
                        <strong>GERİ ALINAMAZ.</strong>
                    </div>
                    <p>
                        {"Bu veri girişi yerini: ("}
                        <strong>{eventType.event_type_name}</strong>
                        {") silerek, ekli müşteri profillerinden bu kaydı kaldıracaksınız."}
                    </p>
                    <p>
                        {"Veri giriş yerini silmek için aşağıdaki alana tam ismini giriniz."}
                    </p>
                </>
            ),
            asyncFunction: async () => {

                try {

                    await apiCall({
                        url: `/data/event-types/${eventType._id}`,
                        method: "DELETE",
                        toast: `Veri Giriş Yeri: '${eventType.event_type_name}' silindi.`
                    });

                    this.setState((prevState) => ({
                        ...prevState,
                        eventTypes: RemoveItem(prevState.eventTypes, eventType._id)
                    }));
                }
                catch (e) {

                    this.props.setError(e);
                }
            }
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    getEventTypeTableColumns() {

        return [
            {
                Header: "Veri Giriş Yeri",
                accessor: "event_type_name",
                style: {"whiteSpace": "unset"},
                maxWidth: 500,
                sortable: true,
            },
            {
                Header: "API (event_id değeri)",
                accessor: "_id",
                style: {"whiteSpace": "unset"},
                maxWidth: 500,
                sortable: true,
            },
            {

                Header: "Bağlı olduğu Etiketler",
                id: "event_type_tags",
                maxWidth: 240,
                sortable: true,
                accessor: (props) => props.event_type_tag_types,
                Cell: (props) => {

                    const tagTypes = props.value;
                    return (
                        tagTypes && tagTypes.map((tagType) => (
                            <p key={tagType._id}>{tagType.tag_type_name}</p>
                        ))
                    );
                },
            },
            {

                Header: "CRM üzerinden Veri Girişi Açık Mı?",
                id: "event_type_ui_allowed",
                sortable: false,
                maxWidth: 200,
                accessor: (props) => {
                    return props.event_type_ui_allowed ? "EVET" : "HAYIR";
                },
            },
            {

                Header: "Müşteri Sayısı",
                accessor: "customer_count",
                sortable: false,
                maxWidth: 120
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 360,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (
                    <>
                        <div className="level">
                            <div className="level-item">
                                <button
                                    type="button"
                                    tabIndex="0"
                                    className="button"
                                    onClick={() => {
                                        this.excelExportAction(props.value);
                                    }}>
                                    Katılan Müşteriler
                                </button>
                            </div>
                            <div className="level-item">
                                <Link tabIndex="0" className="button" to={`/data/event-types/${props.value._id}/edit`}>
                                    <span className="icon is-small"><i className="fa fa-edit" /></span>
                                </Link>
                            </div>
                            <div className="level-item">
                                <a tabIndex="0" className="button" href="#" onClick={() => {
                                    this.showDeleteConfirm(props.value);
                                }}>
                                    <span className="icon is-small"><i className="fa fa-trash" /></span>
                                </a>
                            </div>
                        </div>
                        {
                            props.value.event_type_payment_form_allowed &&
                            <button
                                className="button cell-button"
                                type="button"
                                tabIndex="0"
                                onClick={() => {
                                    this.dailyExcelExportAction(props.value);
                                }}>
                                Günlük Rapor
                            </button>
                        }
                    </>
                )
            }
        ];
    }

    render() {

        if (!this.state.eventTypes) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Veri giriş yer ismi ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/data/event-types/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni Veri Giriş Yeri</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getEventTypeTableColumns()}
                    data={this.filterEventTypes(this.state.eventTypes, this.state.searchText)}
                    defaultSorted={[{
                        id: "event_type_name",
                        desc: false
                    }]}
                />
            </>
        );
    }
}

export default withContext(EventTypes);
