/**
 * TeachSectionForm component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Moment from "moment-timezone";

import FormElements from "../FormElements";
import * as Yup from "yup";
import {Formik} from "formik";
import apiCall from "../../services/api";

class PaymentItem extends Component {

    static propTypes = {
        stores: PropTypes.array.isRequired,
        eventType: PropTypes.object.isRequired,
        customer: PropTypes.object.isRequired,
        onPaymentAdded: PropTypes.func,
    };

    static defaultProps = {
        item: {},
    };

    state = {
        error: null
    };

    handleSubmit = async (formData, {setSubmitting, resetForm}) => {

        const {eventType, customer, stores, onPaymentAdded} = this.props;
        const {payment_store_id, payment_amount, payment_date, payment_receipt_no} = formData;

        const store = _.find(stores, {value: payment_store_id});

        const payload = {
            customer_id: customer._id,
            payment_store_id: store.value,
            payment_store_name: store.label,
            payment_amount,
            payment_date,
            payment_receipt_no
        };

        this.setState((prevState) => ({
            ...prevState,
            error: null
        }));

        try {

            const {data} = await apiCall({
                url: `/data/event-types/${eventType._id}/payments`,
                method: "POST",
                payload,
                toast: "Harcama kaydedildi.",
                withAuth: true
            });

            onPaymentAdded(data);
            resetForm({});
        }
        catch (e) {

            this.setState((prevState) => ({
                ...prevState,
                error: e.message || e,
            }));

            return;
        }

        setSubmitting(false);
    };

    render() {

        const {stores} = this.props;

        return (
            <>
                <Formik
                    initialValues={{
                        payment_amount: "",
                        payment_date: new Date()
                    }}
                    onSubmit={this.handleSubmit}
                    validationSchema={() => Yup.object().shape({
                        payment_store_id: Yup.string()
                            .required("Kategori zorunlu bir alan."),
                        payment_receipt_no: Yup.string()
                            .required("Fatura numarası zorunlu bir alan."),
                        payment_amount: Yup.number()
                            .required("Tutar zorunlu bir alan."),
                        payment_date: Yup.date()
                            .required("Fatura tarihi zorunlu bir alandır")
                    })}
                    render={(
                        {
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            setFieldTouched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                        }
                    ) => (
                        <form autoComplete="off" className="venuexForm payment-item-form" onSubmit={handleSubmit}>

                            <FormElements.SelectInput
                                key={`${values.payment_store_id}-${Date.now()}`}
                                isDisabled={isSubmitting}
                                value={values.payment_store_id}
                                inputName="payment_store_id"
                                error={errors.payment_store_id}
                                options={stores}
                                noOptionsMessage={() => "No result"}
                                displayName="Mağaza *"
                                placeholder="Mağaza seçebilirsiniz"
                                onChange={(selectedOption) => {

                                    setFieldValue("payment_store_id", selectedOption.value);
                                    setFieldTouched("payment_store_id");
                                }}
                                onBlur={() => {
                                    setFieldTouched("payment_store_id", true);
                                }}
                            />

                            <FormElements.TextInput
                                isDisabled={isSubmitting}
                                type="number"
                                step="0.01"
                                autoComplete="off"
                                error={touched.payment_amount && errors.payment_amount}
                                inputName="payment_amount"
                                displayName="Ödeme Tutarı * (Örnek: 25,99)"
                                value={values.payment_amount || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                isDisabled={isSubmitting}
                                type="text"
                                autoComplete="off"
                                error={touched.payment_receipt_no && errors.payment_receipt_no}
                                inputName="payment_receipt_no"
                                displayName="Fatura No *"
                                value={values.payment_receipt_no || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.DateInput
                                autoComplete="off"
                                displayName="Fatura Tarihi *"
                                inputName="payment_date"
                                includeTime
                                timeIntervals={1}
                                placeholder="GG/AA/YYYY SS:dd"
                                error={touched.payment_date && errors.payment_date}
                                isSubmitting={isSubmitting}
                                selectedValue={values.payment_date}
                                onChange={(selectedDate) => {
                                    setFieldValue("payment_date", selectedDate);
                                    setFieldTouched("payment_date");
                                }}
                                onBlur={() => {
                                    setFieldTouched("payment_date", true);
                                }}
                                minDate={Moment().subtract(1, "year").toDate()}
                                maxDate={new Date()}
                                hint="GG/AA/YYYY SS:dd şeklinde input girişi yapılmalıdır, örnek: 01/01/2021 14:53"
                            />

                            <FormElements.Button
                                classNames={["is-link", "full-width"]}
                                loading={isSubmitting}
                                type="submit">
                                Yeni Ekle
                            </FormElements.Button>
                        </form>
                    )}
                />
            </>
        );
    }
}

export default PaymentItem;
