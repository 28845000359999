/**
 * Format phone string
 *
 */
module.exports = function () {
  var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "TL";

  if (!num) {
    return "-";
  }

  var price = Number(num.toFixed(2)).toLocaleString();

  if (["$", "€"].includes(currency)) {
    return "".concat(currency).concat(price);
  }

  return "".concat(price, " ").concat(currency);
};