import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Link, withRouter} from "react-router-dom";

import Styles from "../../assets/styles/loader.module.css";
import Logo from "./vadi-istanbul-logo.svg";
import UserLight from "./user-light.svg";
import UserDark from "./user-dark.svg";
import RoleAuth from "../RoleAuth/RoleAuth";
import withContext from "../../context/withContext";

class Header extends PureComponent {

    static propTypes = {

        // Parent
        layout: PropTypes.string,
        handleDrawerToggle: PropTypes.func,

        // Context
        authContext: PropTypes.shape({
            admin: PropTypes.shape(),
            clearAdmin: PropTypes.func.isRequired
        }),
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired
        }),

        // React router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    logout = (e) => {

        e.preventDefault();
        this.props.authContext.clearAdmin();
        this.props.history.replace("/login");
    };

    render() {

        return (
            <nav className="navbar is-fixed-top" role="navigation">
                <div className="navbar-brand">
                    {
                        this.props.layout === "extendedLayout" ? (
                            <div onClick={this.props.handleDrawerToggle} className="navbar-burger marginLeftReset" role="button" tabIndex="0" style={{outline: "none"}}>
                                <span/>
                                <span/>
                                <span/>
                            </div>
                        ) : null
                    }
                    <Link to="/" className="navbar-item navbar-logo">
                        <img
                            src={Logo}
                            alt="CRM LOGO"
                            height="40"
                        />
                    </Link>
                    <div className="navbar-item" style={{padding: "0.5rem"}}>

                        <div style={{
                            height: "40px",
                            width: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            {
                                this.props.loadingContext.isLoading ? (
                                    <div className={Styles["lds-dual-ring"]}/>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                <div className="navbar-menu">
                    <div className="navbar-end">
                        <div className="navbar-item has-dropdown is-hoverable">
                            <span className="navbar-link">
                                <figure className="image is-32x32">
                                    {
                                        this.props.authContext.admin && this.props.authContext.admin.admin_profile_photo ? (
                                            <img
                                                className="is-rounded"
                                                src={this.props.authContext.admin.admin_profile_photo.small}
                                                alt={this.props.authContext.admin.admin_name}
                                            />
                                        ) : (
                                            <>
                                                <img className="is-rounded is-light-figure" src={UserLight} alt={this.props.authContext.admin.admin_name}/>
                                                <img className="is-rounded is-dark-figure" src={UserDark} alt={this.props.authContext.admin.admin_name}/>
                                            </>
                                        )
                                    }
                                </figure>
                                {" "}
                                {this.props.authContext.admin.admin_name}
                                {" "}
                                {this.props.authContext.admin.admin_surname}
                            </span>
                            <span className="navbar-dropdown is-size-6 is-boxed is-right">
                                <Link className="navbar-item" to="/profile">Profiliniz</Link>
                                <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">
                                    <Link className="navbar-item" to="/admins">Kullanıcılar</Link>
                                    <Link className="navbar-item" to="/invites">Kullanıcı Davetleri</Link>
                                </RoleAuth>
                                <div className="navbar-divider"/>
                                <a className="navbar-item" onClick={this.logout} href="#">Çıkış</a>
                            </span>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default withRouter(withContext(Header));
