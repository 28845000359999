import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Formik} from "formik";
import {Link, Redirect} from "react-router-dom";

import {uiTitle} from "../../../config.json";
import {ApiError, FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class Login extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        authContext: PropTypes.shape({
            admin: PropTypes.shape(),
            setAdmin: PropTypes.func.isRequired,
        }),

        // React router
        location: PropTypes.shape({
            state: PropTypes.shape()
        }),
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {
        // API,
        error: null,
        isLoginSuccess: false
    };

    constructor(props) {

        super(props);

        // If user has already authenticated. Redirect to home page.
        if (this.props.authContext.admin) {
            return this.props.history.replace("/");
        }
    }

    async loginAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);
        this.setState((prevState) => ({
            ...prevState,
            error: null
        }));

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/auth/login",
                method: "POST",
                payload,
                withAuth: false
            });

            this.props.authContext.setAdmin(data);

            this.setState((prevState) => ({
                ...prevState,
                isLoginSuccess: true
            }));
        }
        catch (e) {

            this.setState((prevState) => ({
                ...prevState,
                error: e
            }));
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    handleSubmit = (formData, formikBag) => {

        this.loginAsync(formData, formikBag);
    };

    render() {

        if (this.state.isLoginSuccess) {
            // If isLoginSuccess success set to truthy, login action
            // is completed and we may redirect to home page.
            return (
                <Redirect to="/"/>
            );
        }

        return (

            <>

                <h1 className="title is-4">{`${uiTitle} - Giriş`}</h1>

                {
                    this.state.error ? (
                        <ApiError error={this.state.error} clearError={() => this.setState((prevState) => ({...prevState, error: null}))}/>
                    ) : null
                }

                {
                    this.props.location.state && this.props.location.state.successMsg ? (
                        <div className="notification is-primary">
                            {this.props.location.state.successMsg}
                        </div>
                    ) : null
                }

                <Formik
                    initialValues={{
                        admin_email: "",
                        admin_password: ""
                    }}
                    onSubmit={this.handleSubmit}
                    validationSchema={() => Yup.object().shape({
                        admin_email: Yup.string()
                            .email("Geçersiz bir e-posta adresi.")
                            .required("E-posta zorunlu bir alan."),
                        admin_password: Yup.string()
                            .min(6, "Şifreniz en az 6 karakter içermelidir.")
                            .required("Şifre zorunlu bir alan.")
                    })}
                    render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                            <FormElements.TextInput
                                type="email"
                                autoComplete="off"
                                error={touched.admin_email && errors.admin_email}
                                inputName="admin_email"
                                displayName="E-posta"
                                isSubmitting={isSubmitting}
                                value={values.admin_email || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="password"
                                autoComplete="off"
                                error={touched.admin_password && errors.admin_password}
                                inputName="admin_password"
                                displayName="Şifre"
                                isSubmitting={isSubmitting}
                                value={values.admin_password || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.Submit
                                submitClasses={["full-width"]}
                                submitText="Giriş"
                                isSubmitting={isSubmitting}
                            />
                        </form>
                    )}
                />

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "1.5rem 0 0"
                }}>
                    <Link to="/forget-password" style={{color: "#999", fontSize: "0.9rem"}}>Şifremi unuttum</Link>
                </div>
            </>
        );
    }
}

export default withContext(Login);
