/**
 * CustomerCheck Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import {Redirect} from "react-router-dom";
import {isValidPhoneNumber} from "react-phone-number-input";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";

class CustomerCheck extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        entryContext: PropTypes.shape({
            entryCustomer: PropTypes.shape(),
            entryEventType: PropTypes.shape(),
            setEntryCustomer: PropTypes.func.isRequired,
            clearEntryCustomer: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    state = {
        isChecking: false,
        checkResult: null
    };

    componentDidMount() {

        // clear state.
        this.props.entryContext.clearEntryCustomer();

        // check if actual type matches.
        const entryEventType = this.props.entryContext.entryEventType;

        if (!entryEventType) {
            this.props.history.replace("/customer-entry");
            return;
        }

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/customer-entry",
            name: "Ziyaretçi Kayıt"
        }, {
            url: "/customer-entry/check",
            name: "Kayıt Başlangıç"
        }]);
    }

    async checkPhoneAsync(payload, {setSubmitting}) {

        // START LOADING
        this.props.setError(null);
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // Checking State
        this.setState((prevState) => ({
            ...prevState,
            isChecking: true
        }));

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customer-entry/check",
                method: "POST",
                payload: {
                    ...payload,
                    eventType: this.props.entryContext.entryEventType
                }
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);

            // Checking State
            this.props.entryContext.setEntryCustomer(data.customer, () => {

                this.setState((prevState) => ({
                    ...prevState,
                    isChecking: false,
                    checkResult: data.type
                }));
            });

        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);

            // Checking State
            this.setState((prevState) => ({
                ...prevState,
                isChecking: false
            }));

            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        const {customer_phone} = formData;

        if (!isValidPhoneNumber(customer_phone)) {
            console.log("Invalid!");

            formikBag.setFieldError("customer_phone", "Geçersiz bir telefon numarası girdiniz");
            formikBag.setSubmitting(false);
            return;
        }

        this.checkPhoneAsync(formData, formikBag);
    };

    render() {

        if (!this.props.entryContext.entryEventType) {
            return null;
        }

        if (this.state.checkResult === "register") {

            return (
                <Redirect push to="/customer-entry/register"/>
            );
        }

        if (this.state.checkResult === "update") {

            return (
                <Redirect push to={`/customer-entry/update/${this.props.entryContext.entryCustomer._id}`}/>
            );
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    {this.props.entryContext.entryEventType.event_type_name}
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Lütfen önce ziyaretçinin telefon numarasını sorunuz ve aşağıdaki alana giriniz.",
                                "Ziyaretçiye kayıt işlemi sırasında, sms ile doğrulama kodu gideceğini belirtiniz."
                            ]}
                            />
                        </div>

                        <Formik
                            initialValues={{
                                customer_phone: ""
                            }}
                            onSubmit={this.handleSubmit}
                            render={({
                                values,
                                errors,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched,
                                isSubmitting,
                                submitForm
                            }) => (
                                <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                    <FormElements.PhoneInput
                                        type="text"
                                        autoComplete="off"
                                        displayName="Cep Telefonu"
                                        inputName="customer_phone"
                                        error={errors.customer_phone}
                                        isSubmitting={isSubmitting}
                                        value={values.customer_phone || ""}
                                        onChange={async (phoneNumber) => {

                                            await setFieldValue("customer_phone", phoneNumber);
                                            await setFieldTouched("customer_phone");

                                            if (phoneNumber && phoneNumber.startsWith("+90") && phoneNumber.length === 13) {
                                                submitForm();
                                            }
                                        }}
                                    />

                                    <FormElements.Submit
                                        submitClasses={["full-width"]}
                                        submitText="Sorgula"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(CustomerCheck);


