/**
 * Format type texts
 * @param str
 * @returns {*}
 */

import Iconv from "iconv-lite";

export function upperCaseText(str) {

    if (!str) {
        return str;
    }

    return str
        .replace(/i/g, "İ")
        .replace(/ı/g, "I")
        .replace(/ü/g, "Ü")
        .replace(/ğ/g, "Ğ")
        .replace(/ö/g, "Ö")
        .replace(/ş/g, "Ş")
        .replace(/ç/g, "Ç")
        .toUpperCase();
}

export function lowerCaseText(str) {

    if (!str) {
        return str;
    }

    return str
        .replace(/İ/g, "i")
        .replace(/Ö/g, "ö")
        .replace(/Ü/g, "ü")
        .replace(/Ğ/g, "ğ")
        .replace(/Ş/g, "ş")
        .replace(/Ç/g, "ç")
        .toLowerCase();
}

export function removeTurkishChars(str) {

    if (!str) {
        return str;
    }

    return str
        .replace(/İ/g, "I")
        .replace(/i/g, "i")
        .replace(/ı/g, "i")
        .replace(/Ö/g, "O")
        .replace(/ö/g, "o")
        .replace(/Ü/g, "U")
        .replace(/ü/g, "u")
        .replace(/Ğ/g, "G")
        .replace(/ğ/g, "g")
        .replace(/Ş/g, "S")
        .replace(/ş/g, "s")
        .replace(/Ç/g, "C")
        .replace(/ç/g, "c");
}

export function previewForLatin5(text) {

    const latin5EncodedBuff = Iconv.encode(text, "ISO-8859-9");
    return Iconv.decode(latin5EncodedBuff, "ISO-8859-9");
}
