/**
 * EventType Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";

import {uiDomain} from "../../../config.json";
import {FormElements} from "../../../components";
import formatTypeText from "../../../services/utils/format_type_text";

export default class FeedbackTypeForm extends PureComponent {

    static propTypes = {
        tagType: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    render() {

        const tagType = this.props.tagType;
        if (!tagType) {
            return null;
        }

        return (
            <Formik
                initialValues={{
                    feedback_tag_type_name: tagType.feedback_tag_type_name,
                    feedback_tag_type_exp: tagType.feedback_tag_type_exp,
                }}
                onSubmit={this.props.handleSubmit}
                validationSchema={() => Yup.object().shape({
                    feedback_tag_type_name: Yup.string()
                        .min(2, "Etiket en az iki karakter içermelidir.")
                        .required("Etiket zorunlu bir alandır.")
                        .test("AllowedChars", "Etiket sadece ingilizce karakterler, kısa çizgi, boşluk ve rakam içerebilir.", (value) => {

                            return (/^([A-Z0-9]+\s)*[A-Z0-9-+\s]+$/).test(value);
                        })
                })}
                render={({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            error={touched.feedback_tag_type_name && errors.feedback_tag_type_name}
                            inputName="feedback_tag_type_name"
                            displayName="Etiket Tipi *"
                            isSubmitting={isSubmitting}
                            value={values.feedback_tag_type_name || ""}
                            onChange={(e) => {

                                const val = e.target.value;
                                setFieldValue("feedback_tag_type_name", formatTypeText(val));
                                setFieldTouched("feedback_tag_type_name");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.TextArea
                            type="text"
                            autoComplete="off"
                            error={touched.feedback_tag_type_exp && errors.feedback_tag_type_exp}
                            inputName="feedback_tag_type_exp"
                            displayName="Açıklama"
                            isSubmitting={isSubmitting}
                            value={values.feedback_tag_type_exp || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            hint={`Bu müşteri etiketini, ${uiDomain} adresini kullanan diğer kullanıcıların anlaması içindir. İstediğiniz notu, bilgiyi girebilirsiniz.`}
                        />
                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText={this.props.submitText}
                            isSubmitting={isSubmitting}
                        />
                    </form>
                )}
            />
        );
    }
}


