import {keys} from "./constants";

const tryParse = (item) => {
    try {
        return JSON.parse(item);
    }
    catch (e) {
        return false;
    }
};

class Storage {
    constructor() {
        this.localStorage = window.localStorage;
    }

    getItem(item) {
        return this.localStorage.getItem(item);
    }

    setItem(key, value) {
        this.localStorage.setItem(key, value);
    }

    removeItem(key) {
        this.localStorage.removeItem(key);
    }

    getToken() {
        return tryParse(this.getItem(keys.Token));
    }

    setToken(token) {
        this.setItem(keys.Token, JSON.stringify(token));
    }

    removeToken() {
        this.removeItem(keys.Token);
    }

    getRefresh() {
        return tryParse(this.getItem(keys.Refresh));
    }

    setRefresh(refresh) {
        this.setItem(keys.Refresh, JSON.stringify(refresh));
    }

    removeRefresh() {
        this.removeItem(keys.Refresh);
    }

    getAdmin() {
        return tryParse(this.getItem(keys.Admin));
    }

    setAdmin(admin) {
        this.setItem(keys.Admin, JSON.stringify(admin));
    }

    removeAdmin() {
        this.removeItem(keys.Admin);
    }

    getAuth() {

        return {
            token: this.getToken(),
            refresh: this.getRefresh(),
            admin: this.getAdmin()
        };
    }

    setAuth(data) {

        this.setAdmin(data.admin);
        this.setToken(data.token);
        this.setRefresh(data.refresh);
    }

    removeAuth() {

        this.removeAdmin();
        this.removeToken();
        this.removeRefresh();
    }
}

export default new Storage();
