/**
 * CustomerReports component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import {endPoint} from "../../../services/constants";
import apiCall from "../../../services/api";
import getPrettyNumber from "../../../services/utils/get_pretty_number";

export default class MonthlyReports extends Component {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setError: PropTypes.func.isRequired,
        setBreadcrumb: PropTypes.func.isRequired
    };

    state = {
        monthlyReports: null,
        selectedMonthlyReport: null,
        previousMonthlyReport: null
    };

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/data/event-types",
            name: "Aylık Müşteri Raporu"
        }]);

        this.getMonthlyReportsAsync();
    }

    async getMonthlyReportsAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/reports/monthly-reports"});
            const monthlyReports = data.monthlyReports;
            if (!monthlyReports || monthlyReports.length === 0) {
                this.props.setError("Şu anda rapora ulaşılamıyor, lütfen daha sonra tekrar deneyin.");
            }

            this.setState((prevState) => ({
                ...prevState,
                monthlyReports,
                selectedMonthlyReport: monthlyReports[0],
                previousMonthlyReport: monthlyReports[1],
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    dateChanged = (e) => {

        const selectedMonthlyReportId = e.target.value;
        const selectedMonthlyReportIndex = this.state.monthlyReports.findIndex((monthlyReport) => monthlyReport._id === selectedMonthlyReportId);

        if (selectedMonthlyReportIndex >= 0) {

            this.setState((prevState) => ({
                ...prevState,
                selectedMonthlyReport: prevState.monthlyReports[selectedMonthlyReportIndex],
                previousMonthlyReport: selectedMonthlyReportIndex < (prevState.monthlyReports.length - 1) ? prevState.monthlyReports[selectedMonthlyReportIndex + 1] : undefined,
            }));
        }
    };

    getPreviousMonthDataFor(fieldName) {

        if (!this.state.previousMonthlyReport) {
            return "-";
        }

        let currentResult;
        let previousResult;

        if (fieldName.startsWith("source.")) {

            const sourceId = fieldName.split(".")[1];

            const previousSource = this.state.previousMonthlyReport.sources && this.state.previousMonthlyReport.sources.find((source) => source._id === sourceId);
            previousResult = (previousSource && previousSource.count) || "-";

            const currentSource = this.state.selectedMonthlyReport.sources && this.state.selectedMonthlyReport.sources.find((source) => source._id === sourceId);
            currentResult = (currentSource && currentSource.count) || "-";
        }
        else {

            currentResult = this.state.selectedMonthlyReport[fieldName] || "-";
            previousResult = this.state.previousMonthlyReport[fieldName] || "-";
        }

        if (!previousResult) {
            return "-";
        }

        let percentageHtml;

        if (currentResult !== "-" && previousResult !== "-") {

            const percentage = (currentResult - previousResult) / currentResult * 100.0;

            let percentageClass = "still";
            if (percentage < 0) {
                percentageClass = "down";
            }
            else if (percentage > 0) {
                percentageClass = "up";
            }

            percentageHtml = (
                <div className={`changeArrow ${percentageClass}`}>
                    %
                    {percentage.toFixed(2)}
                </div>
            );
        }

        return (
            <div className="subtitle">
                {`Geçtiğimiz Ay: ${getPrettyNumber(previousResult)}`}
                {percentageHtml}
            </div>
        );
    }

    getMonthlyReport() {

        return (
            <>
                <h1 className="mainTitle">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                {this.state.selectedMonthlyReport.monthly_report_pretty_date}
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                <select
                                    value={this.state.dateFilter}
                                    onChange={this.dateChanged}>
                                    {
                                        this.state.monthlyReports.map((monthlyReport) => (
                                            <option key={monthlyReport._id} value={monthlyReport._id}>{monthlyReport.monthly_report_pretty_date}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </h1>

                <h3 className="sectionTitle">Müşteriler</h3>
                <div className="columns is-multiline">
                    <div className="column is-6-desktop is-12-tablet is-12 dashboard-box">
                        <div className="box dashboard-box">
                            <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                            <div className="heading" lang="tr">Ay Sonu Toplam Müşteri</div>
                            <div className="title">{getPrettyNumber(this.state.selectedMonthlyReport.total_customers)}</div>
                            {this.getPreviousMonthDataFor("total_customers")}
                        </div>
                    </div>
                    <div className="column is-6-desktop is-12-tablet is-12 dashboard-box">
                        <div className="box dashboard-box">
                            <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                            <div className="heading" lang="tr">Yeni Müşteri</div>
                            <div className="title">{getPrettyNumber(this.state.selectedMonthlyReport.new_customers)}</div>
                            {this.getPreviousMonthDataFor("new_customers")}
                        </div>
                    </div>
                    <div className="column is-4-desktop is-12-tablet is-12 dashboard-box">
                        <div className="box dashboard-box">
                            <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                            <div className="heading" lang="tr">Yeni Müşteri E-posta</div>
                            <div className="title">{getPrettyNumber(this.state.selectedMonthlyReport.new_customers_with_email)}</div>
                            {this.getPreviousMonthDataFor("new_customers_with_email")}
                        </div>
                    </div>
                    <div className="column is-4-desktop is-12-tablet is-12 dashboard-box">
                        <div className="box dashboard-box">
                            <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                            <div className="heading" lang="tr">Yeni Erkek Müşteri</div>
                            <div className="title">{getPrettyNumber(this.state.selectedMonthlyReport.new_male_customers)}</div>
                            {this.getPreviousMonthDataFor("new_male_customers")}
                        </div>
                    </div>
                    <div className="column is-4-desktop is-12-tablet is-12 dashboard-box">
                        <div className="box dashboard-box">
                            <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                            <div className="heading" lang="tr">Yeni Kadın Müşteri</div>
                            <div className="title">{getPrettyNumber(this.state.selectedMonthlyReport.new_female_customers)}</div>
                            {this.getPreviousMonthDataFor("new_female_customers")}
                        </div>
                    </div>
                </div>

                <h3 className="sectionTitle">
                    Veri Girişleri
                    <a
                        className="button is-info sectionAction"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`${endPoint}/reports/monthly-reports/${this.state.selectedMonthlyReport._id}/event-type-detail`}>
                        Aylık Veri Giriş Raporu
                    </a>
                </h3>
                <div className="columns is-multiline">
                    {
                        this.state.selectedMonthlyReport.sources.map((source) => (
                            <div key={source._id} className="column is-6-desktop is-12-tablet is-12 dashboard-box">
                                <div className="box dashboard-box">
                                    <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                                    <div className="heading" lang="tr">{source.title}</div>
                                    <div className="title">{getPrettyNumber(source.count)}</div>
                                    {this.getPreviousMonthDataFor(`source.${source._id}`)}
                                </div>
                            </div>
                        ))
                    }
                </div>

                <h3 className="sectionTitle">{`E-posta Gönderimleri (${this.state.selectedMonthlyReport.new_email_notifications.length})`}</h3>
                <div className="columns is-multiline">
                    {
                        this.state.selectedMonthlyReport.new_email_notifications.map((emailNotification) => (
                            <div key={emailNotification._id} className="column is-6-desktop is-12-tablet is-12 dashboard-box">
                                <div className="box dashboard-box">
                                    <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                                    <div className="heading" lang="tr">{emailNotification.title}</div>
                                    <div className="title">{getPrettyNumber(emailNotification.count)}</div>
                                    <div className="subtitle">
                                        {`Gönderim Tarihi: ${emailNotification.send_date_as_string}`}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <h3 className="sectionTitle">{`Sms Gönderimleri (${this.state.selectedMonthlyReport.new_sms_notifications.length})`}</h3>
                <div className="columns is-multiline">
                    {
                        this.state.selectedMonthlyReport.new_sms_notifications.map((smsNotification) => (
                            <div key={smsNotification._id} className="column is-6-desktop is-12-tablet is-12 dashboard-box">
                                <div className="box dashboard-box">
                                    <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                                    <div className="heading" lang="tr">{smsNotification.title}</div>
                                    <div className="title">{getPrettyNumber(smsNotification.count)}</div>
                                    <div className="subtitle">
                                        {`Gönderim Tarihi: ${smsNotification.send_date_as_string}`}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <h3 className="sectionTitle">Diğer</h3>
                <div className="columns is-multiline">
                    <div className="column is-4-desktop is-12-tablet is-12 dashboard-box">
                        <div className="box dashboard-box">
                            <span className="tag is-light is-normal">{this.state.selectedMonthlyReport.monthly_report_pretty_date}</span>
                            <div className="heading" lang="tr">Yeni Şikayet/Öneri</div>
                            <div className="title">{getPrettyNumber(this.state.selectedMonthlyReport.new_feedback)}</div>
                            {this.getPreviousMonthDataFor("new_feedback")}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render() {

        return (
            <>
                <div className="columns is-multiline">
                    <div className="column is-12">
                        <div className="notification is-warning">
                            {"Aylık raporlar takip eden ayın 1. günü sabahı hesaplanır ve sisteme kaydedilir. "}
                        </div>
                    </div>
                </div>

                <div className="dashboard">
                    {
                        this.state.monthlyReports && this.state.monthlyReports.length > 0 ? this.getMonthlyReport() : null
                    }
                </div>
            </>
        );
    }
}
