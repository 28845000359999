module.exports = [{
  display: "SMS kabul ediyor ?",
  field: "customer_allows_sms",
  type: "boolean",
  value: true
}, {
  display: "Iletisim Izni ?",
  field: "customer_accepts_kvkk",
  type: "boolean",
  value: true
}, {
  display: "Telefon",
  field: "customer_phone",
  type: "exists"
}];