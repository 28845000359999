import React, {PureComponent} from "react";
import {Redirect, Route} from "react-router-dom";

import withContext from "../../context/withContext";
import PropTypes from "prop-types";

class PrivateRoute extends PureComponent {

    static propTypes = {

        authContext: PropTypes.shape({
            admin: PropTypes.shape()
        }),
        component: PropTypes.func,
        // React router
        location: PropTypes.shape({
            state: PropTypes.shape()
        }),
    };

    render() {

        const isAuthenticated = this.props.authContext.admin;
        const Component = this.props.component;
        const rest = {
            ...this.props,
            component: undefined
        };

        if (!isAuthenticated) {
            return (
                <Redirect to={{pathname: "/login", state: {from: this.props.location}}}/>
            );
        }


        return (
            <Route
                {...rest}
                render={(props) => (
                    <Component {...props}/>
                )}
            />
        );
    }
}

export default withContext(PrivateRoute);
