import React from "react";
import PropTypes from "prop-types";

import formatDatetime from "../../../services/utils/format_datetime";
import formatPrice from "../../../../../common/format_price";

export default class CustomerEventsPayments extends React.Component {
    static get propTypes() {
        return {
            customer: PropTypes.object.isRequired
        };
    }

    render() {
        return (
            <div className="tabContainer customerActivities">
                <div className="columns">
                    <div className="column">
                        <ul>
                            <li>
                                <h5>TOPLAM HARCAMA:</h5>
                                {this.props.customer.customer_total_event_payment ?
                                    <span>
                                        {formatPrice(this.props.customer.customer_total_event_payment.total)}
                                    </span>
                                    :
                                    <span>0 TL</span>}
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div className="timelineSection">
                    <div className="timelineLine"/>
                    {
                        this.props.customer.customer_events_payments
                            .map((customerEventPayment) => {

                                return (
                                    <div key={customerEventPayment._id} className="timelineItem">
                                        <div className="timelineIcon">
                                            <span><i className="fa fa-user"/></span>
                                        </div>
                                        <div className="timelinePreHeader">{formatDatetime(customerEventPayment.updatedAt)}</div>
                                        <div className="timelineContent">
                                            {
                                                <>
                                                    <p>{customerEventPayment.payment_event_name}</p>
                                                    <p>{`${customerEventPayment.payment_store_name} (${customerEventPayment.payment_category_names.join(", ")})`}</p>
                                                    <p>{`${customerEventPayment.payment_amount} TL`}</p>
                                                    <p>{formatDatetime(customerEventPayment.payment_date)}</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
