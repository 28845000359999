/**
 * TextInput component
 */
import React from "react";
import PropTypes from "prop-types";
import Cleave from "cleave.js/react";

import InlineError from "../InlineError/InlineError";

const CleaveInput = (props) => (

    <div className={`field ${props.error ? "is-danger" : ""}`}>
        <label className="label" htmlFor={props.inputName}>
            <span>
                {props.displayName}
            </span>
            {
                props.hint
                    ? (
                        <span className="icon has-text-info tooltip is-tooltip-multiline is-tooltip-left" data-tooltip={props.hint}>
                            <i className="fas fa-info-circle"/>
                        </span>
                    ) : null
            }
        </label>
        <div className="control">
            <Cleave
                autoComplete="off"
                type="text"
                placeholder={props.placeholder}
                disabled={props.isSubmitting || props.disabled}
                className="input"
                name={props.inputName}
                id={props.inputName}
                options={props.cleaveOptions}
                onChange={props.onChange}
            />
        </div>

        <InlineError error={props.error}/>
    </div>
);

CleaveInput.propTypes = {
    placeholder: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    hint: PropTypes.string,
    error: PropTypes.string,
    cleaveOptions: PropTypes.shape().isRequired,
    onChange: PropTypes.func.isRequired
};

export default CleaveInput;
