/**
 * EmailNotifications Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import Memoize from "memoize-one";
import {Link} from "react-router-dom";

import apiCall from "../../../services/api";
import RemoveItem from "../../../services/utils/remove_item";
import UpdateItem from "../../../services/utils/update_item";
import withContext from "../../../context/withContext";
import {CrmTable, NotificationFilterDetails, NotificationStatus, TestModal} from "../../../components";
import {endPoint} from "../../../services/constants";

class EmailNotifications extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        showNamedConfirm: PropTypes.func.isRequired,
        showConfirm: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        emailNotifications: null,

        // COMPONENT
        showTestModal: false,
        searchText: ""
    };

    filterEmailNotifications = Memoize((emailNotifications, searchText) => {

        if (!emailNotifications) {
            return emailNotifications;
        }

        const trimmedSearchText = searchText.trim();
        if (!trimmedSearchText) {
            return emailNotifications;
        }

        const regex = new RegExp(`${trimmedSearchText}`, "i");
        return emailNotifications.filter((emailNotification) => emailNotification.email_notification_subject.match(regex));
    });

    componentDidMount() {

        this.props.setBreadcrumb([{
            url: "/marketing/email-notifications",
            name: "E-posta Gönderimi"
        }]);

        this.getEmailNotificationsAsync();
    }

    async getEmailNotificationsAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/marketing/email-notifications"});

            this.setState((prevState) => ({
                ...prevState,
                emailNotifications: data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }


    showDeleteConfirm = (emailNotification) => {

        this.props.showNamedConfirm({
            validateText: emailNotification.email_notification_subject,
            children: (
                <>
                    <div className="notification is-warning is-fixed-to-body">
                        {"Bu silme işlemi "}
                        <strong>GERİ ALINAMAZ.</strong>
                    </div>
                    <p>
                        {"Bu gönderimi: ("}
                        <strong>{emailNotification.email_notification_subject}</strong>
                        {") silerek, gönderim yapıldıysa raporlar dahil, tüm sistemden sileceksiniz."}
                    </p>
                    <p>
                        {"E-posta gönderimini silmek için aşağıdaki alana e-postanın tam başlığını giriniz."}
                    </p>
                </>
            ),
            asyncFunction: async () => {

                try {

                    await apiCall({
                        url: `/marketing/email-notifications/${emailNotification._id}`,
                        method: "DELETE",
                        toast: `E-posta Gönderimi: '${emailNotification.email_notification_subject}' silindi.`
                    });

                    this.setState((prevState) => ({
                        ...prevState,
                        emailNotifications: RemoveItem(prevState.emailNotifications, emailNotification._id)
                    }));
                }
                catch (e) {

                    this.props.setError(e);
                }
            }
        });
    };

    handleSearch = (e) => {

        const searchText = e.target.value.trimStart();

        this.setState((prevState) => ({
            ...prevState,
            searchText
        }));
    };

    showTestModal = (emailNotification) => {

        this.setState((prevState) => ({
            ...prevState,
            showTestModal: true,
            sendTestEmail: (inputValue) => {

                return apiCall({
                    url: `/marketing/email-notifications/${emailNotification._id}/test`,
                    method: "PUT",
                    payload: {
                        emails: inputValue
                    },
                    toast: "Test gönderimi başarılı. İletim süresi 5 dakikayı bulabilir."
                });
            }
        }));
    };

    showSendConfirm = (emailNotification) => {

        this.props.showConfirm({
            title: emailNotification.email_notification_subject,
            message: "Bu kampanya için GERÇEK gönderim yapacaksınız, onaylıyor musunuz? (Daha önce test göndermediyseniz, ilk önce test etmelisiniz.)",
            buttons: [{
                title: "Tamamdır, ŞİMDİ GÖNDER!",
                asyncFn: async () => {

                    // START LOADING
                    this.props.loadingContext.setIsFullScreenLoading(true);

                    try {

                        await apiCall({
                            url: `/marketing/email-notifications/${emailNotification._id}/send`,
                            method: "PUT",
                            toast: "Email gönderiniz başarıyla oluşturulmuştur. Yetkili kişi, mail adresine iletilen maile onay verdiğinde gönderime hazır hale gelecektir."
                        });

                        this.setState((prevState) => ({
                            ...prevState,
                            emailNotifications: UpdateItem(prevState.emailNotifications, {
                                ...emailNotification,
                                email_notification_status: "WAITING-FOR-APPROVAL"
                            })
                        }));
                    }
                    catch (e) {
                        this.props.setError(e);
                    }

                    // STOP LOADING
                    this.props.loadingContext.setIsFullScreenLoading(false);
                }
            }]
        });
    };

    getEmailNotificationTableColumns() {
        return [
            {
                Header: "Konu Başlığı",
                accessor: "email_notification_subject",
                sortable: true,
            },
            {
                Header: "Filtreler",
                id: "email_notification_filters",
                accessor: (props) => props,
                sortable: false,
                Cell: (props) => (
                    <NotificationFilterDetails
                        type="EMAIL"
                        filters={props.value.email_notification_filters}
                        status={props.value.email_notification_status}
                        potential={props.value.email_notification_potential}
                        actual={props.value.email_notification_actual}
                        externalLists={props.value.email_external_lists && props.value.email_external_lists.map((email_external_list) => ({
                            name: email_external_list.list_name,
                            count: email_external_list.list_count
                        }))}
                    />
                )
            },
            {
                Header: "Durum?",
                id: "email_notification_sent_at",
                className: "will-overflow",
                maxWidth: 250,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (
                    <NotificationStatus
                        error={props.value.email_notification_error}
                        status={props.value.email_notification_status}
                        createdBy={props.value.email_notification_created_by}
                        createdAt={props.value.createdAt}
                        sentBy={props.value.email_notification_sent_by}
                        sentAt={props.value.email_notification_sent_end_at}
                        scheduledFor={props.value.email_notification_scheduled_for_datetime}
                    />
                )
            },
            {
                Header: "Aksiyon",
                id: "actions",
                maxWidth: 250,
                sortable: false,
                accessor: (props) => props,
                Cell: (props) => (

                    <div className="columns is-multiline notification-list-actions">

                        <div className="column is-12">
                            <a className="button" target="_blank" rel="noopener noreferrer" href={`${endPoint}/marketing/email-notifications/${props.value._id}/display`}>
                                Görüntüle
                            </a>
                        </div>

                        {
                            props.value.email_notification_status === "CREATED" || props.value.email_notification_status === "SCHEDULED" ? (
                                <>
                                    <div className="column is-12">
                                        <button type="button" tabIndex="0" className="button is-primary" onClick={() => {
                                            this.showTestModal(props.value);
                                        }}>
                                            Test Gönder
                                        </button>
                                    </div>

                                    {
                                        props.value.email_notification_status !== "SCHEDULED" && 
                                        <div className="column is-12">
                                            <button type="button" tabIndex="0" className="button is-link" onClick={() => {
                                                this.showSendConfirm(props.value);
                                            }}>
                                            Şimdi Gönder
                                            </button>
                                        </div>}

                                    {/* <div className="column is-12">
                                        <Link tabIndex="0" className="button" to={`/marketing/email-notifications/${props.value._id}/edit`}>
                                            Düzenle
                                        </Link>
                                    </div> */}
                                </>
                            ) : null
                        }

                        {

                            props.value.email_notification_status === "SENT" ? (
                                <div className="column is-12">
                                    <Link tabIndex="0" className="button is-primary" to={`/marketing/email-notifications/${props.value._id}/report`}>
                                        Rapor
                                    </Link>
                                </div>
                            ) : null
                        }
                        {
                            props.value.email_notification_status !== "SEND-IN-PROGRESS" ? (
                                <div className="column is-12">
                                    <button type="button" tabIndex="0" className="button is-danger" onClick={() => {
                                        this.showDeleteConfirm(props.value);
                                    }}>
                                        Sil
                                    </button>
                                </div>
                            ) : null
                        }

                    </div>
                )
            }
        ];
    }

    render() {

        if (!this.state.emailNotifications) {
            return null;
        }

        return (
            <>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <div className="control has-icons-left">

                                    <input
                                        type="text"
                                        className="input"
                                        placeholder="Konu başlığıyla ile arayın"
                                        onChange={this.handleSearch}
                                        value={this.state.searchText}
                                    />

                                    <span className="icon is-small is-left">
                                        <i className="fa fa-search"/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="level-right">
                        <div className="level-item">
                            <Link
                                tabIndex="0"
                                className="button"
                                to="/marketing/email-notifications/new">
                                <span className="icon is-medium">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span>Yeni E-Posta Gönderimi</span>
                            </Link>
                        </div>
                    </div>
                </div>

                <CrmTable
                    columns={this.getEmailNotificationTableColumns()}
                    data={this.filterEmailNotifications(this.state.emailNotifications, this.state.searchText)}
                    defaultSorted={[{
                        id: "-createdAt",
                        desc: false
                    }]}
                />

                {
                    this.state.showTestModal ? (

                        <TestModal
                            type="Email"
                            isVisible={!!this.state.showTestModal}
                            onClose={() => {

                                this.setState((prevState) => ({
                                    ...prevState,
                                    showTestModal: false
                                }));
                            }}
                            onSendAsync={this.state.sendTestEmail}
                        />

                    ) : null
                }
            </>
        );
    }
}

export default withContext(EmailNotifications);
