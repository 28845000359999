/**
 * Confirm modal component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

class TestModal extends Component {

    static propTypes = {

        isVisible: PropTypes.bool.isRequired,
        onSendAsync: PropTypes.func,
        onClose: PropTypes.func,
        operationInProgress: PropTypes.bool,
        type: PropTypes.oneOf(["Sms", "Email", "Push"])
    };

    static defaultProps = {

        title: "Emin misiniz?",
        confirmText: "Tamamdır, TEST gönder!"
    };

    _isMounted = false;

    state = {
        error: null,
        inputValue: "",
        operationInProgress: false,
    };

    componentDidMount() {

        this._isMounted = true;
    }

    componentWillUnmount() {

        this._isMounted = false;
    }

    onSendAsync = async () => {

        this.setState((prevState) => ({
            ...prevState,
            operationInProgress: true,
            error: null
        }));

        try {
            await this.props.onSendAsync(this.state.inputValue);
        }
        catch (e) {

            this.setState((prevState) => ({
                ...prevState,
                error: e.message || e,
                operationInProgress: false
            }));

            return;
        }

        if (this._isMounted) {
            this.setState((prevState) => ({
                ...prevState,
                inputValue: "",
                operationInProgress: false
            }));

            this.props.onClose();
        }
    };

    render() {

        if (!this.props.isVisible) {
            return null;
        }

        let placeholder;
        switch (this.props.type) {
            case "Email":
                placeholder = "E-posta Adresleri";
                break;
            case "Push":
                placeholder = "Telefon Numaraları";
                break;
            case "Sms":
                placeholder = "Telefon Numaraları";
                break;
            default:
                placeholder = "Something goes wrong";
        }

        return (

            <div className="modal-container">
                <div
                    className={`modal confirm-modal is-active ${this.state.operationInProgress ? "operation-in-progress" : ""}`}>
                    <div
                        role="presentation"
                        className="modal-background"
                        onClick={() => {
                            if (!this.props.operationInProgress) {
                                this.props.onClose();
                            }
                        }}
                    />
                    <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Test Gönderimi</p>
                            <button
                                type="button"
                                tabIndex="0"
                                className="delete hot-fix"
                                onClick={() => {
                                    if (!this.props.operationInProgress) {
                                        this.props.onClose();
                                    }
                                }}
                            />
                        </header>
                        <section className="modal-card-body">

                            {
                                this.props.type === "Push" ? (
                                    <>
                                        <p>
                                            Test gönderimi yapmak istediğiniz numaraları +905... formatında virgül (,) ile ayırarak boşluksuz giriniz. Örnek:
                                        </p>
                                        <div className="notification is-warning is-fixed-to-body">
                                            +90532XXXYYZZ,+90532AAABBCC
                                        </div>

                                        <div className="notification is-danger is-fixed-to-body">
                                            Girilen telefon numaralarına ait push token değeri varsa test gönderimi yapılacaktır.
                                        </div>
                                    </>

                                ) : null
                            }

                            {
                                this.props.type === "Sms" ? (
                                    <>
                                        <p>
                                            Test gönderimi yapmak istediğiniz numaraları +905... formatında virgül (,) ile ayırarak boşluksuz giriniz. Örnek:
                                        </p>
                                        <div className="notification is-warning is-fixed-to-body">
                                            +90532XXXYYZZ,+90532AAABBCC
                                        </div>
                                    </>

                                ) : null
                            }

                            {
                                this.props.type === "Email" ? (
                                    <>
                                        <p>
                                            Test gönderimi yapmak istediğiniz e-postaları virgül (,) ile ayırarak boşluksuz giriniz.
                                        </p>
                                        <p>
                                            <h5>
                                                <strong>
                                                    KNS.AE uzantılı e-posta adreslerine gönderim yapılamamaktadır. Başka e-posta adresi ile deneyebilirsiniz.
                                                </strong>
                                            </h5>
                                        </p>
                                        <div className="notification is-warning is-fixed-to-body">
                                            ali@abc.com.tr,veli@gmail.com
                                        </div>
                                    </>
                                ) : null
                            }

                            <div className="control">
                                <input
                                    className="input is-medium"
                                    name="name"
                                    type="text"
                                    autoComplete="off"
                                    placeholder={placeholder}
                                    value={this.state.inputValue}
                                    disabled={this.props.operationInProgress}
                                    onChange={(e) => {

                                        const {value} = e.currentTarget;

                                        this.setState((prevState) => ({
                                            ...prevState,
                                            inputValue: value
                                        }));
                                    }}
                                />
                            </div>

                            {
                                this.state.error ? (
                                    <div className="notification is-danger is-fixed-to-body" style={{marginTop: "1rem"}}>
                                        {this.state.error}
                                    </div>
                                ) : null
                            }
                        </section>
                        <footer className="modal-card-foot" style={{
                            alignItems: "center",
                            justifyContent: "flex-end"
                        }}>
                            <button
                                type="button"
                                color="button is-danger"
                                className={`button is-fullwidth is-size-5 has-text-weight-bold is-info ${this.state.operationInProgress ? "is-loading" : null}`}
                                onClick={() => {
                                    if (!this.props.operationInProgress) {
                                        this.onSendAsync();
                                    }
                                }}
                                disabled={this.props.operationInProgress}>
                                Tamamdır, TEST gönder!
                            </button>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}

export default TestModal;
