/**
 * ToastContent component
 */

import React from "react";
import PropTypes from "prop-types";

const ToastContent = ({title, message}) => (
    <div className="kns-toast-container">
        <h1>{title}</h1>
        <p>{message}</p>
    </div>
);

ToastContent.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
};

export default ToastContent;
