import Districts from "../../../../common/districts";

export default () => {
    return [{
        display: "Etiket",
        field: "customer_tags",
        allowsMultiple: true,
        max: 5,
        filters: [{
            type: "includes",
            input: "AsyncSelect",
            endpoint: "/data/tag-types",
            foreignField: "tag_type_name",
        }, {
            type: "not-includes",
            input: "AsyncSelect",
            endpoint: "/data/tag-types",
            foreignField: "tag_type_name",
        }]
    }, {
        display: "Veri Giriş Yeri",
        field: "customer_events",
        allowsMultiple: true,
        max: 5,
        filters: [{
            type: "includes",
            input: "AsyncSelect",
            endpoint: "/data/event-types",
            foreignField: "event_type_name",
        }, {
            type: "not-includes",
            input: "AsyncSelect",
            endpoint: "/data/event-types",
            foreignField: "event_type_name",
        }]
    }, {
        display: "İlçe",
        field: "customer_district",
        allowsMultiple: true,
        max: 5,
        filters: [{
            type: "includes",
            input: "Select",
            options: Districts.map((district) => ({label: district, value: district})),
        }]
    }, {
        display: "Kayıt Tarihi",
        field: "createdAt",
        filters: [{
            type: "before",
            input: "DateInput"
        }, {
            type: "after",
            input: "DateInput"
        }],
        allowsMultiple: false
    }, {
        display: "Harcama Mağazası",
        field: "customer_total_event_payment.stores",
        allowsMultiple: false,
        filters: [{
            type: "includes",
            input: "AsyncSelect",
            endpoint: "/data/stores",
            foreignField: "store_name",
        }]
    }, {
        display: "Harcama Kategorisi",
        field: "customer_total_event_payment.categories",
        allowsMultiple: false,
        filters: [{
            type: "includes",
            input: "AsyncSelect",
            endpoint: "/data/payment-categories",
            foreignField: "category_name",
        }]
    }, {
        display: "Harcama Etkinliği",
        field: "customer_total_event_payment.events",
        allowsMultiple: false,
        filters: [{
            type: "includes",
            input: "AsyncSelect",
            endpoint: "/data/event-types",
            foreignField: "event_type_name",
        }, {
            type: "not-includes",
            input: "AsyncSelect",
            endpoint: "/data/event-types",
            foreignField: "event_type_name",
        }]
    }, {
        display: "Harcama Tutarı",
        field: "customer_total_event_payment.total",
        allowsMultiple: false,
        filters: [{
            type: "greater",
            input: "NumberInput"
        }, {
            type: "less",
            input: "NumberInput"
        }]
    }, {
        display: "Harcama Tarihi",
        field: "payment_date",
        filters: [{
            type: "before",
            input: "DateInput"
        }, {
            type: "after",
            input: "DateInput"
        }],
        allowsMultiple: false,
    }, {
        display: "Fatura Adedi",
        field: "customer_total_event_payment_count",
        filters: [{
            type: "greater",
            input: "NumberInput"
        }, {
            type: "less",
            input: "NumberInput"
        }],
        allowsMultiple: false,
    }, {
        display: "Kupon Adedi",
        field: "customer_total_coupons_count",
        filters: [{
            type: "greater",
            input: "NumberInput"
        }, {
            type: "less",
            input: "NumberInput"
        }],
        allowsMultiple: false,
    }, {
        display: "E-posta",
        field: "customer_email",
        filters: [{
            type: "exists"
        }],
        allowsMultiple: false
    }, {
        display: "Cinsiyet",
        field: "customer_gender",
        filters: [{
            type: "equals",
            input: "GenderRadio"
        }],
        allowsMultiple: false
    }, {
        display: "Yaş",
        field: "customer_birthdate",
        filters: [{
            type: "exists"
        }, {
            type: "greater",
            input: "NumberInput"
        }, {
            type: "less",
            input: "NumberInput"
        }],
        allowsMultiple: false
    }, {
        display: "İletişim İzni ?",
        field: "customer_accepts_kvkk",
        filters: [{
            type: "boolean",
            input: "BooleanSwitch"
        }],
        allowsMultiple: false
    }, {
        display: "SMS kabul ediyor ?",
        field: "customer_allows_sms",
        filters: [{
            type: "boolean",
            input: "BooleanSwitch"
        }],
        allowsMultiple: false
    }, {
        display: "E-posta kabul ediyor ?",
        field: "customer_allows_email",
        filters: [{
            type: "boolean",
            input: "BooleanSwitch"
        }],
        allowsMultiple: false
    }, {
        display: "Turist Mi?",
        field: "customer_is_tourist",
        filters: [{
            type: "boolean",
            input: "BooleanSwitch"
        }],
        allowsMultiple: false
    }];
};
