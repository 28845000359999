/**
 * TextInput component
 */
import React from "react";
import PropTypes from "prop-types";

import InlineError from "../InlineError/InlineError";
import Hint from "../Hint/Hint";

const TextInput = (props) => (


    <div className={`field ${props.error ? "is-danger" : ""}`}>
        {
            props.displayName ? (
                <label className="label" htmlFor={props.inputName}>
                    <span>
                        {props.displayName}
                    </span>
                    <Hint hint={props.hint} hintAsLabelExplanation={props.hintAsLabelExplanation}/>
                </label>
            ) : null
        }

        <div className="control">
            <input
                autoComplete={props.autoComplete}
                type={props.type}
                step={props.step}
                disabled={props.readOnly ? true : (props.isSubmitting || props.disabled)}
                readOnly={props.readOnly}
                className="input"
                name={props.inputName}
                id={props.inputName}
                value={props.value}
                placeholder={props.placeholder || props.displayName}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </div>

        <InlineError error={props.error}/>
    </div>
);

TextInput.defaultProps = {
    type: "text",
    autoComplete: "on"
};

TextInput.propTypes = {
    type: PropTypes.string,
    step: PropTypes.string,
    autoComplete: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hint: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    hintAsLabelExplanation: PropTypes.bool
};

export default TextInput;
