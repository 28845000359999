import React from "react";

export default function FullScreenLayout(Child) {

    function fullScreenLayout(props) {

        return (

            <section className="hero is-fullheight is-fullscreen-auth">
                <div className="hero-body">
                    <div className="container is-fluid">
                        <div className="columns is-centered">
                            <div className="column is-one-quarter-fullhd is-two-fifths-desktop is-half-tablet">
                                <div className="box">
                                    <Child {...props}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    fullScreenLayout.displayName = "FullScreenLayout";

    return fullScreenLayout;
}
