/**
 * Submit component contains
 * submit & cancel buttons.
 */

/**
 * Submit component
 */

import React from "react";
import PropTypes from "prop-types";

const Button = ({classNames, loading, onClick, iconClass, text, children, ...otherProps}) => {

    const buttonClasses = ["button", ...classNames];
    if (loading) {
        buttonClasses.push("is-loading");
    }

    return (

        <div className="field" style={{paddingTop: "1rem"}}>
            <div className="control">
                <button
                    type="button"
                    onClick={onClick ? onClick : undefined}
                    className={buttonClasses.join(" ")}
                    {...otherProps}>

                    {
                        iconClass && (
                            <span className="icon is-medium">
                                <i className={iconClass}/>
                            </span>
                        )
                    }
                    {text || children}
                </button>
            </div>
        </div>
    );
};

Button.defaultProps = {
    text: "",
    classes: []
};

Button.propTypes = {

    classNames: PropTypes.arrayOf(PropTypes.string),
    iconClass: PropTypes.string,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    loading: PropTypes.bool
};

export default Button;
