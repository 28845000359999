import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";

import {RoleAuth} from "../../components";
import withContext from "../../context/withContext";

class Home extends PureComponent {

    static propTypes = {

        // Context
        authContext: PropTypes.shape({
            admin: PropTypes.shape(),
        })
    };

    render() {

        return (

            <>
                <RoleAuth admin={this.props.authContext.admin} maxRole="CrmDataCollector">
                    <Redirect to="/customer-entry"/>
                </RoleAuth>

                <RoleAuth admin={this.props.authContext.admin} minRole="CrmAdmin">
                    <Redirect to="/dashboard"/>
                </RoleAuth>
            </>
        );
    }
}

export default withContext(Home);
