/**
 * CustomerTagAdd Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import TagTypeForm from "./TagTypeForm";

class TagTypeAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/data/tag-types",
            name: "Müşteri Etiketleri (Tags)"
        }, {
            url: "/data/tag-types/new",
            name: "Yeni Etiket"
        }]);
    }

    async createTagTypeAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: "/data/tag-types",
                method: "POST",
                payload,
                toast: `Etiket oluşturuldu: ${payload.tag_type_name}`
            });

            this.props.history.replace("/data/tag-types");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createTagTypeAsync(formData, formikBag);
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Yeni Müşteri Etiketi
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Etiket Tipi sadece ingilizce karakterler, kısa çizgi, boşluk ve rakam içerebilir.",
                                "Etiket Tipi sistem genelinde benzersiz olmalıdır, aynı isme sahip ikinci etiket tipini ekleyemezsiniz.",
                                "Örnek etiket Tipleri (TAG): 'WIFI', 'AVM ICI ETKINLIKLER', 'MOBILE APP'",
                                "Tüm kurallar sistemde tanımlamıştır, aşağıda test edebilir, hata almadığınız her değeri girebilirsiniz."
                            ]}
                            />
                        </div>

                        <TagTypeForm
                            tagType={{}}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(TagTypeAdd);


