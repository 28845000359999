/**
 * EventType Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import {FormElements} from "../../components";
import {upperCaseText} from "../../services/utils/text_transforms";
import genderOptions from "../../services/utils/gender_options";
import feedbackTypes from "../../services/utils/feedback_types";
import withContext from "../../context/withContext";
import transformSelectInitialValue from "../../services/utils/transform_select_initial_value";

class FeedbackForm extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),

        customerPhone: PropTypes.string.isRequired,
        customer: PropTypes.any,
        feedback: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        isEdit: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        customer: null,
        feedback: {},
        isEdit: false,
        handleSubmit: () => console.log("handle submit unbinded.")
    };

    // Second parameter is formikBag, containing
    // access to formik properties
    handleFeedbackSubmit = ({feedback_tag, feedback_type, ...formData}, formikBag) => {

        const payload = {
            ...formData,
            customer_id: this.props.customer && this.props.customer._id,
            feedback_tag: feedback_tag && feedback_tag.value,
            feedback_type: feedback_type && feedback_type.value
        };

        this.props.handleSubmit(payload, formikBag);
    };

    render() {

        const {feedback, customer, customerPhone, isEdit} = this.props;
        if (!feedback) {
            return null;
        }

        return (

            <Formik
                enableReinitialize
                initialValues={{
                    customer_phone: customerPhone,
                    customer_name: (customer && customer.customer_name) || "",
                    customer_surname: (customer && customer.customer_surname) || "",
                    customer_gender: (customer && customer.customer_gender) || "",
                    feedback_tag: transformSelectInitialValue(feedback.tag, "feedback_tag_type_name"),
                    feedback_type: feedback.feedback_type || "",
                    feedback_content: feedback.feedback_content || "",
                    feedback_is_resolved: feedback.feedback_is_resolved || false,
                    feedback_sms_enabled: !isEdit,
                }}
                validationSchema={() => Yup.object().shape({
                    customer_name: Yup.string()
                        .min(2, "İsim bilgisi en az 2 karakter içermelidir.")
                        .required("İsim bilgisi zorunludur."),
                    customer_surname: Yup.string()
                        .min(2, "Soyisim bilgisi en az 2 karakter içermelidir.")
                        .required("Soyisim bilgisi zorunludur."),
                    customer_gender: Yup.string()
                        .test("genderEnumCheck", "Cinsiyet bilgisi zorunludur.", (gender) => gender && (gender === "male" || gender === "female")),
                    feedback_tag: Yup.string()
                        .min(1)
                        .required("Geri Bildirim Etiketi zorunlu bir alandır."),
                    feedback_type: Yup.string()
                        .min(1)
                        .required("Bildirim türü zorunlu bir alandır."),
                    feedback_content: Yup.string()
                        .min(10, "Mesaj en az 10 karakter içermelidir.")
                        .required("Mesaj alanı zorunlu bir alandır."),
                    feedback_is_resolved: Yup.boolean()
                        .required("."),
                    feedback_sms_enabled: Yup.boolean()
                        .required("SMS seçeneği zorunlu bir alandır.")
                })}
                onSubmit={this.handleFeedbackSubmit}
                render={({
                    touched,
                    values,
                    errors,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting,
                    handleChange,
                    handleBlur
                }) => (
                    <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                        <div className="is-divider" data-content="MÜŞTERİ PROFİLİ"/>

                        <FormElements.TextInput
                            type="text"
                            disabled
                            autoComplete="off"
                            displayName="Cep Telefonu *"
                            inputName="customer_phone"
                            error={touched.customer_phone && errors.customer_phone}
                            isSubmitting={isSubmitting}
                            value={values.customer_phone || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            displayName="İsim *"
                            inputName="customer_name"
                            error={touched.customer_name && errors.customer_name}
                            isSubmitting={isSubmitting}
                            value={values.customer_name || ""}
                            onChange={(e) => {

                                const val = e.target.value;
                                setFieldValue("customer_name", upperCaseText(val));
                                setFieldTouched("customer_name");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            displayName="Soyisim *"
                            inputName="customer_surname"
                            error={touched.customer_surname && errors.customer_surname}
                            isSubmitting={isSubmitting}
                            value={values.customer_surname || ""}
                            onChange={(e) => {

                                const val = e.target.value;
                                setFieldValue("customer_surname", upperCaseText(val));
                                setFieldTouched("customer_surname");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.Radio
                            displayName="Cinsiyet *"
                            inputName="customer_gender"
                            options={genderOptions}
                            error={touched.customer_gender && errors.customer_gender}
                            isSubmitting={isSubmitting}
                            value={values.customer_gender || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <div className="is-divider" data-content="GERİ BİLDİRİM FORMU"/>

                        <FormElements.SelectInputAsync
                            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                            value={values.feedback_tag}
                            defaultValue={feedback.tag}
                            menuPosition="fixed"
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            defaultOptions
                            isMenuOpen
                            error={touched.feedback_tag && errors.feedback_tag}
                            endpoint="/data/feedback-tag-types"
                            foreignField="feedback_tag_type_name"
                            displayName="Geri Bildirim Etiketleri *"
                            placeholder="Eklenmesini istediğiniz geri bildirim etiketini seçin"
                            inputName="feedback_tag"
                            classNamePrefix="react-select"
                            onChange={(selectedOption) => {

                                setFieldValue("feedback_tag", selectedOption);
                                setFieldTouched("feedback_tag");
                            }}
                            onBlur={() => {
                                setFieldTouched("feedback_tag");
                            }}
                        />

                        <FormElements.SelectInput
                            displayName="Geri Bildirim Tipi *"
                            inputName="feedback_type"
                            options={feedbackTypes}
                            error={touched.feedback_type && errors.feedback_type}
                            isSubmitting={isSubmitting}
                            value={values.feedback_type || ""}
                            onChange={(selectedOption) => {

                                setFieldValue("feedback_type", selectedOption);
                                setFieldTouched("feedback_type");
                            }}
                            onBlur={() => {

                                setFieldTouched("feedback_type");
                            }}

                        />

                        <FormElements.TextArea
                            type="text"
                            autoComplete="off"
                            error={touched.feedback_content && errors.feedback_content}
                            inputName="feedback_content"
                            displayName="Müşteri Mesajı *"
                            isSubmitting={isSubmitting}
                            value={values.feedback_content || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            hint="Müşteriden alınan bildirim."
                        />

                        <FormElements.Switch
                            hint="Geri Bildirim Çözümlendi mi?"
                            error={touched.feedback_is_resolved && errors.feedback_is_resolved}
                            inputName="feedback_is_resolved"
                            displayName="Geri Bildirim Çözümlendi mi?"
                            isSubmitting={isSubmitting}
                            value={!!values.feedback_is_resolved}
                            onChange={(e) => {

                                const val = e.target.checked;
                                setFieldValue("feedback_is_resolved", val);
                                setFieldTouched("feedback_is_resolved");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.Switch
                            hint="Müşteriye 'Bildiriminiz alınmıştır, Teşekkür ederiz.' mesajı gitsin mi?'"
                            error={touched.feedback_sms_enabled && errors.feedback_sms_enabled}
                            inputName="feedback_sms_enabled"
                            displayName="Müşteriye kaydınız alındı SMS'i gönder."
                            isSubmitting={isSubmitting}
                            value={!!values.feedback_sms_enabled}
                            onChange={(e) => {

                                const val = e.target.checked;
                                setFieldValue("feedback_sms_enabled", val);
                                setFieldTouched("feedback_sms_enabled");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText={this.props.submitText}
                            isSubmitting={isSubmitting}
                        />
                    </form>
                )}
            />
        );
    }
}

export default withContext(FeedbackForm);
