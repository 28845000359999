/**
 * SmsNotification Add Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import SmsNotificationForm from "./SmsNotificationForm";

class SmsNotificationAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/marketing/sms-notifications",
            name: "SMS Gönderimi"
        }, {
            url: "/marketing/sms-notifications/new",
            name: "Yeni SMS Gönderimi"
        }]);
    }

    async createSmsNotificationAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: "/marketing/sms-notifications",
                method: "POST",
                payload,
                toast: `Yeni SMS gönderimi oluşturuldu: ${payload.sms_notification_text}`
            });

            this.props.history.replace("/marketing/sms-notifications");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createSmsNotificationAsync(formData, formikBag);
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Yeni SMS Gönderimi
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "KVKK kabul etmiş, Cep Telefonu bilgisi olan ve SMS ile iletişim izni hala devam eden müşteriler pre-filtered gelmektedir.",
                                "İleri tarihli gönderimlerin sonradan tarih bilgisini değiştiremezsiniz, tarih değiştirmek istiyorsanız silip tekrar eklemelisiniz.",
                                "Etkili bir gönderim için SMS uzunluğunun 120 karakter aşağısında olması tavsiye edilir."
                            ]}
                            />
                        </div>

                        <SmsNotificationForm
                            setError={this.props.setError}
                            smsNotification={{}}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(SmsNotificationAdd);


