/**
 * Breadcrumb component
 */

import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const Breadcrumb = (props) => {

    if (!props.items || props.items.length === 0) {
        return null;
    }

    return (

        <nav className={`breadcrumb is-fluid has-arrow-separator venuex-breadcrumb ${props.layout}`}>
            <ul>
                {
                    props.items.map((item, index) => (

                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index} className={index === props.items.length - 1 ? "is-active" : ""}>
                            <Link to={item.url} href={item.url}>
                                {item.name}
                            </Link>
                        </li>

                    ))
                }
            </ul>
        </nav>
    );
};

Breadcrumb.propTypes = {

    layout: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string
    }))
};

export default Breadcrumb;
