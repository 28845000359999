/**
 *  SelectInputAsync component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import ReactSelectAsync from "react-select/lib/Async";

import InlineError from "../InlineError/InlineError";
import apiCall from "../../../services/api";
import Hint from "../Hint/Hint";

class SelectInputAsync extends PureComponent {

    state = {
        noOptionsMessage: "Type to search available options"
    };

    handleChange = (value) => {
        // this is going to call setFieldValue and manually update values.{inputName}
        this.props.onChange(value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.{inputName}
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    handleInputChange = (newValue = "") => {

        const inputValue = newValue.trimStart();

        let noOptionsMessage = "No Result";
        if (!inputValue || inputValue.length === 0) {
            noOptionsMessage = "Type to search available options";
        }
        else if (inputValue.length === 1) {
            noOptionsMessage = "Type at least 2 character to retrieve result";
        }

        this.setState({
            noOptionsMessage
        });

        return inputValue;
    };

    loadOptions = async (inputValue) => {

        if (!this.props.defaultOptions && (!inputValue || inputValue.length < 2)) {
            return [];
        }

        const endPoint = inputValue ?
            `${this.props.endpoint}?search=${inputValue}` :
            this.props.endpoint;

        const {data} = await apiCall({
            url: endPoint
        });

        return data.map((dataItem) => ({
            // add all fields for later use maybe ?
            ...dataItem,
            value: this.props.idField ? dataItem[this.props.idField] : dataItem._id,
            label: dataItem[this.props.foreignField],
            isDisabled: !!dataItem.isDisabled
        }));
    };

    render() {

        return (
            <div className={`field ${this.props.error ? "is-danger" : ""}`}>
                <label className="label" htmlFor={this.props.inputName}>
                    <span>
                        {this.props.displayName}
                    </span>
                    <Hint hint={this.props.hint}/>
                </label>

                <ReactSelectAsync
                    key={this.props.endpoint}
                    {...this.props}
                    classNamePrefix="react-select"
                    cacheOptions
                    loadOptions={this.loadOptions}
                    placeholder={this.props.placeholder || this.props.displayName}
                    onBlur={this.handleBlur}
                    onInputChange={this.handleInputChange}
                    noOptionsMessage={() => this.state.noOptionsMessage}
                    onChange={this.handleChange}
                />

                <InlineError error={this.props.error}/>
            </div>
        );
    }
}

SelectInputAsync.defaultProps = {

    customFilter: {
        ignoreCase: true,
        ignoreAccents: true,
        matchStart: true
    }
};

SelectInputAsync.propTypes = {

    defaultOptions: PropTypes.bool,
    inputName: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,

    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,

    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    hint: PropTypes.string,
    error: PropTypes.string,

    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,

    idField: PropTypes.string,
    foreignField: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,

    customFilter: PropTypes.shape({
        ignoreCase: PropTypes.bool,
        ignoreAccents: PropTypes.bool,
        matchStart: PropTypes.bool,
        trim: PropTypes.bool,
    })
};

export default SelectInputAsync;
