/**
 * Spinner component
 */

import React, {Component} from "react";

import Styles from "../../assets/styles/loader.module.css";

export default class Spinner extends Component {

    componentDidMount() {

        document.body.classList.add("modal-open");
    }

    componentWillUnmount() {

        document.body.classList.remove("modal-open");
    }

    render() {

        return (
            <div className={Styles["spinner-container"]}>
                <div className={Styles["lds-dual-ring"]}/>
            </div>
        );
    }
}
