/**
 * Coupon Campaign Add Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {toast as showToast} from "react-toastify";

import {FormElements, ToastContent} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import {Formik} from "formik";
import * as Yup from "yup";

class CouponCodeGenerate extends PureComponent {

  static propTypes = {

      // Context
      loadingContext: PropTypes.shape({
          isLoading: PropTypes.bool.isRequired,
          setIsLoading: PropTypes.func.isRequired
      }),

      // layout hoc
      setBreadcrumb: PropTypes.func.isRequired,
      setError: PropTypes.func.isRequired,

      // react router
      history: PropTypes.shape({
          replace: PropTypes.func
      }),
      match: PropTypes.shape({
          params: PropTypes.objectOf(PropTypes.string)
      })
  };

  componentDidMount() {

      // Update subheader statically
      this.props.setBreadcrumb([{
          url: "/campaign/coupon-campaigns",
          name: "Kupon Kampanyaları"
      }, {
          url: "/campaign/coupon-campaigns/new",
          name: "Kupon Kodu"
      }]);
  }

  async generateCouponCodesAsync(payload, {setSubmitting}) {

      // START LOADING
      setSubmitting(true);
      this.props.loadingContext.setIsLoading(true);

      // API REQUEST
      try {

          const {data} = await apiCall({
              url: "/campaign/coupon-codes/generate-coupons",
              method: "POST",
              payload: {
                  ...payload,
                  couponCampaignId: this.props.match.params["campaignid"]
              },
          });

          showToast.success(<ToastContent message={data.message}/>);

          this.props.history.replace("/campaign/coupon-campaigns");
      }
      catch (e) {

          this.props.setError(e);
      }

      // STOP LOADING
      setSubmitting(false);
      this.props.loadingContext.setIsLoading(false);
  }

  // Second parameter is formikBag, containing
  // access to formik properties
  handleSubmit = (formData, formikBag) => {

      this.generateCouponCodesAsync(formData, formikBag);
  };

  render() {

      const initialValues = {
          couponCodeLimit:  100,
      };

      return (
          <div className="columns is-centered">
              <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                  <div className="box knsForm">
                      <div className="content">
                          <h5>
                              <span className="icon is-small">
                                  <i className="fa fa-angle-double-right"/>
                              </span>
                              <span>
                  Kupon Kodlarını Oluştur
                              </span>
                          </h5>
                          <FormElements.FormExplanation messages={[
                              "Oluşturmak istediğiniz kupon adedini giriniz.",
                              "Formu onayladıktan sonra kuponlar otomatik olarak oluşturulacaktır.",
                              "Bu işlem 2-5 dakika sürebilir, sayfayı yenilemeyi unutmayın.",
                              "Sonrasında kampanya HAZIR durumda olacaktır!",
                          ]}
                          />
                      </div>

                      <Formik
                          initialValues={initialValues}
                          onSubmit={this.handleSubmit}
                          validationSchema={() => Yup.object().shape({
                              couponCodeLimit: Yup.number()
                                  .min(5, "En az 5 adet kupon kodu oluşturabilirsiniz.")
                                  .required("Kupon Kodu Miktarı zorunlu bir alandır.")
                          })}
                          render={({
                              values,
                              errors,
                              touched,
                              handleSubmit,
                              handleBlur,
                              isSubmitting,
                              handleChange
                          }) => (
                              <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                  <FormElements.TextInput
                                      type="number"
                                      autoComplete="off"
                                      error={touched.couponCodeLimit && errors.couponCodeLimit}
                                      inputName="couponCodeLimit"
                                      displayName="Kupon Kodu Miktarı *"
                                      isSubmitting={isSubmitting}
                                      value={values.couponCodeLimit || ""}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                  />

                                  <FormElements.Submit
                                      submitClasses={["full-width"]}
                                      submitText="Oluştur"
                                      isSubmitting={isSubmitting}
                                  />
                              </form>
                          )}
                      />
                  </div>
              </div>
          </div>
      );
  }
}


export default withContext(CouponCodeGenerate);


