import React from "react";
import PropTypes from "prop-types";
import InlineError from "../InlineError/InlineError";
import Hint from "../Hint/Hint";

export default class Switch extends React.PureComponent {

    static propTypes = {
        inputName: PropTypes.string.isRequired,
        displayName: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        isSubmitting: PropTypes.bool,
        hint: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.bool,
        error: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        hintAsLabelExplanation: PropTypes.bool
    };

    render() {

        return (
            <div className={`field ${this.props.error ? "is-danger" : ""}`}>
                <p className="switchText">
                    {this.props.displayName}
                    <Hint hint={this.props.hint} hintAsLabelExplanation={this.props.hintAsLabelExplanation}/>
                </p>
                <input
                    type="checkbox"
                    disabled={this.props.isSubmitting || this.props.disabled}
                    className="switch is-info"
                    name={this.props.inputName}
                    id={this.props.inputName}
                    checked={this.props.value}
                    placeholder={this.props.placeholder || this.props.displayName}
                    onChange={this.props.onChange}
                />
                <label className="label" htmlFor={this.props.inputName}/>
                <InlineError error={this.props.error}/>
            </div>
        );
    }
}
