/**
 * CustomerTagAdd Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import EventTypeForm from "./EventTypeForm";

class EventTypeAdd extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        })
    };

    componentDidMount() {

        // Update subheader statically
        this.props.setBreadcrumb([{
            url: "/data/event-types",
            name: "Veri Giriş Yerleri"
        }, {
            url: "/data/event-types/new",
            name: "Yeni Veri Giriş Yeri"
        }]);
    }

    async createEventTypeAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: "/data/event-types",
                method: "POST",
                payload,
                toast: `Veri giriş yeri oluşturuldu: ${payload.event_type_name}`
            });

            this.props.history.replace("/data/event-types");
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        setSubmitting(false);
        this.props.loadingContext.setIsLoading(false);
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.createEventTypeAsync(formData, formikBag);
    };

    render() {

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Yeni Veri Giriş Yeri
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Veri giriş yeri ismi sadece ingilizce karakterler, kısa çizgi, boşluk ve rakam içerebilir.",
                                "Veri giriş yeri ismi sistem genelinde benzersiz olmalıdır, aynı isme sahip ikinci bir veri giris yeri ekleyemezsiniz.",
                                "Örnek isimler: 'UGUR BOCEGI ILE KARA KEDI - OCAK 2020', 'GUPSE OZAY ILE SOYLESI - ARALIK 2019', 'WIFI - LOGIN' vs.",
                                "Tüm kurallar sistemde tanımlamıştır, aşağıda test edebilir, hata almadığınız her değeri girebilirsiniz."
                            ]}
                            />
                        </div>

                        <EventTypeForm
                            eventType={{}}
                            handleSubmit={this.handleSubmit}
                            submitText="Kaydet"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(EventTypeAdd);


