/**
 * SmsNotification Edit Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import SmsNotificationForm from "./SmsNotificationForm";

class SmsNotificationEdit extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        smsNotification: null
    };

    smsNotificationId = this.props.match.params["smsnotificationid"];

    componentDidMount() {

        this.getSmsNotitificationAsync(); // Update subheader statically
    }

    async getSmsNotitificationAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/marketing/sms-notifications/${this.smsNotificationId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                smsNotification: data
            }));

            this.props.setBreadcrumb([{
                url: "/marketing/sms-notifications",
                name: "SMS Gönderimi"
            }, {
                url: `/marketing/sms-notifications/${this.smsNotificationId}/edit`,
                name: data.sms_notification_title
            }]);
        }
        catch (e) {

            console.log(e);
            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    async updateSmsNotificationAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/marketing/sms-notifications/${this.smsNotificationId}`,
                method: "PUT",
                payload,
                toast: `SMS Gönderimi güncellendi: ${payload.sms_notification_text}`
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/marketing/sms-notifications");
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.updateSmsNotificationAsync(formData, formikBag);
    };

    render() {

        if (!this.state.smsNotification) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    SMS Gönderimi Güncelleme
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "KVKK kabul etmiş, Cep Telefonu bilgisi olan ve SMS ile iletişim izni hala devam eden müşteriler pre-filtered gelmektedir.",
                                "İleri tarihli gönderimlerin sonradan tarih bilgisini değiştiremezsiniz, tarih değiştirmek istiyorsanız silip tekrar eklemelisiniz.",
                                "Etkili bir gönderim için SMS uzunluğunun 120 karakter aşağısında olması tavsiye edilir."
                            ]}
                            />
                        </div>

                        <SmsNotificationForm
                            isEdit
                            setError={this.props.setError}
                            smsNotification={this.state.smsNotification}
                            handleSubmit={this.handleSubmit}
                            submitText="Güncelle"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(SmsNotificationEdit);


