module.exports = [{
  "schema_field": "customer_name",
  "csv_field": "ad",
  "display": "AD",
  "type": "customer_information",
  "required": true
}, {
  "schema_field": "customer_surname",
  "csv_field": "soyad",
  "display": "SOYAD",
  "type": "customer_information",
  "required": true
}, {
  "schema_field": "customer_gender",
  "type": "customer_information",
  "csv_field": "cinsiyet",
  "display": "CİNSİYET",
  "required": true
}, {
  "schema_field": "customer_phone",
  "type": "customer_information",
  "csv_field": "telefon",
  "display": "TELEFON",
  "required": true
}, {
  "schema_field": "customer_email",
  "type": "customer_information",
  "csv_field": "eposta",
  "display": "EPOSTA",
  "required": true
}, {
  "schema_field": "customer_accepts_kvkk",
  "type": "marketting_permission",
  "csv_field": "kvkk_izni",
  "display": "KVKK İZİN",
  "required": true
}, {
  "schema_field": "customer_allows_call",
  "type": "marketting_permission",
  "csv_field": "arama_izni",
  "display": "ARAMA İZİN"
}, {
  "schema_field": "customer_allows_sms",
  "type": "marketting_permission",
  "csv_field": "sms_izni",
  "display": "SMS İZİN"
}, {
  "schema_field": "customer_allows_email",
  "type": "marketting_permission",
  "csv_field": "eposta_izni",
  "display": "EPOSTA İZİN"
}, {
  "schema_field": "customer_accepts_kvkk_at",
  "type": "marketting_permission_date",
  "csv_field": "kvkk_izin_tarihi",
  "display": "KVKK TARİH",
  "required": true
}, {
  "schema_field": "customer_allows_call_at",
  "type": "marketting_permission_date",
  "csv_field": "arama_izin_tarihi",
  "display": "ARAMA TARİH"
}, {
  "schema_field": "customer_allows_sms_at",
  "type": "marketting_permission_date",
  "csv_field": "sms_izin_tarihi",
  "display": "SMS TARİH"
}, {
  "schema_field": "customer_allows_email_at",
  "type": "marketting_permission_date",
  "csv_field": "eposta_izin_tarihi",
  "display": "EPOSTA TARİH"
}];