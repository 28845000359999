/**
 * EventType Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import Moment from "moment-timezone";

import ReasonForSharing from "../../../../../common/reason_for_sharing";
import dateCheck from "../../../services/utils/date_check";

class CustomerExportForm extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),

        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    static defaultProps = {
        customer: null,
        feedback: {},
        isEdit: false,
        handleChange: () => console.log("handle submit unbinded.")
    };

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = ({export_company_staff, export_reason_for_sharing, export_shared_on_date}, formikBag) => {

        this.props.handleSubmit({
            company_staff_id: export_company_staff._id,
            reason_for_sharing:export_reason_for_sharing,
            shared_on_date:export_shared_on_date
        }, formikBag);
    };

    render() {

        return (

            <Formik
                enableReinitialize
                initialValues={{
                    export_company: null,
                    export_company_staff: null,
                    export_reason_for_sharing: null,
                    export_shared_on_date: Moment().format("DD/MM/YYYY")
                }}
                validationSchema={() => Yup.object().shape({
                    export_company: Yup.string()
                        .nullable()
                        .min(1)
                        .required("Şirket zorunlu bir alandır."),
                    export_company_staff: Yup.string()
                        .nullable()
                        .min(1)
                        .required("Personel zorunlu bir alandır."),
                    export_reason_for_sharing: Yup.string()
                        .nullable()
                        .min(1)
                        .required("Personel zorunlu bir alandır."),
                    export_shared_on_date: Yup.string()
                        .test("dateStructureCheck", "Geçersiz bir tarih girdiniz. GG/AA/YYYY formatında olmalıdır.", dateCheck.dateStructureCheck)
                        .test("dateRangeCheck", "Lütfen ileri tarihli bir seçim yapınız.", dateCheck.dateStructureCheck)
                })}
                onSubmit={this.handleSubmit}
                render={({
                    touched,
                    values,
                    errors,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                        <FormElements.SelectInput
                            isDisabled={isSubmitting}
                            displayName="Paylaşma Nedeni"
                            inputName="export_reason_for_sharing"
                            error={errors["export_reason_for_sharing"]}
                            options={ReasonForSharing}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "No result"}
                            placeholder="Select Role"
                            onChange={(selectedOption) => {

                                setFieldValue("export_reason_for_sharing", selectedOption.value);
                                setFieldTouched("export_reason_for_sharing");
                            }}
                            onBlur={() => {
                                setFieldTouched("export_reason_for_sharing", true);
                            }}
                        />

                        <FormElements.DateInput
                            autoComplete="off"
                            displayName="Paylaşma Tarihi"
                            inputName="export_shared_on_date"
                            placeholder="GG/AA/YYYY"
                            error={touched.export_shared_on_date && errors.export_shared_on_date}
                            isSubmitting={isSubmitting}
                            selectedValue={values.export_shared_on_date}
                            onChange={(selectedDate) => {
                                setFieldValue("export_shared_on_date", selectedDate);
                                setFieldTouched("export_shared_on_date");
                            }}
                            onBlur={() => {
                                setFieldTouched("export_shared_on_date", true);
                            }}
                            hint="GG/AA/YYYY şeklinde input girişi yapılmalıdır, örnek: 01/01/2019"
                            minDate={Moment().add(1, "day").toDate()}
                            maxDate={Moment().add(1, "months").toDate()}
                            selectsEnd
                        />

                        <FormElements.SelectInputAsync
                            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                            value={values.export_company}
                            menuPosition="fixed"
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            defaultOptions
                            isMenuOpen
                            error={touched.export_company && errors.export_company}
                            endpoint="/export-logs/company"
                            foreignField="company_name"
                            displayName="Şirketler *"
                            placeholder="Şirket Seçin"
                            inputName="export_company"
                            classNamePrefix="react-select"
                            isClearable
                            onChange={(selectedOption) => {

                                setFieldValue("export_company", selectedOption);
                                setFieldTouched("export_company");

                                setFieldValue("export_company_staff", null);
                                setFieldTouched("export_company_staff");
                            }}
                            onBlur={() => {
                                setFieldTouched("export_company");
                            }}
                        />
                        {
                            values.export_company && values.export_company._id &&
                            <FormElements.SelectInputAsync
                                styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                                value={values.export_company_staff}
                                menuPosition="fixed"
                                menuPlacement="auto"
                                menuPortalTarget={document.body}
                                defaultOptions
                                isMenuOpen
                                error={touched.export_company_staff && errors.export_company_staff}
                                endpoint={`/export-logs/company/${values.export_company._id}/staff`}
                                foreignField="company_staff_full_name"
                                displayName="Personel *"
                                placeholder="Eklenmesini istediğiniz geri bildirim etiketini seçin"
                                inputName="export_company_staff"
                                classNamePrefix="react-select"
                                isClearable
                                onChange={(selectedOption) => {

                                    setFieldValue("export_company_staff", selectedOption);
                                    setFieldTouched("export_company_staff");
                                }}
                                onBlur={() => setFieldTouched("export_company_staff")}
                            />
                        }

                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText={this.props.submitText}
                            isSubmitting={isSubmitting}
                        />
                    </form>
                )}
            />
        );
    }
}

export default withContext(CustomerExportForm);
