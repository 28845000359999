/**
 * EventType Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";

import {FormElements} from "../../../components";
import formatTypeText from "../../../services/utils/format_type_text";
import transformSelectInitialValue from "../../../services/utils/transform_select_initial_value";
import {uiDomain} from "../../../config";

export default class EventTypeForm extends PureComponent {

    static propTypes = {
        eventType: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    handleSubmit = (formData, formikBag) => {

        // Transform Select input data to just ids
        const transformedPayload = {
            ...formData,
            event_type_tag_types: formData["event_type_tag_types"] ? formData["event_type_tag_types"].map((event_type_tag_type) => event_type_tag_type.value) : undefined
        };

        this.props.handleSubmit(transformedPayload, formikBag);
    };

    render() {

        const eventType = this.props.eventType;
        if (!eventType) {
            return null;
        }

        return (
            <Formik
                initialValues={{
                    event_type_name: eventType.event_type_name,
                    event_type_exp: eventType.event_type_exp,
                    event_type_ui_allowed: !!eventType.event_type_ui_allowed,
                    event_type_single_participation: !!eventType.event_type_single_participation,
                    event_type_payment_form_allowed: !!eventType.event_type_payment_form_allowed,
                    event_type_coupon_form_allowed: !!eventType.event_type_coupon_form_allowed,
                    event_type_offline_form: !!eventType.event_type_offline_form,
                    event_type_tag_types: transformSelectInitialValue(eventType.event_type_tag_types, "tag_type_name"),
                }}
                onSubmit={this.handleSubmit}
                validationSchema={() => Yup.object().shape({
                    event_type_name: Yup.string()
                        .min(2, "Veri giriş yeri ismi en az iki karakter içermelidir.")
                        .required("Veri giriş yeri zorunlu bir alandır.")
                        .test("AllowedChars", "Veri giriş yeri ismi sadece ingilizce karakterler, kısa çizgi, boşluk ve rakam içerebilir.", (value) => {

                            return (/^([A-Z0-9]+\s)*[A-Z0-9-+\s]+$/).test(value);
                        }),
                    event_type_tag_types: Yup.array()
                        .min(1, "Veri giriş yeri en az bir adet etiket karakter içermelidir.")
                        .required("Etiket seçimi zorunludur.")
                })}
                render={({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                        <FormElements.TextInput
                            type="text"
                            autoComplete="off"
                            error={touched.event_type_name && errors.event_type_name}
                            inputName="event_type_name"
                            displayName="Veri Giriş Yeri İsmi *"
                            isSubmitting={isSubmitting}
                            value={values.event_type_name || ""}
                            onChange={(e) => {

                                const val = e.target.value;
                                setFieldValue("event_type_name", formatTypeText(val));
                                setFieldTouched("event_type_name");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.SelectInputAsync
                            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
                            value={values.event_type_tag_types}
                            menuPosition="fixed"
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            defaultOptions
                            isMenuOpen
                            error={touched.event_type_tag_types && errors.event_type_tag_types}
                            endpoint="/data/tag-types"
                            foreignField="tag_type_name"
                            displayName="Bağlı Etiketler *"
                            placeholder="Bağlanmasını istediğiniz etiketler"
                            inputName="event_type_tag_types"
                            classNamePrefix="react-select"
                            isMulti={true}
                            onChange={(selectedOption) => {

                                setFieldValue("event_type_tag_types", selectedOption);
                                setFieldTouched("event_type_tag_types");
                            }}
                            onBlur={() => {
                                setFieldTouched("event_type_tag_types");
                            }}
                        />

                        <FormElements.Switch
                            error={touched.event_type_ui_allowed && errors.event_type_ui_allowed}
                            inputName="event_type_ui_allowed"
                            displayName="CRM üzerinden Veri Girişi açık mı?"
                            isSubmitting={isSubmitting}
                            value={values.event_type_ui_allowed}
                            onChange={(e) => {

                                const val = e.target.checked;
                                setFieldValue("event_type_ui_allowed", val);
                                setFieldTouched("event_type_ui_allowed");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.Switch
                            error={touched.event_type_payment_form_allowed && errors.event_type_payment_form_allowed}
                            inputName="event_type_payment_form_allowed"
                            displayName="Veri Girişi sırasında 'HARCAMA' formu gösterilsin mi?"
                            isSubmitting={isSubmitting}
                            value={values.event_type_payment_form_allowed}
                            onChange={(e) => {

                                const val = e.target.checked;
                                setFieldValue("event_type_payment_form_allowed", val);
                                setFieldTouched("event_type_payment_form_allowed");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.Switch
                            error={touched.event_type_coupon_form_allowed && errors.event_type_coupon_form_allowed}
                            inputName="event_type_coupon_form_allowed"
                            displayName="Veri Girişi sırasında 'KUPON' formu gösterilsin mi?"
                            isSubmitting={isSubmitting}
                            value={values.event_type_coupon_form_allowed}
                            onChange={(e) => {

                                const val = e.target.checked;
                                setFieldValue("event_type_coupon_form_allowed", val);
                                setFieldTouched("event_type_coupon_form_allowed");
                            }}
                            onBlur={handleBlur}
                        /> 

                        <FormElements.Switch
                            error={touched.event_type_offline_form && errors.event_type_offline_form}
                            inputName="event_type_offline_form"
                            hint="Daha önceden toplanmış verilerin (ıslak imzası bulunan müşterilerin) girilmesine izin veren opsiyonu açar."
                            displayName="Veri Girişi 'OFFLINE' yapılabilir mi?"
                            isSubmitting={isSubmitting}
                            value={values.event_type_offline_form}
                            onChange={(e) => {

                                const val = e.target.checked;
                                setFieldValue("event_type_offline_form", val);
                                setFieldTouched("event_type_offline_form");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.Switch
                            error={touched.event_type_single_participation && errors.event_type_single_participation}
                            inputName="event_type_single_participation"
                            hint="Ör: Yılbaşı Çekilişi gibi tek katılımlı veri girişleri için, müşteri birden fazla katılım yapmak isterse 'daha önce katıldınız' mesajı alacaktır."
                            displayName="Sadece tek katılıma izin ver"
                            isSubmitting={isSubmitting}
                            value={values.event_type_single_participation}
                            onChange={(e) => {

                                const val = e.target.checked;
                                setFieldValue("event_type_single_participation", val);
                                setFieldTouched("event_type_single_participation");
                            }}
                            onBlur={handleBlur}
                        />

                        <FormElements.TextArea
                            type="text"
                            autoComplete="off"
                            error={touched.event_type_exp && errors.event_type_exp}
                            inputName="event_type_exp"
                            displayName="Açıklama"
                            isSubmitting={isSubmitting}
                            value={values.event_type_exp || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            hint={`Bu müşteri etiketini, ${uiDomain} adresini kullanan diğer kullanıcıların anlaması içindir. İstediğiniz notu, bilgiyi girebilirsiniz.`}
                        />

                        <FormElements.Submit
                            submitClasses={["full-width"]}
                            submitText={this.props.submitText}
                            isSubmitting={isSubmitting}
                        />

                    </form>
                )}
            />
        );
    }
}


