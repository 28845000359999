import React from "react";
import PropTypes from "prop-types";

export default class FilterRadio extends React.PureComponent {

    static propTypes = {
        inputName: PropTypes.string.isRequired,
        filters: PropTypes.arrayOf(PropTypes.shape()),
        selectedFilter: PropTypes.string,
        onOptionSelected: PropTypes.func.isRequired
    };

    render() {

        /*
            filter:
            {
                name: "İçeren",
                type: "contains",
                input: ReactElement <-> undefined
            }
         */
        const options = this.props.filters.map((filter, index) => (
            <div key={filter.name}>
                <div style={{float: "left", marginBottom: "0.5rem"}}>
                    <input
                        type="radio"
                        className="is-checkradio is-info"
                        value={filter.type}
                        name={this.props.inputName}
                        id={`${this.props.inputName}_${index}`}
                        checked={filter.type === this.props.selectedFilter}
                        onChange={(e) => {

                            const val = e.target.value;
                            this.props.onOptionSelected(val);
                        }}
                    />
                    <label className="label" htmlFor={`${this.props.inputName}_${index}`}>
                        {filter.name}
                    </label>
                </div>

                {
                    filter.input && (this.props.selectedFilter === filter.type) ? (
                        <div className="filterValue">
                            {filter.input}
                        </div>
                    ) : null
                }
            </div>
        ));

        return (

            <div className="field">
                <div style={{display: "flex", flexDirection: "column"}}>
                    {options}
                </div>
            </div>
        );
    }
}
