import React from "react";
import PropTypes from "prop-types";
import formatDatetime from "../../../services/utils/format_datetime";

export default class CustomerHistory extends React.Component {

    static get propTypes() {
        return {
            customer: PropTypes.object.isRequired
        };
    }

    render() {

        const {customer_histories} = this.props.customer;

        console.log("customer_histories: ", customer_histories);

        return (
            <div className="tabContainer customerActivities">
                <div className="timelineSection">
                    <div className="timelineLine"/>
                    {
                        customer_histories.map(({version, date, changes}) => {

                            return (
                                <div key={version} className="timelineItem">
                                    <div className="timelineIcon">
                                        <span><i className="fa fa-edit"/></span>
                                    </div>
                                    <div className="timelinePreHeader">{formatDatetime(date)}</div>
                                    <div className="timelineContent">
                                        {
                                            changes && changes.map((text) => (<p key={`${version}${text}`}>{text}</p>))
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}
