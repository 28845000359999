/**
 * CustomerFilter component
 */

import React, {Component} from "react";
import PropTypes from "prop-types";
import Collapsible from "react-collapsible";
import {toast as showToast} from "react-toastify";

import FilterModal from "./FilterModal/FilterModal";
import {getFilterOptions} from "./filterDefinitions";
import FilterSchema from "./filterSchema";

export default class CustomerFilter extends Component {

    static propTypes = {
        filters: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        getTitle: PropTypes.func.isRequired,
        addFilter: PropTypes.func.isRequired,
        removeFilter: PropTypes.func.isRequired,
        isCollapsable: PropTypes.bool,
    };

    filterSchema = FilterSchema();

    state = {
        isFilterModalOpen: false
    };

    openFilterModal = (e) => {

        if (this.props.filters.length > 5) {
            showToast("Maksimum 5 adet filtre oluşturabilirsiniz.", {
                autoClose: 3000,
                type: "error"
            });
            return;
        }

        e.preventDefault();
        this.setState((prevState) => ({
            ...prevState,
            isFilterModalOpen: true
        }));
    };

    filterCreated = ({filter, schema}) => {

        this.setState((prevState) => ({
            ...prevState,
            isFilterModalOpen: false
        }));

        this.props.addFilter({
            display: schema.display,
            field: schema.field,
            type: filter.type,
            value: filter.value,
            label: filter.label
        });
    };

    closeFilterModal = () => {

        this.setState((prevState) => ({
            ...prevState,
            isFilterModalOpen: false
        }));
    };

    removeFilter = (index) => {

        this.props.removeFilter(index);
    };

    getCustomerFilterBlock() {

        return (
            <>
                <div className="customerFilterList columns is-multiline">

                    <div className="column is-4-fullhd is-6-desktop is-6-tablet is-12-mobile">
                        <div className="customerFilterItem box" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <a href="#" onClick={this.openFilterModal} style={{padding: "1rem"}}>
                                <span className="icon is-small">
                                    <i className="fas fa-plus"/>
                                </span>
                                <span style={{marginLeft: "0.5rem"}}>Yeni Filtre Ekleyin</span>
                            </a>
                        </div>
                    </div>
                    {
                        this.props.filters.map((filterItem, index) => (

                            <div key={filterItem.display} className="column is-4-fullhd is-6-desktop is-6-tablet is-12-mobile">
                                <div className="customerFilterItem box">
                                    <button
                                        className="delete is-small"
                                        type="button"
                                        onClick={() => {
                                            this.removeFilter(index);
                                        }}
                                    />
                                    <h5>{filterItem.display}</h5>
                                    <p>{getFilterOptions(filterItem)}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    this.state.isFilterModalOpen ? (
                        <FilterModal
                            filterSchema={this.filterSchema}
                            filters={this.props.filters}
                            onClose={this.closeFilterModal}
                            onSubmit={this.filterCreated}
                        />
                    ) : null
                }
            </>

        );
    }

    render() {


        return (
            <div className="customerFilter">
                {
                    this.props.isCollapsable ? (

                        <Collapsible
                            classParentString={this.props.filters.length > 0 ? "filterActive Collapsible" : "Collapsible"}
                            trigger={(
                                <a href="#">
                                    {this.props.getTitle()}
                                    <span className="floatRight icon is-small">
                                        <i className="fa fa-angle-down"/>
                                    </span>
                                </a>
                            )}
                            triggerWhenOpen={
                                <a href="#">
                                    {this.props.getTitle()}
                                    <span className="floatRight icon is-small">
                                        <i className="fa fa-angle-up"/>
                                    </span>
                                </a>
                            }
                            easing="ease-in-out">
                            {
                                this.getCustomerFilterBlock()
                            }

                        </Collapsible>

                    ) : (
                        <div className="filterActive Collapsible">
                            <span className="filterActive Collapsible__trigger is-open">
                                <a href="#">
                                    {this.props.getTitle()}
                                </a>
                            </span>
                            <div className="Collapsible__contentInner">
                                {
                                    this.getCustomerFilterBlock()
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}
