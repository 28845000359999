import React, {useState, useEffect} from "react";
import {FormElements, CrmTable} from "../../components";
import withContext from "../../context/withContext";
import CustomerImportKeys from "../../../../common/customer_import_keys";
import * as Yup from "yup";
import _ from "lodash";
import {Formik} from "formik";
import Moment from "moment-timezone";
import apiCall from "../../services/api";

const getCustomerTableColumns = () => {
    return CustomerImportKeys.map((item) => ({
        Header: item.display,
        accessor: item.schema_field
    }));
};

const getSelectOptions = () => CustomerImportKeys.map((item) => ({label: item.display, value: item.schema_field}));

const CustomerImport = ({
    authContext,
    setError,
    loadingContext,
    setBreadcrumb
}) => {
    const [file, setFile] = useState(null);
    const [validCustomers, setValidCustomers] = useState(null);
    const [invalidCustomers, setInvalidCustomers] = useState(null);
    const [csvHeaders, setCsvHeaders] = useState({});
    const [csvHeaderWarnings, setCsvHeaderWarnings] = useState(null);
    const [tableData, setTableData] = useState([]);

    const [isSystemAdmin] = useState(authContext.admin.admin_role === "SystemAdmin");
    const [isHeadersParsed, setIsHeadersParsed] = useState(false);
    const [isParsingComplete, setIsParsingComplete] = useState(false);


    useEffect(() => {
        setBreadcrumb([{
            url: "/customer-import",
            name: "Müşteri Aktarımı"
        }]);
    }, []);

    const exportInvalidCustomersAsync = async (invalids) => {
        try {
            if (!invalids || invalids.length === 0) {
                throw new Error("Geçersiz kayıt yok");
            }

            const fileName = `${Moment().tz("Asia/Istanbul").format("DD-MM-YYYY_HH-mm-ss")}_gecersiz_musteri_listesi.xlsx`;

            await apiCall({
                url: "/csv-import/exportInvalids",
                method: "POST",
                payload: JSON.stringify(invalids),
                options: {
                    responseType: "arraybuffer",
                },
                headers: {
                    "Content-Disposition": "application/json"
                },
                download: true,
                filename: fileName
            });
        }
        catch (e) {
            setError(e);
        }
    };

    const saveCustomersAsync = async (payload, {setSubmitting}) => {

        // Start loading
        setSubmitting(true);
        loadingContext.setIsLoading(true);

        try {
            await apiCall({
                url: "/csv-import/importCustomers",
                method: "POST",
                payload: payload.validCustomers,
                toast: `${payload.validCustomers.length} müşteri kayıt edildi`
            });
        }
        catch (error) {
            setError(error);
        }

        // Stop loading
        setSubmitting(false);
        loadingContext.setIsLoading(false);
    };

    const parseHeadersAsync = async (payload, {setSubmitting}) => {

        // Start loading
        setSubmitting(true);
        loadingContext.setIsLoading(true);

        setFile(payload.csv_file);

        try {
            const formData = new FormData();
            formData.append("file", payload.csv_file);

            const {data} = await apiCall({
                url: "/csv-import/parseHeaders",
                method: "POST",
                payload: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            setCsvHeaders(data.mappedHeaders);

            if (!isSystemAdmin) {
                if (data.warnings && data.warnings.length > 0) {
                    setCsvHeaderWarnings(data.warnings);
                    throw new Error("Yüklenen CSV dosyasının sütun başlıkları uyumlu değil");
                }

                // eslint-disable-next-line no-use-before-define
                processCsvAsync({
                    csvHeaders: data.mappedHeaders,
                    csv_file: payload.csv_file
                });
            }

            setCsvHeaderWarnings(data.warnings);
            setIsHeadersParsed(true);
        }
        catch (e) {
            setIsHeadersParsed(false);
            setError(e);
        }

        // Stop loading
        setSubmitting(false);
        loadingContext.setIsLoading(false);
    };

    const processCsvAsync = async (payload, formikBag) => {

        // Start loading
        if (formikBag) {
            formikBag.setSubmitting(true);
        }
        loadingContext.setIsLoading(true);

        const formData = new FormData();
        formData.append("file", file || payload.csv_file);
        formData.append("mappedHeaders", JSON.stringify(payload.csvHeaders));

        try {
            const {data} = await apiCall({
                url: "/csv-import/parseRows",
                method: "POST",
                payload: formData,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            setValidCustomers(data.validCustomers);
            setInvalidCustomers(data.invalidCustomers);
            setTableData(data.validRecords);
        }
        catch (error) {
            console.log(error);
            setError(error);
        }

        setIsParsingComplete(true);

        if (formikBag) {
            formikBag.setSubmitting(false);
        }
        loadingContext.setIsLoading(false);
    };

    const _handleParseHeaders = async (formData, formikBag) => {
        parseHeadersAsync(formData, formikBag);
    };

    const _handleProcessCsv = async (formData, formikBag) => {
        processCsvAsync(formData, formikBag);
    };

    const _handleSaveCustomer = async (formData, formikBag) => {
        saveCustomersAsync(formData, formikBag);
    };

    const _handleExportInvalid = async () => {
        exportInvalidCustomersAsync(invalidCustomers);
    };

    if (!isSystemAdmin && csvHeaderWarnings && csvHeaderWarnings.length > 0) {
        return csvHeaderWarnings && <FormElements.FormExplanation messages={[
            ...csvHeaderWarnings
        ]}></FormElements.FormExplanation>;
    }

    return (

        <div className="dashboard">

            {!isHeadersParsed &&

                <div className="columns is-centered">

                    <div className="column is-three-fifths-fullhd is-four-fifths-desktop">

                        <div className="box knsForm">
                            <div className="content">
                                <h5>
                                    <span className="icon is-small">
                                        <i className="fa fa-angle-double-right"/>
                                    </span>
                                    <span>
                                                Müşteri verisi aktarma
                                    </span>
                                </h5>
                                <FormElements.FormExplanation messages={[
                                    `Dosyada bulunması gereken sütun başlıkları;
                                            ad | soyad | cinsiyet | telefon | eposta | kvkk_izni | arama_izni | sms_izni | eposta_izni | kvkk_izin_tarihi | arama_izin_tarihi | sms_izin_tarihi | eposta_izin_tarihi`,
                                    "İzinler 'evet' veya 'hayir' olarak belirtilmelidir",
                                    "Cinsiyet 'erkek', 'kadin' veya 'bilinmiyor' olarak belirtilmelidir",
                                    "Tarihler 'YYYY-AA-GG' formatında olmalıdır ( 2021-08-20 )",
                                    "Telefon numaraları +90 alan kodu ile başlamalıdır",
                                ]}
                                />
                            </div>

                            <Formik
                                initialValues={{csv_file: ""}}
                                onSubmit={_handleParseHeaders}
                                validationSchema={() => Yup.object().shape({
                                    csv_file: Yup.string()
                                        .required("Dosyayı Seçiniz zorunlu bir alandır."),
                                })}
                                render={({
                                    values,
                                    errors,
                                    setFieldValue,
                                    setFieldTouched,
                                    setSubmitting,
                                    isSubmitting,
                                    handleSubmit,
                                }) => (
                                    <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                        <FormElements.FileInputWithoutUpload
                                            inputName="csv_file"
                                            displayName="Müşteri İzin Dosyası CSV*"
                                            value={values.csv_file || ""}
                                            extensions={["txt", "csv"]}
                                            isLoading={isSubmitting}
                                            mimeTypes={["text/plain", "text/csv", "application/vnd.ms-excel"]}
                                            setFile={(selectedFile) => {
                                                setFieldValue("csv_file", selectedFile);
                                                setFieldTouched("csv_file", true);
                                            }}
                                            setError={(error) => {
                                                console.error(error);
                                            }}

                                            uploadInProgress={setSubmitting}
                                            error={errors["csv_file"]}
                                        />

                                        <FormElements.Submit
                                            submitClasses={["full-width"]}
                                            submitText="Yükle"
                                            isSubmitting={isSubmitting}
                                        />
                                    </form>
                                )}
                            />
                        </div>
                    </div>
                </div>
            }
            { isSystemAdmin && isHeadersParsed && !isParsingComplete &&
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                                Müşteri CSV Düzenleme (systemAdmin)
                                </span>
                            </h5>
                            <span className="is-danger">
                                {" "}
                                Müşteriden gelen CSV dosyasında, eğer müşteri önerilen sütun başlıklarına
                                uymuş ise, sağ taraftaki ilgili alanlar seçili gelecektir. Eğer müşteri bir yazım yanlışı yapmış, önerilenlere uymamış, veya başlığı boş bırakmış
                                ise, ilgili alan listeden manuel olarak seçilip devam edilebilir. İlgili alanı boş bırakılan sütunlar yok sayılacaktır.
                                {" "}
                            </span>

                            {
                                csvHeaderWarnings && <FormElements.FormExplanation messages={[
                                    ...csvHeaderWarnings
                                ]}></FormElements.FormExplanation>
                            }
                        </div>
                        <Formik
                            initialValues={{
                                csvHeaders,
                            }}
                            onSubmit={_handleProcessCsv}
                            render={({
                                values,
                                errors,
                                setFieldValue,
                                setFieldTouched,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <div style={{width: "100%"}}>
                                            {
                                                values.csvHeaders && values.csvHeaders.map(({csv_field}, i) => (
                                                    <FormElements.TextInput
                                                        key={i}
                                                        type="text"
                                                        disabled
                                                        onChange={() => {}}
                                                        inputName={csv_field}
                                                        value={csv_field || ""}
                                                    />
                                                ))
                                            }
                                        </div>
                                        <div style={{width: "500px"}}>
                                            {
                                                values.csvHeaders.map(({display, schema_field}, i) => (
                                                    <FormElements.SelectInput
                                                        defaultValue={{label: display, value:schema_field}}
                                                        key={`input${i}`}
                                                        isSearchable={true}
                                                        isClearable
                                                        inputName={display}
                                                        options={getSelectOptions()}
                                                        placeholder="Choose relative Key"
                                                        onChange={(selectedOption) => {
                                                            setFieldValue(`csvHeaders.${i}.schema_field`, (selectedOption && selectedOption.value) || "");
                                                            setFieldTouched(`csvHeaders.${i}.display`);
                                                        }}
                                                        setError={(error) => {
                                                            console.error(error);
                                                        }}
                                                        error={errors[`csvHeaders.${i}.display`]}
                                                    />
                                                ))
                                            }
                                        </div>
                                    </div>

                                    <FormElements.Submit
                                        submitClasses={["full-width"]}
                                        submitText="Devam Et"
                                        isSubmitting={isSubmitting}
                                    />
                                </form>
                            )}
                        />

                    </div>
                </div>
            </div>
            }
            {
                isParsingComplete &&
                <>
                    <div className="columns is-multiline">
                        <div className="column is-2-desktop is-3-tablet is-6 dashboard-box">
                            <div className="box dashboard-box">
                                <div className="heading" lang="tr">Geçerli</div>
                                <div className="title">{`${validCustomers ? validCustomers.length : 0}`}</div>
                            </div>
                        </div>

                        <div className="column is-2-desktop is-3-tablet is-6 dashboard-box">
                            <div className="box dashboard-box">
                                <div className="heading" lang="tr">Geçersiz</div>
                                <div className="title">{`${invalidCustomers ? invalidCustomers.length : 0}`}</div>
                            </div>
                        </div>

                        <div className="column is-8-desktop is-6-tablet is-12 dashboard-box"
                            style={{display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
                            <Formik
                                initialValues={{
                                    validCustomers
                                }}
                                onSubmit={_handleSaveCustomer}
                                render={({
                                    handleSubmit,
                                    isSubmitting
                                }) => (
                                    <>
                                        <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>
                                            <FormElements.Submit
                                                submitClasses={["full-width"]}
                                                submitText="Geçerli kayıtları ekle"
                                                isSubmitting={isSubmitting}
                                            />
                                        </form>

                                        <button
                                            className="button full-width"
                                            type="button"
                                            tabIndex="0"
                                            disabled={invalidCustomers && invalidCustomers.length === 0}
                                            onClick={() => _handleExportInvalid()}>
                                            Geçersiz kayıtları raporla
                                        </button>
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <CrmTable
                        columns={getCustomerTableColumns()}
                        data={tableData}
                    />
                </>
            }
        </div>
    );
};


export default withContext(CustomerImport);
