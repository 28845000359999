import React, {PureComponent} from "react";
import _ from "lodash";
import {Redirect} from "react-router-dom";
import Collapsible from "react-collapsible";

import {RoleAuth} from "../../components";
import withContext from "../../context/withContext";
import PropTypes from "prop-types";
import apiCall from "../../services/api";
import getPrettyNumber from "../../services/utils/get_pretty_number";
import InfoCard from "../../components/Cards/InfoCard";
import ActivityCard from "../../components/Cards/ActivityCard";

class Dashboard extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // Context
        authContext: PropTypes.shape({
            admin: PropTypes.shape(),
        }),

        // layout hoc
        setError: PropTypes.func.isRequired
    };

    state = {

        // API
        data: null,
    };

    componentDidMount() {

        this.getDashboardAsync();
        this.getSmsAccountsAsync();
    }

    async getSmsAccountsAsync() {

        try {

            const {data} = await apiCall({method: "GET", url: "/data/sms-accounts"});

            this.setState((prevState) => ({
                ...prevState,
                smsAccounts: data
            }));
        }
        catch (e) {
            console.log(e);
        }
    }

    async getDashboardAsync() {

        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({url: "/data/dashboard", method: "post"});

            this.setState((prevState) => ({
                ...prevState,
                data
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsLoading(false);
    }

    renderCustomer() {

        if (!this.state.data) {
            return null;
        }

        const {
            dashboard_total_customers,
            dashboard_total_male_customers,
            dashboard_total_female_customers,
            dashboard_total_customers_with_email_and_permission,
            dashboard_total_customers_with_phone_and_permission
        } = this.state.data;

        return (
            <>
                <h3 className="sectionTitle">Müşteri</h3>
                <div className="tile is-ancestor">
                    <div className="tile is-parent">
                        <InfoCard
                            title="Toplam"
                            icon="fas fa-user-friends"
                            value={dashboard_total_customers}
                            hasTextClass="has-text-success"
                        />
                    </div>
                    <div className="tile is-parent">
                        <InfoCard
                            title="Kadın"
                            icon="fas fa-female"
                            value={dashboard_total_female_customers}
                        />
                    </div>
                    <div className="tile is-parent">
                        <InfoCard
                            title="Erkek"
                            icon="fas fa-male"
                            value={dashboard_total_male_customers}
                        />
                    </div>
                    <div className="tile is-parent">
                        <InfoCard
                            title="E-posta"
                            icon="fas fa-envelope"
                            value={dashboard_total_customers_with_email_and_permission}
                        />
                    </div>
                    <div className="tile is-parent">
                        <InfoCard
                            title="Telefon"
                            icon="fas fa-comment"
                            value={dashboard_total_customers_with_phone_and_permission}
                        />
                    </div>
                </div>
            </>
        );
    }

    renderActivity() {

        const {dashboard_total_tag_customers} = this.state.data;

        if (!dashboard_total_tag_customers.length) {
            return;
        }

        return (

            <Collapsible trigger={
                <h3 className="sectionTitle">Müşteri Etiketleri</h3>
            }>
                <div className="columns is-multiline has-text-centered is-multiline">
                    {
                        _.map(dashboard_total_tag_customers, (tagData) => (
                            <ActivityCard
                                key={tagData._id}
                                title={tagData.name}
                                value={getPrettyNumber(tagData.count)}
                            />
                        ))
                    }
                </div>
            </Collapsible>
        );
    }

    renderEventTypes() {

        const {dashboard_total_event_customers} = this.state.data;

        if (!dashboard_total_event_customers.length) {
            return;
        }

        return (
            <Collapsible trigger={
                <h3 className="sectionTitle">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                Veri Giriş Yerleri
                            </div>
                        </div>
                    </div>
                </h3>
            }>
                <div className="columns is-multiline has-text-centered is-multiline">
                    {
                        _.map(dashboard_total_event_customers, (eventData) => (
                            <ActivityCard
                                key={eventData._id}
                                title={eventData.name}
                                value={getPrettyNumber(eventData.count)}
                                tag="Veri Giriş Yeri / Aktivite"
                            />
                        ))
                    }
                </div>
            </Collapsible>
        );
    }

    renderSmsAccount(title, accounts) {

        if (!accounts.otp && !accounts.transactional && !accounts.bulk) {
            return;
        }

        const accountTitles = [
            {
                key: "otp",
                title: "OTP",
                exp: "Mobile App & Wifi girişlerinde iletilen 4 haneli şifre için kullanılıyor. Hızlı ve anında iletim sağlıyor."
            },
            {
                key: "transactional",
                title: "Transactional",
                exp: "Öncelik gerektirmeyen, rezervasyon onayı vs. gibi tekli iletiler için kullanılıyor."
            },
            {
                key: "bulk",
                title: "Bulk",
                exp: "Toplu SMS Gönderimi için kullanılmaktadır."
            }
        ];

        return (
            <Collapsible trigger={
                <h3 className="sectionTitle">{title}</h3>
            }>

                <div className="columns is-multiline has-text-centered is-multiline">
                    {
                        _.map(accountTitles, (item) => {

                            if (!accounts[item.key]) {
                                return null;

                            }

                            const {username, balance} = accounts[item.key];

                            return (
                                <ActivityCard
                                    key={`${item.key}-${accounts[item.key]}`}
                                    // title={`${item.title}: ${_.get(accounts, `${item.key}.username`)}`}
                                    title={
                                        <>
                                            <span>{username}</span>
                                            <br/>
                                            <b>{item.title}</b>
                                        </>
                                    }
                                    value={`${getPrettyNumber(balance)} Kredi`}
                                    exp={item.exp}
                                />
                            );
                        })
                    }
                </div>
            </Collapsible>
        );
    }

    renderSmsAccounts() {

        const {smsAccounts} = this.state;

        if (!smsAccounts) {
            return;
        }

        const {infobip, postaGuvercini} = smsAccounts;

        return (
            <>
                {
                    infobip &&
                    this.renderSmsAccount("SMS (Infobip) Hesapları", infobip)
                }
                {
                    postaGuvercini &&
                    this.renderSmsAccount("SMS (Posta Güvercini) Hesapları", postaGuvercini)
                }
            </>
        );
    }

    render() {

        return (

            <>
                <RoleAuth admin={this.props.authContext.admin} maxRole="CrmDataCollector">
                    <Redirect to="/customer-entry"/>
                </RoleAuth>

                <div className="dashboard">
                    {
                        this.state.data &&
                        <>
                            {this.renderCustomer()}
                            {this.renderActivity()}
                            {this.renderEventTypes()}
                            {/*{this.renderFeedbackTagTypes()}*/}
                        </>
                    }
                    {this.renderSmsAccounts()}
                </div>
            </>
        );
    }
}

export default withContext(Dashboard);
