/**
 * CustomerTagAdd Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import EventTypeForm from "./EventTypeForm";

class EventTypeEdit extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        eventType: null
    };

    eventTypeId = this.props.match.params["eventtypeid"];

    componentDidMount() {

        this.getEventTypeAsync(); // Update subheader statically
    }

    async getEventTypeAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/data/event-types/${this.eventTypeId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                eventType: data
            }));

            this.props.setBreadcrumb([{
                url: "/data/event-types",
                name: "Veri Giriş Yerleri"
            }, {
                url: `/data/event-types/${this.eventTypeId}/edit`,
                name: data.event_type_name
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    async updateEventTypeAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/data/event-types/${this.eventTypeId}`,
                method: "PUT",
                payload,
                toast: `Aktivite tipi güncelledi: ${payload.event_type_name}`
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/data/event-types");
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.updateEventTypeAsync(formData, formikBag);
    };

    render() {

        if (!this.state.eventType) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    Veri Giriş Yeri Güncelleme
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "Veri giriş yeri ismi sadece ingilizce karakterler, kısa çizgi, boşluk ve rakam içerebilir.",
                                "Veri giriş yeri ismi sistem genelinde benzersiz olmalıdır, aynı isme sahip ikinci bir veri giris yeri ekleyemezsiniz.",
                                "Örnek isimler: 'UGUR BOCEGI ILE KARA KEDI - OCAK 2020', 'GUPSE OZAY ILE SOYLESI - ARALIK 2019', 'WIFI - LOGIN' vs.",
                                "Tüm kurallar sistemde tanımlamıştır, aşağıda test edebilir, hata almadığınız her değeri girebilirsiniz."
                            ]}
                            />
                        </div>
                        <EventTypeForm
                            eventType={this.state.eventType}
                            handleSubmit={this.handleSubmit}
                            submitText="Güncelle"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(EventTypeEdit);


