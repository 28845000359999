/**
 *  component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import ReactSelect, {createFilter} from "react-select";

import InlineError from "../InlineError/InlineError";
import Hint from "../Hint/Hint";

export default class SelectInput extends PureComponent {

    static defaultProps = {

        customFilter: {
            ignoreCase: true,
            ignoreAccents: true,
            matchStart: true
        }
    };

    static propTypes = {
        inputName: PropTypes.string.isRequired,
        displayName: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
        isDisabled: PropTypes.bool,
        placeholder: PropTypes.string,
        value: PropTypes.any,
        hint: PropTypes.string,
        error: PropTypes.string,
        hintAsLabelExplanation: PropTypes.bool,

        isMulti: PropTypes.bool,
        isClearable: PropTypes.bool,
        isSearchable: PropTypes.bool,

        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
        customFilter: PropTypes.shape({
            ignoreCase: PropTypes.bool,
            ignoreAccents: PropTypes.bool,
            matchStart: PropTypes.bool,
            trim: PropTypes.bool,
        })
    };

    getSelectedOption = (value) => {

        if (Array.isArray(value)) {
            return this.props.options.filter((option) => {
                return value.find((valueItem) => valueItem.value === option.value);
            });
        }

        return this.props.options.find((option) => {
            return option.value === value;
        });
    };

    handleChange = (value) => {
        // this is going to call setFieldValue and manually update values.{inputName}
        this.props.onChange(value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.{inputName}
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    };

    render() {

        return (
            <div className={`field ${this.props.error ? "is-danger" : ""}`}>
                {
                    this.props.displayName &&
                    <label className="label" htmlFor={this.props.inputName}>
                        <span>
                            {this.props.displayName}
                        </span>
                        <Hint hint={this.props.hint} hintAsLabelExplanation={this.props.hintAsLabelExplanation}/>
                    </label>
                }

                <ReactSelect
                    {...this.props}
                    value={this.getSelectedOption(this.props.value)}
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "Sonuç bulunamadı"}
                    placeholder={this.props.placeholder || this.props.displayName}
                    filterOption={createFilter(this.props.customFilter)}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                />

                <InlineError error={this.props.error}/>

            </div>
        );
    }
}
