import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {Formik} from "formik";
import {Redirect} from "react-router-dom";

import {uiTitle} from "../../../config.json";
import {ApiError, FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import {upperCaseText} from "../../../services/utils/text_transforms";

class Register extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),
        authContext: PropTypes.shape({
            clearAdmin: PropTypes.func.isRequired,
        }),

        // React router
        location: PropTypes.shape({
            state: PropTypes.shape()
        }),
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        // API,
        inviteError: null,
        registerError: null,
        adminInvite: null,
        isInviteCheckCompleted: false,
        isRegisterSuccess: false,
    };

    params = this.props.match.params;

    constructor(props) {

        super(props);

        this.props.authContext.clearAdmin();
        this.validateTokenAsync();
    }

    async validateTokenAsync() {

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/invites/check/${this.params.token}`,
                withAuth: false
            });

            this.setState((prevState) => ({
                ...prevState,
                adminInvite: data
            }));

        }
        catch (e) {

            this.setState((prevState) => ({
                ...prevState,
                inviteError: e
            }));
        }

        this.setState((prevState) => ({
            ...prevState,
            isInviteCheckCompleted: true
        }));
    }

    async registerAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);

        // Reset State
        this.setState((prevState) => ({
            ...prevState,
            registerError: null
        }));

        // API REQUEST
        try {

            await apiCall({
                url: "/auth/register",
                method: "POST",
                payload,
                withAuth: false
            });

            this.setState((prevState) => ({
                ...prevState,
                isRegisterSuccess: true
            }));
        }
        catch (e) {

            this.setState((prevState) => ({
                ...prevState,
                registerError: e
            }));
        }

        // STOP LOADING
        setSubmitting(false);
    }

    handleSubmit = (formData, formikBag) => {

        const payload = {
            ...formData,
            admin_invite_token: this.params.token
        };

        this.registerAsync(payload, formikBag);
    };

    render() {

        if (!this.state.isInviteCheckCompleted) {
            // If isValidateTokenLoading do not show anything yet.
            return null;
        }


        if (this.state.inviteError) {
            return (
                <ApiError error={this.state.inviteError}/>
            );
        }

        if (this.state.isRegisterSuccess) {
            return (
                <Redirect to={{
                    pathname: "/login",
                    state: {
                        successMsg: "Başarıyla kaydoldunuz, şimdi sisteme e-posta adresiniz ve belirlediğiniz şifreniz ile giriş yapabilirsiniz."
                    }
                }}
                />
            );
        }
        return (

            <>

                <h1 className="title is-4">{`${uiTitle} - Kayıt`}</h1>

                {
                    this.state.registerError ? (
                        <ApiError error={this.state.registerError} clearError={() => this.setState((prevState) => ({...prevState, registerError: null}))}/>
                    ) : (
                        <div className="notification" style={{padding: "1rem"}}>
                            CRM üzerinde yapacağınız işlemler e-posta adresiniz ile ilişkilendirilmektedir.
                        </div>
                    )
                }

                <Formik
                    initialValues={{
                        admin_email: this.state.adminInvite.admin_invite_email,
                        admin_name: "",
                        admin_surname: "",
                        admin_password: "",
                        admin_password_again: ""
                    }}
                    onSubmit={this.handleSubmit}
                    validationSchema={() => Yup.object().shape({
                        admin_email: Yup.string()
                            .email("Geçersiz bir e-posta adresi.")
                            .required("E-posta zorunlu bir alan."),
                        admin_name: Yup.string()
                            .min(2, "İsim bilgisi en az 2 karakter içermelidir.")
                            .required("İsim bilgisi zorunludur."),
                        admin_surname: Yup.string()
                            .min(2, "Soyisim bilgisi en az 2 karakter içermelidir.")
                            .required("Soyisim bilgisi zorunludur."),
                        admin_password: Yup.string()
                            .min(6, "Şifreniz en az 6 karakter içermelidir.")
                            .required("Şifre zorunlu bir alan."),
                        admin_password_again: Yup.string()
                            .required("Şifre (tekrar) zorunlu bir alan.")
                            .oneOf([Yup.ref("admin_password")], "Şifreler uyuşmuyor.")
                    })}
                    render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldTouched,
                        isSubmitting
                    }) => (
                        <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit}>

                            <FormElements.TextInput
                                disabled
                                type="email"
                                autoComplete="off"
                                error={touched.admin_email && errors.admin_email}
                                inputName="admin_email"
                                displayName="E-posta"
                                isSubmitting={isSubmitting}
                                value={values.admin_email || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                error={touched.admin_name && errors.admin_name}
                                inputName="admin_name"
                                displayName="İsim"
                                isSubmitting={isSubmitting}
                                value={values.admin_name || ""}
                                onChange={(e) => {

                                    const val = e.target.value;
                                    setFieldValue("admin_name", upperCaseText(val));
                                    setFieldTouched("admin_name");
                                }}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                error={touched.admin_surname && errors.admin_surname}
                                inputName="admin_surname"
                                displayName="Soyisim"
                                isSubmitting={isSubmitting}
                                value={values.admin_surname || ""}
                                onChange={(e) => {

                                    const val = e.target.value;
                                    setFieldValue("admin_surname", upperCaseText(val));
                                    setFieldTouched("admin_surname");
                                }}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="password"
                                autoComplete="off"
                                error={touched.admin_password && errors.admin_password}
                                inputName="admin_password"
                                displayName="Şifre"
                                isSubmitting={isSubmitting}
                                value={values.admin_password || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.TextInput
                                type="password"
                                autoComplete="off"
                                error={touched.admin_password_again && errors.admin_password_again}
                                inputName="admin_password_again"
                                displayName="Şifre (Tekrar)"
                                isSubmitting={isSubmitting}
                                value={values.admin_password_again || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <FormElements.Submit
                                submitClasses={["full-width"]}
                                submitText="Kaydol"
                                isSubmitting={isSubmitting}
                            />
                        </form>
                    )}
                />
            </>
        );
    }
}

export default withContext(Register);
