
/**
 * SmsNotification Form Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import * as Yup from "yup";
import Moment from "moment-timezone";

import {CustomerFilter, FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import dateCheck from "../../../services/utils/date_check";
import apiCall from "../../../services/api";
import preDefinedSMSFilters from "../../../../../common/pre_defined_sms_filters";
import preDefinedSMSText from "../../../../../common/pre_defined_sms_blacklist_text";
import {previewForLatin5} from "../../../services/utils/text_transforms";

class SmsNotificationForm extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired,
            setIsFullScreenLoading: PropTypes.func.isRequired
        }),

        setError: PropTypes.func.isRequired,

        smsNotification: PropTypes.shape().isRequired,
        submitText: PropTypes.string.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        isEdit: PropTypes.bool
    };

    state = {
        filters: this.props.smsNotification.sms_notification_filters || [],
        filteredCustomerCount: 0,
        isSubmitting: false
    };

    componentDidMount() {

        this.getFilteredCustomerCount();
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.filters !== this.state.filters) {
            this.getFilteredCustomerCount();
        }
    }

    getFilterText = () => {

        if (!this.state.filters || this.state.filters.length === 0) {

            return (
                // eslint-disable-next-line
                <span>SMS Filtresi <strong>Aktif</strong>. Tahmini gönderim yapılacak müşteri sayısı: <strong>{this.state.filteredCustomerCount}</strong></span>
            );
        }

        return (
            // eslint-disable-next-line
            <span>SMS Filtresi ve Özelleştirilmiş Filtre <strong>Aktif</strong>. Tahmini gönderim yapılacak müşteri sayısı: <strong>{this.state.filteredCustomerCount}</strong></span>
        );
    };

    async getFilteredCustomerCount() {

        this.props.setError(null);
        this.props.loadingContext.setIsFullScreenLoading(true);

        const filter = [...this.state.filters, ...preDefinedSMSFilters];

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: "/customers/count",
                method: "POST",
                payload: {
                    filter
                }
            });

            this.setState((prevState) => ({
                ...prevState,
                filteredCustomerCount: data.filteredCustomerCount
            }));
        }
        catch (e) {

            this.props.setError(e);
        }

        this.props.loadingContext.setIsFullScreenLoading(false);
    }

    render() {

        const smsNotification = this.props.smsNotification;
        if (!smsNotification) {
            return null;
        }

        return (
            <>
                <CustomerFilter
                    getTitle={this.getFilterText}
                    filters={this.state.filters}
                    addFilter={(filter) => {

                        this.setState((prevState) => ({
                            ...prevState,
                            filters: [filter, ...prevState.filters]
                        }));
                    }}
                    removeFilter={(filterIndex) => {
                        this.setState((prevState) => ({
                            ...prevState,
                            filters: [
                                ...prevState.filters.slice(0, filterIndex),
                                ...prevState.filters.slice(filterIndex + 1)
                            ]
                        }));
                    }}
                    clearFilters={() => {
                        this.setState((prevState) => ({
                            ...prevState,
                            filters: []
                        }));
                    }}
                />

                <Formik
                    initialValues={{
                        sms_notification_title: smsNotification.sms_notification_title || "",
                        sms_notification_text: smsNotification.sms_notification_text || "",
                        sms_notification_scheduled_for_datetime: smsNotification.sms_notification_scheduled_for_datetime || ""
                    }}
                    onSubmit={(values, formikBag) => {

                        const sms_notification_filters = this.state.filters;

                        this.props.handleSubmit({
                            ...values,
                            sms_notification_filters,
                            sms_notification_potential: this.state.filteredCustomerCount
                        }, formikBag);
                    }}
                    validationSchema={() => Yup.object().shape({
                        sms_notification_title: Yup.string()
                            .min(5, "Gönderim adı en az 5 karakter içermelidir.")
                            .required("Gönderim adı zorunlu bir alandır."),
                        sms_notification_text: Yup.string()
                            .min(10, "SMS İçeriği en az 10 karakter içermelidir.")
                            .required("SMS İçeriği zorunlu bir alandır."),
                        sms_notification_scheduled_for_datetime: Yup.date()
                            .test("dateTimeStructureCheck", "Geçersiz bir tarih girdiniz. GG/AA/YYYY SS:dd formatında olmalıdır.", dateCheck.dateTimeStructureCheck)
                            .test("dateTimeRangeCheck", "Lütfen ileri tarihli bir seçim yapınız.", dateCheck.dateTimeIsInFutureCheck)
                    })}
                    render={({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <form autoComplete="off" className="venuexForm" onSubmit={handleSubmit} style={{marginTop: "1rem"}}>

                            <FormElements.TextInput
                                type="text"
                                autoComplete="off"
                                error={touched.sms_notification_title && errors.sms_notification_title}
                                inputName="sms_notification_title"
                                displayName="Gönderim Adı (Müşteri Görmez, rapor takibi için.)*"
                                isSubmitting={isSubmitting}
                                value={values.sms_notification_title || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                hint="Müşteri Görmez, rapor takibi için"
                            />

                            <FormElements.TextArea
                                type="text"
                                autoComplete="off"
                                error={touched.sms_notification_text && errors.sms_notification_text}
                                inputName="sms_notification_text"
                                displayName="SMS İçeriği *"
                                isSubmitting={isSubmitting}
                                value={values.sms_notification_text || ""}
                                onChange={(e) => {

                                    const val = e.target.value;
                                    setFieldValue("sms_notification_text", val);
                                    setFieldTouched("sms_notification_text");
                                }}
                                onBlur={handleBlur}
                                hint="Sadece ingilizce karakterler kullanabilirsiniz."
                            />

                            {
                                values.sms_notification_text && !errors.sms_notification_text ? (
                                    <div className="smsPreview">
                                        <h5>{`ÖNİZLEME (SMS uzunluk: ${values.sms_notification_text.length + preDefinedSMSText.length + 1})`}</h5>
                                        <p>{previewForLatin5(`${values.sms_notification_text} ${preDefinedSMSText}`)}</p>
                                    </div>
                                ) : null
                            }

                            <FormElements.DateInput
                                disabled={this.props.isEdit}
                                autoComplete="off"
                                includeTime={true}
                                displayName="İleri Tarih İçin Gönderim Ayarla"
                                inputName="sms_notification_scheduled_for_datetime"
                                placeholder="GG/AA/YYYY SS:dd"
                                error={touched.sms_notification_scheduled_for_datetime && errors.sms_notification_scheduled_for_datetime}
                                isSubmitting={isSubmitting}
                                selectedValue={values.sms_notification_scheduled_for_datetime}
                                onChange={(selectedDate) => {
                                    setFieldValue("sms_notification_scheduled_for_datetime", selectedDate);
                                    setFieldTouched("sms_notification_scheduled_for_datetime");
                                }}
                                onBlur={() => {
                                    setFieldTouched("sms_notification_scheduled_for_datetime", true);
                                }}
                                hint="En geç 1 ay sonrası, en erken 1 gün sonrası için gönderim ayarlayabilirsiniz. GG/AA/YYYY SS:dd şeklinde input girişi yapılmalıdır, örnek: 01/01/2019 16:30"
                                minDate={Moment().add(1, "day").toDate()}
                                maxDate={Moment().add(1, "months").toDate()}
                                selectsEnd
                            />

                            <FormElements.Submit
                                submitClasses={["full-width"]}
                                submitText={this.props.submitText}
                                isSubmitting={isSubmitting}
                            />
                        </form>
                    )}
                />
            </>
        );
    }
}


export default withContext(SmsNotificationForm);
