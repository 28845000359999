/**
 * EmailNotification Edit Component
 */

import React, {PureComponent} from "react";
import PropTypes from "prop-types";

import {FormElements} from "../../../components";
import withContext from "../../../context/withContext";
import apiCall from "../../../services/api";
import EmailNotificationForm from "./EmailNotificationForm";

class EmailNotificationEdit extends PureComponent {

    static propTypes = {

        // Context
        loadingContext: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            setIsLoading: PropTypes.func.isRequired
        }),

        // layout hoc
        setBreadcrumb: PropTypes.func.isRequired,
        setError: PropTypes.func.isRequired,

        // react router
        history: PropTypes.shape({
            replace: PropTypes.func
        }),
        match: PropTypes.shape({
            params: PropTypes.objectOf(PropTypes.string)
        })
    };

    state = {
        emailNotification: null
    };

    emailNotificationId = this.props.match.params["emailnotificationid"];

    componentDidMount() {

        this.getEmailNotitificationAsync(); // Update subheader statically
    }

    async getEmailNotitificationAsync() {

        // START LOADING
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            const {data} = await apiCall({
                url: `/marketing/email-notifications/${this.emailNotificationId}`,
                method: "GET"
            });

            this.setState((prevState) => ({
                ...prevState,
                emailNotification: data
            }));

            this.props.setBreadcrumb([{
                url: "/marketing/email-notifications",
                name: "E-posta Gönderimi"
            }, {
                url: `/marketing/email-notifications/${this.emailNotificationId}/edit`,
                name: data.email_notification_subject
            }]);
        }
        catch (e) {

            this.props.setError(e);
        }

        // STOP LOADING
        this.props.loadingContext.setIsLoading(false);
    }

    async updateEmailNotificationAsync(payload, {setSubmitting}) {

        // START LOADING
        setSubmitting(true);
        this.props.loadingContext.setIsLoading(true);

        // API REQUEST
        try {

            await apiCall({
                url: `/marketing/email-notifications/${this.emailNotificationId}`,
                method: "PUT",
                payload,
                toast: `E-posta Gönderimi güncellendi: ${payload.email_notification_subject}`
            });

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.history.replace("/marketing/email-notifications");
        }
        catch (e) {

            // STOP LOADING
            setSubmitting(false);
            this.props.loadingContext.setIsLoading(false);
            this.props.setError(e);
        }
    }

    // Second parameter is formikBag, containing
    // access to formik properties
    handleSubmit = (formData, formikBag) => {

        this.updateEmailNotificationAsync(formData, formikBag);
    };

    render() {

        if (!this.state.emailNotification) {
            return null;
        }

        return (
            <div className="columns is-centered">
                <div className="column is-three-fifths-fullhd is-four-fifths-desktop">
                    <div className="box knsForm">
                        <div className="content">
                            <h5>
                                <span className="icon is-small">
                                    <i className="fa fa-angle-double-right"/>
                                </span>
                                <span>
                                    E-posta Gönderimi Güncelleme
                                </span>
                            </h5>
                            <FormElements.FormExplanation messages={[
                                "KVKK kabul etmiş, E-posta bilgisi olan ve E-posta ile iletişim izni hala devam eden müşteriler pre-filtered gelmektedir.",
                                "İleri tarihli gönderimlerin sonradan tarih bilgisini değiştiremezsiniz, tarih değiştirmek istiyorsanız silip tekrar eklemelisiniz.",
                            ]}
                            />
                        </div>
                        <EmailNotificationForm
                            isEdit
                            setError={this.props.setError}
                            emailNotification={this.state.emailNotification}
                            handleSubmit={this.handleSubmit}
                            submitText="Güncelle"
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default withContext(EmailNotificationEdit);


