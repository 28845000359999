import React from "react";
import PropTypes from "prop-types";
import formatDatetime from "../../../services/utils/format_datetime";

export default class CustomerEvents extends React.Component {
    static get propTypes() {
        return {
            customer: PropTypes.object.isRequired
        };
    }

    render() {
        return (
            <div className="tabContainer customerActivities">
                <div className="timelineSection">
                    <div className="timelineLine"/>
                    {
                        this.props.customer.customer_events
                            .map((customerEvent) => {

                                return (
                                    <div key={customerEvent._id} className="timelineItem">
                                        <div className="timelineIcon">
                                            <span><i className="fa fa-user"/></span>
                                        </div>
                                        <div className="timelinePreHeader">{formatDatetime(customerEvent.updatedAt)}</div>
                                        <div className="timelineContent">
                                            {
                                                customerEvent.customer_event ? <p>{customerEvent.customer_event.event_type_name}</p> : <i>Silinmiş Etkinlik</i>
                                            }
                                        </div>
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        );
    }
}
