/**
 * Backdrop component
 */

import React from "react";
import PropTypes from "prop-types";
import getPrettyNumber from "../../services/utils/get_pretty_number";

const InfoCard = ({title, value, icon, hasTextClass}) => {

    return (
        <div className="box tile is-child">
            <div className="level">
                <div className="level-item has-widget-icon">
                    <div className="is-widget-icon">
                        <span className={`icon ${hasTextClass} is-large`}>
                            <i className={`${icon} fa-2x`}/>
                        </span>
                    </div>
                </div>
                <div className="level-item">
                    <div className="is-widget-label">
                        <h3 className="subtitle is-spaced">
                            {title}
                        </h3>
                        <h1 className="title">
                            <div>{getPrettyNumber(value)}</div>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

InfoCard.propTypes = {
    title: PropTypes.string.isRequired,
    hasTextClass: PropTypes.string,
    value: PropTypes.any.isRequired,
    icon: PropTypes.string
};

InfoCard.defaultProps = {
    hasTextClass: "has-text-info",
};

export default InfoCard;
